<script setup lang="ts">
import { computed } from 'vue';

import { IconSize } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';

import type { SButtonProps, SButtonSlots } from '../../sbutton-types';
import type { ButtonExposed, VButtonEmits } from '../../types';
import { useBaseButton } from '../../composables/useBaseButton';
import { useButtonEmits } from '../../composables/useButtonEmits';

const props = withDefaults(defineProps<SButtonProps>(), {
  kind: 'primary',
  label: '',
  role: 'button',
  size: 'medium',
  tag: Tag.BUTTON,
});
const emits = defineEmits<VButtonEmits>();
const slots = defineSlots<SButtonSlots>();

const isDisabledState = computed(() => props.disabled || props.isLoading || undefined);

const iconSize = computed(() => {
  switch (props.size) {
    case 'xlarge':
      return IconSize.SIZE_32;
    case 'large':
      return IconSize.SIZE_24;
    case 'medium':
      return IconSize.SIZE_20;
    case 'small':
    case 'xsmall':
    default:
      return IconSize.SIZE_16;
  }
});

const {
  mainTag,
  iconComponent,
  isIconOnly,
  isIconRight,
  computedAttributes,
  onTouchStart,
  onTouchEnd,
  focusOnElement,
} = useBaseButton(props, slots);

const {
  click,
  keydown,
  mousedown,
  focus,
  blur,
} = useButtonEmits(emits);

defineExpose<ButtonExposed>({
  focus: focusOnElement,
});
</script>

<template>
  <component v-auto-id="'SButton'"
    :is="tag"
    v-bind="computedAttributes"
    :id="id"
    ref="mainTag"
    :class="{
      [$style.sbutton]: true,
      [$style[`sbutton--size-${size}`]]: size,
      [$style[`sbutton--kind-${kind}`]]: kind,
      [$style['sbutton--icon-only']]: isIconOnly,
      [$style['sbutton--icon-right']]: isIconRight,
      [$style['sbutton--full-width']]: fullWidth,
      [$style['sbutton--is-loading']]: isLoading,
    }"
    :disabled="isDisabledState"
    :role="role"
    @touchstart="onTouchStart"
    @touchend="onTouchEnd"
    @click="click"
    @keydown="keydown"
    @mousedown="mousedown"
    @focus="focus"
    @blur="blur"
  >
    <component
      :is="iconComponent"
      v-if="iconComponent"
      :class="$style.sbutton__icon"
      :size="iconSize"
      :name="iconName"
      kind="custom"
    />
    <slot name="default">
      <span
        v-if="!isIconOnly"
        :class="$style.sbutton__label"
      >
        {{ label }}
      </span>
    </slot>
  </component>
</template>

<style module lang="scss">
.sbutton {
  $self: &;

  @include font-caption1-c2;

  position: relative;
  display: flex;
  gap: $buttonGapBetweenBig;
  align-items: center;
  justify-content: center;
  height: get($buttonSizes, xsmall);
  padding: 0 $buttonPaddingBig;
  color: var(--ColorContent3);
  text-transform: $buttonTextTransformNone;
  text-decoration: none;
  background-color: var(--ColorButton1);
  border: none;
  border-radius: $buttonBorderRadius;

  &__icon {
    flex-shrink: 0;
  }

  &__label {
    display: inherit;
    user-select: none;

    &:first-letter {
      text-transform: $buttonTextTransformUppercase;
    }
  }

  &--icon-only {
    justify-content: center;
    padding: 0;
  }

  &--icon-right {
    flex-direction: row-reverse;
  }

  &--kind {
    @each $kind, $kindProps in $buttonKindProps {
      &-#{$kind} {
        color: get($kindProps, 'color');
        background-color: get($kindProps, 'backgroundColor');
        box-shadow: inset 0 0 0 1px get($kindProps, 'boxShadowColor');

        &#{$self}--is-loading {
          background-color: get($kindProps, 'backgroundColorHover');
        }

        &:focus-visible {
          background-color: get($kindProps, 'backgroundColorHover');
        }

        &:hover:not(#{$self}--is-loading, [disabled]) {
          @include is-app-layout-desktop {
            cursor: pointer;
            background-color: get($kindProps, 'backgroundColorHover');
          }
        }
      }
    }
  }

  &--size {
    &-xsmall {
      gap: $buttonGapBetweenSmall;
      height: get($buttonSizes, xsmall);
      padding: 0 $buttonPaddingSmall;

      &#{$self}--icon-only {
        width: get($buttonSizes, xsmall);
      }
    }

    &-small {
      @include font-body-b4;

      height: get($buttonSizes, small);

      &#{$self}--icon-only {
        width: get($buttonSizes, small);
      }
    }

    &-medium {
      @include font-body-b4;

      height: get($buttonSizes, medium);

      &#{$self}--icon-only {
        width: get($buttonSizes, medium);
      }
    }

    &-large {
      @include font-body-b2;

      height: get($buttonSizes, large);

      &#{$self}--icon-only {
        width: get($buttonSizes, large);
      }
    }
  }

  &--full-width {
    width: 100%;
  }

  &--is-loading {
    cursor: default;
  }

  &[disabled]:not(#{$self}--is-loading) {
    cursor: default;
    opacity: $buttonDisableOpacity;
  }
}
</style>
