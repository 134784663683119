import type { SportEventLiveStatusInfo } from 'web/src/modules/sportline/types';
import { MatchProgressTimeDirection } from 'web/src/modules/sportline/enums';
import { LiveStatusTimeDirection } from 'web/src/modules/sportline/enums/rest';

import getTimeCorrection from './getTimeCorrection';
import LiveStatusParseStrategy from './LiveStatusParseStrategy';
import parseScore from './parseScore';

export default class FullProgressLiveStatusParseStrategy extends LiveStatusParseStrategy {
  getMainTime(): SportEventLiveStatusInfo['matchProgress']['mainTime'] {
    const { fullProgress, createAt } = this.liveStatus;

    if (!fullProgress) {
      return super.getMainTime();
    }

    const { time } = fullProgress;

    if (time?.seconds === undefined) {
      return super.getMainTime();
    }

    if (time?.minutes === undefined) {
      return undefined;
    }

    const correction = getTimeCorrection(createAt, this.getTimeDirection());

    return {
      minutes: time.minutes + correction.minutes,
      seconds: time.seconds + correction.seconds,
    };
  }

  getAdditionalTime(): SportEventLiveStatusInfo['matchProgress']['additionalTime'] {
    const { fullProgress, createAt } = this.liveStatus;

    if (!fullProgress) {
      return super.getAdditionalTime();
    }

    const { time, additionalTime } = fullProgress;

    if (time?.seconds === undefined) {
      return super.getAdditionalTime();
    }

    if (additionalTime?.minutes === undefined && additionalTime?.seconds === undefined) {
      return undefined;
    }

    const correction = getTimeCorrection(createAt, this.getTimeDirection());

    return {
      minutes: (additionalTime.minutes || 0) + correction.minutes,
      seconds: (additionalTime.seconds || 0) + correction.seconds,
    };
  }

  getTimeDirection(): MatchProgressTimeDirection {
    const { fullProgress } = this.liveStatus;

    if (!fullProgress) {
      return super.getTimeDirection();
    }

    if (fullProgress.timeDirection === LiveStatusTimeDirection.Direct) {
      return MatchProgressTimeDirection.Direct;
    }

    if (fullProgress.timeDirection === LiveStatusTimeDirection.Reverse) {
      return MatchProgressTimeDirection.Reversed;
    }

    return MatchProgressTimeDirection.None;
  }

  getCurrentSetScore(): SportEventLiveStatusInfo['matchProgress']['currentSetScore'] {
    const { fullProgress } = this.liveStatus;

    if (!fullProgress) {
      return super.getCurrentSetScore();
    }

    const parsedScore = parseScore(fullProgress.score);
    const [host, guest] = parsedScore?.parts || [];

    return host && guest
      ? {
          host,
          guest,
          delimiter: parsedScore?.delimiter,
        }
      : undefined;
  }
}
