import type { Ref } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import type { BannerContent } from 'web/src/modules/banners/store/types';

export interface BannerLocationComposable {
  href: Ref<string | undefined>;
  onClick(): void;
}

export default function useBannerLocation(banner: Ref<BannerContent | undefined>): BannerLocationComposable {
  const router = useRouter();

  const href = computed(() => (banner.value ? router.resolve({ path: banner.value.url }).href : undefined));

  function onClick(): void {
    if (banner.value) {
      void router.push({ path: banner.value.url });
    }
  }

  return {
    href,
    onClick,
  };
}
