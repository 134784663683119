import {
  getMatchStreamUrl as fetchMatchStreamUrl,
  getCustomerMatchStreamEvents,
  getCustomerStreams,
  RequestOptionsPriority,
} from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type {
  GetCustomerMatchStreamEventsResponse,
  GetCustomerMatchStreamsResponse,
  GetMatchStreamUrlResponse,
} from 'web/src/modules/sportline/types/rest';

export function getCustomerStreamEventsRequest(
  { silent }: { silent?: boolean },
): Promise<GetCustomerMatchStreamEventsResponse> {
  const apiClient = useGraphqlClient();
  return getCustomerMatchStreamEvents(apiClient, (node) => node.queries.matchStream.getCustomerEvents, {}, {
    silent,
    priority: silent ? RequestOptionsPriority.LOW : undefined,
  });
}

export function getCustomerStreamsRequest(
  { silent }: { silent?: boolean },
): Promise<GetCustomerMatchStreamsResponse> {
  const apiClient = useGraphqlClient();
  return getCustomerStreams(apiClient, (node) => node.queries.matchStream.getCustomerStreams, {}, {
    silent,
    priority: silent ? RequestOptionsPriority.LOW : undefined,
  });
}

export function getMatchStreamUrl(
  options: { eventId: number },
  { silent }: { silent?: boolean },
): Promise<GetMatchStreamUrlResponse> {
  const apiClient = useGraphqlClient();
  return fetchMatchStreamUrl(
    apiClient,
    (node) => node.queries.matchStream.getMatchStreamUrl,
    { options },
    {
      silent,
      priority: silent ? RequestOptionsPriority.LOW : undefined,
    },
  );
}
