import { isObject, isOptionalString, isOptionalBoolean, isString, isNull, isUndefined } from "@leon-hub/guards";
const InputEventType = {
  INPUT: "input",
  CHANGE: "change",
  FOCUS: "focus",
  BLUR: "blur",
  SUBMIT: "submit"
};
const AdditionalInputEventType = {
  MOUSEDOWN: "mousedown",
  KEYDOWN: "keydown",
  MOUSEOVER: "mouseover",
  MOUSELEAVE: "mouseleave",
  PASTE: "paste"
};
const CustomInputEventType = {
  FILLED: "filled",
  CLEAR: "clear",
  ICON_PREFIX_CLICK: "icon-prefix-click",
  ICON_SUFFIX_CLICK: "icon-suffix-click"
};
const InputFileTypes = {
  Image: "Image",
  Video: "Video",
  Pdf: "Pdf",
  Other: "Other"
};
const InputMode = {
  NONE: "none",
  TEXT: "text",
  DECIMAL: "decimal",
  NUMERIC: "numeric",
  TEL: "tel",
  SEARCH: "search",
  EMAIL: "email",
  URL: "url"
};
const TextInputTypes = {
  TEXT: "text",
  NUMBER: "number",
  TEL: "tel",
  HIDDEN: "hidden",
  EMAIL: "email",
  PASSWORD: "password"
};
const VInputTypes = {
  TEXT: "text",
  NUMBER: "number",
  TEL: "tel",
  PASSWORD: "password",
  FILE: "file",
  HIDDEN: "hidden",
  DATE: "date",
  EMAIL: "email"
};
function isBaseInputProps(value) {
  if (!isObject(value)) {
    return false;
  }
  return isOptionalString(value.label) && isOptionalString(value.name) && isOptionalString(value.value) && isOptionalBoolean(value.disabled);
}
function isRawLocation(value) {
  return isString(value) || isObject(value) && (isString(value.path) || isString(value.name));
}
function isHintLink(value) {
  if (!isObject(value)) {
    return false;
  }
  return isRawLocation(value.href) && isOptionalString(value.target) && isString(value.text);
}
function isOptionalHintLinkOrNull(value) {
  return isNull(value) || isUndefined(value) || isHintLink(value);
}
function isInputHintProps(value) {
  if (!isObject(value)) {
    return false;
  }
  return isOptionalString(value.error) && isOptionalString(value.errorRight) && isOptionalString(value.hint) && isOptionalString(value.hintRight) && isOptionalHintLinkOrNull(value.hintLink) && isOptionalHintLinkOrNull(value.hintLinkRight);
}
function isVInputFileEvent(event) {
  return isObject(event) && "target" in event && isObject(event.target) && "files" in event.target && "name" in event.target;
}
export {
  AdditionalInputEventType,
  CustomInputEventType,
  InputEventType,
  InputFileTypes,
  InputMode,
  TextInputTypes,
  VInputTypes,
  isBaseInputProps,
  isHintLink,
  isInputHintProps,
  isOptionalHintLinkOrNull,
  isVInputFileEvent
};
