<script lang="ts" setup>
import { BadgeKind } from '@components/badge';
import { SwitchKind } from '@components/switch';

import type { VListItemEmits, VListItemProps } from '../../types';
import { ListItemSuffixAlign } from '../../enums';
import VListItemInner from '../VListItemInner/VListItemInner.vue';

import $style from '../../styles/common.module.scss';

withDefaults(defineProps<VListItemProps>(), {
  switchKind: SwitchKind.DEFAULT,
  suffixAlign: ListItemSuffixAlign.CENTER,
  title: '',
  iconCustomStyles: '',
  counter: '',
  href: '',
  iconName: undefined,
  iconSuffixName: undefined,
  countryCode: '',
  badgeLabel: '',
  progressValue: 0,
  buttonLabel: '',
});

const emit = defineEmits<VListItemEmits>();

function onClick(event: MouseEvent): void {
  emit('click', event);
}

function emitChangeSwitcher(value: boolean) {
  emit('change-switcher', value);
}

function emitClickButton() {
  emit('click-button', true);
}

function emitClickSwitcher(event: KeyboardEvent) {
  emit('click-switcher', event);
}
</script>

<template>
  <li v-auto-id="'VListItem'"
    :class="{
      [$style['list-item']]: true,
      [$style['list-item--no-hover']]: noHover,
      [$style['list-item--have-switcher']]: haveSwitcher,
      [$style['list-item--active']]: isActive,
      [$style['list-item--big-margin']]: isBigMargin,
      [$style['list-item--reward-center']]: isRewardCenter,
    }"
  >
    <slot>
      <VListItemInner
        :title="title"
        :badge-label="badgeLabel"
        :badge-icon="badgeIcon"
        :badge-kind="isRewardCenter ? BadgeKind.ACCENT : badgeKind"
        :suffix-text="suffixText"
        :counter="counter"
        :have-expand="haveExpand"
        :have-switcher="haveSwitcher"
        :suffix-align="suffixAlign"
        :is-new="isNew"
        :icon-name="iconName"
        :country-code="countryCode"
        :is-switch-disabled="isSwitchDisabled"
        :switch-kind="switchKind"
        :is-switch-checked="isSwitchChecked"
        :is-title-centered="isTitleCentered"
        :icon-suffix-name="iconSuffixName"
        :href="href"
        :progress-value="progressValue"
        :no-wrap="noWrap"
        :have-progress="haveProgress"
        :icon-colored="iconColored"
        :is-reward-center="isRewardCenter"
        :icon-custom-styles="iconCustomStyles"
        :button-label="buttonLabel"
        :button-kind="buttonKind"
        @click="onClick"
        @change-switcher="emitChangeSwitcher"
        @click-button="emitClickButton"
        @click-switcher="emitClickSwitcher"
      >
        <template
          v-if="$slots.prefix"
          #prefix
        >
          <slot name="prefix" />
        </template>
        <template
          v-if="$slots.inner"
          #inner
        >
          <slot name="inner" />
        </template>
        <template
          v-if="$slots.additional"
          #additional
        >
          <slot name="additional" />
        </template>
      </VListItemInner>
    </slot>
  </li>
</template>
