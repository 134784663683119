<script lang="ts" setup>
import { computed } from 'vue';

import { normalizeError } from '@leon-hub/errors';
import {
  IconDirection,
  IconName,
  IconSize,
  loadIcons,
} from '@leon-hub/icons';

import type { VIconEmits, VIconProps } from './types';
import { useVIcon } from './composables';

const props = withDefaults(defineProps<VIconProps>(), {
  name: IconName.CROSS,
  title: '',
  size: IconSize.SIZE_20,
  direction: IconDirection.EAST,
});
const emit = defineEmits<VIconEmits>();

const {
  hasSize,
  iconPath,
  hasDirection,
  isLoading,
} = useVIcon(props);

function onClick(event: MouseEvent) {
  emit('click', event);
}

if (!isLoading.value) {
  void loadIcons().catch((err: unknown) => console.error(normalizeError(err)));
}

const styles = computed(() => {
  if (props.customFill) {
    return {
      fill: `url(#${props.customFill})`,
    };
  }

  return undefined;
});
</script>

<template>
  <svg v-auto-id="'VIcon'"
    :class="{
      [$style.icon]: true,
      [$style[`icon--direction-${direction}`]]: hasDirection,
      [$style[`icon--size-${size}`]]: hasSize,
    }"
    :style="styles"
    aria-hidden="true"
    role="presentation"
    :title="title"
    @click="onClick"
  >
    <use :xlink:href="iconPath" />
  </svg>
</template>

<style lang="scss" module>
$directions: (
  east: 0deg,
  south: 90deg,
  west: 180deg,
  north: 270deg,
);
$sizes: (
  'size-8': 8px,
  'size-10': 10px,
  'size-12': 12px,
  'size-14': 14px,
  'size-16': 16px,
  'size-18': 18px,
  'size-20': 20px,
  'size-24': 24px,
  'size-28': 28px,
  'size-32': 32px,
  'size-36': 36px,
  'size-40': 40px,
  'size-44': 44px,
  'size-48': 48px,
  'size-50': 50px,
  'size-52': 52px,
  'size-56': 56px,
  'size-60': 60px,
  'size-68': 68px,
  'size-80': 80px,
  'size-90': 90px,
  'size-96': 96px,
  'size-100': 100px,
  'size-130': 130px,
  'size-160': 160px,
  'full-size': 100%,
  mini: 8px,
  tiny: 12px,
  small: 16px,
  default: 20px,
  medium: 24px,
  big: 32px,
  large: 48px,
  huge: 60px,
  giant: 80px,
  icon_130: 130px,
  icon_160: 160px,
);

.icon {
  width: map-get($sizes, default);
  height: map-get($sizes, default);
  vertical-align: middle;
  fill: currentcolor;

  @each $name, $size in $sizes {
    @if $name != default {
      &--size-#{$name} {
        width: $size;
        height: $size;
      }
    }
  }

  @each $name, $degrees in $directions {
    @if $name != east {
      &--direction-#{$name} {
        transform: rotate($degrees);
      }
    }
  }
}
</style>
