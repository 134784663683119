import type { Ref } from 'vue';
import { computed } from 'vue';

import type { MarketsColumn } from 'web/src/modules/sportline/types';
import { getMarketColumnsFromMarketColumnsInfo } from 'web/src/modules/sportline/composables/markets/utils';

import type {
  SelectedColumnInfoElement,
  SelectedColumnInfoMap,
} from '../types';

/**
 * Creates available columns for sport basis
 * (Columns list for sport)
 */
export function createColumnsMapRef(sportlineElements: Ref<SelectedColumnInfoElement[]>): Ref<SelectedColumnInfoMap<MarketsColumn[]>> {
  return computed<SelectedColumnInfoMap<MarketsColumn[]>>(() => {
    const elements = sportlineElements.value;
    const result: SelectedColumnInfoMap<MarketsColumn[]> = {};

    for (const element of elements) {
      const { key } = element;

      if (!key) {
        continue;
      }

      result[key] = getMarketColumnsFromMarketColumnsInfo(element);
    }

    return result;
  });
}
