import { logger } from '@leon-hub/logging';

import type { GetEventsInput } from 'web/src/modules/sportline/services/api/types';
import type { GetSportEventsResponse } from 'web/src/modules/sportline/types/rest';
import { assertUnknownGetEventsResponse } from 'web/src/modules/sportline/asserts/rest';
import useSportlineApi from 'web/src/modules/sportline/services/api/useSportlineApi';
import {
  filterCorruptedEventsInResponse,
  logCorruptedEventsIds,
} from 'web/src/modules/sportline/utils/response';

export default async function getEvents(input: GetEventsInput): Promise<GetSportEventsResponse> {
  const apiClient = useSportlineApi();
  const response = await apiClient.getEvents(input);
  try {
    assertUnknownGetEventsResponse(response);
    return filterCorruptedEventsInResponse(response, logCorruptedEventsIds);
  } catch (error) {
    logger.error('apiClient getEvents response corrupted', error);
    // always return response for user
    return { totalCount: 0, events: [] };
  }
}
