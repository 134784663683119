export enum CyberSportDiscipline {
  AOE = 'AOE',
  AOE2 = 'AOE2',
  AOE4 = 'AOE4',
  CALL_OF_DUTY = 'COD',
  CALL_OF_DUTY_MOBILE = 'CODMOBILE',
  CS2_DUELS = 'CS2_DUELS',
  CSGO = 'CSGO',
  DEADLOCK = 'DEADLOCK',
  DOTA = 'DOTA',
  DOTA_DUELS = 'DOTA_DUELS',
  FIFA = 'FIFA',
  GEOGUESSR = 'GEOGUESSR',
  HEARTH_STONE = 'HS',
  HEROES_OF_THE_STORM = 'HOTS',
  KING_OF_GLORY = 'KOG',
  LEAGUE_OF_LEGENDS = 'LOL',
  OVERWATCH = 'OW',
  STARCRAFT = 'SC',
  STARCRAFT_2 = 'SC2',
  VALORANT = 'VLR',
  WARCRAFT_3 = 'WAR3',
  WORLD_OF_TANKS = 'WOT',
  RAINBOW_SIX = 'R6',
  ROCKET_LEAGUE = 'RL',
  ARTIFACT = 'ART',
  SMITE = 'SMITE',
  ARENA_OF_VALOR = 'AOV',
  QUAKE = 'QUAKE',
  PES = 'PES',
  NHL = 'NHL',
  CROSSFIRE = 'XFIRE',
  STREET_FIGHTER = 'SF',
  NBA = 'NBA',
  LOL_WILD_RIFT = 'WR',
  MARVEL_RIVALS = 'MARVEL_RIVALS',
  MOBILE_LEGENDS_BANG_BANG = 'MLBB',
  OSU = 'OSU',
  BRAWL_STARS = 'BRST',
  HALO = 'HALO',
  FREE_FIRE = 'FFIRE',
  APEX_LEGENDS = 'APLE',
  PUBG_MOBILE = 'PUBGMOBILE',
  PUBG = 'PUBG',
  BOOST = 'BOOST',
  FORTNITE = 'FORTNITE',
  TEKKEN = 'TEKKEN',
}
