import type { Ref } from 'vue';
import { computed } from 'vue';

import type { IProgressBar, IWidgetType } from '@leon-hub/api-sdk';
import { progressBarLinearProgress, progressBarNone, TimerMode } from '@leon-hub/api-sdk';

import type { SiteConfigDocument } from '@core/site-config';

// types
import type {
  BetlineWidgetType,
  BuildSportOptions,
} from 'web/src/modules/sportline/types/rest';
import type {
  SportFamilyMapping,
  SportRepresentationSetting,
  SportRepresentationSettings,
  SportSegmentMapping,
} from 'web/src/modules/sportline/types/settings';
import {
  betlineWidgetTypeBetgenius,
  betlineWidgetTypeBetradar,
  betlineWidgetTypeLsports,
  betlineWidgetTypeOddin,
} from 'web/src/modules/sportline/constants/rest';
// enums
import { SportFamily, SportProgressBarType } from 'web/src/modules/sportline/enums';
// constants
import { sportSegments } from 'web/src/modules/sportline/constants';

type SportConfiguration = SiteConfigDocument['sportLine']['sportConfigurationV2'];

type SportUrlMapping = SiteConfigDocument['sportLine']['sportUrlMapping'];

interface UseBuildSportSettingsProps {
  sportlineBlock: Ref<Optional<SiteConfigDocument['sportLine']>>;
}

interface UseBuildSportSettingsComposable {
  sportFamilyMapping: Ref<SportFamilyMapping>;
  isZeroMarginEnabled: Ref<boolean>;
  isCybersportV2Enabled: Ref<boolean>;
  parseSportlineSettings: Ref<BuildSportOptions>;
}

function getAvailableWidgetTypes(): BetlineWidgetType[] {
  const result: BetlineWidgetType[] = [
    betlineWidgetTypeBetradar,
    betlineWidgetTypeLsports,
    betlineWidgetTypeBetgenius,
  ];

  if (process.env.VUE_APP_FEATURE_SPORTLINE_ODDIN_WIDGETS_ENABLED) {
    result.push(betlineWidgetTypeOddin);
  }

  return result;
}

function mapToBetlineWidgetType(widgetType?: Maybe<IWidgetType>): Optional<BetlineWidgetType> {
  return !!widgetType && getAvailableWidgetTypes().includes(widgetType as BetlineWidgetType)
    ? widgetType
    : undefined;
}

function mapToSportProgressBarType(progressBar?: Maybe<IProgressBar>): Optional<SportProgressBarType> {
  switch (progressBar) {
    case progressBarLinearProgress:
      return SportProgressBarType.Linear;

    case progressBarNone:
      // fallthrough
    default:
      return SportProgressBarType.None;
  }
}

function getDefaultSportRepresentationSettings(): Map<number | string, SportRepresentationSetting> {
  return new Map<number | string, SportRepresentationSetting>();
}

/** Core options for sportline adapters */
export function useBuildSportSettings(
  props: UseBuildSportSettingsProps,
): UseBuildSportSettingsComposable {
  const { sportlineBlock } = props;

  const isZeroMarginEnabled = computed(() => !!sportlineBlock.value?.zeroMarginEnabled);
  const isCybersportV2Enabled = computed(() => !!sportlineBlock.value?.cybersportV2Enabled);

  const sportConfiguration = computed<SportConfiguration>(() => sportlineBlock.value?.sportConfigurationV2 ?? []);
  const sportUrlMapping = computed<SportUrlMapping>(() => sportlineBlock.value?.sportUrlMapping ?? []);

  // Mapping for URLs
  const sportFamilyMapping = computed<SportFamilyMapping>(() => sportUrlMapping.value.reduce((result, config) => ({
    ...result,
    [config.sportId]: config.sportUrl?.toLowerCase(),
  }), {}));

  const sportSegmentMapping = computed<SportSegmentMapping>(() => {
    const result: SportSegmentMapping = {};

    if (isCybersportV2Enabled.value) {
      result[SportFamily.ESport] = sportSegments.cyberSport;
    }

    if (process.env.VUE_APP_FEATURE_VIRTUAL_SPORT_ENABLED && sportlineBlock.value?.virtualSportLiveEventsEnabled && sportSegments.virtual) {
      result[SportFamily.VirtualSoccer] = sportSegments.virtual;
      result[SportFamily.VirtualSoccerV2] = sportSegments.virtual;
      result[SportFamily.VirtualBasketball] = sportSegments.virtual;
      result[SportFamily.VirtualTennis] = sportSegments.virtual;
      result[SportFamily.VirtualTennisInplay] = sportSegments.virtual;
      result[SportFamily.VirtualCricket] = sportSegments.virtual;
    }

    return result;
  });

  const sportRepresentationSettings = computed<SportRepresentationSettings>(() => Object.fromEntries(sportConfiguration.value
    .reduce((result, config) => result.set(config.sportFamily, {
      useFullProgress: config.config.timerMode === TimerMode.SECONDS,
      defaultWidgetType: mapToBetlineWidgetType(config.config.widgetType),
      progressBarType: mapToSportProgressBarType(config.config.progressBar),
    } as SportRepresentationSetting), getDefaultSportRepresentationSettings())
    .entries()));

  const parseSportlineSettings = computed<BuildSportOptions>(() => ({
    sportSegmentMapping: sportSegmentMapping.value,
    sportFamilyMapping: sportFamilyMapping.value,
    sportRepresentationSettings: sportRepresentationSettings.value,
    canUseZeroMargin: isZeroMarginEnabled.value,
  }));

  return {
    sportFamilyMapping,
    isZeroMarginEnabled,
    isCybersportV2Enabled,
    parseSportlineSettings,
  };
}
