import type { ComputedRef, Ref } from 'vue';
import { computed, onBeforeUnmount, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { priceChangePolicyAllowChanges, priceChangePolicyDenyChanges } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';
import {
  Events as AnalyticsEvent,
  TargetParamEnter,
} from '@leon-hub/yandex-metrika';

import { useAnalytics } from '@core/analytics';
import { useIsLoggedIn } from '@core/auth';
import { usePriceChangePolicy } from '@core/customer';
import { useBalance, useCurrencyStore, useFormatMoney } from '@core/money';

import type { VFastMoneyChoiceProperties } from 'web/src/components/FastMoneyChoice';
import { useFastBetsValues } from 'web/src/modules/fast-bets/composables';
import { useIdentificationService } from 'web/src/modules/identification';
import useIsIdentifiedUser from 'web/src/modules/identification/composables/useIsIdentifiedUser';

import type { BetSlipPlaceBetButtonProps } from '../components/BetSlipPlaceBetButton/types';
import type { BetSlipSummaryProps } from '../components/BetSlipSummary/types';
import {
  BetSlipMainButtonMode,
  SettingsTabs,
} from '../enums';
import { useAccumulatorBoostStore } from '../submodules/accumulator-boost/store';
import { useFreebetStore } from '../submodules/freebet/store';
import { useMultiSinglesStore } from '../submodules/multi-singles/store';
import { useSlipInfoStore } from '../submodules/slip-info/store';
import { useSlipRootStore } from '../submodules/slip-root/store';
import useSlipUserSettings from '../submodules/slip-user-settings/store/useSlipUserSettings';
import { useSlipViewSettingsStore } from '../submodules/slip-view-settings/store';
import { useStakeInputStore } from '../submodules/stake-input/store';
import { getNettWinning, getTaxAmount } from '../utils';

interface UseBetSlipFooter {
  // BetSlipSummary
  betSlipSummaryProps: ComputedRef<BetSlipSummaryProps>;
  stakeInputFocus(): void;
  stakeInputBlur(): void;
  handleStakeInput(value: string): void;
  selectSystemValue(value: string): Promise<void>;
  setSelectedFreeBet(value: string): void;
  onChangeFreeBetSwitch(checked: boolean): void;
  allowPriceChanges(): void;
  allowTotalHandicapChanges(): void;
  toggleSameStakeForSingles(): void;
  onClearBetList(): void;
  showSlipSettings(): void;
  // FastMoneyChoice
  isFastBetsEnabled: Ref<boolean>;
  fastBetsProps: ComputedRef<VFastMoneyChoiceProperties>;
  selectFastBet(value: number): void;
  editFastBets(): void;
  // common keyboard
  isMobileKeyboardShown: Ref<boolean>;
  hideKeyboard(): void;
  backspace(): void;
  numberInput(value: string): void;
  decimalSeparatorClick(): void;
  // PlaceBetButton
  mainButtonProps: ComputedRef<BetSlipPlaceBetButtonProps>;
  mainButtonClick(): Promise<void>;
  // life-cycle
  onAppear(): void;
  onDisappear(): void;
}

export default function useBetSlipFooter(): UseBetSlipFooter {
  const router = useRouter();

  const slipRootStore = useSlipRootStore();

  const { handleSlipClose, handleMainButtonClick } = slipRootStore;

  const summaryStatus = toRef(slipRootStore, 'summaryStatus');
  const mainButtonMode = toRef(slipRootStore, 'mainButtonMode');
  const placeBetButtonDisabled = toRef(slipRootStore, 'placeBetButtonDisabled');

  const { isLoggedIn } = useIsLoggedIn();

  const analytics = useAnalytics();

  const { isIdentifiedUser } = useIsIdentifiedUser();

  const { priceChangePolicy, totalHandicapPriceChangePolicy, savePriceChangePolicy } = usePriceChangePolicy();

  const slipSettingsStore = useSlipUserSettings();

  const slipViewSettingsStore = useSlipViewSettingsStore();

  const { saveBetSlipConfiguration, selectSettingsTab } = slipSettingsStore;

  const { showSlipSettings, setClearBetListWarnVisibility } = slipViewSettingsStore;

  const isFullyVisibleOnMobile = toRef(slipViewSettingsStore, 'isFullyVisibleOnMobile');

  const isHiddenOnMobile = toRef(slipViewSettingsStore, 'isHiddenOnMobile');

  const currencyStore = useCurrencyStore();

  const currencySymbol = toRef(currencyStore, 'formattedCurrency');

  const stakeInputStore = useStakeInputStore();

  const isStakeInputFocused = toRef(stakeInputStore, 'isStakeInputFocused');

  const stakeInputValue = toRef(stakeInputStore, 'stakeInputValue');

  const safeStakeValue = toRef(stakeInputStore, 'safeStakeValue');

  const isReadyToDisplayErrors = toRef(stakeInputStore, 'isReadyToDisplayErrors');

  const { balance } = useBalance();

  const formatMoney = useFormatMoney();

  const {
    selectFastBet,
    stakeInputFocus,
    stakeInputBlur,
    handleStakeInput,
    hideKeyboard,
    handleKeyboardStakeInput,
    backspace,
    numberInput,
    decimalSeparatorClick,
  } = stakeInputStore;

  const freebetStore = useFreebetStore();

  const freeBetOptions = toRef(freebetStore, 'freeBetOptions');

  const freeBetSwitchChecked = toRef(freebetStore, 'freeBetSwitchChecked');

  const selectedFreeBetId = toRef(freebetStore, 'selectedFreeBetId');

  const disabledFreeBetSwitch = toRef(freebetStore, 'disabledFreeBetSwitch');

  const selectedFreeBetTermKey = toRef(freebetStore, 'selectedFreeBetTermKey');

  const showFreeBet = toRef(freebetStore, 'showFreeBet');

  const {
    setSelectedFreeBet,
    onChangeFreeBetSwitch,
  } = freebetStore;

  const slipInfoStore = useSlipInfoStore();

  const { selectSystemValue, resetPriceChangesError, allowTotalHandicapChanges } = slipInfoStore;

  const maxPrize = toRef(slipInfoStore, 'maxPrize');
  const isHandicapInSelection = toRef(slipInfoStore, 'isHandicapInSelection');

  const multiSinglesStore = useMultiSinglesStore();

  const { setLocalSameStakeForSingles } = multiSinglesStore;

  // BetSlipSummary

  const formattedBalance = computed<string>(() => formatMoney(balance.value));

  const formattedTotalOdds = toRef(slipInfoStore, 'formattedTotalOdds');
  const warningMessage = toRef(slipInfoStore, 'warningMessage');
  const systemOptions = toRef(slipInfoStore, 'systemOptions');
  const minStake = toRef(slipInfoStore, 'minStake');
  const maxStake = toRef(slipInfoStore, 'maxStake');
  const isSyncInProgress = toRef(slipInfoStore, 'isSyncInProgress');
  const isBetModeMatchesSlipTypes = toRef(slipInfoStore, 'isBetModeMatchesSlipTypes');
  const isMultiSinglesMode = toRef(slipInfoStore, 'isMultiSinglesMode');
  const betMode = toRef(slipInfoStore, 'betMode');
  const selectedSystemValue = toRef(slipInfoStore, 'selectedSystemValue');
  const multiSinglesTotalPrice = toRef(slipInfoStore, 'multiSinglesTotalPrice');
  const maxAvailableFastBetValue = toRef(slipInfoStore, 'maxAvailableFastBetValue');
  const selectedStakeValue = toRef(slipInfoStore, 'selectedStakeValue');
  const taxPercent = toRef(slipInfoStore, 'taxPercent');
  const multiSinglesTotalWin = toRef(slipInfoStore, 'multiSinglesTotalWin');
  const multiSinglesTotalTax = toRef(slipInfoStore, 'multiSinglesTotalTax');

  const sameStakeForSingles = toRef(multiSinglesStore, 'sameStakeForSingles');

  const accumulatorBoostStore = useAccumulatorBoostStore();

  const formattedOddsWithoutBoost = toRef(accumulatorBoostStore, 'formattedOddsWithoutBoost');
  const maxPrizeWithoutBoost = toRef(accumulatorBoostStore, 'maxPrizeWithoutBoost');
  const boostedWinValue = toRef(accumulatorBoostStore, 'boostedWinValue');

  const prizeValue = computed<number>(() => boostedWinValue.value ?? maxPrize.value);

  const taxAmount = computed<number | null>(() => {
    if (!taxPercent.value) {
      return null;
    }
    if (isMultiSinglesMode.value) {
      return multiSinglesTotalTax.value;
    }
    return getTaxAmount({
      stake: safeStakeValue.value,
      winValue: prizeValue.value,
      taxPercent: taxPercent.value,
    });
  });

  const nettMaxPrize = computed<number>(() => {
    if (isMultiSinglesMode.value) {
      /** tax is calculated */
      return multiSinglesTotalWin.value;
    }
    return getNettWinning({
      stake: safeStakeValue.value,
      winValue: prizeValue.value,
      taxPercent: taxPercent.value ?? 0,
    });
  });

  const formattedMaxPrize = computed<string>(() => formatMoney(nettMaxPrize.value));

  const formattedTaxAmount = computed<string | null>(() => (taxAmount.value ? formatMoney(-taxAmount.value) : null));

  const formattedMaxPrizeWithoutBoost = computed<string>(() => (maxPrizeWithoutBoost.value ? formatMoney(maxPrizeWithoutBoost.value) : ''));

  const betSlipSummaryProps = computed<BetSlipSummaryProps>(() => ({
    currencySymbol: currencySymbol.value,
    betMode: betMode.value,
    status: summaryStatus.value,
    stakeValue: stakeInputValue.value,
    inputFocused: isStakeInputFocused.value,
    balance: formattedBalance.value,
    minStake: minStake.value,
    maxStake: maxStake.value,
    selectedSystemValue: selectedSystemValue.value,
    totalOdds: formattedTotalOdds.value,
    totalOddsWithoutBoost: formattedOddsWithoutBoost.value,
    maxPrize: formattedMaxPrize.value,
    maxPrizeWithoutBoost: formattedMaxPrizeWithoutBoost.value,
    warningMessage: warningMessage.value,
    systemOptions: systemOptions.value,
    readyToDisplayErrors: isReadyToDisplayErrors.value,
    isSyncInProgress: isSyncInProgress.value,
    denyPriceChanges: priceChangePolicy.value === priceChangePolicyDenyChanges && isLoggedIn.value,
    denyTotalHandicapChanges: totalHandicapPriceChangePolicy.value === priceChangePolicyDenyChanges && isLoggedIn.value,
    isHandicapInSelection: isHandicapInSelection.value,
    wrongBetMode: !isBetModeMatchesSlipTypes.value,
    showFreeBet: showFreeBet.value,
    freeBetOptions: freeBetOptions.value,
    freeBetSwitchChecked: freeBetSwitchChecked.value,
    freeBetSwitchDisabled: disabledFreeBetSwitch.value,
    selectedFreeBet: selectedFreeBetId.value,
    freeBetTermKey: selectedFreeBetTermKey.value,
    isMultiSingles: isMultiSinglesMode.value,
    sameStakeForSingles: sameStakeForSingles.value,
    isLoggedIn: isLoggedIn.value,
    isEnteringAnimationPlaying: process.env.VUE_APP_LAYOUT_DESKTOP ? false : !isFullyVisibleOnMobile.value,
    isVisible: !isHiddenOnMobile.value,
    taxPercent: taxPercent.value ? `${taxPercent.value}%` : undefined,
    taxAmount: formattedTaxAmount.value ?? undefined,
  }));

  const onClearBetList = (): void => {
    hideKeyboard();
    setClearBetListWarnVisibility(true);
  };

  const allowPriceChanges = (): void => {
    resetPriceChangesError();
    void savePriceChangePolicy({ priceChangePolicy: priceChangePolicyAllowChanges });
    analytics.push(AnalyticsEvent.CLICK_MAP, {
      clickCounter: { autoUpdateCoeff: 'turnOn' },
    });
  };

  const toggleSameStakeForSingles = (): void => {
    const newValue = !sameStakeForSingles.value;
    setLocalSameStakeForSingles(newValue);
    void saveBetSlipConfiguration({ sameStakeForSingleByDefault: newValue });
  };

  // FastMoneyChoice
  const { isFastBetsEnabled, fastBetsShowMax, fastBetsOptions } = useFastBetsValues();

  const fastBetsProps = computed<VFastMoneyChoiceProperties>(() => ({
    items: fastBetsOptions.value,
    currentValue: selectedStakeValue.value,
    maxValue: maxAvailableFastBetValue.value,
    minAvailableValue: minStake.value || 0,
    maxAvailableValue: maxStake.value || undefined,
    showEditButton: isLoggedIn.value,
    maxEnabled: fastBetsShowMax.value,
  }));

  const editFastBets = (): void => {
    selectSettingsTab(SettingsTabs.FAST_BETS);
    showSlipSettings();
  };

  // common keyboard

  const isMobileKeyboardShown = toRef(stakeInputStore, 'isMobileKeyboardShown');

  // PlaceBetButton

  const mainButtonProps = computed<BetSlipPlaceBetButtonProps>(() => ({
    mode: mainButtonMode.value,
    disabled: placeBetButtonDisabled.value,
    multiSinglesTotalPrice: multiSinglesTotalPrice.value,
    isMultiSinglesMode: isMultiSinglesMode.value,
  }));

  const showIdentificationModal = async (): Promise<void> => {
    if (process.env.VUE_APP_FEATURE_IDENT_ENABLED) {
      await useIdentificationService().showIdentificationStatusModal();
    }
  };

  const mainButtonClick = async (): Promise<void> => {
    switch (mainButtonMode.value) {
      case BetSlipMainButtonMode.NOT_LOGGED_IN:
        analytics.push(AnalyticsEvent.CLICK_MAP, {
          clickCounter: {
            enter: TargetParamEnter.BET_SLIP,
          },
        });
        await router.push({ name: RouteName.LOGIN });
        return;
      case BetSlipMainButtonMode.NOT_ENOUGH_BALANCE:
        handleSlipClose();
        if (!isIdentifiedUser.value) {
          void showIdentificationModal();
        } else {
          analytics.clickMap({ deposit: 'betSlipDeposit' });
          await router.push({ name: RouteName.DEPOSITS });
        }
        return;
      case BetSlipMainButtonMode.IDENTIFICATION_REQUIRED:
        void showIdentificationModal();
        return;
      default:
        handleMainButtonClick();
    }
  };

  const keyboardInputHandler = (event: KeyboardEvent) => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      return;
    }
    handleKeyboardStakeInput(event);
  };

  const onAppear = (): void => {
    if (process.env.VUE_APP_RENDERING_CSR && !process.env.VUE_APP_LAYOUT_DESKTOP) {
      document.addEventListener('keydown', keyboardInputHandler);
    }
  };

  const onDisappear = (): void => {
    if (process.env.VUE_APP_RENDERING_CSR && !process.env.VUE_APP_LAYOUT_DESKTOP) {
      document.removeEventListener('keydown', keyboardInputHandler);
    }
  };

  onBeforeUnmount(hideKeyboard);

  return {
    // BetSlipSummary
    betSlipSummaryProps,
    stakeInputFocus,
    stakeInputBlur,
    handleStakeInput,
    selectSystemValue,
    setSelectedFreeBet,
    onChangeFreeBetSwitch,
    allowPriceChanges,
    allowTotalHandicapChanges,
    toggleSameStakeForSingles,
    onClearBetList,
    showSlipSettings,
    // FastMoneyChoice
    isFastBetsEnabled,
    fastBetsProps,
    selectFastBet,
    editFastBets,
    // common keyboard
    isMobileKeyboardShown,
    hideKeyboard,
    backspace,
    numberInput,
    decimalSeparatorClick,
    // PlaceBetButton
    mainButtonProps,
    mainButtonClick,
    // life-cycle related
    onAppear,
    onDisappear,
  };
}
