import { ref, toRef, computed, watch } from "vue";
import { defineStore } from "pinia";
import { priceChangePolicyUndefined, priceChangePolicyDenyChanges, customerBetTypeEgs, customerBetTypeSport, affiliatePartnerStatusNo, doSavePriceChangePolicyV2, getUserDataPhone, doUpdateCustomerData, customerConfigIsOddsTypeAmerican, customerConfigIsOddsTypeClassic } from "@leon-hub/api-sdk";
import { phoneStringToPhoneInput } from "@leon-hub/api-utils";
import { getCordovaAppConfig, handleDomainUpdates } from "@leon-hub/cordova";
import { emailPattern } from "@leon-hub/form-utils";
import { useLocalStorageManager } from "@leon-hub/local-storage";
import { DecodeValue } from "@leon-hub/utils";
import { useAnalyticsCommonDataStore } from "@core/analytics";
import { useGraphqlClient } from "@core/app-rest-client";
import { useAuthStore } from "@core/auth";
function getSavePriceChangePolicy(value) {
  if (!value || value === priceChangePolicyUndefined) {
    return priceChangePolicyDenyChanges;
  }
  return value;
}
function isValidCustomerBetType(type) {
  return !!type && (type === customerBetTypeEgs || type === customerBetTypeSport);
}
const LOCAL_STORAGE_BET_TYPE_KEY = "customer:betType";
const useCustomerBetTypeStore = /* @__PURE__ */ defineStore("customer-bet-type", () => {
  const localStorage = useLocalStorageManager();
  const betType = ref();
  function setBetType(type) {
    betType.value = type;
    localStorage.setItem(LOCAL_STORAGE_BET_TYPE_KEY, type);
  }
  function onInit() {
    const type = localStorage.getItem(LOCAL_STORAGE_BET_TYPE_KEY);
    if (isValidCustomerBetType(type)) {
      setBetType(type);
    }
  }
  onInit();
  return {
    betType,
    setBetType
  };
});
const useCustomerDataStore = /* @__PURE__ */ defineStore("customer-data", () => {
  var _a;
  const api = useGraphqlClient();
  const authStore = useAuthStore();
  const { setLoggedIn } = authStore;
  const isLoggedIn = toRef(authStore, "isLoggedIn");
  const customerConfigCache = ref(null);
  const { setBetType } = useCustomerBetTypeStore();
  const localStorageManager = useLocalStorageManager();
  const customerData = ref(null);
  const forcedVerificationType = ref(null);
  const isShowTurnOnTfaStep = ref(!((_a = customerData.value) == null ? void 0 : _a.g2svCustomerStatus));
  const customerOddTypeConfig = ref();
  const isLoaded = computed(() => customerData.value !== null);
  const isAsoTermsConfirmationNeeded = computed(
    () => {
      var _a2;
      return ((_a2 = customerData.value) == null ? void 0 : _a2.isAsoTermsConfirmationNeeded) ?? false;
    }
  );
  const email = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.email) || "";
  });
  const emailSubscription = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.emailSubscription) || 0;
  });
  const login = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.customerLogin) || "";
  });
  const phone = computed(() => {
    var _a2;
    return phoneStringToPhoneInput(((_a2 = customerData.value) == null ? void 0 : _a2.phone) || "");
  });
  const phoneDecodedString = computed(() => {
    var _a2;
    let phoneString = ((_a2 = customerData.value) == null ? void 0 : _a2.phone) === "N/A" ? "" : phone.value.suffix;
    if (!!phoneString && !phoneString.startsWith("+")) {
      phoneString = `+${phone.value.suffix}`;
    }
    return new DecodeValue(phoneString || "").toDecodedString();
  });
  const phoneDecodedStringFull = computed(() => {
    var _a2;
    let phoneString = "";
    if (((_a2 = customerData.value) == null ? void 0 : _a2.phone) !== "N/A") {
      const prefix = phone.value.prefix.startsWith("+") ? phone.value.prefix.slice(1) : phone.value.prefix;
      const suffix = phone.value.suffix.startsWith("+") ? phone.value.suffix.slice(1) : phone.value.suffix;
      phoneString = `+${prefix}${suffix}`;
    }
    return new DecodeValue(phoneString).toDecodedString();
  });
  const phoneDecoded = computed(
    () => {
      var _a2;
      return phoneStringToPhoneInput(new DecodeValue(((_a2 = customerData.value) == null ? void 0 : _a2.phone) || "").toDecodedString());
    }
  );
  const fullName = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.fullName) || "";
  });
  const verificationType = computed(
    () => {
      var _a2;
      return ((_a2 = customerData.value) == null ? void 0 : _a2.verificationType) ?? null;
    }
  );
  const isCustomerVerificationAsDefaultAvailable = computed(
    () => {
      var _a2;
      return !!((_a2 = customerData.value) == null ? void 0 : _a2.isCustomerVerificationAsDefaultAvailable);
    }
  );
  const verificationStatus = computed(
    () => {
      var _a2;
      return (_a2 = customerData.value) == null ? void 0 : _a2.verificationStatus;
    }
  );
  const isAdditionalVerificationNeeded = computed(() => {
    var _a2;
    return !!((_a2 = customerData.value) == null ? void 0 : _a2.additionalVerificationNeeded);
  });
  const isVerificationPurseCheck = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.verificationLevel) === "PURSE_CHECK";
  });
  const isCountryConfirmed = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.isCountryConfirmed) ?? false;
  });
  const city = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.city) ?? "";
  });
  const address = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.address) ?? "";
  });
  const country = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.country) ?? "";
  });
  const countryName = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.countryName) ?? "";
  });
  const vipStatus = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.vipStatus) ?? 0;
  });
  const customerBetType = computed(() => {
    var _a2;
    return (_a2 = customerData.value) == null ? void 0 : _a2.customerBetType;
  });
  const shortComments = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.shortComments) ?? [];
  });
  const priceChangePolicy = computed(() => {
    var _a2;
    return getSavePriceChangePolicy((_a2 = customerData.value) == null ? void 0 : _a2.priceChangePolicy);
  });
  const totalHandicapPriceChangePolicy = computed(() => {
    var _a2;
    return getSavePriceChangePolicy((_a2 = customerData.value) == null ? void 0 : _a2.totalHandicapPriceChangePolicy);
  });
  const historyFilterOptions = computed(() => {
    var _a2;
    const options = (_a2 = customerData.value) == null ? void 0 : _a2.historyFilterOptions;
    return options ? [...options] : [];
  });
  const defaultHistoryFilterOption = computed(() => historyFilterOptions.value.find((item) => item.isDefault) ?? null);
  const g2svFeatureStatus = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.g2svFeatureStatus) ?? false;
  });
  const g2svCustomerStatus = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.g2svCustomerStatus) ?? false;
  });
  const lastDepositedSystemId = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.lastDepositedSystemId) || null;
  });
  const affiliatePartnerStatus = computed(
    () => {
      var _a2;
      return ((_a2 = customerData.value) == null ? void 0 : _a2.affiliatePartnerStatus) ?? affiliatePartnerStatusNo;
    }
  );
  const isExAllowedCountry = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.exAllowedCountry) ?? false;
  });
  const isForbiddenCountry = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.forbiddenCountry) ?? false;
  });
  const isRegistrationFinish = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.isRegistrationFinish) ?? false;
  });
  const isSimpleRegistrant = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.isSimpleRegistrant) ?? false;
  });
  const personalLink = computed(() => {
    var _a2, _b;
    const personalDomainLink = (_b = (_a2 = customerData.value) == null ? void 0 : _a2.personalDomainData) == null ? void 0 : _b.name;
    if (personalDomainLink !== void 0) {
      if (!personalDomainLink.startsWith("http")) {
        return `https://${personalDomainLink}`;
      }
      return personalDomainLink;
    }
    return "";
  });
  const isEmailConfirmed = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.isEmailConfirmed) ?? false;
  });
  const haveValidEmailValue = computed(() => new RegExp(emailPattern).test(email.value));
  const isAppsFlyerEnabled = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.isAppsFlyerEnabled) ?? false;
  });
  const isAppsFlyerDepositQualified = computed(
    () => {
      var _a2;
      return ((_a2 = customerData.value) == null ? void 0 : _a2.isAppsFlyerDepositQualified) ?? false;
    }
  );
  const isHiddenBalance = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.isHiddenBalance) ?? false;
  });
  const nickname = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.nickname) || "";
  });
  const userLanguageTag = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.userLanguageTag) || "";
  });
  const createPinCodeModalCounter = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.createPinCodeModalCounter) || 0;
  });
  const isPinCodeRequestedOnLogin = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.isPinCodeRequestedOnLogin) ?? false;
  });
  const showSlipOnFirstAdded = computed(() => {
    if (!isLoggedIn.value || !customerData.value) {
      return true;
    }
    return customerData.value.showSlipOnFirstAdded;
  });
  const useStandardBet = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.useStandardBet) ?? false;
  });
  const standardBetAmount = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.standardBetAmount) || null;
  });
  const isEkycRequestable = toRef(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.isEkycRequestable) ?? false;
  });
  const sameStakeForSingleByDefault = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.sameStakeForSingleByDefault) ?? false;
  });
  const fastBets = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.fastBets) ?? null;
  });
  const videoVerificationStatus = computed(
    () => {
      var _a2;
      return ((_a2 = customerData.value) == null ? void 0 : _a2.videoVerificationStatus) ?? null;
    }
  );
  const videoVerificationStatusExpiration = computed(
    () => {
      var _a2;
      if ((_a2 = customerData.value) == null ? void 0 : _a2.videoVerificationStatusExpiration) {
        return customerData.value.videoVerificationStatusExpiration;
      }
      return null;
    }
  );
  const isVideoVerificationOnboardingShown = computed(
    () => {
      var _a2;
      return ((_a2 = customerData.value) == null ? void 0 : _a2.isVideoVerificationOnboardingShown) ?? false;
    }
  );
  const latestRequestedVerificationType = computed(
    () => {
      var _a2;
      return ((_a2 = customerData.value) == null ? void 0 : _a2.latestRequestedVerificationType) ?? "";
    }
  );
  const showVerification = computed(() => {
    var _a2;
    return !!((_a2 = customerData.value) == null ? void 0 : _a2.showVerification);
  });
  const isReferralProgramNotificationClicked = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.customerConfig.isReferralProgramNotificationClicked.value) ?? false;
  });
  const isResponsibleGamblingOnboardingShown = computed(
    () => {
      var _a2;
      return ((_a2 = customerData.value) == null ? void 0 : _a2.customerConfig.isResponsibleGamblingOnboardingShown.value) ?? false;
    }
  );
  const isAchievementsMainOnboardingShown = computed(
    () => {
      var _a2;
      return (_a2 = customerData.value) == null ? void 0 : _a2.customerConfig.isAchievementsMainOnboardingShown.value;
    }
  );
  const isPrivateProfile = computed(
    () => {
      var _a2;
      return (_a2 = customerData.value) == null ? void 0 : _a2.customerConfig.isPrivateProfile.value;
    }
  );
  const isHiddenNickname = computed(
    () => {
      var _a2;
      return (_a2 = customerData.value) == null ? void 0 : _a2.customerConfig.isHiddenNickname.value;
    }
  );
  const responsibleGamingLimits = computed(
    () => {
      var _a2;
      return ((_a2 = customerData.value) == null ? void 0 : _a2.responsibleGamingLimits) ?? [];
    }
  );
  const isVip = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.isVip) ?? false;
  });
  const isLoyaltyProgramShown = computed(
    () => {
      var _a2;
      return (_a2 = customerData.value) == null ? void 0 : _a2.customerConfig.isLoyaltyProgramShown.value;
    }
  );
  const hasFomoBonus = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.hasFomoBonus) ?? false;
  });
  const fomoBonusActionUrl = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.fomoBonusActionUrl) ?? "";
  });
  const fomoBonusCampaignId = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.fomoBonusCampaignId) ?? 0;
  });
  const fomoBonusCategoryId = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.fomoBonusCategoryId) ?? "";
  });
  const isWithdrawalBlockedByVerif = computed(() => {
    var _a2;
    return ((_a2 = customerData.value) == null ? void 0 : _a2.isWithdrawalBlockedByVerif) ?? false;
  });
  const setIsShowTurnOnTfaStep = (value) => {
    isShowTurnOnTfaStep.value = value;
  };
  const setCustomerData = (data) => {
    customerData.value = data;
  };
  function setCustomerConfig(config) {
    customerConfigCache.value = Object.keys(config).reduce((acc, key) => {
      const item = config[key];
      acc[item.key] = item.value;
      return acc;
    }, {});
  }
  function setCustomerOddTypeConfig(isOddsTypeClassic, isOddsTypeAmerican) {
    if (!process.env.VUE_APP_FEATURE_SPORTLINE_ODDS_SELECTOR_ENABLED) {
      return;
    }
    if (isOddsTypeAmerican.value) {
      customerOddTypeConfig.value = isOddsTypeAmerican.key;
      localStorageManager.setItem("odd-type", customerConfigIsOddsTypeAmerican);
    } else {
      customerOddTypeConfig.value = isOddsTypeClassic.key;
      localStorageManager.setItem("odd-type", customerConfigIsOddsTypeClassic);
    }
  }
  const updateLocalCustomerData = (data) => {
    if (!isLoggedIn.value || !customerData.value) {
      return;
    }
    customerData.value = {
      ...customerData.value,
      ...data
    };
  };
  const setupCustomerData = (response) => {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      const { backup } = getCordovaAppConfig();
      if (backup.isEnabled && response.personalAppDomain) {
        void handleDomainUpdates({ personalDomain: response.personalAppDomain });
      }
    }
    setCustomerData(response);
    setCustomerConfig(response.customerConfig);
    const { isOddsTypeClassic, isOddsTypeAmerican } = response.customerConfig;
    setCustomerOddTypeConfig(isOddsTypeClassic, isOddsTypeAmerican);
    setLoggedIn(true);
  };
  const updateCustomerData = async (payload) => {
    const defaults = {
      address: address.value,
      city: city.value,
      phone: phone.value,
      subscribe: String(emailSubscription.value),
      nickname: nickname.value
    };
    await doUpdateCustomerData(
      api,
      (node) => node.mutations.customer.updateUserData,
      {
        options: {
          ...defaults,
          ...payload
        }
      }
    );
  };
  const loadCustomerPhone = async ({ silent }) => {
    const response = await getUserDataPhone(
      api,
      (node) => node.queries.customer.getUserData,
      {
        options: {}
      },
      { silent }
    );
    if (response.customerData) {
      updateLocalCustomerData(response.customerData);
    }
  };
  const savePriceChangePolicy = async (payload = {}) => {
    if (!Object.keys(payload).length) {
      return;
    }
    const oldValue = {
      priceChangePolicy: priceChangePolicy.value,
      totalHandicapPriceChangePolicy: totalHandicapPriceChangePolicy.value
    };
    const nextValue = { ...oldValue, ...payload };
    try {
      updateLocalCustomerData(nextValue);
      await doSavePriceChangePolicyV2(
        api,
        (node) => node.mutations.customer.savePriceChangePolicyV2,
        { options: nextValue }
      );
    } catch {
      updateLocalCustomerData(oldValue);
    }
  };
  const setCustomerConfigCache = (key, value) => {
    if (!customerConfigCache.value) {
      return;
    }
    customerConfigCache.value[key] = value;
  };
  const setForcedVerificationType = (value) => {
    forcedVerificationType.value = value;
  };
  const setResponsibleGamingLimits = (type, isSet) => {
    const limit = responsibleGamingLimits.value.find((item) => item.type === type);
    if (limit) {
      limit.isSet = isSet;
    }
  };
  watch(customerBetType, (newValue) => {
    if (isValidCustomerBetType(newValue)) {
      setBetType(newValue);
    }
  }, { immediate: true });
  const analyticsStore = useAnalyticsCommonDataStore();
  function watchAnalyticsData() {
    watch(login, (newValue) => {
      analyticsStore.setCustomerId(newValue);
    }, { immediate: true });
    watch(isVip, (newValue) => {
      analyticsStore.setIsVip(newValue);
    }, { immediate: true });
    watch(customerBetType, (newValue) => {
      analyticsStore.setCustomerBetType(newValue);
    }, { immediate: true });
    watch(vipStatus, (newValue) => {
      analyticsStore.setCustomerVipStatus(newValue);
    }, { immediate: true });
  }
  watchAnalyticsData();
  return {
    customerOddTypeConfig,
    forcedVerificationType,
    isLoaded,
    customerData,
    email,
    emailSubscription,
    login,
    phone,
    phoneDecodedString,
    phoneDecoded,
    fullName,
    verificationType,
    verificationStatus,
    isVerificationPurseCheck,
    isCountryConfirmed,
    city,
    address,
    country,
    countryName,
    vipStatus,
    priceChangePolicy,
    totalHandicapPriceChangePolicy,
    historyFilterOptions,
    defaultHistoryFilterOption,
    g2svFeatureStatus,
    g2svCustomerStatus,
    lastDepositedSystemId,
    affiliatePartnerStatus,
    isExAllowedCountry,
    isForbiddenCountry,
    isRegistrationFinish,
    isSimpleRegistrant,
    personalLink,
    customerBetType,
    isEmailConfirmed,
    haveValidEmailValue,
    isAppsFlyerEnabled,
    isAppsFlyerDepositQualified,
    isHiddenBalance,
    nickname,
    userLanguageTag,
    shortComments,
    createPinCodeModalCounter,
    isPinCodeRequestedOnLogin,
    showSlipOnFirstAdded,
    useStandardBet,
    standardBetAmount,
    sameStakeForSingleByDefault,
    fastBets,
    videoVerificationStatus,
    videoVerificationStatusExpiration,
    isVideoVerificationOnboardingShown,
    latestRequestedVerificationType,
    isAdditionalVerificationNeeded,
    isAsoTermsConfirmationNeeded,
    isReferralProgramNotificationClicked,
    isResponsibleGamblingOnboardingShown,
    isAchievementsMainOnboardingShown,
    isCustomerVerificationAsDefaultAvailable,
    showVerification,
    customerConfig: customerConfigCache,
    phoneDecodedStringFull,
    isShowTurnOnTfaStep,
    isVip,
    isLoyaltyProgramShown,
    hasFomoBonus,
    fomoBonusActionUrl,
    fomoBonusCampaignId,
    fomoBonusCategoryId,
    setCustomerData,
    setForcedVerificationType,
    updateLocalCustomerData,
    setupCustomerData,
    updateCustomerData,
    loadCustomerPhone,
    savePriceChangePolicy,
    setCustomerConfigCache,
    setIsShowTurnOnTfaStep,
    isEkycRequestable,
    isPrivateProfile,
    isHiddenNickname,
    responsibleGamingLimits,
    setResponsibleGamingLimits,
    isWithdrawalBlockedByVerif
  };
});
function useCustomerLogin() {
  const customerDataStore = useCustomerDataStore();
  const login = toRef(customerDataStore, "login");
  return {
    login
  };
}
function usePriceChangePolicy() {
  const customerDataStore = useCustomerDataStore();
  const priceChangePolicy = toRef(customerDataStore, "priceChangePolicy");
  const totalHandicapPriceChangePolicy = toRef(customerDataStore, "totalHandicapPriceChangePolicy");
  const { savePriceChangePolicy } = customerDataStore;
  return {
    priceChangePolicy,
    totalHandicapPriceChangePolicy,
    savePriceChangePolicy
  };
}
export {
  useCustomerBetTypeStore,
  useCustomerDataStore,
  useCustomerLogin,
  usePriceChangePolicy
};
