import type { FieldGuards } from '@leon-hub/types';
import {
  isNumber,
  isValidInterface,
} from '@leon-hub/guards';

import type {
  CoreSportReferenceResponse,
} from 'web/src/modules/sportline/types/rest';

export const isCoreSportReferenceResponseFieldGuards: FieldGuards<CoreSportReferenceResponse> = {
  id: isNumber,
};

export function isCoreSportReferenceResponse(value: unknown): value is CoreSportReferenceResponse {
  return isValidInterface<CoreSportReferenceResponse>(value, isCoreSportReferenceResponseFieldGuards)
    && Object.keys(value).length === 1;
}
