export interface AnimationOptions {
  timing(progress: number): number;
  duration: number;
  draw(progress: number): void;
}

export default class RequestAnimationFrame {
  private options: AnimationOptions;

  private animationId = 0;

  constructor(options: AnimationOptions) {
    this.options = options;
  }

  start(): void {
    if (this.animationId !== 0) {
      return;
    }

    const start = performance.now();
    const { options } = this;

    const animate = (time: number) => {
      let timeFraction = (time - start) / options.duration;

      if (timeFraction < 0) {
        timeFraction = 0;
      }

      if (timeFraction > 1) {
        timeFraction = 1;
      }

      const progress = options.timing(timeFraction);

      options.draw(progress);

      if (timeFraction < 1) {
        this.animationId = requestAnimationFrame(animate);
        return;
      }

      // animation finished
      this.stop();
    };

    requestAnimationFrame(animate);
  }

  stop(): void {
    cancelAnimationFrame(this.animationId);
    this.animationId = 0;
  }
}
