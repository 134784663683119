import type { MaybeRef } from 'vue';
import { toValue } from 'vue';

import type { ExpandableSportlineBlockKey, ExpandedSportlineBlockMap, SetExpandedStatePayload } from './types';

export function applyExpandedStatePayload(
  payload: SetExpandedStatePayload,
  expandedMap: ExpandedSportlineBlockMap,
  defaultValueRef?: MaybeRef<boolean>,
): ExpandedSportlineBlockMap {
  const expandKey = toValue(payload.expandKey);
  const value = toValue(payload.value);
  const defaultValue = toValue(defaultValueRef);
  const result = { ...expandedMap };

  if (value === defaultValue) {
    delete result[expandKey];
  } else {
    result[expandKey] = value;
  }

  return result;
}

export function getIsExpandedFromMap(
  expandedMap: MaybeRef<ExpandedSportlineBlockMap>,
  expandedKey: MaybeRef<Maybe<ExpandableSportlineBlockKey>>,
  isExpandedByDefault?: MaybeRef<boolean>,
): boolean {
  const expandedMapValue = toValue(expandedMap);
  const expandedKeyValue = toValue(expandedKey);
  const isExpandedByDefaultValue = toValue(isExpandedByDefault ?? true);

  return expandedKeyValue
    ? (expandedMapValue[expandedKeyValue] ?? isExpandedByDefaultValue)
    : isExpandedByDefaultValue;
}
