import { MarketStatus } from '@leon-hub/api-sdk';

import type { EventWithChangedMarket, SlipEntry } from '../types';

export default function getEventsWithChangedMarkets(entries: SlipEntry[]): EventWithChangedMarket[] {
  return entries
    .filter((entry) => {
      if (!entry) return false;
      const { marketStatus } = entry;
      return marketStatus === MarketStatus.PRICE_DOWN || marketStatus === MarketStatus.PRICE_UP;
    })
    .map(({
      event,
      odds,
      market,
      runner,
    }) => ({
      event,
      odds,
      runner,
      market,
    }));
}
