import type { SportFamily } from 'web/src/modules/sportline/enums';
import {
  DefaultSportFamilyEmoji,
  SportFamilyEmoji,
} from 'web/src/modules/sportline/constants';

export default function getSportFamilyEmoji(family?: string | SportFamily): string {
  if (!family) return DefaultSportFamilyEmoji;

  return SportFamilyEmoji[family] || DefaultSportFamilyEmoji;
}
