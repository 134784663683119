import type { RouteLocationRaw } from 'vue-router';

import type { BaseRouteNameType } from '@leon-hub/routing-config-names';
import { isString } from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';

import type { SportlinePersistentLocation } from 'web/src/modules/sportline/submodules/persist-filters/types';
import type { SportlinePageIdentifier } from 'web/src/modules/sportline/types/navigation';

import type {
  LeagueRouteLocationParameters,
  RegionRouteLocationParameters,
  SportEventDetailsRouteLocationParameters,
  SportRouteLocationParameters,
} from '../types';

export function resolveSportsPageLink(target: Partial<SportRouteLocationParameters>): RouteLocationRaw {
  return target.urlName
    ? {
        name: RouteName.SPORTLINE_PREMATCH_EVENTS,
        params: { family: target.urlName.toLowerCase() },
      }
    : {
        name: RouteName.SPORTLINE_PREMATCH_EVENTS,
        params: {},
      };
}

export function resolveRegionPageLink(target: RegionRouteLocationParameters): RouteLocationRaw {
  return {
    name: RouteName.SPORT_REGION,
    params: { ...target },
  };
}

export function resolveLeaguePageLink(target: LeagueRouteLocationParameters): RouteLocationRaw {
  return {
    name: RouteName.SPORT_LEAGUE,
    params: { ...target },
  };
}

export function resolveTopLeaguePageLink(target: LeagueRouteLocationParameters): RouteLocationRaw {
  return {
    name: RouteName.SPORTLINE_TOP_LEAGUE,
    params: { ...target },
  };
}

export function resolveLiveLeaguePageLink(target: LeagueRouteLocationParameters): RouteLocationRaw {
  return {
    name: RouteName.SPORTLINE_LIVE_LEAGUE,
    params: { ...target },
  };
}

export function resolveSportEventsDetailsPageLink(
  target: SportEventDetailsRouteLocationParameters,
): RouteLocationRaw {
  return {
    name: RouteName.SPORT_EVENT_DETAILS,
    params: { ...target },
  };
}

export function resolveLivePageLink(target: Partial<SportRouteLocationParameters>): RouteLocationRaw {
  return {
    name: RouteName.SPORTLINE_LIVE_EVENTS,
    params: target.urlName
      ? { family: target.urlName.toLowerCase() }
      : {},
  };
}

export function resolveCybersportPageLink(
  target: { urlName?: string },
): RouteLocationRaw {
  const regionParameters: { region?: string } = target.urlName
    ? { region: target.urlName.toLowerCase() }
    : {};
  return {
    name: RouteName.SPORTLINE_CYBERSPORT,
    params: { ...regionParameters },
  };
}

function isStringishLocation(value?: string | SportlinePageIdentifier): value is Optional<string> {
  return !value || isString(value);
}

/**
 * Used to resolve links in main header and mobile sportline navigation element
 */
export function resolveHeaderBarsSportlinePageLink(
  to: BaseRouteNameType,
  persistLocation: SportlinePersistentLocation,
): RouteLocationRaw {
  const {
    live,
    sports,
    cybersport,
  } = persistLocation;

  switch (to) {
    case RouteName.SPORTLINE_LIVE_EVENTS:
      return isStringishLocation(live)
        ? resolveLivePageLink({ urlName: live })
        : resolveLivePageLink({ urlName: live.sportFamily });

    case RouteName.SPORTLINE_PREMATCH_EVENTS: {
      if (isStringishLocation(sports)) {
        return resolveSportsPageLink({ urlName: sports });
      }

      if (
        sports.leagueId
        && sports.leagueUrlName
        && sports.regionUrlName
        && sports.sportFamily
      ) {
        return resolveLeaguePageLink({
          id: sports.leagueId,
          urlName: sports.leagueUrlName,
          regionUrlName: sports.regionUrlName,
          sportFamily: sports.sportFamily,
        });
      }

      if (
        sports.regionId
        && sports.regionUrlName
        && sports.sportFamily
      ) {
        return resolveRegionPageLink({
          id: sports.regionId,
          urlName: sports.regionUrlName,
          sportFamily: sports.sportFamily,
        });
      }

      return resolveSportsPageLink({ urlName: sports.sportFamily });
    }

    case RouteName.SPORTLINE_CYBERSPORT:
      return isStringishLocation(cybersport)
        ? resolveCybersportPageLink({ urlName: cybersport })
        : resolveCybersportPageLink({ urlName: cybersport.regionUrlName });

    default:
      return { name: to ?? RouteName.HOME };
  }
}
