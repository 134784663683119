import { getPixelRatio } from '@leon-hub/utils';

import type { Web2ImageSource } from '../types';
import { getImageKey } from './getImageKey';

const defaultPixelRatio = 1;
const pixelRatio = getPixelRatio() ?? defaultPixelRatio;

export function getImageFromImageSource(
  imageSource: Web2ImageSource,
  isSupportWebp = false,
): string {
  let webImage;

  if (isSupportWebp) {
    webImage = imageSource[getImageKey(pixelRatio, true)];
  }

  return webImage || imageSource[getImageKey(pixelRatio)] || imageSource.src;
}
