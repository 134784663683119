import { Tag } from '@leon-hub/tags';

import type { VButtonProps } from '../types';
import { ButtonHeight, ButtonKind, ButtonType } from '../enums';

export function getVButtonDefaultProps(): VButtonProps {
  return {
    tag: Tag.BUTTON,
    label: '',
    kind: ButtonKind.PRIMARY,
    type: ButtonType.BUTTON,
    role: 'button',
    height: ButtonHeight.LARGE,
    hasAnimation: true,
    isUppercase: true,
  };
}
