import type { Ref } from 'vue';
import { computed } from 'vue';

import { useTheme } from '@core/theme';

import type { CustomLogoOptions } from 'web/src/components/Brand/VBrand/composables/utils/types';

import type {
  UseVBrandProps,
} from '../types';
import {
  getLogoUrl,
} from './utils';

export interface VBrandComposable {
  logoUrl: Ref<string>;
}

export default function useVBrand(props: UseVBrandProps): VBrandComposable {
  const {
    isHeader,
    isDark,
    isDarkIcon,
    isSimplifiedLogo,
    customBrand,
  } = props;

  const { isLight } = useTheme();

  const isConstColor = computed(() => process.env.VUE_APP_FEATURE_DARK_HEADER_ENABLED && isHeader.value);
  const customLogoOptions = computed<CustomLogoOptions>(() => (
    process.env.VUE_APP_FEATURE_LOGO_ESPORT_ENABLED && customBrand?.value === 'esport'
      ? { product: 'leonesport' }
      : {}));

  const logoUrl = computed(() => getLogoUrl({
    ...customLogoOptions.value,
    isSimplified: isSimplifiedLogo.value,

    isLight: isConstColor.value ? isDarkIcon.value : (isDarkIcon.value ? true : !isDark.value && isLight.value),
  }));

  return {
    logoUrl,
  };
}
