import { isString } from '@leon-hub/guards';

import type { SportFamily } from 'web/src/modules/sportline/enums';
import type { Sport } from 'web/src/modules/sportline/types';

export default function isSportFamilyEquals(sport?: Maybe<string | SportFamily | Sport>, family?: Maybe<string | SportFamily>): boolean {
  if (!sport || !family) {
    return false;
  }

  const loweredFamily = family.toLowerCase();

  if (isString(sport)) {
    return sport.toLowerCase() === loweredFamily;
  }

  return loweredFamily === sport.navigationParameters.urlName.toLowerCase()
    || loweredFamily === sport.representation.family.toLowerCase();
}
