import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import { useSiteConfigStore } from '@core/site-config';

import { OddsType } from 'web/src/modules/sportline/submodules/odds/enums/OddsType';
import useOddsStore from 'web/src/modules/sportline/submodules/odds/store/useOddsStore';

export interface OddsSettingsComposable {
  isOddsFormatSwitcherEnabled: Ref<boolean>;
  currentOddsType: Ref<OddsType>;
}

export function useOddsSettings(): OddsSettingsComposable {
  if (process.env.VUE_APP_FEATURE_SPORTLINE_ODDS_SELECTOR_DISABLED) {
    return {
      isOddsFormatSwitcherEnabled: ref(false),
      currentOddsType: ref(OddsType.CLASSIC),
    };
  }

  const sportlineBlock = toRef(useSiteConfigStore(), 'sportLineBlock');

  const isOddsFormatSwitcherEnabled = computed<boolean>(() => !!sportlineBlock.value?.oddsFormatSwitcherEnabled);

  const currentOddsType = toRef(useOddsStore(), 'currentOddsType');

  const computedOddsType = computed<OddsType>(() => {
    if (isOddsFormatSwitcherEnabled.value) {
      return currentOddsType.value;
    }
    return OddsType.CLASSIC;
  });

  return {
    isOddsFormatSwitcherEnabled,
    currentOddsType: computedOddsType,
  };
}
