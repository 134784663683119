import { getBatchSlipInfo } from '@leon-hub/api-sdk';

import type { ApplicationGqlClient } from '@core/app-rest-client';

import { OddsType } from 'web/src/modules/sportline/submodules/odds/enums/OddsType';

import type { BatchedSlipInfoDocument, GetBatchedSlipDataPayload } from '../types';

export default function requestBatchedSlipInfo({
  slipEntries,
  slipType = null,
  oddsType = OddsType.CLASSIC,
  silent = false,
}: GetBatchedSlipDataPayload, apiClient: ApplicationGqlClient): Promise<BatchedSlipInfoDocument> {
  return getBatchSlipInfo(apiClient, (node) => node.queries.betSlip.getBatchSlipInfo, {
    options: {
      slipType,
      slipEntries,
      oddsType,
      checkCombiBetAvailability: true,
    },
  }, { silent });
}
