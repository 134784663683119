import type { SlipType } from '@leon-hub/api-sdk';
import { SlipTypeId } from '@leon-hub/api-sdk';

import { SlipTypeSingle } from '../../../constants';

interface Payload {
  betMode: SlipTypeId;
  slipTypes: SlipType[];
  selectedSystemValue: string | null;
  slipSize: number;
}

export default function getMatchedSlipType({
  betMode,
  slipTypes,
  selectedSystemValue,
  slipSize,
}: Payload): string {
  let matchedOption;
  switch (betMode) {
    case SlipTypeId.SINGLE:
      return SlipTypeSingle;
    case SlipTypeId.EXPRESS:
      matchedOption = slipTypes.find((item) => (item && item.typeId === betMode));
      if (matchedOption) {
        return matchedOption.code;
      }
      break;
    case SlipTypeId.SYSTEM:
      if (slipSize < 3) {
        return '';
      }
      if (selectedSystemValue) {
        matchedOption = slipTypes
          .find((item) => (item && item.typeId === betMode && item.code === selectedSystemValue));
      }
      if (!matchedOption) {
        matchedOption = slipTypes.find((item) => (item && item.typeId === betMode));
      }
      return matchedOption ? matchedOption.code : `0_2/${slipSize}`;
    default:
      return '';
  }
  return '';
}
