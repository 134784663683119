import type { FieldGuards } from '@leon-hub/types';
import {
  isBoolean,
  isNumber,
  isString,
  isUndefined,
  isValidInterface,
} from '@leon-hub/guards';

import type {
  MinimalWorkingSportResponse,
} from 'web/src/modules/sportline/types/rest';

export const isMinimalWorkingSportResponseFieldGuards: FieldGuards<MinimalWorkingSportResponse> = {
  id: isNumber,
  name: isString,
  family: isString,
  virtual: [isUndefined, isBoolean],
};

export function isMinimalWorkingSportResponse(value: unknown): value is MinimalWorkingSportResponse {
  return isValidInterface<MinimalWorkingSportResponse>(value, isMinimalWorkingSportResponseFieldGuards);
}
