import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { resolveRoute } from '@leon-hub/navigation-config';

import type { VRouterLinkProps } from '../types';
import type { VRouterLinkComposable } from './types';

export default function useVRouterLink(props: VRouterLinkProps): VRouterLinkComposable {
  const router = useRouter();

  const target = computed(() => resolveRoute(router, props.to));

  const href = computed(() => target.value?.href ?? '');

  return {
    href,
    target,
  };
}
