import { computed, toRef } from 'vue';

import { useSiteConfigStore } from '@core/site-config';

import { useIsDevIP } from 'web/src/modules/core/composables/root';
import { shouldOpenCatalog } from 'web/src/modules/sportline/utils';

import type { OpenInCatalogParameters } from '../types';
import { buildOpenInCatalogParameters } from '../utils';

interface UseOpenInCatalogComposable {
  openInCatalogIfEnabled(event: MouseEvent, params: OpenInCatalogParameters): boolean;
}

export function useOpenInCatalog(): UseOpenInCatalogComposable {
  const siteConfigStore = useSiteConfigStore();
  const { isDevIP } = useIsDevIP();

  const sportLineSettingsBlock = toRef(() => siteConfigStore.sportLineBlock);

  const sportCatalogUrl = computed<Maybe<string>>(() => (
    sportLineSettingsBlock.value?.sportlineCatalogUrl ?? null
  ));

  function openInCatalog(parameters: string): void {
    const url = sportCatalogUrl.value;

    if (!url) {
      throw new Error(`web2.sportline.catalog.url is not configured!
      Set it at /siteconfig#/system/properties2?group=WEB2%20Sportline`);
    }

    window.open(`${url}${parameters}`, '_blank');
  }

  function openInCatalogIfEnabled(event: MouseEvent, params: OpenInCatalogParameters): boolean {
    if (!isDevIP.value || !shouldOpenCatalog(event)) {
      return false;
    }

    event.preventDefault();
    event.stopPropagation();

    openInCatalog(buildOpenInCatalogParameters(params));

    return true;
  }

  return {
    openInCatalogIfEnabled,
  };
}
