<script lang="ts" setup>
import { IconName, IconSize, SlottIconName } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VCheckbox } from '@components/checkbox';
import { VIcon } from '@components/v-icon';

import type {
  VListItemExtendedEmits,
  VListItemExtendedProps,
  VListItemExtendedSlots,
} from '../../types';
import { ListItemExtendedKind } from '../../enums';
import { useVListItemExtended } from './composables/useVListItemExtended';

import $style from '../../styles/common.module.scss';

const props = withDefaults(defineProps<VListItemExtendedProps>(), {
  kind: ListItemExtendedKind.ICON,
  iconName: IconName.STAR,
  haveExpand: true,
});

const emit = defineEmits<VListItemExtendedEmits>();
defineSlots<VListItemExtendedSlots>();

function emitClickCheckbox(): void {
  emit('click-checkbox');
}

function emitClick(): void {
  emit('click');
}

const {
  computedIconProperties,
  hasIcon,
  hasLogo,
  hasCheckbox,
  hasSubTitleContent,
  isLogoDisabled,
  haveSuffix,
} = useVListItemExtended(props);

const expandLeftIconProperties: Readonly<VIconProps> = {
  name: null ? SlottIconName.SLOTT_ANGLE_SMALL_RIGHT : IconName.EXPAND_RIGHT,
  size: null ? IconSize.SIZE_20 : IconSize.SIZE_16,
};

const lockIconProperties: Readonly<VIconProps> = {
  name: IconName.LOCK,
  size: IconSize.SIZE_24,
};

function onClick(): void {
  if (hasCheckbox.value) {
    emitClickCheckbox();
    return;
  }

  emitClick();
}
</script>

<template>
  <li v-auto-id="'VListItemExtended'"
    :class="{
      [$style['list-item-extended']]: true,
      [$style[`list-item-extended--kind-${kind}`]]: kind !== ListItemExtendedKind.ICON,
      [$style[`list-item-extended--icon-background__${iconBackground}`]]: !!iconBackground,
      [$style[`list-item-extended--subtitle-first-color__${subTitleFirstColor ?? 'default'}`]]: true,
      [$style['list-item-extended--disabled']]: isDisabled,
    }"
    @click.prevent="onClick"
  >
    <div
      :class="$style['list-item-extended__inner']"
    >
      <div
        :class="$style['list-item-extended__prefix']"
      >
        <div
          v-if="hasIcon"
          :class="$style['list-item-extended__prefix-icon']"
        >
          <VIcon v-bind="computedIconProperties" />
        </div>
        <div
          v-else-if="hasLogo"
          :class="$style['list-item-extended__prefix-logo']"
        >
          <VIcon
            v-if="isDisabled"
            v-bind="lockIconProperties"
            :class="$style['list-item-extended__prefix-lock']"
          />
          <slot
            name="logo-image"
          />
        </div>
        <VCheckbox
          v-else-if="hasCheckbox"
          :id="`${title}_checkbox`"
          :checked="isChecked"
          :class="$style['list-item-extended__prefix-checkbox']"
        />
      </div>
      <div
        :class="$style['list-item-extended__default']"
      >
        <span
          :class="$style['list-item-extended__title']"
        >{{ title }}</span>
        <div
          v-if="hasSubTitleContent"
          :class="$style['list-item-extended__subtitle-content']"
        >
          <span
            v-if="!!subTitleFirst && (!isLogoDisabled || hideUnavailableMessage)"
            :class="$style['list-item-extended__subtitle']"
          >
            {{ subTitleFirst }}
          </span>
          <span
            v-if="!!subTitleSecond && !isLogoDisabled"
            :class="$style['list-item-extended__subtitle']"
          >
            {{ subTitleSecond }}
          </span>
          <span
            v-else-if="isLogoDisabled && !hideUnavailableMessage"
            :class="$style['list-item-extended__subtitle--locked']"
          >
            {{ $t('WEB2_SERVICE_UNAVAILABLE') }}
          </span>
        </div>
      </div>
      <div
        v-if="haveSuffix"
        :class="$style['list-item-extended__suffix']"
      >
        <VIcon
          v-bind="expandLeftIconProperties"
        />
      </div>
    </div>
  </li>
</template>
