<script lang="ts" setup>
import {
  inject,
  onMounted,
  useSlots,
  watch,
} from 'vue';

import { Tag } from '@leon-hub/tags';

import { VIcon } from '@components/v-icon';

import type { VAccordionSharedState } from 'web/src/components/Accordion/types';
import { AccordionItemType } from 'web/src/components/Accordion/types';

import type { VAccordionItemEmits, VAccordionItemProps } from './types';
import { useVAccordionItem } from './composables';

const props = withDefaults(defineProps<VAccordionItemProps>(), {
  tag: Tag.DIV,
  title: 'No title provided',
  indicator: true,
  itemType: AccordionItemType.BASIC,
});

const emit = defineEmits<VAccordionItemEmits>();
const slots = useSlots();

const accordionSharedState = inject('accordionSharedState', {} as VAccordionSharedState);

const {
  noIconProperties,
  isOpen,
  hasHeaderSlot,
  hasSuffixSlot,
  hasPrefixSlot,
  hasComputedIcon,
  hasEmptyIcon,
  hasPrefix,
  rootTag,
  headerTag,
  contentTag,
  computedIconProperties,
  computedIndicatorProperties,
  independentlyOpenToggle,
} = useVAccordionItem(props, slots, accordionSharedState);

function emitToggle() {
  emit('toggle', props.id);
}

function emitClose() {
  emit('close', props.id);
}

function emitOpen() {
  emit('open', props.id);
}

function toggle(silent = false): void {
  if (accordionSharedState.controlled) {
    if (!silent) {
      emitToggle();
      if (props.open) {
        emitClose();
      } else {
        emitOpen();
      }
    }
    return;
  }

  independentlyOpenToggle();

  if (!accordionSharedState.independentItems) {
    accordionSharedState.openItem = isOpen.value ? null : props.id;
  }
}

onMounted(() => {
  if (props.open) {
    toggle(true);
  }
});

watch(() => isOpen.value, (newValue: boolean) => {
  if (!accordionSharedState.controlled) {
    emitToggle();
    if (newValue) {
      emitOpen();
    } else {
      emitClose();
    }
  }
});
</script>

<template>
  <component v-auto-id="'VAccordionItem'"
    :is="rootTag"
    :class="{
      [$style['accordion-item']]: true,
      [$style['accordion-item--large']]: isLarge,
      [$style['accordion-item--open']]: isOpen,
      [$style['accordion-item--panel']]: !!accordionSharedState.panels,
      [$style['accordion-item--no-background']]: !!noBackground,
      // Disable default styling when panels or header slot used, or when header has inline layout
      [$style['accordion-item--default']]: !hasHeaderSlot && !accordionSharedState.panels && !props.inline,
      [$style['accordion-item--how-to']]: itemType === AccordionItemType.HOW_TO,
    }"
  >
    <slot
      name="header"
      :is-open="isOpen"
      :toggle="toggle"
    >
      <component
        :is="headerTag"
        :class="{
          [$style['accordion-item__header']]: true,
          [$style['accordion-item__header--open']]: isOpen,
          [$style['accordion-item__header--clickable']]: !accordionSharedState.controlled,
          [$style['accordion-item__header--inline']]: inline,
          [$style['accordion-item__header--center']]: !!isCenteredTitle,
        }"
        @click="toggle(false)"
      >
        <div
          v-if="hasPrefix"
          :class="$style['accordion-item__prefix']"
        >
          <VIcon
            v-if="hasComputedIcon && computedIconProperties"
            v-bind="computedIconProperties"
          />
          <VIcon
            v-else-if="hasEmptyIcon"
            v-bind="noIconProperties"
          />
          <slot
            v-else-if="hasPrefixSlot"
            name="prefix"
            :is-open="isOpen"
            :toggle="toggle"
          />
          <div v-else>
            Something went wrong
          </div>
        </div>
        <div :class="$style['accordion-item__title']">
          <slot
            name="title"
            :is-open="isOpen"
            :toggle="toggle"
          >
            {{ title }}
          </slot>
        </div>
        <div
          v-if="hasSuffixSlot"
          :class="$style['accordion-item__suffix']"
        >
          <slot
            name="suffix"
            :is-open="isOpen"
            :toggle="toggle"
          />
        </div>
        <div
          v-if="computedIndicatorProperties"
          :class="$style['accordion-item__indicator']"
        >
          <VIcon
            :class="$style['accordion-item__indicator-icon']"
            v-bind="computedIndicatorProperties"
          />
        </div>
      </component>
    </slot>
    <component
      :is="contentTag"
      v-show="isOpen"
      :class="$style['accordion-item__content']"
    >
      <slot
        :is-open="isOpen"
        :toggle="toggle"
      >
        No content provided for {{ id }}
      </slot>
    </component>
  </component>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .accordion-item {
    margin-bottom: $accordionItemMarginBottom;
    overflow: hidden;

    &--panel {
      background-color: var(--Layer1);
      border-radius: $accordionItemPanelBorderRadius;
    }

    &--large {
      min-height: 64px;
      margin-bottom: 8px;

      .accordion-item__indicator,
      .accordion-item__header {
        height: 64px;
      }
    }

    &__header {
      @include accordionItemHeaderFont;

      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: $accordionItemHeaderMinHeight;
      padding-left: $accordionItemHeaderPaddingLeft;
      color: $accordionItemHeaderColor;
      background-color: $accordionItemHeaderBackgroundColor;
      border-radius: $accordionItemPanelBorderRadius;

      @if $isDesktop {
        cursor: pointer;
      }

      @include for-hover {
        &:hover {
          color: var(--TextDefault);
          cursor: pointer;
          background-color: var(--Highlight);
        }
      }

      &--center {
        padding-left: 0;

        .accordion-item__title {
          width: 100%;
          max-width: 100%;
          text-align: center;
        }
      }

      &--open {
        color: var(--TextDefault);
        border-radius: $accordionItemPanelOpenBorderRadius;

        &:hover {
          background-color: var(--Layer1);
        }
      }

      &--large {
        height: $accordionItemLarge;
      }
    }

    &__indicator {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      color: $accordionItemIconColor;
    }

    &__content {
      background-color: var(--Layer1);
      border-radius: $accordionItemContentBorderRadius;
    }

    &__title {
      max-width: calc(100% - 56px);
      padding: 12px 0;
    }
  }
}
</style>
