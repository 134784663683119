<script lang="ts">
import type {
  Component,
  PropType,
} from 'vue';
import {
  computed,
  defineComponent,
  h,
  inject,
  useCssModule,
  watch,
} from 'vue';

import { Color } from '@leon-hub/colors';
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';

import { ButtonKind, VButton } from '@components/buttons';
import { SHintBlock, VHintBlock } from '@components/hint-block';
import { VImage } from '@components/image';
import { VList, VListItem, VListItemAccordion, VListItemExtended } from '@components/list';
import { VIcon } from '@components/v-icon';

import VAccordion from 'web/src/components/Accordion/VAccordion/VAccordion.vue';
import VAccordionItem from 'web/src/components/Accordion/VAccordionItem/VAccordionItem.vue';
import VAnimationStarter from 'web/src/components/AnimationStarter/VAnimationStarter/VAnimationStarter.vue';
import VBrand from 'web/src/components/Brand/VBrand/VBrand.vue';
import VCard from 'web/src/components/Card/VCard/VCard.vue';
import VDynamicContentLink from 'web/src/components/DynamicContent/VDynamicContentLink/VDynamicContentLink.vue';
import SubHeader from 'web/src/components/SubHeader/VSubHeader/VSubHeader.vue';
import TextSlotPlaceholder from 'web/src/components/TextSlotPlaceholder/components/TextSlotPlaceholder.vue';
import BonusLoyaltyProgramBalance
  from 'web/src/modules/bonuses/components/BonusLoyaltyProgramBalance/BonusLoyaltyProgramBalance.vue';
import { replaceContentTags } from 'web/src/utils/html';

// eslint-disable-next-line vue/one-component-per-file
export default defineComponent({
  name: 'VDynamicContent',
  props: {
    tag: {
      type: String as PropType<Tag>,
      default: Tag.DIV,
    },
    content: {
      type: String,
      default: '',
    },
    isCmsContent: {
      type: Boolean,
    },
    noMargins: {
      type: Boolean,
    },
    skipReplaceLinks: {
      type: Boolean,
    },
  },
  emits: [
    'open-info',
    'open-faq',
    'click-link',
    'close',
    'loyalty-block-click',
    'click',
    'restore-password-phone-check-go-to-email',
    'registration-phone-check-go-to-email-registration',
    'registration-phone-check-go-to-phone-check-by-sms',
    'restore-password-phone-check-go-to-phone-check-by-sms',
    'achievement-gift-item-link-click',
  ],
  setup(props, { attrs, emit }) {
    const cmsData = inject<object>('cmsData', {});
    const getMetaData = inject('getMetaData', () => {});
    const $style = useCssModule();

    const replacedLinksContent = computed<string>(() => ((
      props.skipReplaceLinks
        ? props.content
        : replaceContentTags(props.content || '', 'a', 'VDynamicContentLink', {
            'is-cms-content': props.isCmsContent,
          })
    )));

    const baseComponentOptions = {
      components: {

        Button: VButton,
        VButton,
        VIcon,
        VAccordion,
        VAccordionItem,
        VImage,
        VDynamicContentLink,
        List: VList,
        ListItem: VListItem,
        ListItemExtended: VListItemExtended,
        ListItemAccordion: VListItemAccordion,
        VList,
        VListItem,
        VListItemExtended,
        VListItemAccordion,
        TextSlotPlaceholder,
        SubHeader,
        VCard,
        BonusLoyaltyProgramBalance,
        Brand: VBrand,
        VBrand,
        VHintBlock,
        VAnimationStarter,
        ...process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? {
              SHintBlock,
            }
          : {},
      },
      data: () => ({
        cmsData: Object.freeze(cmsData),
        IconName: Object.freeze(IconName),
        Color: Object.freeze(Color),
        IconSize: Object.freeze(IconSize),
        ButtonKind: Object.freeze(ButtonKind),
        Tag: Object.freeze(Tag),
      }),
      computed: {
        metaData: () => getMetaData(),
      },
    };

    let newComponent: string | Component = 'div';

    watch(replacedLinksContent, (newValue) => {
      // eslint-disable-next-line vue/one-component-per-file
      newComponent = defineComponent({
        ...baseComponentOptions,
        template: `<${props.tag} :class="{'${$style['no-margins']}': ${props.noMargins}}">${newValue}</${props.tag}>`,
      });
    }, {
      immediate: true,
    });

    return () => h(newComponent, {
      ...attrs,
      onOpenInfo(val: string) {
        emit('open-info', val);
      },
      onOpenFaq() {
        emit('open-faq');
      },
      onClickLink($event: { href: string }) {
        emit('click-link', $event);
      },
      onClose() {
        emit('close');
      },
      onLoyaltyBlockClick($event: string) {
        emit('loyalty-block-click', $event);
      },
      onClick() {
        emit('click');
      },
      onRestorePasswordPhoneCheckGoToEmail() {
        emit('restore-password-phone-check-go-to-email');
      },
      onRegistrationPhoneCheckGoToEmailRegistration() {
        emit('registration-phone-check-go-to-email-registration');
      },
      onRegistrationPhoneCheckGoToPhoneCheckBySms() {
        emit('registration-phone-check-go-to-phone-check-by-sms');
      },
      onRestorePasswordPhoneCheckGoToPhoneCheckBySms() {
        emit('restore-password-phone-check-go-to-phone-check-by-sms');
      },
      onAchievementGiftItemLinkClick(route: string, text: string) {
        emit('achievement-gift-item-link-click', route, text);
      },
    });
  },
});
</script>

<style lang="scss" module>
.no-margins {
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}
</style>
