import {
  isString,
} from '@leon-hub/guards';

import type {
  CoreSportResponse,
  GetSportsSportResponse,
} from 'web/src/modules/sportline/types/rest';
import type { SportFamilyMapping } from 'web/src/modules/sportline/types/settings';

export type MatchSportFamilySource = string
| Pick<CoreSportResponse, 'id' | 'family'>
| Pick<GetSportsSportResponse, 'id' | 'family'>;

export function matchSportFamily(source: MatchSportFamilySource, map?: SportFamilyMapping | null): string {
  if (isString(source)) {
    return source.toLowerCase();
  }

  if (!map) {
    return source.family.toLowerCase();
  }

  const id = String(source.id);
  const family = map[id];

  // LEONWEB-2609 return family from map or old data
  return (family || source.family).toLowerCase();
}
