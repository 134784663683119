import { toRef } from 'vue';

import {
  Events as AnalyticsEvent,
} from '@leon-hub/yandex-metrika';

import { useGoogleAnalytics } from '@core/analytics';
import { ServerDate } from '@core/app-settings';
import { useIsLoggedIn } from '@core/auth';
import { useCountryStore } from '@core/country';
import { useCustomerDataStore } from '@core/customer';

import type { ShareSuccessLogPayload } from '../submodules/slip-share/types';

interface UseShareEventLogging {
  logShareSuccess(data: ShareSuccessLogPayload): void;
  logLinkCopied(data: ShareSuccessLogPayload): void;
  logUnableToShare(message: string, shareData: Omit<ShareSuccessLogPayload, 'shareCode'>): void;
  logGetSharedCouponSuccess(data: ShareSuccessLogPayload): void;
  logGetSharedCouponError(shareCode: string, message: string): void;
}

/**
 * LEONWEB-14699
 * https://docs.google.com/document/d/11mZcLO99c-1jUNtVDGZECrtyZMBCAHMNkTpzxkpLZOc/edit?usp=sharing
 */

type LoggedStatus = 'logged_in' | 'logged_out';

interface CommonLogData {
  customer_id: string;
  customer_status: LoggedStatus;
  vs_lw: boolean;
  full_url: string; // 'https://3956.fun/' or any site mirror with Page Path
  country: string; // country code;
}

interface SuccessLogData {
  bet_type: string; // 'single' or system, or any other relevant value
  bet_count: number; // number of events included in the coupon
  bet_total_odd: string; // '6.57' or '2 | 1.2 | 3.3' for multisingles,
  bet_share_code: string;
}

export function useShareEventLogging(): UseShareEventLogging {
  const { pushGTM } = useGoogleAnalytics();

  const { isLoggedIn } = useIsLoggedIn();

  const customerDataStore = useCustomerDataStore();

  const customerId = toRef(customerDataStore, 'login');
  const isVip = toRef(customerDataStore, 'isVip');
  const customerCountry = toRef(customerDataStore, 'country');

  const countryCode = toRef(useCountryStore(), 'countryCode');

  const getCommonLogData = (): CommonLogData => ({
    customer_id: customerId.value,
    customer_status: isLoggedIn.value ? 'logged_in' : 'logged_out',
    vs_lw: isVip.value,
    full_url: document.location.href,
    country: customerCountry.value || countryCode.value,
  });

  const getSuccessPayload = (payload: ShareSuccessLogPayload): SuccessLogData => ({
    // LEONWEB-15173 - data for the moment share button was pressed, related to generated image
    bet_type: payload.betMode.toLowerCase(),
    bet_count: payload.eventsCount,
    bet_total_odd: payload.odds,
    bet_share_code: payload.shareCode ?? '',
  });

  const logShareSuccess = (data: ShareSuccessLogPayload): void => {
    pushGTM(AnalyticsEvent.BET_SHARE, {
      ...getCommonLogData(),
      ...getSuccessPayload(data),
      bet_share_timestamp: ServerDate.now(),
    });
  };

  const logLinkCopied = (data: ShareSuccessLogPayload): void => {
    pushGTM(AnalyticsEvent.BET_SHARE_COPY_LINK, {
      ...getCommonLogData(),
      ...getSuccessPayload(data),
      bet_copy_timestamp: ServerDate.now(),
    });
  };

  const logUnableToShare = (message: string, { betMode, eventsCount }: Omit<ShareSuccessLogPayload, 'shareCode'>): void => {
    pushGTM(AnalyticsEvent.BET_SHARE_ERROR, {
      ...getCommonLogData(),
      bet_type: betMode.toLowerCase(),
      bet_count: eventsCount,
      bet_share_error_message: message,
      bet_share_error_timestamp: ServerDate.now(),
    });
  };

  const logGetSharedCouponSuccess = ({ shareCode, betMode, eventsCount, odds }: ShareSuccessLogPayload): void => {
    pushGTM(AnalyticsEvent.SHARED_BET_LOAD, {
      ...getCommonLogData(),
      bet_type: betMode.toLowerCase(),
      bet_count: eventsCount,
      bet_total_odd: odds,
      bet_share_code: shareCode,
      bet_load_timestamp: ServerDate.now(),
    });
  };

  const logGetSharedCouponError = (shareCode: string, message: string): void => {
    pushGTM(AnalyticsEvent.SHARED_BET_ERROR, {
      ...getCommonLogData(),
      bet_share_code: shareCode,
      bet_load_error_message: message,
      bet_load_error_timestamp: ServerDate.now(),
    });
  };

  return {
    logShareSuccess,
    logLinkCopied,
    logUnableToShare,
    logGetSharedCouponSuccess,
    logGetSharedCouponError,
  };
}
