import type { FieldGuards } from '@leon-hub/types';
import {
  isNumber,
  isString,
  isValidInterface,
} from '@leon-hub/guards';

import type {
  CoreLeagueResponse,
  MinimalWorkingLeagueResponse,
} from 'web/src/modules/sportline/types/rest';

import { isCoreRegionReferenceResponse } from './isCoreRegionReferenceResponse';
import { isCoreRegionResponse } from './isCoreRegionResponse';
import { isCoreSportReferenceResponse } from './isCoreSportReferenceResponse';
import { isMinimalWorkingSportResponse } from './isMinimalWorkingSportResponse';

export const isMinimalWorkingLeagueResponseFieldGuards: FieldGuards<MinimalWorkingLeagueResponse> = {
  id: isNumber,
  region: [isCoreRegionReferenceResponse, isCoreRegionResponse],
  name: isString,
  nameDefault: isString,
  sport: [isCoreSportReferenceResponse, isMinimalWorkingSportResponse],
  url: isString,
};

export function isMinimalWorkingLeagueResponse(value: unknown): value is CoreLeagueResponse {
  return isValidInterface<MinimalWorkingLeagueResponse>(value, isMinimalWorkingLeagueResponseFieldGuards);
}
