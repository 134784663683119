import { logger } from '@leon-hub/logging';

import type {
  BuildSportOptions,
  CoreLeagueResponse,
  CoreRegionResponse,
  CoreSportEventResponse,
  CoreSportResponse,
  GetSportEventsResponse,
  ParseLineFactoryOptions,
  ParseLiveStatusOptions,
} from 'web/src/modules/sportline/types/rest';
import {
  ParseSportEventError,
} from 'web/src/modules/sportline/errors/rest';
import {
  extractBuildSportOptions,
  ResponseMappings,
} from 'web/src/modules/sportline/utils/rest/pre-build';

export abstract class AbstractLineFactory<
  ListElement,
  Result,
> {
  /**
   * Storage for responses. Used to find response by reference in response (like { id: string })
   */
  protected responseMappings = new ResponseMappings();

  /**
   * Accumulator for parse errors
   */
  protected errors: ParseSportEventError[] = [];

  readonly buildSportOptions: BuildSportOptions;

  protected abstract result: Result;

  constructor(
    protected readonly response: GetSportEventsResponse,
    options?: BuildSportOptions & ParseLiveStatusOptions,
    protected readonly factoryOptions?: ParseLineFactoryOptions,
  ) {
    this.buildSportOptions = extractBuildSportOptions(options);
  }

  /**
   * Fill up response mappings and try to build events
   */
  protected parseResponse(): Result {
    for (const eventResponse of this.response.events || []) {
      try {
        const leagueResponse = this.responseMappings.getLeagueResponseOrSetNew(eventResponse.league);
        const regionResponse = this.responseMappings.getRegionResponseOrSetNew(leagueResponse.region);
        const sportResponse = this.responseMappings.getSportResponseOrSetNew(leagueResponse.sport);
        this.processEventResponse({
          sportResponse,
          regionResponse,
          leagueResponse,
          eventResponse,
        });
      } catch (error) {
        if (error instanceof ParseSportEventError) {
          this.errors.push(error);
        } else {
          throw error;
        }
      }
    }

    if (this.errors.length > 0) {
      logger.error('Sportline: parse sportline error occurred', {
        eventsIds: this.errors.map((error) => error.response?.id).filter((id) => !!id),
        messages: [...new Set(this.errors.map((error) => error.message))],
        buildOptions: this.buildSportOptions,
      });
    }

    return this.result;
  }

  /**
   * Process event responses
   * Fill up result
   * @abstract
   */
  protected abstract processEventResponse(options: {
    sportResponse: CoreSportResponse;
    regionResponse: CoreRegionResponse;
    leagueResponse: CoreLeagueResponse;
    eventResponse: CoreSportEventResponse;
  }): void;

  /**
   * Main build line method
   * @abstract
   */
  abstract build(): ListElement[];
}
