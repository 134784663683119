<script lang="ts" setup>
import VHeadlineBanner from 'web/src/components/HeadlineBanner/VHeadlineBanner/VHeadlineBanner.vue';

import type { MainBannerItemEmits, MainBannerItemProps } from './types';
import { useMainBannerItem } from './composables';

const props = withDefaults(defineProps<MainBannerItemProps>(), {
  aspectRatio: 3,
  isActive: true,
});
const emit = defineEmits<MainBannerItemEmits>();

const {
  item,
  bannerProperties,
} = useMainBannerItem(props);
</script>

<template>
  <VHeadlineBanner v-auto-id="'MainBannerItem'"
    ref="item"
    v-bind="bannerProperties"
    :class="{
      [$style['bn-item']]: true,
      [$style[`bn-item--location-${banner.location}`]]: !!banner.location,
    }"
    @click="emit('click', $event)"
  />
</template>

<style lang="scss" module>
.bn-item {
  &--hovered {
    cursor: pointer;
  }
}
</style>
