import type { Ref } from 'vue';
import { toRef } from 'vue';

import { useRootStore } from '@core/root';

export interface UseIsDevIP {
  isDevIP: Ref<boolean>;
}

function useIsDevIP(): UseIsDevIP {
  const isDevIP = toRef(useRootStore(), 'isDevIP');

  return {
    isDevIP,
  };
}

export default useIsDevIP;
