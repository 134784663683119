import type {
  RegionElement,
  SportEvent,
  SportEventElement,
} from 'web/src/modules/sportline/types';

import {
  getSportEventElementsListCounters,
  reduceListWithSportEventsCounters,
} from '../getCounters';

export function filterRegionElementEvents<SE extends SportEvent = SportEvent>(
  regionElement: Readonly<RegionElement<SE>>,
  filter: (sportEventElement: SportEventElement<SE>) => boolean,
): RegionElement<SE> {
  const leagues = regionElement.leagues
    .map((leagueElement) => {
      const sportEvents = leagueElement.sportEvents.filter((sportEvent) => filter(sportEvent));
      const outrightEvents = leagueElement.outrightEvents
        .filter((sportEvent) => filter(sportEvent as SportEventElement<SE>));

      return {
        ...leagueElement,
        sportEvents,
        outrightEvents,
        counters: getSportEventElementsListCounters([...sportEvents, ...outrightEvents]),
      };
    })
    .filter((leagueElement) => leagueElement.sportEvents.length > 0
      || leagueElement.outrightEvents.length > 0);

  return {
    ...regionElement,
    leagues,
    counters: reduceListWithSportEventsCounters(leagues),
  };
}

export function filterRegionsListEvents<SE extends SportEvent = SportEvent>(
  regionElements: ReadonlyArray<RegionElement<SE>>,
  filter: (sportEventElement: SportEventElement<SE>) => boolean,
): RegionElement<SE>[] {
  return regionElements
    .map((regionElement) => filterRegionElementEvents<SE>(regionElement, filter))
    .filter((regionElement) => regionElement.leagues.length > 0);
}

export function filterEmptyRegionElements<SE extends SportEvent = SportEvent>(
  regionElements: ReadonlyArray<RegionElement<SE>>,
): RegionElement<SE>[] {
  return regionElements
    .filter((regionElement) => regionElement.leagues
      .some((leagueElement) => leagueElement.sportEvents.length > 0
        || leagueElement.outrightEvents.length > 0));
}
