import type { MaybeRef, Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { SportlineType } from 'web/src/modules/sportline/enums';
import type { SportlineEvent, SportlineEventId } from 'web/src/modules/sportline/types';
import type { CheckMaybe } from 'web/src/modules/sportline/types/utils';
import {
  isLiveSportlineEvent,
  isOutrightSportlineEvent,
  isPrematchSportlineEvent,
} from 'web/src/modules/sportline/utils/sportlineEvent/sportlineTypeCheck';

interface UseSportlineEventTypeProps {
  sportEvent: Ref<Optional<Maybe<SportlineEvent>>>;
}

interface UseSportlineEventTypeComposable {
  sportlineEventId: Ref<Optional<SportlineEventId>>;
  sportlineType: Ref<Optional<SportlineType>>;
  isLive: Ref<boolean>;
  isPrematch: Ref<boolean>;
  isOutright: Ref<boolean>;
}

export function useSportlineEventTypeRef<E extends Maybe<SportlineEvent>>(sportlineEvent: MaybeRef<E>): Ref<CheckMaybe<E, SportlineType>> {
  const sportlineEventRef = toRef(sportlineEvent);
  return computed(() => (sportlineEventRef.value?.type ?? null) as CheckMaybe<E, SportlineType>);
}

export function useIsOutrightSportlineEvent(sportlineEvent: Ref<Optional<Maybe<SportlineEvent>>>): Ref<boolean> {
  return computed<boolean>(() => isOutrightSportlineEvent(sportlineEvent.value));
}

export function useSportlineEventType(
  props: UseSportlineEventTypeProps,
): UseSportlineEventTypeComposable {
  const { sportEvent } = props;

  const sportlineEventId = computed<Optional<string>>(() => sportEvent.value?.id);
  const sportlineType = computed<Optional<SportlineType>>(() => sportEvent.value?.type);
  const isLive = computed(() => isLiveSportlineEvent(sportEvent.value));
  const isPrematch = computed(() => isPrematchSportlineEvent(sportEvent.value));

  return {
    sportlineEventId,
    sportlineType,
    isLive,
    isPrematch,
    isOutright: useIsOutrightSportlineEvent(sportEvent),
  };
}
