import { AppErrorCode } from '@leon-hub/app-errors';

export class SportlineRestErrorCode extends AppErrorCode {
  static readonly MISSING_EVENT = new AppErrorCode('MISSING');

  static readonly INVALID_CTAG = new AppErrorCode('INVALID_CODE');

  static readonly INVALID_VTAG = new AppErrorCode('invalid');

  static readonly MISSING_EVENT_STATISTIC = new AppErrorCode('UNKNOWN_EVENT_ID');
}
