<script lang="ts" setup>
import {
  watch,
} from 'vue';

import type {
  VListItemAccordionEmits,
  VListItemAccordionExpose,
  VListItemAccordionProps,
  VListItemAccordionSlots,
} from '../../types';
import { useVListItemAccordion } from '../../composables/useVListItemAccordion';
import { ListItemAccordionKind, ListItemSuffixAlign } from '../../enums';
import VListItem from '../VListItem/VListItem.vue';
import VListItemInner from '../VListItemInner/VListItemInner.vue';

import $style from '../../styles/common.module.scss';

const props = withDefaults(defineProps<VListItemAccordionProps>(), {
  iconName: undefined,
  countryCode: '',
  suffixText: '',
  counter: '',
  kind: ListItemAccordionKind.DEFAULT,
});

const emit = defineEmits<VListItemAccordionEmits>();

defineSlots<VListItemAccordionSlots>();

const {
  isOpen,
  iconSuffixProperties,
  iconNameAccordion,
  isIndependentlyOpen,
  $accordionSharedState,
  toggle,
  listItemRef,
  scrollIntoView,
} = useVListItemAccordion(props, emit);

watch(() => props.open, (newValue) => {
  if (props.isDynamicOpen) {
    toggle(newValue);
    return;
  }

  if (newValue && !props.haveSwitcher) {
    toggle(true);
    return;
  }

  if (props.haveSwitcher) {
    isIndependentlyOpen.value = Boolean(newValue);
    if (!$accordionSharedState.independentItems) {
      $accordionSharedState.updateOpenItem(isOpen.value ? null : props.id);
    }
  }
}, {
  immediate: !props.isDynamicOpen,
});

defineExpose<VListItemAccordionExpose>({
  scrollIntoView,
});
</script>

<template>
  <VListItem v-auto-id="'VListItemAccordion'"
    :id="id"
    :class="{
      [$style['list-item-accordion']]: true,
      [$style['list-item-accordion--open']]: isOpen,
      [$style['list-item-accordion--have-switcher']]: haveSwitcher,
      [$style[`list-item-accordion--kind-${props.type}`]]: props.type !== ListItemAccordionKind.DEFAULT,
      [$style['list-item-accordion--done']]: props.type === ListItemAccordionKind.PROMO && isDone,
      [$style['list-item-accordion--no-background']]: noBackground,
      [$style['list-item-accordion--big-margin']]: isBigMargin,
    }"
  >
    <VListItemInner
      ref="listItemRef"
      :title="title"
      :have-switcher="haveSwitcher"
      :icon-name="iconNameAccordion"
      :country-code="countryCode"
      :icon-suffix-name="iconSuffixProperties"
      :is-switch-disabled="isSwitchDisabled"
      :is-switch-checked="isOpen"
      :is-title-centered="isTitleCentered"
      :suffix-text="suffixText"
      :counter="counter"
      :suffix-align="ListItemSuffixAlign.TOP"
      @click="toggle()"
    >
      <template
        v-if="$slots.inner"
        #inner
      >
        <slot name="inner" />
      </template>
    </VListItemInner>
    <div
      v-show="isOpen"
      :class="$style['list-item-accordion__content']"
    >
      <slot
        :is-open="isOpen"
        :toggle="toggle"
        name="content"
      >
        No content provided for {{ id }}
      </slot>
    </div>
  </VListItem>
</template>
