import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SelectedColumnInfoElement } from 'web/src/modules/sportline/composables/select-main-market';
import type { MarketsColumn, MarketType } from 'web/src/modules/sportline/types';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';
import { useProvideSelectedColumn } from 'web/src/modules/sportline/composables/select-main-market';

interface UseProvideMarketColumnProps {
  basisKey: Ref<SportlineFragmentBasisKey>;
  isMarketTypesSelectionEnabled: Ref<boolean>;
  marketsColumns: Ref<MarketsColumn[]>;
  marketsTypes: Ref<MarketType[]>;
}

interface UseProvideMarketColumnComposable {
  canSelectMarketType: Ref<boolean>;
}

/**
 * @deprecated fallback for the old components
 */
export function useProvideMarketColumn(
  props: UseProvideMarketColumnProps,
): UseProvideMarketColumnComposable {
  const {
    isMarketTypesSelectionEnabled,
    marketsColumns,
    marketsTypes,
    basisKey,
  } = props;

  const sportlineElements = computed<SelectedColumnInfoElement[]>(() => [{
    marketsColumns: marketsColumns.value,
    marketsTypes: marketsTypes.value,
    key: basisKey.value,
  }]);

  useProvideSelectedColumn({ sportlineElements });

  const canSelectMarketType = computed<boolean>(() => (
    isMarketTypesSelectionEnabled.value && marketsColumns.value.length > 0
  ));

  return { canSelectMarketType };
}
