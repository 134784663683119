import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SiteConfigDocument } from '@core/site-config';

interface UseWidgetsSettingsProps {
  sportlineBlock: Ref<Optional<SiteConfigDocument['sportLine']>>;
  widgetsBlock: Ref<Optional<SiteConfigDocument['widgets']>>;
}

interface UseWidgetsSettingsComposable {
  isLiveStreamEnabled: Ref<boolean>;
  isSportradarLiveMatchTrackerEnabled: Ref<boolean>;
  isBetGeniusWidgetEnabled: Ref<boolean>;
  isLSportsWidgetEnabled: Ref<boolean>;
  isLiveStreamWidgetFloatingEnabled: Ref<boolean>;
  isSportRadarWidgetFloatingEnabled: Ref<boolean>;
}

export function useWidgetsSettings(
  props: UseWidgetsSettingsProps,
): UseWidgetsSettingsComposable {
  const { sportlineBlock, widgetsBlock } = props;

  const isLiveStreamEnabled = computed(() => !!sportlineBlock.value?.liveStreamEnabled);
  const isSportradarLiveMatchTrackerEnabled = computed(() => !!widgetsBlock.value?.sportradarLiveMatchTrackerEnabled);
  const isBetGeniusWidgetEnabled = computed(() => !!sportlineBlock.value?.betgeniusWidgetEnabled);
  const isLSportsWidgetEnabled = computed(() => !!sportlineBlock.value?.lsportsWidgetEnabled);
  const isLiveStreamWidgetFloatingEnabled = computed(() => !!sportlineBlock.value?.liveStreamWidgetFloatingEnabled);
  const isSportRadarWidgetFloatingEnabled = computed(() => !!sportlineBlock.value?.sportRadarWidgetFloatingEnabled);

  return {
    isLiveStreamEnabled,
    isSportradarLiveMatchTrackerEnabled,
    isBetGeniusWidgetEnabled,
    isLSportsWidgetEnabled,
    isLiveStreamWidgetFloatingEnabled,
    isSportRadarWidgetFloatingEnabled,
  };
}
