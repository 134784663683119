import type {
  BatchedSlipInfoDocument,
  SlipEntryId,
  SlipInfoArray,
  UpdateSlipInfoEntriesPayload,
} from '../types';
import getSlipEntryId from '../../../utils/getSlipEntryId';
import getMultiSinglesSlipEntry from '../../multi-singles/utils/getMultiSinglesSlipEntry';

interface Payload extends UpdateSlipInfoEntriesPayload {
  currentSlipInfo: BatchedSlipInfoDocument | null;
  selectedEntriesIds: SlipEntryId[];
}

export default function updateSlipInfoBySlipEntries({
  entries,
  overwriteMultisingles,
  currentSlipInfo,
  selectedEntriesIds,
}: Payload): SlipInfoArray | null {
  const currentSlipEntries = currentSlipInfo?.slipEntries;
  if (!currentSlipEntries?.length || !entries.length) {
    return null;
  }
  let updatedSlipInfo: SlipInfoArray = [];
  if (currentSlipEntries.length === entries.length || overwriteMultisingles) {
    // singles
    const availableEntries = !overwriteMultisingles ? currentSlipEntries : currentSlipEntries
      .filter((entry) => selectedEntriesIds.includes(getSlipEntryId(getMultiSinglesSlipEntry(entry))));
    updatedSlipInfo = availableEntries.map((entry, index) => ({
      ...entry,
      entries: [entries[index]],
    }));
  } else if (currentSlipEntries.length === 1) {
    // combi
    const combiEntry = currentSlipEntries[0];
    updatedSlipInfo = [{ ...combiEntry, entries }];
  } else {
    throw new Error('unmatched entries lists to updateSlipInfoEntries');
  }
  return updatedSlipInfo;
}
