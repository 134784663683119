import type { SlipEntryId } from '../../../types';
import type { MultiSinglesMetaInfo } from '../types';

export default function modifyMetaInfoRecordOnFocus(
  id: SlipEntryId,
  currentMetaInfo: MultiSinglesMetaInfo,
): MultiSinglesMetaInfo | null {
  const matchedItem = currentMetaInfo[id];
  if (!matchedItem) {
    return null;
  }
  if (matchedItem.clearValueOnFocus || matchedItem.stakeValue === '0') {
    const updatedItem = {
      ...matchedItem,
      stakeValue: '',
      selectedFastBetValue: null,
      stakeWasEdited: true,
    };
    const copy = { ...currentMetaInfo };
    copy[id] = updatedItem;
    return copy;
  }
  return null;
}
