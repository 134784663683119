function createTestAttribute(attributes) {
  if (!(attributes == null ? void 0 : attributes.el)) {
    return {};
  }
  return Object.keys(attributes).reduce((result, key) => {
    const name = key === "el" ? "data-test-el" : `data-test-attr-${key}`;
    result[name] = attributes[key];
    return result;
  }, {});
}
function setElementAttributes(element, values) {
  const attributes = createTestAttribute(values);
  for (const key of Object.keys(attributes)) {
    try {
      element.setAttribute(key, attributes[key]);
    } catch {
    }
  }
}
const dataTest = {
  beforeMount: (element, binding) => setElementAttributes(element, binding.value),
  updated: (element, binding) => setElementAttributes(element, binding.value),
  beforeUnmount: (element, binding) => setElementAttributes(element, binding.value)
};
export {
  dataTest,
  setElementAttributes
};
