import type { Component } from 'vue';

import { LSpinner, SSpinner } from '@components/spinner';
import { VIcon } from '@components/v-icon';

import type { VBaseButtonProps } from '../../types';

export function getIconComponentForButton(
  props: Pick<VBaseButtonProps, 'isLoading' | 'iconName'>,
): Component | undefined {
  if (props.isLoading) {
    return process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? SSpinner : LSpinner;
  }

  return props.iconName ? VIcon : undefined;
}
