import type { FieldGuards } from '@leon-hub/types';
import {
  bindIsArrayOf,
  isBoolean,
  isNumber,
  isString,
  isUndefined,
  isValidInterface,
} from '@leon-hub/guards';

import type { CoreMarketRunnerResponse } from 'web/src/modules/sportline/types/rest';

import { isBetlineSelectionTag } from './isBetlineSelectionTag';

export const isCoreMarketRunnerResponseFieldGuards: FieldGuards<CoreMarketRunnerResponse> = {
  id: isNumber,
  name: isString,
  open: isBoolean,
  r: isNumber,
  c: isNumber,
  tags: [isUndefined, bindIsArrayOf(isBetlineSelectionTag)],
  price: isNumber,
  handicap: [isString, isUndefined],
};

export function isCoreMarketRunnerResponse(value: unknown): value is CoreMarketRunnerResponse {
  return isValidInterface<CoreMarketRunnerResponse>(value, isCoreMarketRunnerResponseFieldGuards);
}
