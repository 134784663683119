import type { FieldGuards } from '@leon-hub/types';
import { isValidInterface } from '@leon-hub/guards';

import type {
  GetLeagueResponse,
  GetRegionResponse,
  GetSportResponse,
} from 'web/src/modules/sportline/types/rest';

import { isCoreLeagueResponseFieldGuards } from './isCoreLeagueResponse';
import { isCoreRegionResponseFieldGuards } from './isCoreRegionResponse';
import { isCoreSportResponseFieldGuards } from './isCoreSportResponse';

const isGetSportResponseFieldGuards: FieldGuards<GetSportResponse> = {
  ...isCoreSportResponseFieldGuards,
};

export function isGetSportResponse(value: unknown): value is GetSportResponse {
  return isValidInterface<GetSportResponse>(value, isGetSportResponseFieldGuards);
}

export const isGetRegionResponseFieldGuards: FieldGuards<GetRegionResponse> = {
  ...isCoreRegionResponseFieldGuards,
  sport: isGetSportResponse,
};

export function isGetRegionResponse(value: unknown): value is GetRegionResponse {
  return isValidInterface<GetRegionResponse>(value, isGetRegionResponseFieldGuards);
}

const isGetLeagueResponseFieldGuards: FieldGuards<GetLeagueResponse> = {
  ...isCoreLeagueResponseFieldGuards,
  sport: (value: unknown): value is never => !value,
  region: isGetRegionResponse,
};

export function isGetLeagueResponse(value: unknown): value is GetLeagueResponse {
  return isValidInterface<GetLeagueResponse>(value, isGetLeagueResponseFieldGuards);
}
