import type { Ref } from 'vue';
import { computed } from 'vue';

import type { Competitor, SportlineEvent } from 'web/src/modules/sportline/types';

interface UseSportlineEventCompetitorsProps {
  sportlineEvent: Ref<Maybe<SportlineEvent> | undefined>;
}

interface UseSportlineEventCompetitorsComposable {
  host: Ref<Maybe<Competitor>>;
  guest: Ref<Maybe<Competitor>>;
}

export function useSportlineEventCompetitors(
  props: UseSportlineEventCompetitorsProps,
): UseSportlineEventCompetitorsComposable {
  const { sportlineEvent } = props;

  const host = computed<Maybe<Competitor>>(() => (sportlineEvent.value?.competitors[0] ?? null));
  const guest = computed<Maybe<Competitor>>(() => (sportlineEvent.value?.competitors[1] ?? null));

  return { host, guest };
}
