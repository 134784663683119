import type { SportSegment } from 'web/src/modules/sportline/types';
import { SportSegmentId } from 'web/src/modules/sportline/enums';

const defaultSegment: SportSegment = {
  id: SportSegmentId.Default,
};
const cyberSportSegment: SportSegment = {
  id: SportSegmentId.CyberSport,
};
const extraSegments = process.env.VUE_APP_FEATURE_VIRTUAL_SPORT_ENABLED
  ? {
      virtual: {
        id: SportSegmentId.Default,
        isVirtual: true,
      },
    }
  : {};

export const sportSegments = {
  default: defaultSegment,
  cyberSport: cyberSportSegment,
  ...extraSegments,
};
