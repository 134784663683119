import {
  widgetTypeBetgenius,
  widgetTypeBetradar,
  widgetTypeLsports,
} from '@leon-hub/api-sdk';

// TBD add ODDIN to { WidgetType } from '@leon-hub/api-sdk'
// TBD add NONE to { WidgetType } from '@leon-hub/api-sdk'
export const betlineWidgetTypeBetradar = widgetTypeBetradar;
export const betlineWidgetTypeLsports = widgetTypeLsports;
export const betlineWidgetTypeBetgenius = widgetTypeBetgenius;
export const betlineWidgetTypeOddin = 'ODDIN' as const;
export const betlineWidgetTypeNone = 'NONE' as const;
