<script setup lang="ts">
import { computed, ref, toRef } from 'vue';

import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import type {
  Market,
  MarketType,
  Sport,
  SportElement,
} from 'web/src/modules/sportline/types';
import { useMarketColumns } from 'web/src/modules/sportline/composables/list';
import {
  useSportlineElementMarketColumns,
  useSportlineElementMarketTypes,
} from 'web/src/modules/sportline/composables/markets';
import { useSportlineEventType } from 'web/src/modules/sportline/composables/sportline-event';
import { selectMarketTypeByColumn } from 'web/src/modules/sportline/utils/markets';
import { useMarketRunners, useSlipCaption } from 'web/src/modules/sportline/views/runner/composables';
import PrimaryMarketRunner from 'web/src/modules/sportline/views/runner/PrimaryMarketRunner.vue';

interface PrimaryMarketProps {
  market: Market;
  sportElement?: Maybe<SportElement>;
  // @TODO use SportlineEvent instead of SportEventDetails
  sportEventDetails: SportEventDetails;
  doubleGap?: boolean;
  isPrimaryMarketV2?: boolean;
}

interface PrimaryMarketEmits {
  (e: 'click-runner', event: MouseEvent): void;
}

interface PrimaryMarketSlots {
  top(props: { marketName: string }): void;
  bottom(props: { hasZeroMargin: boolean }): void;
}

const props = defineProps<PrimaryMarketProps>();
const emit = defineEmits<PrimaryMarketEmits>();
defineSlots<PrimaryMarketSlots>();

const market = toRef(props, 'market');
const sportElement = toRef(props, 'sportElement', null);
const sportEvent = toRef(props, 'sportEventDetails');

const sportEventId = computed<string>(() => sportEvent.value.id);
const sport = computed<Sport>(() => (sportElement.value?.sport ?? sportEvent.value.sport));
const marketType = computed<MarketType>(() => market.value.type);
const markets = computed<Market[]>(() => sportEvent.value.markets);

const { marketsColumns } = useSportlineElementMarketColumns({ sportlineElement: sportElement });
const { marketsTypes } = useSportlineElementMarketTypes({ sportlineElement: sportElement });

const selectedColumnId = ref<Maybe<string>>(null);

const selectedMarketType = computed<Maybe<MarketType>>(() => selectMarketTypeByColumn({
  columns: marketsColumns.value,
  allMarketsTypes: marketsTypes.value,
  selectedColumnId: selectedColumnId.value,
}));

const selectedColumnSize = computed(() => (selectedMarketType.value?.columns ?? null));

const {
  actualMarketType,
  actualMarketTypePriority,
  actualMarket,
} = useMarketColumns({
  sport,
  selectedColumnSize,
  selectedColumnId,
  columns: marketsColumns,
  markets,
});
const {
  isOutright,
  sportlineType,
} = useSportlineEventType({ sportEvent });
const {
  runnersPlaces,
} = useMarketRunners({
  sport,
  market: actualMarket,
  marketType,
});
const { slipCaption } = useSlipCaption({ sportlineEvent: sportEvent });
</script>

<template>
  <div v-auto-id="'PrimaryMarket'" :class="$style['primary-market__wrapper']">
    <div
      :class="{
        [$style['primary-market']]: true,
        [$style['primary-market--v2']]: isPrimaryMarketV2,
      }"
    >
      <template v-if="actualMarketType && actualMarketTypePriority > 0">
        <slot
          name="top"
          :market-name="actualMarketType.name"
        />
      </template>

      <div
        v-if="isPrimaryMarketV2"
        :class="$style['primary-market__market-name']"
      >
        {{ market.name }}
      </div>

      <div
        :class="{
          [$style['primary-market__runners']]: true,
          [$style['primary-market__runners--double-gap']]: doubleGap,
        }"
      >
        <div
          v-for="(runner, index) in runnersPlaces"
          :key="runner?.id ?? index"
          :class="$style['primary-market__runner']"
        >
          <PrimaryMarketRunner
            :runner="runner"
            :market="actualMarket"
            :slip-caption="slipCaption"
            :sport-event-id="sportEventId"
            :sportline-type="sportlineType"
            :is-outright-event="isOutright"
            :is-runner-v2="isPrimaryMarketV2"
            @click-runner="emit('click-runner', $event)"
          />
        </div>
      </div>

      <slot
        name="bottom"
        :has-zero-margin="!!actualMarket?.hasZeroMargin"
      />
    </div>
  </div>
</template>

<style module lang="scss">
.primary-market {
  $self: &;

  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;

  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__runners {
    display: flex;
    gap: 4px;
    justify-content: center;
    width: 100%;

    &--double-gap {
      gap: 8px;
    }

    #{$self}__runner {
      max-width: inherit;
    }
  }

  &:not(#{$self}--v2) {
    #{$self}__runners {
      @media (max-width: $sportlineRunnerBreakpointWidth - 1) {
        &:has(> :nth-child(2)) {
          #{$self}__runner {
            flex-basis: 100%;
            max-width: calc((100% - 8px) / 2);
          }
        }

        &:has(> :nth-child(3)) {
          #{$self}__runner {
            flex-basis: 100%;
            max-width: calc((100% - 16px) / 3);
          }
        }
      }
    }
  }

  &__runner {
    @media (min-width: $sportlineRunnerBreakpointWidth) {
      min-width: 100px;
    }
  }

  &--v2 {
    gap: 0;

    #{$self}__runners {
      gap: 4px;
      width: 140px;

      @media (min-width: 360px) {
        width: 152px;
      }

      @media (min-width: 375px) {
        width: 160px;
      }

      @media (min-width: 412px) {
        width: 180px;
      }

      @media (min-width: 480px) {
        width: 216px;
      }

      @media (min-width: 600px) {
        width: 220px;
      }
    }

    #{$self}__runner {
      flex: 1;
      min-width: 44px;
    }

    #{$self}__market-name {
      width: 100%;
      padding: 4px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      color: var(--BrandText);
      text-align: center;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      border-radius: 8px 8px 0 0;
    }
  }
}
</style>
