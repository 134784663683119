<script lang="ts" setup>
import { normalizeClass } from 'vue';

import type { VAnimationStarterProps } from './types';
import { useVAnimationStarter } from './composables';

const props = withDefaults(defineProps<VAnimationStarterProps>(), {
  rootMargin: '',
  threshold: 0,
});
const {
  animationContainer,
  isAnimated,
} = useVAnimationStarter(props);
</script>

<template>
  <div v-auto-id="'VAnimationStarter'"
    ref="animationContainer"
    class="anim-starter"
    :class="{
      [normalizeClass(animatedClass)]: isAnimated,
    }"
  >
    <slot />
  </div>
</template>
