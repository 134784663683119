import type { ComputedRef, Ref } from 'vue';
import { computed, nextTick, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useWindowResize } from '@leon-hub/browser-composables';
import { openDocument } from '@leon-hub/cordova';
import { Tag } from '@leon-hub/tags';
import { useIntersectionObserver } from '@leon-hub/vue-utils';

import type { VImageRef, Web2ImageSource } from '@components/image';
import { BadgeKind } from '@components/badge';
import { ObjectFitOption, ObjectPosition } from '@components/image';

import type {
  VHeadlineBannerEmits,
  VHeadlineBannerLinkProps,
  VHeadlineBannerProps,
  VHeadlineBannerWrapperProps,
} from '../types';
import { BannerType } from '../enums';
import {
  getBannerImageStyles,
  getBannerTextStyles,
  getBannerWrapperStyles,
  getLinkProps,
  getWrapperProps,
} from './utils';

interface UseVHeadlineBannerComposable {
  backgroundImage: Ref<VImageRef | null>;
  mainImageRef: Ref<VImageRef | null>;
  wrapper: Ref<HTMLElement | undefined>;
  wrapperComponent: ComputedRef<Tag.DIV | Tag.A>;
  wrapperProperties: ComputedRef<object | VHeadlineBannerWrapperProps>;
  wrapperStyles: ComputedRef<Record<string, string | undefined>>;
  isBackgroundImageAvailable: ComputedRef<boolean>;
  backgroundImageSource: ComputedRef<Web2ImageSource>;
  bgImageWidth: ComputedRef<string>;
  hasContentBlock: ComputedRef<boolean>;
  headlineComponent: ComputedRef<Tag.DIV | Tag.P>;
  isHeadlineAvailable: ComputedRef<boolean>;
  isVisible: Ref<boolean>;
  textStyles: ComputedRef<Record<string, string>>;
  isSecondaryTextAvailable: ComputedRef<boolean>;
  imagePosition: ComputedRef<ObjectPosition.LEFT | undefined>;
  linkProperties: ComputedRef<VHeadlineBannerLinkProps>;
  isFrontImageAvailable: ComputedRef<boolean>;
  isButtonAvailable: ComputedRef<boolean>;
  imageStyle: ComputedRef<Record<string, string>>;
  badgeKind: BadgeKind.WHITE;
  imageFit: ComputedRef<ObjectFitOption>;
  onWrapperClick(): void;
  visibilityChanged(value: boolean): void;
  onClick(): void;
  onComponentMounted(): Promise<void>;
  onComponentActivated(): void;
}

export default function useVHeadlineBanner(
  props: VHeadlineBannerProps,
  emits: VHeadlineBannerEmits,
): UseVHeadlineBannerComposable {
  const router = useRouter();

  const backgroundImage = ref<VImageRef | null>(null);
  const mainImageRef = ref<VImageRef | null>(null);
  const backgroundHeight = ref<number>(0);
  const isVisible = ref<boolean>(false);
  const wrapper = ref<HTMLElement>();
  const badgeKind = BadgeKind.WHITE;

  const imageFit = computed(() => (props.isBigHeight ? ObjectFitOption.COVER : ObjectFitOption.CONTAIN));

  const imagePosition = computed(() => (props.bannerType === BannerType.INLINE_BANNER ? ObjectPosition.LEFT : undefined));
  const isHeadlineAvailable = computed(() => !!props.headline);
  const isSecondaryTextAvailable = computed(() => !!props.secondaryText);
  const isButtonAvailable = computed(() => !!props.buttonText);
  const isFrontImageAvailable = computed(() => !!props.backgroundImage || !!props.backgroundColor);
  const isBackgroundImageAvailable = computed(() => !!props.backgroundImage || !props.backgroundColor);
  const backgroundImageSource = computed(() => props.backgroundImage ?? props.mainImage);
  const hasContentBlock = computed(() => !!props.headline || !!props.secondaryText);
  const href = computed(() => (props.isInternalLink ? router.resolve(props.url).href : props.url));
  const wrapperComponent = computed(() => (isButtonAvailable.value || props.isDivContainer ? Tag.DIV : Tag.A));
  const headlineComponent = computed(() => (props.bannerType === BannerType.ASIDE_BANNER ? Tag.DIV : Tag.P));
  const bgImageWidth = computed(() => (props.animation ? 'calc(100% + 40px)' : ''));
  const linkProperties = computed(() => getLinkProps(href.value, props));

  const wrapperProperties = computed(() => getWrapperProps(isButtonAvailable.value, props, linkProperties.value));
  const textStyles = computed(() => getBannerTextStyles(props));

  const wrapperStyles = computed(() => getBannerWrapperStyles(props, backgroundHeight.value, isFrontImageAvailable.value, hasContentBlock.value));
  const imageStyle = computed(() => getBannerImageStyles(props, bgImageWidth.value));

  function visibilityChanged(value: boolean): void {
    isVisible.value = value;
  }

  function onComponentActivated(): void {
    if (!props.fixHeightImg) {
      initBackgroundHeight();
    }
  }

  async function onComponentMounted(): Promise<void> {
    onComponentActivated();
    await nextTick();
    await nextTick();
    if (!props.forceHideImage) {
      forceShowImage();
    }
  }

  useWindowResize(onComponentActivated);

  function forceShowImage(): void {
    backgroundImage.value?.forceShowImage();
    mainImageRef.value?.forceShowImage();
  }

  function initBackgroundHeight(): void {
    if (wrapper.value) {
      backgroundHeight.value = wrapper.value.offsetWidth / props.aspectRatio;
    }
  }

  function onWrapperClick(): void {
    onClick();
  }

  function onClick(): void {
    if (props.isDivContainer) {
      emitClick(props.url);
      return;
    }

    if (!props.isRedirectDisabled) {
      emitClick(props.url);
      if (props.isInternalLink) {
        void router.push({ path: props.url });
      } else if (process.env.VUE_APP_PLATFORM_CORDOVA) {
        openDocument(props.url);
      } else if (process.env.VUE_APP_RENDERING_CSR) {
        window.open(props.url);
      }
    }
  }

  useIntersectionObserver(wrapper, (isIntersecting) => {
    if (isIntersecting) {
      onComponentActivated();
    }
  }, undefined, true);

  function emitClick(url: string): void {
    emits('click', url);
  }

  return {
    isVisible,
    backgroundImage,
    mainImageRef,
    wrapper,
    wrapperComponent,
    wrapperProperties,
    wrapperStyles,
    isBackgroundImageAvailable,
    backgroundImageSource,
    bgImageWidth,
    hasContentBlock,
    headlineComponent,
    isHeadlineAvailable,
    textStyles,
    isSecondaryTextAvailable,
    imagePosition,
    linkProperties,
    isFrontImageAvailable,
    isButtonAvailable,
    badgeKind,
    imageFit,
    imageStyle,
    onWrapperClick,
    visibilityChanged,
    onClick,
    onComponentMounted,
    onComponentActivated,
  };
}
