export enum ObjectFitOption {
  FILL = 'fill',
  COVER = 'cover',
  CONTAIN = 'contain',
  SCALE_DOWN = 'scale-down',
}

export enum ObjectPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left',
}
