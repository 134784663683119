<script lang="ts" setup>
import { ObserveVisibility } from 'vue3-observe-visibility';

import { IconSize } from '@leon-hub/icons';

import { VLogoLoader } from '@components/loader';
import { SSpinner } from '@components/spinner';
import { DefaultFadeTransition } from '@components/transition';

import type { VImageRef } from '../../types';
import type { VLazyImageEmits, VLazyImageProps } from './types';
import { ObjectFitOption } from '../../enums';
import { useVLazyImage } from './composables/useVLazyImage';

const props = withDefaults(defineProps<VLazyImageProps>(), {
  objectFit: ObjectFitOption.FILL,
});

const emit = defineEmits<VLazyImageEmits>();

const vObserveVisibility = ObserveVisibility;

const {
  isNativeLazyMethod,
  imageLoadCompleted,
  visibilityChanged,
  forceShowImage,
  onImageLoad,
  showImage,
} = useVLazyImage(props, emit, { initial: false });

function emitError(): void {
  emit('error');
}

function emitClick(event: MouseEvent) {
  emit('click', event);
}

defineExpose<VImageRef>({
  forceShowImage,
});
</script>

<template>
  <div v-auto-id="'VLazyImage'"
    :class="$style['lazy-image']"
    @click="emitClick"
  >
    <img
      v-if="showImage"
      v-data-test="{ el: 'lazy-image' }"
      :src="src"
      :alt="alt"
      :class="{
        [$style['lazy-image__img']]: true,
        [$style[`lazy-image__img--${objectFit}`]]: objectFit !== ObjectFitOption.FILL,
        [$style[`lazy-image__img-position--${objectPosition}`]]: !!objectPosition,
        [$style['lazy-image__img--is-relative']]: isRelative,
      }"
      loading="lazy"
      fetchpriority="low"
      @load="onImageLoad"
      @error="emitError"
    >
    <span
      v-if="!isNativeLazyMethod() && !imageLoadCompleted"
      v-observe-visibility="{
        callback: visibilityChanged,
        once: true,
      }"
    />
    <DefaultFadeTransition
      :is-transition-disabled="transparentLoaderBg"
    >
      <div
        v-if="!imageLoadCompleted"
        v-data-test="{ el: 'lazy-image-placeholder' }"
        :class="{
          [$style['lazy-image__placeholder']]: true,
          [$style['lazy-image__placeholder--loader']]: showLoader,
          [$style['lazy-image__placeholder--loader-transparent']]: transparentLoaderBg,
        }"
      >
        <template v-if="showLoader">
          <SSpinner
            v-if="null"
            :size="IconSize.SIZE_36"
          />
          <VLogoLoader
            v-else
            :class="$style['lazy-image__loader-svg']"
            :is-fixed-light-logo="lightLoader"
            :is-gradient="isGradient"
            is-small
          />
        </template>
      </div>
    </DefaultFadeTransition>
  </div>
</template>

<style lang="scss" module>
.lazy-image {
  $positions: (
    top: top,
    bottom: bottom,
    right: right,
    left: left
  );

  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height: 100%;

  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 4px;

    &--loader {
      background-color: $lazyImageLoaderBackgroundColor;

      &-transparent {
        background-color: transparent;
      }
    }
  }

  &__loader-svg {
    max-width: 72px;
    margin: auto;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-position: inherit;
    border-radius: inherit;

    &-position {
      @each $name, $position in $positions {
        &--#{$name} {
          object-position: $position;
        }
      }
    }

    &--cover {
      object-fit: cover;
    }

    &--contain {
      object-fit: contain;
    }

    &--scale-down {
      object-fit: scale-down;
    }

    &--none {
      object-fit: none;
    }

    &--is-relative {
      position: relative;
    }
  }
}
</style>
