import {
  IconSize,
} from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import type { VButtonProps } from '../../types';

export function getIconPropsForButton(
  props: Pick<VButtonProps, 'isLoading' | 'iconName' | 'iconSize'>,
): VIconProps | Record<string, never> {
  if (props.isLoading) {
    return {};
  }

  return {
    name: props.iconName,
    size: props.iconSize ?? IconSize.SIZE_16,
  } as VIconProps;
}
