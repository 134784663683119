import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import { CustomerConfig } from '@leon-hub/api-sdk';

import type { SiteConfigDocument } from '@core/site-config';

import { BetlineFlag } from 'web/src/modules/sportline/enums/rest';
import useOddsStore from 'web/src/modules/sportline/submodules/odds/store/useOddsStore';

interface UseBetlineFlagsSettingsProps {
  sportlineBlock: Ref<Optional<SiteConfigDocument['sportLine']>>;
}

interface UseBetlineFlagsSettingsComposable {
  defaultRestApiFlags: Ref<BetlineFlag[]>;
  searchRestApiFlags: Ref<BetlineFlag[]>;
  sportsRestApiFlags: Ref<BetlineFlag[]>;
}

export function useBetlineFlagsSettings(
  props: UseBetlineFlagsSettingsProps,
): UseBetlineFlagsSettingsComposable {
  const { sportlineBlock } = props;
  const oddsStore = useOddsStore();
  const oddType = toRef(oddsStore, 'userOddTypeLocal');

  const doUseSportMarketTypesForMainMarkets = computed(() => !!sportlineBlock.value?.cmsMainMarketsUsageEnabled);
  const isUsedMoreRunnersCount = computed(() => !!sportlineBlock.value?.runnersCountDisplayEnabled);
  const doRemoveDuplicateEvents = computed(() => !!sportlineBlock.value?.doRemoveDuplicateEvents);

  const defaultRestApiFlags = computed<BetlineFlag[]>(() => {
    const flags: BetlineFlag[] = [
      BetlineFlag.RegionFullInfo,
      BetlineFlag.UrlNamesV2,
    ];

    if (doUseSportMarketTypesForMainMarkets.value) {
      flags.push(BetlineFlag.UseSportMarketTypesForMainMarkets);
    }

    if (isUsedMoreRunnersCount.value) {
      flags.push(BetlineFlag.UseRunnersCount);
    }

    if (doRemoveDuplicateEvents.value) {
      flags.push(BetlineFlag.NoDuplicate);
    }

    if (process.env.VUE_APP_FEATURE_SPORTLINE_ODDS_SELECTOR_ENABLED && oddType.value && oddType.value === CustomerConfig.IS_ODDS_TYPE_AMERICAN) {
      flags.push(BetlineFlag.AmericanOddsType);
    }

    return flags;
  });

  const searchRestApiFlags = ref<BetlineFlag[]>([
    BetlineFlag.RegionFullInfo,
    BetlineFlag.UrlNamesV2,
  ]);

  const sportsRestApiFlags = ref<BetlineFlag[]>([
    BetlineFlag.UrlNamesV2,
  ]);

  return {
    defaultRestApiFlags,
    searchRestApiFlags,
    sportsRestApiFlags,
  };
}
