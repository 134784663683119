import { assert, isString, isUndefined } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';

import type {
  SportFamily,
} from 'web/src/modules/sportline/enums';
import type {
  Market,
  MarketColumnsOptions,
  MarketType,
  RunnerLike,
} from 'web/src/modules/sportline/types';

import { getMarketTypeColumnsCount } from './getMarketTypeColumnsCount';

interface PlaceRunnersToColumnsProperties {
  marketType?: MarketType | null;
  market?: Market | null;
  sportFamily?: Maybe<string | SportFamily>;
}

// first level is columns count in server data
// second level is local result
const runnerPlaceMap: Record<number, Record<number, number[]>> = {
  2: {
    2: [0, 1],
    3: [0, 2],
  },
  3: {
    2: [0, 1, 2], // prefer sport actualRunnersCount > defaultSportCount
    3: [0, 1, 2],
  },
};

export function placeRunnersToColumns(
  { marketType, market, sportFamily }: PlaceRunnersToColumnsProperties,
  options?: MarketColumnsOptions,
): RunnerLike[] {
  const preferSportCount = options?.preferSportCount ?? false;
  const actualRunners: RunnerLike[] = market?.runners
    ?? (Array.from({ length: marketType?.columns || 3 })).fill(null) as RunnerLike[];
  const actualMarketType = market?.type ?? marketType ?? null;
  const resultSize = getMarketTypeColumnsCount({
    marketType: actualMarketType,
    sportFamily: (sportFamily ?? undefined) as Optional<SportFamily>,
    runnersCount: actualRunners.length,
  }, { preferSportCount });
  const result: RunnerLike[] = Array.from({ length: resultSize }).fill(null) as RunnerLike[];
  const typeSize = actualMarketType?.columns || result.length;

  if (typeSize) {
    assert(runnerPlaceMap[typeSize], `Cant find map size ${typeSize} for event ${market?.eventId}`);
    assert(runnerPlaceMap[typeSize][resultSize], `Cant find map size ${typeSize}:${resultSize} for event ${market?.eventId}`);
  }

  for (const [index, runner] of Object.entries(actualRunners)) {
    const column = (isString(runner) || !runner || isUndefined(runner?.column)) ? Number(index) : runner?.column;
    let place!: number;

    if (typeSize) {
      place = runnerPlaceMap[typeSize][resultSize][column];
    } else {
      logger.warn(`Empty market type size for event ${market?.eventId}`);
      result[column] = runner;
    }

    if (result[place]) { continue; }

    result[place] = runner;
  }

  return result;
}
