import round from 'lodash/round';

import type { AmountAnimatorOptions, AnimationCallback, AnimationValue } from 'web/src/components/AmountAnimator/types';
import type { AnimationOptions } from 'web/src/utils/RequestAnimationFrame';
import RandomIntervals from 'web/src/utils/RandomIntervals';
import RequestAnimationFrame from 'web/src/utils/RequestAnimationFrame';

class AmountAnimator {
  private animationFrame: RequestAnimationFrame | null = null;

  private randomIntervals: RandomIntervals;

  private values: AnimationValue;

  private readonly options: Pick<AnimationOptions, 'timing' | 'duration'>;

  private callback: AnimationCallback;

  private currentValue: number;

  private isFinished = false;

  constructor(options: AmountAnimatorOptions) {
    const {
      timeRange,
      duration,
      callback,
      values,
    } = options;
    this.randomIntervals = new RandomIntervals(timeRange.min, timeRange.max, duration);
    this.values = values;
    this.currentValue = values.from;
    this.options = {
      timing: (timeFraction) => {
        if (timeFraction === 1) {
          this.isFinished = true;
        }
        return this.randomIntervals.query(timeFraction);
      },
      duration,
    };
    this.callback = callback;
  }

  start(): void {
    const { callback, values } = this;
    const { from, to } = values;

    this.animationFrame = new RequestAnimationFrame({
      ...this.options,
      draw: (progress) => {
        const currentValue = progress ? round((to - from) * progress + from, 2) : from;

        callback(currentValue, this.isFinished);

        this.currentValue = currentValue;
      },
    });
    this.animationFrame.start();
  }

  stop(): void {
    const { animationFrame } = this;

    if (animationFrame) {
      animationFrame.stop();
      this.values.from = this.currentValue;
    }
  }
}

export default AmountAnimator;
