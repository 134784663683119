import { BetType } from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from '@core/analytics';

function transformBetTypeForMetrika(betType: BetType | null): string | null {
  if (!betType) {
    return null;
  }
  return betType === BetType.SINGLE ? 'single' : 'multi';
}

export default function sendCashoutMetrika(currentBetId: number, betType: BetType | null): void {
  const analyticsService = useAnalytics();
  analyticsService.push(AnalyticsEvent.Z_CASH_OUT, {
    submitSlipData: {
      ok: {
        [currentBetId]: {
          cashOut: true,
        },
      },
      cashOutByBetType: transformBetTypeForMetrika(betType),
    },
  });
}
