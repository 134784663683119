// types
import type {
  LiveWidget,
  LiveWidgetIFrames,
  SportlineWidgetType,
} from 'web/src/modules/sportline/types';
import type {
  BetlineWidgetType,
  BuildSportEventOptions,
  CoreSportlineEventWidget,
  MinimalWorkingSportResponse,
} from 'web/src/modules/sportline/types/rest';
// constants
import {
  betlineWidgetTypeBetgenius,
  betlineWidgetTypeBetradar,
  betlineWidgetTypeLsports,
  betlineWidgetTypeNone,
  betlineWidgetTypeOddin,
} from 'web/src/modules/sportline/constants/rest';
import {
  sportlineWidgetTypeBetgenius,
  sportlineWidgetTypeLsports,
  sportlineWidgetTypeOddin,
  sportlineWidgetTypeSportradar,
} from 'web/src/modules/sportline/constants/widgets';

import { findSportRepresentationSetting } from '../pre-build';

type SportWidgetTypesMapping = Partial<Record<BetlineWidgetType, SportlineWidgetType>>;

function getSportWidgetTypesMapping(): SportWidgetTypesMapping {
  const result: SportWidgetTypesMapping = {
    [betlineWidgetTypeBetradar]: sportlineWidgetTypeSportradar,
    [betlineWidgetTypeLsports]: sportlineWidgetTypeLsports,
    [betlineWidgetTypeBetgenius]: sportlineWidgetTypeBetgenius,
  };

  if (process.env.VUE_APP_FEATURE_SPORTLINE_ODDIN_WIDGETS_ENABLED) {
    result[betlineWidgetTypeOddin] = sportlineWidgetTypeOddin;
  }

  return result;
}

function getWidgetTypeBySport(
  { id, family, virtual }: MinimalWorkingSportResponse,
  options?: BuildSportEventOptions,
): BetlineWidgetType {
  if (process.env.VUE_APP_FEATURE_VIRTUAL_SPORT_ENABLED && options && virtual) {
    const sportRepresentationSettings = options?.sportRepresentationSettings;
    const item = findSportRepresentationSetting(sportRepresentationSettings, { id, family });
    return item?.defaultWidgetType ?? betlineWidgetTypeNone;
  }

  return betlineWidgetTypeNone;
}

function getLiveWidgetIFrames({ widgetData }: CoreSportlineEventWidget): LiveWidgetIFrames | undefined {
  if (!widgetData) {
    return undefined;
  }

  const result: LiveWidgetIFrames = {};

  if (widgetData.liveIframe) {
    result.live = widgetData.liveIframe;
  }

  if (widgetData.prematchIframe) {
    result.prematch = widgetData.prematchIframe;
  }

  return result;
}

function isEmptyWidgetType(widgetType?: Maybe<BetlineWidgetType>): widgetType is undefined {
  return !widgetType || widgetType === betlineWidgetTypeNone;
}

export function createSportWidget({ response, sportResponse }: {
  response: CoreSportlineEventWidget;
  sportResponse: MinimalWorkingSportResponse;
}, options?: BuildSportEventOptions): LiveWidget | null {
  const { widgetId, widgetType, widgetVirtual } = response;
  const { family } = sportResponse;

  const finalType = isEmptyWidgetType(widgetType) ? getWidgetTypeBySport(sportResponse, options) : widgetType;

  if (!widgetId || !finalType) {
    return null;
  }

  const sportWidgetType = getSportWidgetTypesMapping()[finalType];

  if (!sportWidgetType) {
    return null;
  }

  const result: LiveWidget = {
    id: widgetId,
    type: sportWidgetType,
    sportFamily: family,
    isVirtual: Boolean(widgetVirtual),
  };

  const iframes = getLiveWidgetIFrames(response);

  if (iframes) {
    result.iframes = iframes;
  }

  return result;
}
