import type {
  ClaimToUpdateSportEventStatusChanged,
} from 'web/src/modules/slip/types/сlaimToUpdate';
import type {
  CoreMarketResponse,
  CoreMarketRunnerResponse,
  CoreSportEventResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';

import applyRunnerStatusChanges from './applyRunnerStatusChanges';

export function applyEventStatusChanges(
  eventData: CoreSportEventResponse,
  changes: ClaimToUpdateSportEventStatusChanged[],
): CoreSportEventResponse {
  return {
    ...eventData,
    lastUpdated: Date.now(),
    markets: eventData.markets?.map((market: CoreMarketResponse) => ({
      ...market,
      runners: market.runners.map((runner: CoreMarketRunnerResponse) => applyRunnerStatusChanges({
        eventId: eventData.id,
        marketId: market.id,
        runner,
      }, changes)),
    })),
  };
}

export function applyEventsStatusChanges(
  currentResponse: GetSportEventsResponse | CoreSportEventResponse,
  changes: ClaimToUpdateSportEventStatusChanged[],
): typeof currentResponse {
  if ('events' in currentResponse) {
    return {
      ...currentResponse,
      events: currentResponse.events
        .map((eventData: CoreSportEventResponse): CoreSportEventResponse => applyEventStatusChanges(
          eventData,
          changes,
        )),
    };
  }

  return applyEventStatusChanges(currentResponse, changes);
}
