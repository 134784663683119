import type { ISlipTypeId } from '@leon-hub/api-sdk';
import { slipTypeIdExpress, slipTypeIdSingle, slipTypeIdSystem } from '@leon-hub/api-sdk';

import { SlipTypeSingle } from '../../../constants';

export function getMatchedBetMode(slipType: string | null): ISlipTypeId {
  if (!slipType || slipType === SlipTypeSingle) {
    return slipTypeIdSingle;
  }
  if (slipType.startsWith('e')) {
    return slipTypeIdExpress;
  }
  return slipTypeIdSystem;
}
