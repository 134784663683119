import type { BannerSportEventFragment } from '@leon-hub/api-sdk';
import {
  assert,
  isArray,
  isBoolean,
  isNumber,
  isObject,
  isString,
  isUndefined,
} from '@leon-hub/guards';

import type {
  CoreCompetitorResponse,
  CoreMarketResponse,
  CoreSportEventResponse,
  LiveStatusResponse,
} from 'web/src/modules/sportline/types/rest';
import { SportEventMatchPhase } from 'web/src/modules/sportline/enums';
import { BetlineSportEventStatus, BetlineType } from 'web/src/modules/sportline/enums/rest';

function isCoreCompetitorResponse(value: unknown): value is CoreCompetitorResponse[] {
  if (!isArray(value)) {
    return false;
  }
  return value.every((item) => isObject(item)
    && isNumber(item.id)
    && isString(item.name));
}

function isCoreMarketResponse(value: unknown): value is CoreMarketResponse[] {
  if (!isArray(value)) {
    return false;
  }
  return value.every((item) => isObject(item)
    && isNumber(item.id)
    && isNumber(item.marketTypeId)
    && isString(item.name)
    && isArray(item.runners)
    && isBoolean(item.open));
}

function isBetlineType(value: unknown): value is BetlineType {
  const expectedValues: String[] = [BetlineType.Live, BetlineType.ExtraLive, BetlineType.Prematch, BetlineType.Planed];
  return isString(value) && expectedValues.includes(value);
}

function isSportEventMatchPhase(value: unknown): value is SportEventMatchPhase {
  return isString(value) && Object.values(SportEventMatchPhase).map((item) => `${item}`).includes(value);
}

function isBetlineSportEventStatus(value: unknown): value is BetlineSportEventStatus {
  return isString(value) && Object.values(BetlineSportEventStatus).map((item) => `${item}`).includes(value);
}

function isLiveStatusResponse(value: unknown): value is LiveStatusResponse {
  return isObject(value);
}

export function mapBannerEvent(bannerEvent: BannerSportEventFragment): CoreSportEventResponse {
  const {
    competitors,
    betline,
    matchPhase,
    status,
    markets,
  } = bannerEvent;
  const liveStatus = bannerEvent.liveStatus ?? undefined;
  assert(isCoreCompetitorResponse(competitors), 'competitors have unexpected value');
  assert(isCoreMarketResponse(markets), 'markets have unexpected value');
  assert(isBetlineType(betline), 'betline have unexpected value');
  assert(isSportEventMatchPhase(matchPhase), 'matchPhase have unexpected value');
  assert(isBetlineSportEventStatus(status), 'status have unexpected value');
  assert(isUndefined(liveStatus) || isLiveStatusResponse(liveStatus), 'liveStatus have unexpected value');

  const result: CoreSportEventResponse = {
    id: bannerEvent.id,
    name: bannerEvent.name,
    nameDefault: bannerEvent.name,
    hasMarketWithZeroMargin: markets[0].hasZeroMargin,
    competitors,
    kickoff: bannerEvent.kickoff,
    lastUpdated: 0,
    league: {
      id: 0,
      name: '',
      nameDefault: '',
      sport: {
        id: 0,
        name: '',
        family: '',
      },
      region: {
        id: 0,
        name: '',
        nameDefault: '',
        url: '',
      },
    },
    betline,
    open: bannerEvent.open,
    matchPhase,
    markets,
    url: bannerEvent.url,
    status,
    liveStatus,
  };
  return result;
}
