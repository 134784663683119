import type { VCircularProgressProps } from '../../types';

export function getSafePercentage(
  props: Pick<VCircularProgressProps, 'percentage'>,
): number {
  if (!props.percentage) {
    return 0;
  }

  if (props.percentage > 100) {
    return 100;
  }
  if (props.percentage < 0) {
    return 0;
  }
  if (Number.isInteger(props.percentage)) {
    return props.percentage;
  }
  if (props.percentage > 10) {
    return Number(props.percentage.toPrecision(3));
  }
  return props.percentage < 1
    ? Number(props.percentage.toPrecision(1))
    : Number(props.percentage.toPrecision(2));
}
