import { isString } from "@leon-hub/guards";
function getObjectFromPairs(pairs) {
  return pairs.reduce((accumulator, current) => {
    if (isString(current.key) && isString(current.value)) {
      accumulator[current.key] = current.value;
    }
    return accumulator;
  }, {});
}
function phoneStringToPhoneInput(phone) {
  return {
    prefix: "7",
    suffix: phone.replace(/^\+?7/, "")
  };
}
export {
  getObjectFromPairs,
  phoneStringToPhoneInput
};
