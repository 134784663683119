import type { Web2Image } from '@leon-hub/api-sdk';
import type { Maybe } from '@leon-hub/types';

import type { Web2ImageSource } from '../types';

export function getImageSourceFromRawImageData(
  imageData: Maybe<Web2Image>,
): Maybe<Web2ImageSource> {
  if (!imageData?.src) {
    return null;
  }

  return {
    src: imageData.src,
    x1: imageData.x1 || undefined,
    x2: imageData.x2 || undefined,
    x3: imageData.x3 || undefined,
    x1webp: imageData.x1webp || undefined,
    x2webp: imageData.x2webp || undefined,
    x3webp: imageData.x3webp || undefined,
  };
}
