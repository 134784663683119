import { RequestGroupScore24 } from '@leon-hub/api';
import {
  getLeagueStatisticsByLeagueId as getBetlineLeagueStatisticsByLeagueId,
  getBetlineLeagueStatus,
} from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type {
  BetlineGetLeagueStatisticResponse,
  BetlineLeagueStatusResponse,
} from 'web/src/modules/sportline/types/rest';

export function getBetlineLeagueStatistics(
  options: { leagueId: number },
  { silent }: { silent?: boolean },
): Promise<BetlineGetLeagueStatisticResponse> {
  const apiClient = useGraphqlClient();
  return getBetlineLeagueStatisticsByLeagueId(apiClient, (node) => node.queries.betLine.getLeagueStatisticsByLeagueId, { options }, {
    silent,
    group: RequestGroupScore24,
  });
}

export function getBetlineLeagueStatisticStatus(
  options: { leagueId: number },
  { silent }: { silent?: boolean },
): Promise<BetlineLeagueStatusResponse> {
  const apiClient = useGraphqlClient();
  return getBetlineLeagueStatus(apiClient, (node) => node.queries.betLine.getLeagueStatus, { options }, {
    silent,
    group: RequestGroupScore24,
  });
}
