<script lang="ts" setup>
import type {
  ButtonExposed,
  VButtonEmits,
  VButtonProps,
  VButtonSlots,
} from '../../types';
import { useBaseButton } from '../../composables/useBaseButton';
import { useButtonEmits } from '../../composables/useButtonEmits';
import { useVButton } from '../../composables/useVButton';
import { getVButtonDefaultProps } from '../../utils/getVButtonDefaultProps';

const props = withDefaults(defineProps<VButtonProps>(), getVButtonDefaultProps());
const emits = defineEmits<VButtonEmits>();
const slots = defineSlots<VButtonSlots>();

const {
  mainTag,
  iconComponent,
  isIconOnly,
  isIconRight,
  isAnimated,
  computedAttributes,
  onTouchStart,
  onTouchEnd,
  focusOnElement,
} = useBaseButton(props, slots);

const {
  isDisabledState,
  iconProps,
  iconClassNames,
  isIconLeft,
  isAnimation,
  isSpecialHeight,
  isSpecialKind,
} = useVButton(props, slots, isAnimated);

const {
  click,
  keydown,
  mousedown,
  focus,
  blur,
} = useButtonEmits(emits);

defineExpose<ButtonExposed>({
  focus: focusOnElement,
});
</script>

<template>
  <component v-auto-id="'Button'"
    :is="tag"
    v-bind="computedAttributes"
    :id="id"
    ref="mainTag"
    :class="{
      [$style.button]: true,
      [$style['button--rounded']]: props.rounded,
      [$style['button--icon-only']]: isIconOnly,
      [$style['button--icon-left']]: isIconLeft,
      [$style['button--icon-right']]: isIconRight,
      [$style['button--full-width']]: props.fullWidth,
      [$style[`button--kind-${props.kind}`]]: isSpecialKind,
      [$style[`button--height-${props.height}`]]: isSpecialHeight,
      [$style['button--is-loading']]: isLoading,
      [$style['button--uppercase']]: isUppercase,
      [$style['button--is-absolute']]: isAbsolutePosition,
      [$style['button--animation']]: isAnimation,
      [$style[`button--text-color-${textColor}`]]: !!textColor,
      [$style['button--no-paddings']]: props.isNoPaddings,
    }"
    :disabled="isDisabledState"
    :role="role"
    @touchstart="onTouchStart"
    @touchend="onTouchEnd"
    @click="click"
    @keydown="keydown"
    @mousedown="mousedown"
    @focus="focus"
    @blur="blur"
  >
    <component
      :is="iconComponent"
      v-bind="iconProps"
      v-if="iconComponent"
      :class="iconClassNames.map((name) => $style[name])"
    />
    <template v-if="$slots.default || label">
      <slot>
        {{ label }}
      </slot>
    </template>

    <slot name="icon" />
  </component>
</template>

<style lang="scss" module>
$allButtonHeights: (
  x-large: 48px,
  large: 44px,
  medium: 40px,
  small: 36px,
  tiny: 32px,
  tiny-extra: 20px,
);

.button {
  $self: &;

  @include medium\14\16\025;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: get($allButtonHeights, large);
  padding: 0 16px;
  color: var(--BrandText);
  text-decoration: none;
  background-color: var(--BrandDefault);
  border: none;
  border-radius: if($isEnvStyleTwin, 32px, 5px);

  &:not([disabled]) {
    cursor: pointer;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--icon-right {
    flex-direction: row-reverse;
  }

  &--full-width {
    width: 100%;
  }

  &--is-absolute {
    position: absolute;
  }

  &__icon {
    flex-shrink: 0;

    &--left {
      margin-right: 4px;
    }

    &--right {
      margin-left: 4px;
    }

    &--loader {
      padding: 2px;
      color: currentcolor;
    }
  }

  &:focus-visible {
    color: var(--BrandText);
    background-color: var(--BrandHighlight);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  &:hover:not([disabled]) {
    @include is-app-layout-desktop {
      color: var(--BrandText);
      background-color: var(--BrandHighlight);
    }
  }

  &--rounded {
    border-radius: 24px;

    &#{$self}--icon-right {
      padding-left: 20px;

      &#{$self}--height {
        &-medium {
          padding-left: 16px;
        }

        &-small {
          padding-left: 20px;
        }

        &-tiny {
          padding-left: 12px;
        }
      }
    }

    &#{$self}--icon-left {
      padding-right: 20px;

      &#{$self}--height {
        &-medium {
          padding-right: 16px;
        }

        &-small {
          padding-right: 20px;
        }

        &-tiny {
          padding-right: 12px;
        }
      }
    }
  }

  &--animation {
    transition: transform 0.1s;
    will-change: transform;

    &:active:not([disabled]) {
      transform: scale(0.99, 0.96);

      @include is-app-layout-desktop {
        @if $isEnvStyleTwin {
          background-color: var(--BrandDefault);
        }
      }
    }

    @include for-phone-only {
      transform: scale(0.99, 0.96);
    }
  }

  &--icon-only {
    justify-content: center;
    width: get($allButtonHeights, large);
    padding: 0;

    &#{$self}--animation:active:not([disabled]) {
      transform: scale(0.95);
    }
  }

  &--with-icon {
    &#{$self}--icon-right {
      flex-direction: row-reverse;
    }
  }

  &--kind {
    &-primary-dark-version {
      color: if($isEnvStyleTwin, var(--BrandText), var(--DBrandText));
      background-color: if($isEnvStyleTwin, var(--BrandDefault), var(--DBrandDefault));

      &:focus-visible {
        color: if($isEnvStyleTwin, var(--BrandText), var(--DBrandText));
        background-color: if($isEnvStyleTwin, var(--BrandHighlight), var(--DBrandHighlight));
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: if($isEnvStyleTwin, var(--BrandText), var(--DBrandText));
          background-color: if($isEnvStyleTwin, var(--BrandHighlight), var(--DBrandHighlight));
        }
      }
    }

    &-primary-opacity {
      color: var(--BrandDefault);
      background-color: var(--OpacityBrandDefault);

      &:focus-visible {
        color: var(--BrandDefault);
        background-color: var(--OpacityBrandHighlight);
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--BrandDefault);
          background-color: var(--OpacityBrandHighlight);
        }
      }
    }

    &-secondary {
      color: var(--TextDefault);
      background-color: var(--Layer2);

      &:focus-visible {
        color: var(--Layer2);
        background-color: var(--TextDefault);
        border: 1px solid rgba(0, 0, 0, 0.3);
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--Layer2);
          background-color: var(--TextDefault);
        }
      }
    }

    &-secondary-dark {
      color: var(--TextDefault);
      background-color: var(--Layer1);

      &:focus-visible {
        color: var(--Layer1);
        background-color: var(--TextDefault);
        border: 1px solid rgba(0, 0, 0, 0.3);
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--Layer1);
          background-color: var(--TextDefault);
        }
      }
    }

    &-base {
      color: var(--TextPrimary);
      background: var(--Button);

      &:focus-visible {
        color: var(--TextDefault);
        background-color: var(--Highlight);
        border: 1px solid rgba(255, 255, 255, 0.3);
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--TextDefault);
          background-color: var(--Highlight);
        }
      }
    }

    &-base-opacity {
      color: var(--TextPrimary);
      background: var(--OpacityLayer1);

      &:focus-visible {
        color: var(--TextDefault);
        background-color: var(--Highlight);
        border: 1px solid rgba(255, 255, 255, 0.3);
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--TextDefault);
          background-color: var(--Highlight);
        }
      }
    }

    &-transparent {
      color: var(--TextPrimary);
      background-color: transparent;

      &:focus-visible {
        color: var(--TextDefault);
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.3);
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--TextDefault);
          background-color: transparent;
        }
      }
    }

    &-transparent-dark-version {
      color: if($isEnvStyleTwin, var(--TextPrimary), var(--DTextPrimary));
      background-color: transparent;

      &:focus-visible {
        color: if($isEnvStyleTwin, var(--TextDefault), var(--DTextDefault));
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.3);
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: if($isEnvStyleTwin, var(--TextDefault), var(--DTextDefault));
          background-color: transparent;
        }
      }
    }

    &-header {
      color: var(--TextDefault);
      background-color: if($isEnvStyleTwin, var(--Layer1), var(--Layer2));

      &:focus-visible {
        color: var(--TextDefault);
        background-color: var(--Highlight);
        border: 1px solid rgba(255, 255, 255, 0.3);
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--TextDefault);
          background-color: var(--Highlight);
        }
      }
    }

    &-header-dark-version {
      color: if($isEnvStyleTwin, var(--TextDefault), var(--DTextDefault));
      background-color: if($isEnvStyleTwin, var(--Layer1), var(--DLayer2));

      &:focus-visible {
        color: if($isEnvStyleTwin, var(--TextDefault), var(--DTextDefault));
        background-color: var(--Highlight);
        border: 1px solid rgba(255, 255, 255, 0.3);
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: if($isEnvStyleTwin, var(--TextDefault), var(--DTextDefault));
          background-color: if($isEnvStyleTwin, var(--Highlight), var(--DHighlight));
        }
      }
    }

    &-header-active {
      color: var(--Layer2);
      background-color: var(--TextDefault);

      &:focus-visible {
        color: var(--Layer2);
        background-color: var(--TextDefault);
        border: 1px solid rgba(255, 255, 255, 0.3);
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--Layer2);
          background-color: var(--TextDefault);
        }
      }
    }

    &-header-active-dark-version {
      color: if($isEnvStyleTwin, var(--Layer2), var(--DLayer2));
      background-color: if($isEnvStyleTwin, var(--TextDefault), var(--DTextDefault));

      &:focus-visible {
        color: if($isEnvStyleTwin, var(--Layer2), var(--DLayer2));
        background-color: if($isEnvStyleTwin, var(--TextDefault), var(--DTextDefault));
        border: 1px solid rgba(255, 255, 255, 0.3);
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: if($isEnvStyleTwin, var(--Layer2), var(--DLayer2));
          background-color: if($isEnvStyleTwin, var(--TextDefault), var(--DTextDefault));
        }
      }
    }

    &-monochrome {
      color: var(--Layer1);
      background-color: var(--TextDefault);

      &:focus-visible {
        color: var(--Layer1);
        background-color: var(--TextPrimary);
        border: 1px solid rgba(255, 255, 255, 0.3);
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--Layer1);
          background-color: var(--TextPrimary);
        }
      }

      &[disabled] {
        color: var(--Layer1);
        background-color: var(--TextDefault);
      }
    }

    &-nav {
      color: var(--TextSecondary);
      background-color: transparent;

      &:focus-visible {
        color: var(--TextDefault);
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.3);
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--TextDefault);
          background-color: transparent;
        }
      }
    }

    &-transparent-blue {
      color: var(--Blue);
      background-color: transparent;

      &:focus-visible {
        color: var(--BlueHighlight);
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.3);
      }

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--BlueHighlight);
          background-color: transparent;
        }
      }

      &[disabled] {
        color: var(--TextPrimary);
        background-color: transparent;
      }
    }

    &-yellow {
      color: var(--AlertButtonText);
      background-color: var(--AlertDefault);

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--AlertButtonText);
          background-color: var(--AlertHighlight);
        }
      }

      &:focus-visible {
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.3);
      }
    }

    &-red {
      color: var(--BrandText);
      background-color: var(--ErrorDefault);

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--BrandText);
          background-color: var(--ErrorHighlight);
        }
      }
    }

    &-cancel {
      color: var(--BrandDefault);
      background-color: var(--OpacityBrandDefault);

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--BrandText);
          background-color: var(--BrandHighlight);
        }
      }

      &[disabled] {
        color: rgba(31, 173, 102, 0.2);
        cursor: default;
        opacity: 0.8;
      }
    }

    &-dark-opacity {
      color: var(--AlertButtonText);
      background-color: transparent;

      &:hover:not([disabled]) {
        @include is-app-layout-desktop {
          color: var(--AlertButtonText);
          background-color: transparent;
        }
      }
    }
  }

  &[disabled] {
    cursor: default;
    opacity: 0.65;
  }

  &--height {
    &-x-large {
      height: get($allButtonHeights, x-large);

      &#{$self}--icon-only {
        width: get($allButtonHeights, x-large);
        padding: 0;
      }
    }

    &-large {
      @if $isEnvStyleTwin {
        border-radius: 24px;
      }
    }

    &-medium {
      @include medium\13\16;

      height: get($allButtonHeights, medium);
      padding: 0 12px;

      @if $isEnvStyleTwin {
        border-radius: 20px;
      }

      &#{$self}--rounded {
        border-radius: 20px;
      }

      &#{$self}--icon-only {
        width: get($allButtonHeights, medium);
        padding: 0;
      }
    }

    &-small {
      @include medium\12\16\04\caps;

      height: get($allButtonHeights, small);
      padding: 0 16px;

      @if $isEnvStyleTwin {
        border-radius: 18px;
      }

      &#{$self}--rounded {
        border-radius: 18px;
      }

      &#{$self}--icon-only {
        width: get($allButtonHeights, small);
        padding: 0;
      }
    }

    &-tiny {
      @include medium\13\16;

      height: get($allButtonHeights, tiny);
      padding: 0 8px;

      @if $isEnvStyleTwin {
        border-radius: 16px;
      }

      &#{$self}--rounded {
        border-radius: 16px;
      }

      &#{$self}--icon-only {
        width: get($allButtonHeights, tiny);
        padding: 0;
      }
    }

    &-tiny-extra {
      @include medium\11\16\-02\caps;

      height: get($allButtonHeights, tiny-extra);
      padding: 0 6px;

      & .button__icon {
        width: 12px;
        height: 12px;
      }
    }
  }

  &--is-loading {
    @include z-index(loading-button);

    @if $isEnvStyleTwin {
      background-color: var(--OpacityBrandHighlight);
    }
  }

  &--text-color {
    &-white {
      color: var(--White);
    }

    &-default {
      color: var(--TextDefault);
    }
  }

  &--no-paddings {
    padding: 0;
  }
}
</style>
