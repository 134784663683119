import type {
  SportFamily,
} from 'web/src/modules/sportline/enums';
import type {
  CoreSportEventResponse,
  GetSportEventsChangesResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import {
  isSportFamilyEquals,
} from 'web/src/modules/sportline/utils';
import {
  ResponseMappings,
} from 'web/src/modules/sportline/utils/rest/pre-build';

export class BetlineReplaceResponse {
  constructor(private current: Maybe<GetSportEventsResponse>) {}

  /**
   * Update only events from one sport in full list
   */
  replaceSportByResponse(
    incomingResponse: Maybe<GetSportEventsChangesResponse | GetSportEventsResponse>,
    sportFamily: SportFamily | string,
  ): Maybe<GetSportEventsResponse> {
    if (!incomingResponse) {
      return this.current
        ? Object.freeze(this.current)
        : null;
    }

    const response = BetlineReplaceResponse.unknownResponseToSportEventsResponse(incomingResponse);

    if (!this.current) {
      return response;
    }

    const filteredEvents = this.filterEventsBySport(sportFamily);

    filteredEvents.otherEvents.splice(filteredEvents.startIndex || 0, 0, ...response.events);

    return Object.freeze({
      enabled: response.enabled,
      vtag: response.vtag,
      events: filteredEvents.otherEvents,
      totalCount: filteredEvents.otherEvents.length,
    });
  }

  /**
   * Update only events from one region in full list
   */
  replaceRegionByResponse(
    incomingResponse: Maybe<GetSportEventsChangesResponse | GetSportEventsResponse>,
    regionId: number,
  ): Maybe<GetSportEventsResponse> {
    if (!incomingResponse) {
      return this.current
        ? Object.freeze(this.current)
        : null;
    }

    const response = BetlineReplaceResponse.unknownResponseToSportEventsResponse(incomingResponse);

    if (!this.current) {
      return response;
    }

    const filteredEvents = this.filterEventsByRegion(regionId);

    filteredEvents.otherEvents.splice(filteredEvents.startIndex || 0, 0, ...response.events);

    return Object.freeze({
      enabled: response.enabled,
      vtag: response.vtag,
      events: filteredEvents.otherEvents,
      totalCount: filteredEvents.otherEvents.length,
    });
  }

  protected filterEventsBySport(sportFamily: SportFamily | string): {
    sportEvents: CoreSportEventResponse[];
    otherEvents: CoreSportEventResponse[];
    startIndex: Maybe<number>;
  } {
    let startIndex: Maybe<number> = null;
    const responseMappings = new ResponseMappings();
    const sportEvents = <CoreSportEventResponse[]>[];
    const otherEvents = (this.current?.events || []).filter((event, index) => {
      const leagueResponse = responseMappings.getLeagueResponseOrSetNew(event.league);
      const sportResponse = responseMappings.getSportResponseOrSetNew(leagueResponse.sport);
      const isSportFamily = isSportFamilyEquals(sportResponse.family, sportFamily);

      if (isSportFamily) {
        sportEvents.push(event);
        if (startIndex === null) { startIndex = index; }
        return false;
      }

      return true;
    });

    return {
      sportEvents,
      otherEvents,
      startIndex,
    };
  }

  protected filterEventsByRegion(regionId: number): {
    regionEvents: CoreSportEventResponse[];
    otherEvents: CoreSportEventResponse[];
    startIndex: Maybe<number>;
  } {
    let startIndex: Maybe<number> = null;
    const responseMappings = new ResponseMappings();
    const regionEvents = <CoreSportEventResponse[]>[];
    const otherEvents = (this.current?.events || []).filter((event, index) => {
      const leagueResponse = responseMappings.getLeagueResponseOrSetNew(event.league);
      const regionResponse = responseMappings.getRegionResponseOrSetNew(leagueResponse.region);
      const isRegionEvent = regionResponse.id === regionId;

      if (isRegionEvent) {
        regionEvents.push(event);
        if (startIndex === null) { startIndex = index; }
        return false;
      }

      return true;
    });

    return {
      regionEvents,
      otherEvents,
      startIndex,
    };
  }

  /** @deprecated use normalizeEventsChangesResponseToDefaultResponse instead */
  static unknownResponseToSportEventsResponse(
    response: GetSportEventsChangesResponse | GetSportEventsResponse,
  ): Readonly<GetSportEventsResponse> {
    return 'events' in response
      ? Object.freeze({ ...response })
      : Object.freeze({
          enabled: response.enabled,
          events: response.data,
          vtag: response.vtag,
          totalCount: response.data.length,
        });
  }
}
