import type { FieldGuards } from '@leon-hub/types';
import {
  bindIsArrayOf,
  isBoolean,
  isNumber,
  isString,
  isUndefined,
  isValidInterface,
} from '@leon-hub/guards';

import type { CoreSportEventResponse } from 'web/src/modules/sportline/types/rest';

import { isSportEventMatchPhase } from '../core';
import { isBetlineSportEventStatus } from './isBetlineSportEventStatus';
import { isBetlineType } from './isBetlineType';
import { isCoreCompetitorResponse } from './isCoreCompetitorResponse';
import { isCoreLeagueReferenceResponse } from './isCoreLeagueReferenceResponse';
import { isCoreLeagueResponse } from './isCoreLeagueResponse';
import { isCoreMarketResponse } from './isCoreMarketResponse';
import { isLiveStatusResponse } from './isLiveStatusResponse';
import { coreSportlineEventWidgetResponseFieldGuards } from './sportline-event-widget';

const isCoreSportEventResponseFieldGuards: FieldGuards<CoreSportEventResponse> = {
  id: isNumber,
  name: isString,
  nameDefault: isString,
  competitors: bindIsArrayOf(isCoreCompetitorResponse),
  kickoff: isNumber,
  lastUpdated: isNumber,
  league: [isCoreLeagueReferenceResponse, isCoreLeagueResponse],
  betline: isBetlineType,
  url: isString,
  open: [isUndefined, isBoolean],
  status: [isUndefined, isBetlineSportEventStatus],
  matchPhase: [isUndefined, isSportEventMatchPhase],
  marketsCount: [isUndefined, isNumber],
  runnersCount: [isUndefined, isNumber],
  liveStatus: [isUndefined, isLiveStatusResponse],
  markets: [isUndefined, bindIsArrayOf(isCoreMarketResponse)],
  hasMarketWithZeroMargin: [isUndefined, isBoolean],
  widgetId: coreSportlineEventWidgetResponseFieldGuards.widgetId,
  widgetType: coreSportlineEventWidgetResponseFieldGuards.widgetType,
  widgetVirtual: coreSportlineEventWidgetResponseFieldGuards.widgetVirtual,
  widgetData: coreSportlineEventWidgetResponseFieldGuards.widgetData,
};

export function isCoreSportEventResponse(value: unknown, silent = false): value is CoreSportEventResponse {
  return isValidInterface<CoreSportEventResponse>(value, isCoreSportEventResponseFieldGuards, { silent });
}
