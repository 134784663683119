import { AppEmitter } from "@leon-hub/app-emitter";
const emitter = /* @__PURE__ */ new AppEmitter();
const addLogBreadCrumbEventType = 1;
const startLoginDeviceRoutineEventType = 0;
const useAppEmitter = () => emitter;
export {
  addLogBreadCrumbEventType,
  emitter,
  startLoginDeviceRoutineEventType,
  useAppEmitter
};
