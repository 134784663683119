import type { RegionRouteLocationParameters } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type {
  CoreRegionResponse,
  CoreSportResponse,
} from 'web/src/modules/sportline/types/rest';
import type { SportFamilyMapping } from 'web/src/modules/sportline/types/settings';

import { matchSportFamily } from './matchSportFamily';

interface BuildNavigationParametersOptions {
  sportResponse: CoreSportResponse;
  regionResponse: CoreRegionResponse;
  sportFamilyMapping: Maybe<SportFamilyMapping>;
}

export function buildRegionNavigationParameters(
  options: BuildNavigationParametersOptions,
): RegionRouteLocationParameters {
  const {
    sportFamilyMapping,
    regionResponse,
    sportResponse,
  } = options;

  return {
    id: String(regionResponse.id),
    urlName: regionResponse.url,
    sportFamily: matchSportFamily(sportResponse, sportFamilyMapping),
  };
}
