import type { TopLeagueListElement } from 'web/src/modules/sportline/types';
import type { SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';

import { extractTopLeaguesByCondition } from './extractTopLeaguesByCondition';

export function extractZeroMarginTopLeagues(
  listElement?: Maybe<SportsTreeSportElement | SportsTreeSportElement[]>,
): TopLeagueListElement[] {
  return extractTopLeaguesByCondition(listElement, (leagueElement) => leagueElement.league.hasZeroMargin);
}
