import { computed, reactive } from 'vue';

import { assert } from '@leon-hub/guards';
import { Tag } from '@leon-hub/tags';

import type { VAccordionSharedState } from 'web/src/components/Accordion/types';

import type { VAccordionProps } from '../types';
import type { VAccordionComposable } from './types';

export default function useVAccordion(props: VAccordionProps): VAccordionComposable {
  const accordionSharedState: VAccordionSharedState = reactive({
    openItem: null,
    panels: false,
    indicators: true,
    independentItems: false,
    controlled: false,
    parentTag: Tag.SECTION,
    hasIcon: false,
  });

  const listTags = new Set<string>([
    Tag.OL,
    Tag.UL,
  ]);

  const isList = computed(() => !!(props.tag && listTags.has(props.tag)));

  function beforeComponentMount() {
    const { indicators } = props;
    assert(indicators !== undefined, 'Expected indicators to be defined');
    accordionSharedState.panels = props.panels;
    accordionSharedState.indicators = indicators;
    accordionSharedState.independentItems = !!props.independentItems;
    accordionSharedState.controlled = !!props.controlled;
    accordionSharedState.parentTag = props.tag ?? Tag.SECTION;
  }

  return {
    isList,
    accordionSharedState,
    beforeComponentMount,
  };
}
