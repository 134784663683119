import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { VIconProps } from '@components/v-icon';

import type { BonusLoyaltyProgramBalanceEmits, BonusLoyaltyProgramBalanceProps } from '../types';
import getIconProps from './utils/getIconProps';

export interface BonusLoyaltyProgramBalanceComposable {
  iconProps: ComputedRef<VIconProps>;
  handleClick(): void;
}

export default function useBonusLoyaltyProgramBalance(
  props: BonusLoyaltyProgramBalanceProps,
  emit: BonusLoyaltyProgramBalanceEmits,
): BonusLoyaltyProgramBalanceComposable {
  const iconProps = computed<VIconProps>(() => getIconProps(props));

  const handleClick = (): void => {
    emit('click');
  };

  return {
    iconProps,
    handleClick,
  };
}
