import type { FieldGuards } from '@leon-hub/types';
import {
  bindIsArrayOf,
  isValidInterface,
} from '@leon-hub/guards';

import type { GetSportsRegionResponse } from 'web/src/modules/sportline/types/rest';

import { isCoreRegionResponseFieldGuards } from './isCoreRegionResponse';
import { isGetSportsLeagueResponse } from './isGetSportsLeagueResponse';

export const isGetSportsRegionResponseFieldGuards: FieldGuards<GetSportsRegionResponse> = {
  ...isCoreRegionResponseFieldGuards,
  leagues: bindIsArrayOf(isGetSportsLeagueResponse),
};

export function isGetSportsRegionResponse(value: unknown): value is GetSportsRegionResponse {
  return isValidInterface<GetSportsRegionResponse>(value, isGetSportsRegionResponseFieldGuards);
}
