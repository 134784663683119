const allowedDelimiters = [':', '.', '/'];

export interface ParsedScoreString {
  parts: [string, string];
  delimiter: string;
}

export default function parseScore(score?: string): ParsedScoreString | undefined {
  if (!score) {
    return undefined;
  }

  const delimiter = allowedDelimiters.find((d) => (new RegExp(`^[^\\${d}]+\\${d}[^\\${d}]+$`)).test(score));

  if (!delimiter) {
    return undefined;
  }

  const [part1, part2] = score.trim().split(delimiter);

  return {
    parts: [part1?.trim() || '', part2?.trim() || ''],
    delimiter,
  };
}
