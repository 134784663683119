import type { Ref } from 'vue';
import type { Router } from 'vue-router';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { routerLinkClickGuard } from '@components/link';

import type { SportlineEvent } from 'web/src/modules/sportline/types';
import { resolveSportEventsDetailsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';

interface UseSportlineEventLinkProps {
  sportEvent: Ref<SportlineEvent>;
  activeEventId?: Ref<Maybe<string>>;
}

interface UseSportlineEventLinkEmits {
  onClick?(): void;
}

interface UseSportlineEventLinkComposable {
  href: Ref<Optional<string>>;
  isActive: Ref<boolean>;
  onLinkClick(mouseEvent: MouseEvent, checkLinkGuard?: boolean): boolean;
}

export function useSportlineEventLink(
  props: UseSportlineEventLinkProps,
  emits: UseSportlineEventLinkEmits,
): UseSportlineEventLinkComposable {
  const { sportEvent, activeEventId } = props;

  const router = useRouter();

  const detailsPageTarget = computed<ReturnType<Router['resolve']>>(() => {
    const location = resolveSportEventsDetailsPageLink(sportEvent.value.navigationParameters);
    return router.resolve(location);
  });
  const isActive = computed<boolean>(() => activeEventId?.value === sportEvent.value.id);
  const href = computed(() => (isActive.value ? undefined : detailsPageTarget.value.href));

  function onLinkClick(mouseEvent: MouseEvent, checkLinkGuard = true): boolean {
    if (!checkLinkGuard || routerLinkClickGuard(mouseEvent)) {
      void router.push(detailsPageTarget.value);
      emits.onClick?.();
      return true;
    }

    return false;
  }

  return {
    href,
    isActive,
    onLinkClick,
  };
}
