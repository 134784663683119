import type { VCircularProgressProps } from '../../types';
import { getMatchedSizeValue } from './getMatchedSizeValue';

export function getViewBox(
  props: VCircularProgressProps,
): string {
  const matchedSizeValue = getMatchedSizeValue(props);

  return `0 0 ${matchedSizeValue} ${matchedSizeValue}`;
}
