import type { OptionalAnyIconName } from '@leon-hub/icons';
import { IconName } from '@leon-hub/icons';

import type { VListItemAccordionProps } from '../types';
import { ListItemAccordionKind } from '../enums';

export function getAccordionIconName(props: VListItemAccordionProps): OptionalAnyIconName {
  if (props.type === ListItemAccordionKind.PROMO) {
    return props.isDone ? IconName.CHECK_FILLED : IconName.FAKE_CHECKBOX;
  }
  return props.iconName;
}
