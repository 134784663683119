import {
  provide,
  ref,
} from 'vue';

import type { VListAccordionSharedState, VListAccordionSharedStateProps, VListProps } from '../types';
import { accordionSharedState } from '../types';

export function useVList(props: VListProps): void {
  const openItem: VListAccordionSharedStateProps['openItem'] = ref(null);

  const updateOpenItem = (value: VListAccordionSharedState['openItem']): void => {
    openItem.value = value;
  };

  provide(accordionSharedState, {
    openItem,
    independentItems: props.independentItems ?? false,
    updateOpenItem,
  });
}
