<script lang="ts" setup>
import { onMounted } from 'vue';

import { IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type { VCircularProgressProps } from './types';
import { useVCircularProgress } from './composables/useVCircularProgress';
import {
  CircleBackground,
  CircleColor,
  CircleSize,
} from './enums';

const props = withDefaults(defineProps<VCircularProgressProps>(), {
  percentage: 0,
  iconSize: IconSize.SIZE_24,
  circleSize: CircleSize.DEFAULT,
  circleBackground: CircleBackground.DEFAULT,
  circleColor: CircleColor.DEFAULT,
});

const {
  progress,
  circularCircle,
  checkIcon,
  matchedSizeValue,
  circleStrokeWidth,
  circlePositionProps,
  viewBox,
  safePercentage,
  relativeSafePercentage,
  strokeDasharray,
  filterId,
  filterShadowColorMatrix,
  onCircularProgressMounted,
} = useVCircularProgress(props);

onMounted(onCircularProgressMounted);

defineExpose({ circularCircle });
</script>

<template>
  <div v-auto-id="'VCircularProgress'"
    ref="circularCircle"
    :class="{
      [$style['circular-progress']]: true,
      [$style[`circular-progress--size-${circleSize}`]]: circleSize,
      [$style[`circular-progress-circle--${circleColor}`]]: circleColor !== CircleColor.DEFAULT,
      [$style[`circular-progress--background-colored`]]: circleBackground === CircleBackground.COLORED,
      [$style[`circular-progress--in-loyalty-program`]]: inLoyaltyProgram,
    }"
  >
    <svg
      :class="$style['circular-progress__svg']"
      :viewBox="viewBox"
      xmlns="http://www.w3.org/2000/svg"
      style="overflow: inherit;"
    >
      <filter
        v-if="!null"
        :id="`inset-shadow-${filterId}`"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
        :width="matchedSizeValue + 20"
        :height="matchedSizeValue + 20"
        :x="-10"
        :y="-10"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          :values="filterShadowColorMatrix"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <circle
        v-bind="circlePositionProps"
        :class="{
          [$style['circular-progress__background']]: true,
          [$style['circular-progress__background--colored']]: circleBackground === CircleBackground.COLORED,
          [$style['circular-progress__background--simple']]: isSimpleProgress,
          [$style['circular-progress__background--white']]: circleColor === CircleColor.WHITE,
        }"
        :stroke-width="circleStrokeWidth"
        fill="none"
      />
      <circle
        v-show="relativeSafePercentage > 0"
        v-bind="circlePositionProps"
        ref="progress"
        :class="{
          [$style['circular-progress__progress']]: true,
          [$style[`circular-progress__progress--${circleColor}`]]: circleColor !== CircleColor.DEFAULT,
        }"
        :stroke-width="circleStrokeWidth"
        :stroke-dasharray="strokeDasharray"
        stroke-linecap="round"
        stroke-dashoffset="0"
        fill="none"
        :filter="!null ? `url(#inset-shadow-${filterId})` : ''"
      />
    </svg>
    <div
      v-if="!isSimpleProgress"
      :class="{
        [$style['circular-progress__content']]: true,
        [$style['circular-progress__content--filled']]: isIconBackground,
      }"
      data-test-id="content"
    >
      <slot>
        <VIcon
          v-if="!!iconName"
          :name="iconName"
          :size="iconSize"
          :class="$style['circular-progress__icon']"
        />
        <template v-else>
          <VIcon
            v-if="safePercentage === 100"
            :name="checkIcon"
            :size="iconSize"
            :class="$style['circular-progress__icon-check']"
          />
          <template v-else>
            <span
              :class="$style['circular-progress__label']"
            >
              {{ safePercentage }}
            </span>
            <span
              :class="{
                [$style['circular-progress__label']]: true,
                [$style['circular-progress__label--percent']]: true,
              }"
            > %</span>
          </template>
        </template>
      </slot>
    </div>
  </div>
</template>

<style lang="scss" module>
$animationTime: 0.5s;
$sizes: (
  mini: 36px,
  default: 52px,
  medium: 64px,
  big: 96px,
  'size-32': 32px,
  'size-40': 40px,
  'size-42': 42px,
  'size-44': 44px,
  'size-52': 52px,
  'size-80': 80px,
  'size-94': 94px,
  'size-112': 112px,
  'size-152': 152px,
  'size-218': 218px,
  'size-s': 44px,
  'size-m': 52px,
  'size-l': 94px,
);

@keyframes circle-chart-fill {
  to { stroke-dasharray: 0 var(--circleLength); }
}

.circular-progress {
  $self: &;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @each $name, $size in $sizes {
    &--size-#{$name} {
      width: $size;
      height: $size;
    }
  }

  &__svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__background {
    stroke: $circleProgressBackgroundStroke;

    &--colored {
      stroke: $circleProgressColoredBackgroundStroke;
    }

    &--simple {
      stroke: $circleProgressSimpledBackgroundStroke;
    }

    &--white {
      stroke: rgba(255, 255, 255, 0.3);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 16px;

    &--filled {
      padding: 8px;
      background-color: $circleProgressIconBackground;
      border-radius: 64px;
    }
  }

  &__progress {
    box-shadow: $circleProgressShadow;
    stroke: $circleProgressStroke;
    transform: rotate(-90deg);
    transform-origin: center;
    animation: circle-chart-fill $animationTime linear reverse;

    &--yellow {
      box-shadow: $circleProgressYellowShadow;
      stroke: $circleProgressYellowStroke;
    }

    &--purple {
      box-shadow: $circleProgressPurpleShadow;
      stroke: $circleProgressPurpleStroke;
    }

    &--green {
      box-shadow: $circleProgressGreenShadow;
      stroke: $circleProgressGreenStroke;
    }

    &--white {
      stroke: white;
    }

    &--light-blue {
      stroke: $circleProgressLightblueStroke;
    }

    &--red {
      box-shadow: $circleProgressRedShadow;
      stroke: $circleProgressRedStroke;
    }
  }

  &__label {
    @include circleProgressLabelFont;

    color: $circleProgressLabelColor;

    &--percent {
      font-size: 10px;
      font-weight: 300;
    }
  }

  &__icon-check {
    fill: var(--BrandDefault);
  }

  &--in-loyalty-program {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-circle--light-blue {
    color: $circleProgressLightblueStroke;

    #{$self}__content--filled {
      background-color: $circleProgressLightblueIconBg;
    }
  }
}
</style>
