import { sleep } from '@leon-hub/utils';

export async function playExpandAnimation(content: HTMLDivElement, { duration, maxHeight }: {
  duration: number;
  maxHeight: number;
}): Promise<void> {
  content.style.willChange = 'visibility, max-height';
  content.style.maxHeight = `${maxHeight}px`;
  content.style.visibility = 'visible';

  await sleep(duration);

  content.style.maxHeight = '';
  content.style.visibility = '';
  content.style.willChange = '';
}
