import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import type {
  Market,
  SportEventMarkets,
} from 'web/src/modules/sportline/types';

export interface SportlineEventMarketsPriorityProps {
  isPrimaryMarketFiltrationDisabled?: Ref<boolean>;
  sportEvent: Ref<Maybe<SportEventMarkets> | undefined>;
}

interface SportlineEventMarketsPriorityComposable {
  markets: Ref<Market[]>;
  primaryMarketIndex: Ref<Maybe<number>>;
  primaryMarket: Ref<Maybe<Market>>;
  secondaryMarkets: Ref<Market[]>;
}

export function useSportlineEventMarketsPriority(
  props: SportlineEventMarketsPriorityProps,
): SportlineEventMarketsPriorityComposable {
  const { sportEvent } = props;
  const isPrimaryMarketFiltrationDisabled = toRef(props.isPrimaryMarketFiltrationDisabled ?? false);

  const markets = computed<Market[]>(() => sportEvent.value?.markets ?? []);

  const primaryMarketIndex = computed<Maybe<number>>(() => {
    if (isPrimaryMarketFiltrationDisabled.value) {
      return null;
    }

    const index = markets.value.findIndex((market) => market.isPrimary);

    return index > -1 ? index : null;
  });
  const primaryMarket = computed<Maybe<Market>>(() => {
    const index = primaryMarketIndex.value;

    if (index === null) {
      return null;
    }

    return markets.value[index] || null;
  });

  const secondaryMarkets = computed<Market[]>(() => {
    const index = primaryMarketIndex.value;

    if (index === null) {
      return markets.value;
    }

    const result = [...markets.value];

    result.splice(index, 1);

    return result;
  });

  return {
    markets,
    primaryMarketIndex,
    primaryMarket,
    secondaryMarkets,
  };
}
