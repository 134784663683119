import type { CombinedSlipEntryInfo } from '../types';
import { getNettWinning } from '../../../utils';

interface Payload {
  sameStakeForSingles: boolean;
  availableEntries: CombinedSlipEntryInfo[];
  selectedStakeValue: number;
  taxPercent: number | null;
}

export function getMultiSinglesTotalWin({
  availableEntries,
  sameStakeForSingles,
  selectedStakeValue,
  taxPercent,
}: Payload): number {
  return availableEntries.reduce((accumulator, currentEntry) => {
    const stake = sameStakeForSingles ? selectedStakeValue
      : Number(currentEntry.metaInfo?.stakeValue || 0);
    const winValue = stake * currentEntry.odds;
    const nettWinning = taxPercent ? getNettWinning({
      stake,
      winValue,
      taxPercent,
    }) : winValue;
    return accumulator + nettWinning;
  }, 0);
}
