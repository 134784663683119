export const UpdateSportlineTimeoutName = {
  Count: 'sportline-count', // bets page zero margin and favourites counters
  ZeroMargin: 'sportline-zero-margin', // zero margin page events list
  Top: 'sportline-top', // top (home) page events update
  TopZeroMargin: 'sportline-top-zero-margin', // top (home) page zero margin block
  TopLeagues: 'sportline-top-leagues', // top league page (bet swipe layout)
  EventSubscription: 'sportline-event-subscription', // cordova subscription for prematch to live update
  StreamEventsIds: 'sportline-stream-events-ids', // Ids list available for customer
  Sports: 'sportline-sports', // Tree on the /bets page
  SidebarSports: 'sportline-sidebar-sports', // Tree in the sidebar
  NavigationSports: 'sportline-navigation-sports', // Tree in the Bet swipe navigation
  CybersportRegions: 'sportline-cybersport-region', // Region tree on the cybersport all tab
  ExtendedEvent: 'sportline-extended-event', // Inline extended event markets (leon desktop)
  FavoriteLeagues: 'sportline-favorite-leagues', // favorites leagues
  FavoriteEvents: 'sportline-favorite-events', // favorites events
  RegionEvents: 'sportline-region-events', // events on the region page
  LeagueEvents: 'sportline-league-events', // events on the league page
  EventDetails: 'sportline-event-details', // details page main info
  EventRelatedEvents: 'sportline-event-related-events', // details page related events
  AllLiveTab: 'sportline-all-live-tab',
  SelectedLiveTab: 'sportline-selected-live-tab',
  ComingLive: 'sportline-coming-live',
} as const;

// eslint-disable-next-line ts/no-redeclare
export type UpdateSportlineTimeoutName = typeof UpdateSportlineTimeoutName[keyof typeof UpdateSportlineTimeoutName];
