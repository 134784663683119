import type { PriceChangePolicy } from '@leon-hub/api-sdk';

import type { CombinedSlipEntryInfo } from '../types';
import isEntryAvailableForBetting from '../../../utils/isEntryAvailableForBetting';
import isMultiSinglesMetaInfoRecordStakeValid from '../../multi-singles/utils/isMultiSinglesMetaInfoRecordStakeValid';

interface Payload {
  input: CombinedSlipEntryInfo[];
  priceChangePolicy: PriceChangePolicy | null;
  autoAcceptTotalHandicapChanges: boolean;
  sameStake: boolean;
}

export default function getSinglesAvailableForBetting({
  input,
  priceChangePolicy,
  sameStake,
  autoAcceptTotalHandicapChanges,
}: Payload): CombinedSlipEntryInfo[] {
  return input.filter((item) => {
    if (!sameStake) {
      const { stakeValue, minStake, maxStake } = item.metaInfo || {};
      if (!item.metaInfo || !isMultiSinglesMetaInfoRecordStakeValid({
        stakeValue,
        minStake,
        maxStake,
      })) {
        return false;
      }
    }
    if (!autoAcceptTotalHandicapChanges && item.oldRunnerName) {
      return false;
    }
    return isEntryAvailableForBetting({
      marketStatus: item.marketStatus,
      priceChangePolicy,
      currentOdds: item.odds,
      originalOdds: item.originalOdds,
    });
  });
}
