import type {
  SportFamily,
} from 'web/src/modules/sportline/enums';
import type {
  MarketColumnsOptions,
  MarketType,
} from 'web/src/modules/sportline/types';
import {
  defaultSportsMarketColumns,
} from 'web/src/modules/sportline/constants';

export function getMarketTypeColumnsCount({ marketType, sportFamily, runnersCount }: {
  marketType?: MarketType | null;
  sportFamily?: SportFamily;
  runnersCount?: number;
}, options?: MarketColumnsOptions): number {
  const preferSportCount = options?.preferSportCount ?? false;
  const defaultCount = 3;
  const defaultForSportCount = sportFamily ? defaultSportsMarketColumns[sportFamily]?.size : undefined;

  if (preferSportCount) {
    if (defaultForSportCount) {
      if (marketType?.columns) {
        // sometimes we have more data than the default sport
        return defaultForSportCount > marketType?.columns
          ? defaultForSportCount
          : marketType?.columns;
      }

      return defaultForSportCount;
    }

    return marketType?.columns || runnersCount || defaultCount;
  }

  return marketType?.columns
    || runnersCount
    || defaultForSportCount
    || defaultCount;
}
