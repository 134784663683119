import type { Ref } from 'vue';
import { computed } from 'vue';

import type { MarketType } from 'web/src/modules/sportline/types';
import { getMarketTypesFromMarketColumnsInfo } from 'web/src/modules/sportline/composables/markets/utils';

import type { SelectedColumnInfoElement, SelectedColumnInfoMap } from '../types';

/**
 * Creates market types map available for sport basis
 * (all available types for sport for all columns)
 */
export function createMarketTypesMapRef(sportlineElements: Ref<SelectedColumnInfoElement[]>): Ref<SelectedColumnInfoMap<MarketType[]>> {
  return computed<SelectedColumnInfoMap<MarketType[]>>(() => {
    const elements = sportlineElements.value;
    const result: SelectedColumnInfoMap<MarketType[]> = {};

    for (const element of elements) {
      const { key } = element;

      if (!key) {
        continue;
      }

      result[key] = getMarketTypesFromMarketColumnsInfo(element);
    }

    return result;
  });
}
