import type {
  LiveSportlineEvent,
  OutrightSportlineEvent,
  PrematchSportlineEvent,
  SportlineEvent,
} from 'web/src/modules/sportline/types';
import { SportlineType } from 'web/src/modules/sportline/enums';

export function isLiveSportlineEvent(
  sportEvent?: Maybe<SportlineEvent>,
): sportEvent is LiveSportlineEvent {
  return sportEvent?.type === SportlineType.Live;
}

export function isAnyPrematchSportlineEvent(
  sportEvent?: Maybe<SportlineEvent>,
): sportEvent is PrematchSportlineEvent {
  return sportEvent?.type === SportlineType.Prematch;
}

export function isPrematchSportlineEvent(
  sportEvent?: Maybe<SportlineEvent>,
): sportEvent is PrematchSportlineEvent {
  return sportEvent?.type === SportlineType.Prematch && !sportEvent?.isOutright;
}

export function isOutrightSportlineEvent(
  sportEvent?: Maybe<SportlineEvent>,
): sportEvent is OutrightSportlineEvent {
  return !!sportEvent?.isOutright;
}
