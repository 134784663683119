import type { Ref } from 'vue';
import { onBeforeUnmount, toRef, watch } from 'vue';

import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import type { Market } from 'web/src/modules/sportline/types';

import type { SportEventInfoProps } from '../types';
import { useSportBannersStore } from '../../../store';

interface UseSportEventInfo {
  sportlineEvent: Ref<SportEventDetails | null>;
  market: Ref<Market | null>;
}

export function useSportEventInfo(props: SportEventInfoProps): UseSportEventInfo {
  const sportBannersStore = useSportBannersStore();
  const {
    activateSlide,
    deactivateSlide,
  } = sportBannersStore;

  const sportlineEvent = toRef(sportBannersStore, 'currentEventDetails');
  const market = toRef(sportBannersStore, 'currentMarket');

  watch(() => props.isActive, (value) => {
    if (value) {
      activateSlide(props.bannerId, props.groupId);
    } else {
      deactivateSlide(props.bannerId);
    }
  }, { immediate: true });

  onBeforeUnmount(() => {
    if (props.isActive) {
      deactivateSlide(props.bannerId);
    }
  });

  return {
    sportlineEvent,
    market,
  };
}
