import type { Ref } from 'vue';
import {
  computed,
} from 'vue';

import type { Maybe } from '@leon-hub/types';

import type { CountryCode } from '../enums';
import { countryToFlagMapping, flagPosition } from '../constants';
import { CountryFlag } from '../enums';

export interface UseVCountryFlagProps {
  countryCode: Ref<Maybe<CountryCode>>;
}

export interface UseVCountryFlagComposable {
  position: Ref<string>;
  imageUrl: Ref<string>;
}

export function useVCountryFlag(props: UseVCountryFlagProps): UseVCountryFlagComposable {
  const { countryCode } = props;

  const flagName = computed<CountryFlag>(() => {
    if (!countryCode.value) {
      return CountryFlag.DEFAULT;
    }
    return countryToFlagMapping[countryCode.value] ?? CountryFlag.DEFAULT;
  });

  /** @TODO check logic "0px 0px" points to a flag, maybe it must be null for this case */
  const position = computed<string>(() => {
    const c = flagPosition[flagName.value];
    return c ? `${c[0] / 2}px ${c[1] / 2}px` : '0px 0px';
  });

  const imageUrl = computed<string>(() => {
    try {
      return require(`../../assets/flags/${flagName.value}.svg`);
    } catch {
      return require('../../assets/flags/default.svg');
    }
  });

  return {
    position,
    imageUrl,
  };
}
