export * from './core';

export enum DateTimeFilterTabSet {
  Full = 'full',
  Default = 'default',
}

export enum FocusedInput {
  NONE = 'none',
  FROM = 'from',
  TO = 'to',
}

export enum DateTimeFilterTabId {
  ALL = 'ALL',
  LIVE = 'LIVE',
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  AFTERTOMORROW = 'AFTERTOMORROW',
  ONEHOUR = 'ONEHOUR',
  TWOHOURS = 'TWOHOURS',
  THREEHOURS = 'THREEHOURS',
  SIXHOURS = 'SIXHOURS',
  TWELVEHOURS = 'TWELVEHOURS',
  TWENTYFOURHOURS = 'TWENTYFOURHOURS',
  DATE_RANGE = 'DATE_RANGE',
}
