/* eslint-disable ts/no-redeclare */

export const enum BetlineType {
  All = 'all',
  ExtraLive = 'inplayall',
  Live = 'inplay',
  Outright = 'outright',
  Prematch = 'prematch',
  Planed = 'planed',
  Virtual = 'virtual',
  UpcomingLive = 'inplayupcoming',
  UpcomingExtraLive = 'inplayupcomingall',
}

export const enum BetlineFlag {
  WithSportMarketGroups = 'smg',
  WithSportMarketGroupsV2 = 'smgv2', // market groups with specifier
  UseSportMarketTypesForMainMarkets = 'mm2',
  RegionFullInfo = 'reg',
  UseRunnersCount = 'rrc',
  NoDuplicate = 'nodup',
  UrlNamesV2 = 'urlv2',
  AllowedWidgetsVersion2 = 'wd2',
  ThemeDark = 'dark',
  ThemeLight = 'light',
  OutrightExtendedMarketLayout = 'outv2',
  AmericanOddsType = 'AMERICAN_ODDS_TYPE',
}

export const enum BetlineOrder {
  Structured = 'STRUCTURED',
  Unsorted = 'UNSORTED',
  SportLeagueKickoff = 'sport-league-kickoff',
  SportKickoff = 'sport-kickoff',
  SportThenKickoff = 'SPORT_THEN_KICKOFF',
}

export const enum CompetitorType {
  Host = 'HOME',
  Guest = 'AWAY',
}

export enum LiveStatusTimeDirection {
  Direct = 'DIRECT',
  Reverse = 'REVERSE',
}

export enum BetlineSportEventStatus {
  OPEN = 'OPEN',
  SUSPENDED = 'SUSPENDED',
  CLOSED = 'CLOSED',
  PASSIVATED = 'PASSIVATED',
  DISABLED = 'DISABLED',
}

export const BetlineSelectionTag = {
  YES: 'YES',
  NO: 'NO',
  UNDER: 'UNDER',
  OVER: 'OVER',
  ODD: 'ODD',
  EVEN: 'EVEN',
  HOME: 'HOME',
  DRAW: 'DRAW',
  AWAY: 'AWAY',
  NEITHER: 'NEITHER',
  BOTH: 'BOTH',
  COMPETITOR: 'COMPETITOR',
  RANGE: 'RANGE',
  SCORE: 'SCORE',
  OTHER: 'OTHER',
  HOMEDRAW: 'HOMEDRAW',
  HOMEAWAY: 'HOMEAWAY',
  DRAWAWAY: 'DRAWAWAY',
} as const;
export type BetlineSelectionTag = typeof BetlineSelectionTag[keyof typeof BetlineSelectionTag];

export enum BetlineStatisticWinnerType {
  Host = 'HOME',
  Guest = 'AWAY',
  Draw = 'DRAW',
}

export enum BetlineStatisticCardType {
  Yellow = 'Yellow',
  Red = 'Red',
  YellowRed = 'Yellow/red',
}

export enum BetlineStatisticScoresType {
  HalfTime = 'HT',
  FullTime = 'FT',
  OverTime = 'OT',
  StopTime = 'ST',
  AfterPenalty = 'AP',
  Sets = 'Sets',
  Winner = 'Winner',
  // also can include
  // Part_1 = '1',
  // Part_2 = '2',
  // Part_3 = '3',
  // ...
  // up to Part_9 in Baseball
}

export enum BetlineStatisticMatchStatus {
  MOVED = '60',
  COMPLETED = '100',
  COMPLETED_ON_PENALTY = '120',
}

export enum BetlineMarketTypeTag {
  TOTAL = 'TOTAL',
  HANDICAP = 'HANDICAP',
  REGULAR = 'REGULAR',
}
