import type { getBetlineMatchEventStatisticsQueryVariables } from '@leon-hub/api-sdk';
import { RequestGroupScore24 } from '@leon-hub/api';
import { getBetlineMatchEventStatistics } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type { BetlineSportEventMatchStatistics } from 'web/src/modules/sportline/types/rest';

export default function getBMEventStatistics(
  options: getBetlineMatchEventStatisticsQueryVariables['options'],
  { silent }: { silent?: boolean },
): Promise<BetlineSportEventMatchStatistics> {
  const apiClient = useGraphqlClient();
  return getBetlineMatchEventStatistics(apiClient, (node) => node.queries.betLine.getMatchStatisticsEvent, { options }, {
    silent,
    group: RequestGroupScore24,
  });
}
