import type { MaybeRef, Ref } from 'vue';
import {
  provide,
  reactive,
  readonly,
  toRef,
  toValue,
} from 'vue';

import type {
  MarketColumnId,
  MarketType,
} from 'web/src/modules/sportline/types';

import type {
  ProvidedSelectedColumnMap,
  SelectColumnPayload,
  SelectedColumnInfoElement,
  SelectedColumnInfoMap,
} from '../types';
import {
  ProvideSelectColumnMethodKey,
  ProvideSelectedColumnKey,
} from '../constants';
import {
  createColumnsMapRef,
  createMarketTypesMapRef,
  createSelectedColumnMapRef,
  createSelectedMarketTypesMapRef,
} from '../utils';

interface UseProvideSelectedColumnProps {
  sportlineElements: MaybeRef<SelectedColumnInfoElement[]>;
}

interface UseProvideSelectedColumnComposable {
  selectedMarketTypes: Ref<SelectedColumnInfoMap<Maybe<MarketType>>>;
  selectedColumnMap: Ref<ProvidedSelectedColumnMap>;
  selectColumn(payload: SelectColumnPayload): void;
}

export function useProvideSelectedColumn(
  props: UseProvideSelectedColumnProps,
): UseProvideSelectedColumnComposable {
  const sportlineElements: Ref<SelectedColumnInfoElement[]> = toRef(props.sportlineElements);

  const selectedColumnIdMap = reactive<SelectedColumnInfoMap<Maybe<MarketColumnId>>>({});

  const columnsMap = createColumnsMapRef(sportlineElements);

  const marketTypesMap = createMarketTypesMapRef(sportlineElements);

  const selectedMarketTypes = createSelectedMarketTypesMapRef(
    sportlineElements,
    columnsMap,
    marketTypesMap,
    selectedColumnIdMap,
  );

  const selectedColumnMap = createSelectedColumnMapRef(
    selectedMarketTypes,
    columnsMap,
    selectedColumnIdMap,
  );

  function selectColumn({ basisKey, columnId }: SelectColumnPayload): void {
    selectedColumnIdMap[toValue(basisKey)] = toValue(columnId);
  }

  provide(ProvideSelectedColumnKey, readonly(selectedColumnMap));
  provide(ProvideSelectColumnMethodKey, selectColumn);

  return {
    selectedMarketTypes,
    selectedColumnMap,
    selectColumn,
  };
}
