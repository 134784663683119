import type { Ref } from 'vue';
import { ref, watch } from 'vue';

import type { Maybe } from '@leon-hub/types';
import { getLocationHref } from '@leon-hub/service-locator-env';

import type {
  Web2ImageSource,
} from '../types';
import { getImageFromImageSource } from '../utils/getImageFromImageSource';
import { isWebpSupported } from '../utils/isWebpSupported';
import { normalizeImagePath } from '../utils/normalizeImagePath';

interface UseRecalculateImageSourceProps {
  imageSource: Ref<Maybe<Web2ImageSource>>;
}

interface UseRecalculateImageSourceComposable {
  normalizedSrc: Ref<Maybe<string>>;
  normalizeSrc(): Promise<void>;
}

export function useRecalculateImageSource(
  props: UseRecalculateImageSourceProps,
): UseRecalculateImageSourceComposable {
  const { imageSource } = props;

  let isSupportWebp = process.env.VUE_APP_RENDERING_SSR ? false : undefined;

  const normalizedSrc = ref<Maybe<string>>(null);

  async function normalizeSrc(): Promise<void> {
    if (isSupportWebp === undefined) {
      isSupportWebp = await isWebpSupported;
    }

    normalizedSrc.value = imageSource.value
      ? normalizeImagePath(
          getLocationHref(),
          getImageFromImageSource(imageSource.value, isSupportWebp),
        )
      : null;
  }

  watch(imageSource, normalizeSrc);

  return {
    normalizedSrc,
    normalizeSrc,
  };
}
