import { assert } from '@leon-hub/guards';

import type { Web2ImageSource } from '@components/image';

const conditionalSourceKeys = ['src', 'x1', 'x2', 'x3', 'x1webp', 'x2webp', 'x3webp'];

function isWeb2ImageSource(key: string): key is keyof Web2ImageSource {
  return conditionalSourceKeys.includes(key);
}

export function getImageKey(ratio: number, isWebpKey = false): keyof Web2ImageSource {
  const key = `x${ratio}${isWebpKey ? 'webp' : ''}`;
  assert(isWeb2ImageSource(key), `Wrong image key: ${key}`);
  return key;
}
