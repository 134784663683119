import type { BetCashOutRequest, CashOut } from '@leon-hub/api-sdk';
import { doCashOut } from '@leon-hub/api-sdk';

import type { ApplicationGqlClient } from '@core/app-rest-client';

export default function doCashOutRequest(payload: {
  graphqlClient: ApplicationGqlClient;
  options: Omit<BetCashOutRequest, 'ts'>;
}): Promise<Pick<CashOut, 'status' | 'amount' | 'msg' | 'newAmount' | 'reason' | 'remainingDelay'>> {
  const { graphqlClient, options } = payload;
  return doCashOut(graphqlClient, (node) => node.mutations.bet.doCashOut, { options });
}
