import type { BatchedSlipInfoDocument, BetInfoObject } from '../types';
import getSlipEntryId from '../../../utils/getSlipEntryId';
import getAllBatchedEntries from './getAllBatchedEntries';

export function replaceOriginalOddsValues(betsInfo: BetInfoObject, updatedSlipInfo: BatchedSlipInfoDocument): BetInfoObject {
  const allEntries = getAllBatchedEntries(updatedSlipInfo.slipEntries);
  const result = { ...betsInfo };
  for (const entry of allEntries) {
    const id = getSlipEntryId(entry);
    result[id].originalOdds = entry.odds;
    if (entry.oddsStr && result[id].originalOddsStr) {
      result[id].originalOddsStr = entry.oddsStr;
    }
  }
  return result;
}
