// types
import type { Sport, SportSegment } from 'web/src/modules/sportline/types';
import type {
  BuildSportOptions,
  ParseSportResponseOptions,
} from 'web/src/modules/sportline/types/rest';
// enums
import { SportProgressBarType } from 'web/src/modules/sportline/enums';
// constants
import {
  SportBackgroundColorDark,
  SportBackgroundColorLight,
  sportSegments,
} from 'web/src/modules/sportline/constants';
// utils
import {
  getSportFamilyEmoji,
  isSportFamilyEquals,
} from 'web/src/modules/sportline/utils';
import {
  buildSportNavigationParameters,
} from 'web/src/modules/sportline/utils/rest/navigation-parameters';

import { findSportRepresentationSetting } from '../pre-build';

export default function createSport(options: ParseSportResponseOptions & Required<BuildSportOptions>): Sport {
  const {
    sport,
    sportFamilyMapping,
    sportRepresentationSettings,
  } = options;

  const id = String(sport.id);
  const { name, background } = sport;
  const family = sport.family.toLowerCase();
  const navigationParameters = buildSportNavigationParameters({
    sportResponse: sport,
    sportFamilyMapping,
  });
  const weight = sport.weight || 0;

  const segmentKey = Object.keys(options.sportSegmentMapping ?? {})
    .find((key: string | number) => isSportFamilyEquals(String(key), family));
  const segment: SportSegment = segmentKey
    ? (options.sportSegmentMapping?.[segmentKey] ?? sportSegments.default)
    : sportSegments.default;

  const sportRepresentationSetting = findSportRepresentationSetting(sportRepresentationSettings, sport);

  return {
    id,
    name,
    family: navigationParameters.urlName,
    segment,
    representation: {
      icon: family,
      family,
      emoji: getSportFamilyEmoji(family),
      useFullProgress: !!sportRepresentationSetting?.useFullProgress,
      progressBarType: sportRepresentationSetting?.progressBarType ?? SportProgressBarType.None,
      weight,
      background: {
        dark: SportBackgroundColorDark[family],
        light: SportBackgroundColorLight[family],
      },
      backgroundImage: background,
    },
    navigationParameters,
  };
}
