import type { PendingBetsRequest } from '@leon-hub/api-sdk';
import { getPendingBets } from '@leon-hub/api-sdk';

import type { ApplicationGqlClient } from '@core/app-rest-client';

import type { PendingBetsDocument } from '../types/PendingBetsDocument';

export default function getPendingBetsFromApi(
  apiClient: ApplicationGqlClient,
  options: Omit<PendingBetsRequest, 'ts'>,
  silent?: boolean,
): Promise<PendingBetsDocument> {
  return getPendingBets(apiClient, (node) => node.queries.customer.getPendingBets, {
    options,
  }, { silent });
}
