import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import type { ISlipTypeId, SlipInfoFieldsFragment, SlipType } from '@leon-hub/api-sdk';
import { GqlApiServiceSuspendedError } from '@leon-hub/api';
import {
  PriceChangePolicy,
  priceChangePolicyAllowChanges,
  SlipStatus,
  SlipTypeId,
} from '@leon-hub/api-sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { assert } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { sleep, Timer } from '@leon-hub/utils';

import { useGraphqlClient } from '@core/app-rest-client';
import { useIsLoggedIn } from '@core/auth';
import { useCustomerDataStore, usePriceChangePolicy } from '@core/customer';
import { useErrorsConverter } from '@core/errors';
import { useI18n } from '@core/i18n';
import { useBalance, useFormatMoney } from '@core/money';
import { useSiteConfigStore } from '@core/site-config';

import type { VSelectOption } from 'web/src/components/Select';
import { useIsDevIP } from 'web/src/modules/core/composables/root';
import { logBetsLimitError } from 'web/src/modules/slip/submodules/slip-info/utils/logBetsLimitError';
import { useOddsSettings } from 'web/src/modules/sportline/composables/settings';
import isMatchedArrays from 'web/src/utils/array/isMatchedArrays';
import formatOdd from 'web/src/utils/formatOdd';
import isSamePlainObject from 'web/src/utils/object/isSamePlainObject';

import type { ExtendedSlipListBettingItem } from '../../../components/SlipListItem/types';
import type {
  ClaimToUpdateSportEventRunnerOddChangedEventData,
  ClaimToUpdateSportEventStatusChangedEventData,
} from '../../../types/сlaimToUpdate';
import type {
  ApplyBatchedSlipInfoUpdatePayload,
  BatchedSlipInfoDocument,
  BetInfoObject,
  BetsLimitErrorPayload,
  ClosedMarkets,
  CombinedSlipEntryInfo,
  EventWithChangedMarket,
  GetBatchedSlipDataPayload,
  OnAddingErrorPayload,
  OnClosedMarketsChangePayload,
  RemoveBetEventsPayload,
  SetupSlipFromSharedDataPayload,
  ShowSlipModalWarningPayload,
  SlipEntry,
  SlipEntryId,
  SlipInfoArray,
  SlipListItem,
  SlipSnapshotForLocalStorage,
  SlipTraceLines,
  SyncAddedSlipItemsPayload,
  UpdateSlipInfoEntriesPayload,
} from '../types';
import { SlipTypeSingle } from '../../../constants';
import {
  AddSlipEntriesError,
  BetSlipWarningTypes,
  LeaveSlipResultChoice,
} from '../../../enums';
import isSlipEntryArray from '../../../guards/isSlipEntryArray';
import isSlipTypeArray from '../../../guards/isSlipTypeArray';
import {
  convertSlipEntryIdToObject,
  findSlipEntryById,
  getSlipEntryId,
  isLiveEventSlipEntry,
  isUnavailableEntryStatus,
} from '../../../utils';
import { useDefaultBetValue } from '../../default-bet-value/composables';
import { useFreebetStore } from '../../freebet/store';
import { useMultiSinglesStore } from '../../multi-singles/store';
import {
  clearMultiSingleMarketStatusById,
  getMultiSinglesMetaInfo,
  getMultiSinglesMetaInfoForCache,
} from '../../multi-singles/utils';
import { useSlipViewSettingsStore } from '../../slip-view-settings/store';
import { useSlipSelectedEntriesStore } from '../../slipSelectedEntries/store';
import { useStakeInputStore } from '../../stake-input/store';
import { useSlipLocalStorage } from '../composables';
import {
  checkSlipSyncErrors,
  checkTotalHandicapReplacement,
  clearOldRunnerInfo,
  clearStatusOnBatchedSlipInfo,
  convertSlipListItemToBetsInfoObject,
  entryIsTotalHandicap,
  getAllBatchedEntries,
  getClosedAndUnlockedEventsData,
  getCombiClearedFromBetById,
  getCombinedSlipEntryInfo,
  getCombiSlipInfo,
  getEventsWithChangedMarkets,
  getFreeBetsFromBatchedSlipInfo,
  getItemsForUpdatedPrice,
  getMatchedBetMode,
  getMatchedSlipType,
  getMaxAvailableFastBetValue,
  getMaxPrize,
  getMetaInfoWithBalanceCheck,
  getMultiSinglesCommonMaxStake,
  getMultiSinglesCommonMinStake,
  getMultiSinglesTotalPrice,
  getMultiSinglesTotalTax,
  getMultiSinglesTotalWin,
  getNotSyncedEntries,
  getOnlyAvailableSlipEntries,
  getSafeSlipType,
  getSinglesAvailableForBetting,
  getSinglesClearedFromBetById,
  getSlipDataForUnsettledBets,
  getSlipEntriesWithChangedStatus,
  getSlipEntriesWithToggledBanker,
  getSlipEntryFromSlipListItem,
  getSlipInfoRequestEntries,
  getSlipInfoWithoutTrace,
  getUniqueSlipEntriesArray,
  handleClosedMarketsChange,
  isEntriesHasLimitStatus,
  isPriceChangedMatters,
  isSlipEntriesMatchSelected,
  logAcceptChanges,
  logAddingToSlip,
  logRemoveFromSlip,
  logSaveSlipItemsAfterBet,
  mapCombinedSlipEntryToExtendedSlipListBettingItemProps,
  mapSlipEntryToEventWithChangedStatus,
  replaceOriginalOddsValues,
  requestBatchedSlipInfo,
  shouldForceSingleMode,
  sortSlipEntries,
  syncBetsInfoAfterSlipRestore,
  updateBetsInfoByCurrentEntries,
  updateBetsInfoOddById,
  updateSlipInfoBySlipEntries,
  updateSlipInfoFromSportlineData,
} from '../utils';

const useSlipInfoStore = defineStore('slip-info-store', () => {
  const apiClient = useGraphqlClient();

  const formatMoney = useFormatMoney();

  const { $translate } = useI18n();

  const bus = useEventsBus();

  const { balance } = useBalance();

  const { isDevIP } = useIsDevIP();

  const { isLoggedIn } = useIsLoggedIn();

  const siteConfigStore = useSiteConfigStore();

  const slipViewSettingsStore = useSlipViewSettingsStore();

  const { convertToBaseError } = useErrorsConverter();

  const {
    clearLocalStorageState,
    saveSnapshotToLocalStorage,
    getSnapshotFromLocalStorage,
  } = useSlipLocalStorage();

  const sportLineBlock = toRef(siteConfigStore, 'sportLineBlock');
  const slipBlock = toRef(siteConfigStore, 'slipBlock');

  const slipSelectedEntriesStore = useSlipSelectedEntriesStore();

  const { setSelectedEntriesIds, removeFromSelectedEntriesIds, addToSelectedEntriesIds } = slipSelectedEntriesStore;

  const { priceChangePolicy, totalHandicapPriceChangePolicy, savePriceChangePolicy } = usePriceChangePolicy();

  const { currentOddsType } = useOddsSettings();

  const multiSinglesStore = useMultiSinglesStore();

  const {
    setLastModifiedMultiSingleId,
    setMultiSinglesMetaInfo,
    clearFocusOnUnavailableEvents,
  } = multiSinglesStore;

  const isMultiSinglesEnabled = toRef(multiSinglesStore, 'isMultiSinglesEnabled');

  const sameStakeForSingles = toRef(multiSinglesStore, 'sameStakeForSingles');

  const lastModifiedMultiSingleId = toRef(multiSinglesStore, 'lastModifiedId');

  const multiSinglesMetaInfo = toRef(multiSinglesStore, 'multiSinglesMetaInfo');

  const stakeInputStore = useStakeInputStore();

  const { safeSetStakeValue } = stakeInputStore;

  const safeStakeValue = toRef(stakeInputStore, 'safeStakeValue');

  const stakeInputValue = toRef(stakeInputStore, 'stakeInputValue');

  const { defaultBetValue } = useDefaultBetValue();

  const customerDataStore = useCustomerDataStore();

  const showSlipOnFirstAdded = toRef(customerDataStore, 'showSlipOnFirstAdded');

  const selectedEntriesIds = toRef(slipSelectedEntriesStore, 'selectedEntriesIds');

  const freebetStore = useFreebetStore();

  const { updateFreeBets, checkIfNeedToCancelFreebet, clearFreeBet } = freebetStore;

  const currentFreeBet = toRef(freebetStore, 'currentFreeBet');

  const isLeaveEnterAnimationPlaying = toRef(slipViewSettingsStore, 'isLeaveEnterAnimationPlaying');

  const {
    setSlipVisibility,
    handleSlipVisibilityChange,
    setDefaultSlipView,
  } = slipViewSettingsStore;

  let syncIntervalId: number | null = null;

  /** state / mutations */

  const batchedSlipInfo = ref<BatchedSlipInfoDocument | null>(null);

  const setBatchedSlipInfo = (slipInfo: BatchedSlipInfoDocument | null): void => {
    batchedSlipInfo.value = slipInfo;
  };

  const setSlipEntries = (slipEntries: SlipInfoArray): void => {
    if (!batchedSlipInfo.value) {
      return;
    }
    const { combiAvailable } = batchedSlipInfo.value;
    setBatchedSlipInfo({ slipEntries, combiAvailable });
  };

  const setSlipType = (slipType: string | null): void => {
    if (!batchedSlipInfo.value) {
      return;
    }
    const slipEntries = batchedSlipInfo.value.slipEntries.map((entry) => ({
      ...entry,
      slipType,
    }));

    setSlipEntries(slipEntries);
  };

  const betsInfo = ref<BetInfoObject>({});

  const setBetsInfo = (snapshot: BetInfoObject): void => {
    betsInfo.value = snapshot;
  };

  const slipEntryCache = ref<SlipEntry[]>([]);

  const setSlipEntryCache = (items: SlipEntry[]): void => {
    slipEntryCache.value = items;
  };

  /** time of the last update - events was added/removed by user */
  const lastChangeTime = ref<number>(0);

  const setLastChangeTime = (timestamp: number): void => {
    lastChangeTime.value = timestamp;
  };

  /** to disable main CTA button until data between slip info and selectedEntriesIds have difference */
  const incorrectSyncState = ref<boolean>(false);

  const setIncorrectSyncStatus = (isIncorrect: boolean): void => {
    incorrectSyncState.value = isIncorrect;
  };

  /** state when waiting data after accept changes action */
  const isPendingChanges = ref<boolean>(false);

  const setPendingChanges = (isPending: boolean): void => {
    isPendingChanges.value = isPending;
  };

  const clearBetListScheduled = ref<boolean>(false);

  const setClearBetListScheduled = (isScheduled: boolean): void => {
    clearBetListScheduled.value = isScheduled;
  };

  const isSlipUpdatesLocked = ref<boolean>(false);

  const setUpdateLockStatus = (isLocked: boolean): void => {
    isSlipUpdatesLocked.value = isLocked;
  };

  const betMode = ref<SlipTypeId>(SlipTypeId.SINGLE);

  const setBetMode = (mode: SlipTypeId): void => {
    betMode.value = mode;
  };

  const canSwitchToCombi = computed<boolean>(() => {
    if (betMode.value === SlipTypeId.SINGLE) {
      return batchedSlipInfo.value?.combiAvailable ?? true;
    }
    return true;
  });

  const betModeWasManuallyChanged = ref<boolean>(false);

  const setBetModeWasManuallyChanged = (wasChanged: boolean): void => {
    betModeWasManuallyChanged.value = wasChanged;
  };

  const selectedSystemValue = ref<string | null>(null);

  const setSystemValue = (value: string | null): void => {
    selectedSystemValue.value = value;
  };

  const entriesWithChangedStatus = ref<SlipEntry[]>([]);

  const setEntriesWithChangedStatus = (entries: SlipEntry[]): void => {
    entriesWithChangedStatus.value = entries;
  };

  /** Dictionary to clear slipEntries with timeout */
  const closedMarkets = ref<ClosedMarkets>({});

  const setClosedMarkets = (value: ClosedMarkets): void => {
    closedMarkets.value = value;
  };

  /** identifier to avoid duplicate bets */
  const lastBetTimeStamp = ref<number | null>(null);

  const setLastBetTimeStamp = (stamp: number | null): void => {
    lastBetTimeStamp.value = stamp;
  };

  /** prevents override new added events by copy from localstorage and shows loader */
  const restoringSlipFromLS = ref<boolean>(false);

  const setRestoreFromLS = (isRestoring: boolean): void => {
    restoringSlipFromLS.value = isRestoring;
  };

  const removeFromClosedMarkets = (id: SlipEntryId): void => {
    const timeoutId = closedMarkets.value[id];
    if (timeoutId) {
      Timer.clearTimeout(timeoutId);
      const copy = { ...closedMarkets.value };
      delete copy[id];
      setClosedMarkets(copy);
    }
  };

  const customWarningMessage = ref<string>('');

  const setCustomWarningMessage = (message = ''): void => {
    customWarningMessage.value = message;
  };

  /** former getters */

  const slipEventsUpdateInterval = computed<number>(() => sportLineBlock.value?.slipEventsUpdateInterval || 3000);

  const slipDeleteClosedEventTimeout = computed<number>(
    () => sportLineBlock.value?.deleteClosedEventTimeout || 60 * 20 * 1000,
  );

  const isZeroMarginEnabled = computed<boolean>(() => sportLineBlock.value?.zeroMarginEnabled ?? false);

  const betsInfoCount = computed<number>(() => Object.keys(betsInfo.value).length);

  const combiOrFirstSlip = computed<SlipInfoFieldsFragment | null>(() => {
    if (!batchedSlipInfo.value) {
      return null;
    }
    return getCombiSlipInfo(batchedSlipInfo.value);
  });

  const slipType = computed<string | null>(() => combiOrFirstSlip.value?.slipType || null);

  const matchedBetMode = computed<ISlipTypeId>(() => getMatchedBetMode(slipType.value));

  const slipTypes = computed<SlipType[]>(() => {
    const slipTypesArray = combiOrFirstSlip.value?.slipTypes;
    if (!slipTypesArray) {
      return [];
    }
    assert(isSlipTypeArray(slipTypesArray), 'Corrupted slipInfo.slipTypes');
    return slipTypesArray;
  });

  const isMultiSinglesMode = computed<boolean>(
    () => betMode.value === SlipTypeId.SINGLE && selectedEntriesIds.value.length > 1,
  );

  const slipEntries = computed<SlipEntry[]>(() => {
    if (!batchedSlipInfo.value) {
      return [];
    }
    return getAllBatchedEntries(batchedSlipInfo.value.slipEntries);
  });

  const allEntries = computed<SlipEntry[]>(
    () => getUniqueSlipEntriesArray([...slipEntries.value, ...slipEntryCache.value]),
  );

  const bankersAvailable = computed<boolean>(() => betMode.value === SlipTypeId.SYSTEM && slipEntries.value.length > 3);

  const bankerCount = computed<number>(() => slipEntries.value
    .filter((entry) => entry.banker)
    .length);

  const maxBankers = computed<number | null>(() => combiOrFirstSlip.value?.maxBankers || null);

  const systemOptions = computed<VSelectOption[]>(() => {
    const systemOnlyTypes = slipTypes.value
      .filter((item) => item && item.typeId === SlipTypeId.SYSTEM);
    return systemOnlyTypes.map(({ code, shortName, name }) => ({
      value: code,
      label: shortName || name,
    }));
  });

  const maxSlipSize = computed<number | null>(() => combiOrFirstSlip.value?.maxSlipSize || null);

  const maxSystemSize = computed<number | null>(() => combiOrFirstSlip.value?.maxSystemSize || null);

  /** show LIVE badge only if its mixed live and prematch events */
  const shouldShowLiveStatus = computed<boolean>(() => {
    let liveCount = 0;
    let prematchCount = 0;
    return slipEntries.value.some((entry) => {
      if (isLiveEventSlipEntry(entry)) {
        liveCount += 1;
      } else {
        prematchCount += 1;
      }

      return liveCount > 0 && prematchCount > 0;
    });
  });

  const totalOdds = computed<number | null>(() => combiOrFirstSlip.value?.totalOdds || null);

  const formattedTotalOdds = computed<string>(() => {
    if (!totalOdds.value || incorrectSyncState.value) {
      return '-';
    }
    return combiOrFirstSlip.value?.totalOddsStr ?? formatOdd(totalOdds.value);
  });

  const hasLimitError = computed<boolean>(() => isEntriesHasLimitStatus(slipEntries.value));

  const marketsChanged = computed<boolean>(() => {
    if (isMultiSinglesMode.value) {
      return false;
    }
    return combiOrFirstSlip.value?.status === SlipStatus.MARKETS_CHANGED;
  });

  const pricesChanged = computed<boolean>(() => {
    if (isMultiSinglesMode.value || !isLoggedIn.value || priceChangePolicy.value === PriceChangePolicy.ALLOW_CHANGES) {
      return false;
    }
    return !!combiOrFirstSlip.value?.entries
      .some((entry) => {
        const id = getSlipEntryId(entry);
        const additionalInfo = betsInfo.value[id];
        return isPriceChangedMatters({
          priceChangePolicy: priceChangePolicy.value,
          originalOdds: additionalInfo?.originalOdds,
          currentOdds: entry.odds,
        });
      });
  });

  const entriesHasChanged = computed<boolean>(() => !isMultiSinglesMode.value && Object.values(betsInfo.value)
    .some((betsInfoItem) => !!betsInfoItem.oldRunnerName));

  const mustAcceptChanges = computed<boolean>(() => marketsChanged.value || pricesChanged.value || entriesHasChanged.value);

  const warningMessage = computed<string>(() => {
    if (isMultiSinglesMode.value) {
      return '';
    }
    if (customWarningMessage.value) {
      return customWarningMessage.value;
    }
    return mustAcceptChanges.value ? combiOrFirstSlip.value?.message ?? '' : '';
  });

  const isSyncInProgress = computed<boolean>(() => !!(incorrectSyncState.value || slipEntryCache.value.length));

  const slipTraceLines = computed<SlipTraceLines[] | null>(() => {
    if (!isDevIP.value || !batchedSlipInfo.value) {
      return null;
    }
    return batchedSlipInfo.value.slipEntries.map((slipEntry) => slipEntry?.trace?.lines ?? []);
  });

  const totalHandicapReplaceEnabled = computed<boolean>(() => slipBlock.value?.totalHandicapReplaceEnabled ?? false);

  const autoAcceptTotalHandicapChanges = computed<boolean>(() => totalHandicapReplaceEnabled.value
    && totalHandicapPriceChangePolicy.value === priceChangePolicyAllowChanges);

  const combinedEntriesInfo = computed<CombinedSlipEntryInfo[]>(() => getCombinedSlipEntryInfo({
    input: slipEntries.value,
    priceChangePolicy: priceChangePolicy.value,
    betsInfo: betsInfo.value,
    multiSinglesMetaInfo: multiSinglesMetaInfo.value,
  }));

  const combinedCachedEntriesInfo = computed<CombinedSlipEntryInfo[]>(() => getCombinedSlipEntryInfo({
    input: slipEntryCache.value,
    priceChangePolicy: priceChangePolicy.value,
    betsInfo: betsInfo.value,
    multiSinglesMetaInfo: multiSinglesMetaInfo.value,
  }));

  const multiSinglesAvailableForBet = computed<CombinedSlipEntryInfo[]>(() => {
    if (!combinedEntriesInfo.value.length) {
      return [];
    }
    return getSinglesAvailableForBetting({
      input: combinedEntriesInfo.value,
      priceChangePolicy: priceChangePolicy.value,
      sameStake: sameStakeForSingles.value,
      autoAcceptTotalHandicapChanges: autoAcceptTotalHandicapChanges.value,
    });
  });

  const multiSinglesTotalPrice = computed<number>(() => {
    if (!isMultiSinglesMode.value) {
      return 0;
    }
    return getMultiSinglesTotalPrice({
      availableItems: multiSinglesAvailableForBet.value,
      sameStakeForSingles: sameStakeForSingles.value,
      commonStakeValue: safeStakeValue.value,
    });
  });

  const multiSinglesCommonMinStake = computed<number>(() => {
    if (!isMultiSinglesMode.value || !sameStakeForSingles.value) {
      return 0;
    }
    return getMultiSinglesCommonMinStake(multiSinglesAvailableForBet.value);
  });

  const multiSinglesCommonMaxStake = computed<number>(() => {
    if (!isMultiSinglesMode.value || !sameStakeForSingles.value) {
      return 0;
    }
    return getMultiSinglesCommonMaxStake(multiSinglesAvailableForBet.value);
  });

  const maxStake = computed<number | null>(() => {
    if (isMultiSinglesMode.value) {
      return multiSinglesCommonMaxStake.value || null;
    }
    return combiOrFirstSlip.value?.maxStake || null;
  });

  const minStake = computed<number | null>(() => {
    if (isMultiSinglesMode.value) {
      return multiSinglesCommonMinStake.value || null;
    }
    return combiOrFirstSlip.value?.minStake || null;
  });

  const bankersLimitReached = computed<boolean>(() => {
    if (mustAcceptChanges.value) {
      return true;
    }
    return !!(maxBankers.value && bankerCount.value >= maxBankers.value);
  });

  const slipEvents = computed<ExtendedSlipListBettingItem[]>(() => {
    const stakeOverBalanceText = $translate('WEB2_STAKE_ABOVE_BALANCE').value;
    const restOfBalance = balance.value - multiSinglesTotalPrice.value;
    return combinedEntriesInfo.value.map((combinedEntry) => {
      const isLive = shouldShowLiveStatus.value && isLiveEventSlipEntry(combinedEntry);
      let errorMessage = '';
      if (isMultiSinglesMode.value && !sameStakeForSingles.value) {
        errorMessage = combinedEntry.metaInfo?.stakeOverBalance
          ? stakeOverBalanceText
          : (combinedEntry.metaInfo?.error || '');
      }
      const needToConfirmReplacement = !autoAcceptTotalHandicapChanges.value && !!combinedEntry.oldRunnerName;
      const showAcceptButton = isMultiSinglesMode.value && (!combinedEntry.matchedPriceChangePolicy || needToConfirmReplacement);
      return mapCombinedSlipEntryToExtendedSlipListBettingItemProps(combinedEntry, {
        disableBankers: bankersLimitReached.value,
        isLive,
        isZeroMarginEnabled: isZeroMarginEnabled.value,
        errorMessage,
        restOfBalance,
        showAcceptButton,
        autoAcceptTotalHandicapChanges: autoAcceptTotalHandicapChanges.value,
      });
    });
  });

  const cacheEvents = computed<ExtendedSlipListBettingItem[]>(() => combinedCachedEntriesInfo.value
    .map((combinedEntry) => {
      const isLive = shouldShowLiveStatus.value && isLiveEventSlipEntry(combinedEntry);
      return mapCombinedSlipEntryToExtendedSlipListBettingItemProps(combinedEntry, {
        disableBankers: true,
        isLive,
        isZeroMarginEnabled: isZeroMarginEnabled.value,
      });
    }));

  const betEvents = computed<ExtendedSlipListBettingItem[]>(() => [...slipEvents.value, ...cacheEvents.value]);

  const isHandicapInSelection = computed<boolean>(() => totalHandicapReplaceEnabled.value && allEntries.value.some((item) => entryIsTotalHandicap(item) && isLiveEventSlipEntry(item)));

  const slipEventsCount = computed<number>(() => betEvents.value.length);

  const isBetModeMatchesSlipTypes = computed<boolean>(() => {
    switch (betMode.value) {
      case SlipTypeId.SINGLE:
        return isMultiSinglesEnabled.value || slipEventsCount.value === 1;
      case SlipTypeId.EXPRESS:
        return slipEventsCount.value >= 2;
      case SlipTypeId.SYSTEM:
        return slipEventsCount.value >= 3;
      default:
        return false;
    }
  });

  const eventsWithChangedMarkets = computed<EventWithChangedMarket[]>(
    () => getEventsWithChangedMarkets(slipEntries.value),
  );

  const selectedStakeValue = computed<number>(() => {
    if (currentFreeBet.value) {
      return currentFreeBet.value.amount;
    }
    return safeStakeValue.value;
  });

  const slipSizeIsNotExceeded = computed<boolean>(() => (maxSlipSize.value ? slipEntries.value.length <= maxSlipSize.value : true));

  const singleModeAvailable = computed<boolean>(() => {
    if (isMultiSinglesEnabled.value) {
      return slipSizeIsNotExceeded.value;
    }
    return selectedEntriesIds.value.length <= 1;
  });

  const expressModeAvailable = computed<boolean>(() => canSwitchToCombi.value && slipSizeIsNotExceeded.value);

  const systemModeAvailable = computed<boolean>(() => {
    if (!canSwitchToCombi.value) {
      return false;
    }
    return maxSystemSize.value ? slipEntries.value.length <= maxSystemSize.value : true;
  });

  const multiSinglesUiCount = computed<number>(() => allEntries.value
    .reduce((accumulator, { marketStatus }) => (
      isUnavailableEntryStatus(marketStatus) ? accumulator : accumulator + 1), 0));

  const displayedSlipEventsCount = computed<number>(
    () => (isMultiSinglesMode.value ? multiSinglesUiCount.value : slipEventsCount.value),
  );

  const taxPercent = computed<number | null>(() => {
    if (currentFreeBet.value) {
      return null;
    }
    return combiOrFirstSlip.value?.taxPercent ?? null;
  });

  const maxAvailableFastBetValue = computed<number>(() => getMaxAvailableFastBetValue({
    isLoggedIn: isLoggedIn.value,
    maxStake: maxStake.value,
    balance: balance.value,
    isMultiSinglesMode: isMultiSinglesMode.value,
    sameStakeForSingles: sameStakeForSingles.value,
    availableMultiSinglesCount: multiSinglesAvailableForBet.value.length || 1,
  }));

  /** tax is already calculated */
  const multiSinglesTotalWin = computed<number>(() => {
    if (!isMultiSinglesMode.value) {
      return 0;
    }
    return getMultiSinglesTotalWin({
      availableEntries: multiSinglesAvailableForBet.value,
      sameStakeForSingles: sameStakeForSingles.value,
      selectedStakeValue: selectedStakeValue.value,
      taxPercent: taxPercent.value,
    });
  });

  const multiSinglesTotalTax = computed<number>(() => {
    if (!taxPercent.value) {
      return 0;
    }
    return getMultiSinglesTotalTax({
      availableEntries: multiSinglesAvailableForBet.value,
      sameStakeForSingles: sameStakeForSingles.value,
      selectedStakeValue: selectedStakeValue.value,
      taxPercent: taxPercent.value,
    });
  });

  const maxPrize = computed<number>(() => getMaxPrize({
    totalOdds: totalOdds.value,
    selectedStakeValue: selectedStakeValue.value,
    currentFreeBet: currentFreeBet.value,
  }));

  const limitErrorInfoPayload = computed<BetsLimitErrorPayload>(() => ({
    bets: combinedEntriesInfo.value.map((item) => ({
      event: item.event,
      market: item.market,
      betChoice: item.runnerName ?? '',
    })),
    stakeValue: selectedStakeValue.value,
    totalOdds: totalOdds.value,
    maxPrize: maxPrize.value,
  }));

  const allOdds = computed<string[]>(() => {
    if (!isMultiSinglesMode.value) {
      return [formattedTotalOdds.value];
    }
    return allEntries.value.map((entry) => entry.oddsStr ?? formatOdd(entry.odds));
  });

  /** former actions */

  const getBatchedSlipData = (payload: GetBatchedSlipDataPayload): Promise<BatchedSlipInfoDocument> => requestBatchedSlipInfo(payload, apiClient);

  const addToBetsInfo = (slipListItem: SlipListItem): void => {
    setBetsInfo({ ...betsInfo.value, ...convertSlipListItemToBetsInfoObject(slipListItem) });
  };

  const addToSlipEntryCache = (item: SlipEntry): void => {
    const updatedCache = [
      ...slipEntryCache.value
        .filter((entry) => getSlipEntryId(item) !== getSlipEntryId(entry)),
      item,
    ];
    setSlipEntryCache(updatedCache);
    if (isMultiSinglesMode.value) {
      setMultiSinglesMetaInfo(
        getMultiSinglesMetaInfoForCache({
          slipEntryCache: updatedCache,
          currentMetaInfo: multiSinglesMetaInfo.value,
          defaultBetAmount: defaultBetValue.value,
        }),
      );
    }
  };

  const removeFromSlipEntryCache = (id: SlipEntryId): void => {
    setSlipEntryCache(slipEntryCache
      .value
      .filter((entry) => getSlipEntryId(entry) !== id));
  };

  const updateLocalSlipInfoEntries = (payload: UpdateSlipInfoEntriesPayload): void => {
    const updatedSlipEntries = updateSlipInfoBySlipEntries({
      ...payload,
      currentSlipInfo: batchedSlipInfo.value,
      selectedEntriesIds: selectedEntriesIds.value,
    });
    if (updatedSlipEntries) {
      setSlipEntries(updatedSlipEntries);
    }
  };

  const matchSlipType = (): void => {
    if (betMode.value !== matchedBetMode.value) {
      setBetMode(matchedBetMode.value);
    }
  };

  const acceptSingleEntryChanges = (id: SlipEntryId): void => {
    const updatedBetsInfo = updateBetsInfoOddById({
      id,
      allEntries: allEntries.value,
      currentBetsInfo: betsInfo.value,
      clearReplacementData: true,
    });
    if (updatedBetsInfo && batchedSlipInfo.value) {
      setBetsInfo(updatedBetsInfo);
      const updatedSlipEntries = clearMultiSingleMarketStatusById({ currentSlipInfo: batchedSlipInfo.value, id });
      setSlipEntries(updatedSlipEntries);
    }
  };

  const updateMultiSinglesMetaInfo = (): void => {
    const updatedMetaInfo = getMultiSinglesMetaInfo({
      batchedSlipInfo: batchedSlipInfo.value,
      defaultBetAmount: defaultBetValue.value,
      previousValue: multiSinglesMetaInfo.value,
      betsInfo: betsInfo.value,
      priceChangePolicy: priceChangePolicy.value,
      balance: isLoggedIn.value ? balance.value : null,
      formatMoney,
    });
    setMultiSinglesMetaInfo(updatedMetaInfo);
  };

  const checkMultiSinglesStakeOverBalance = (): void => {
    const checkedMetaInfo = getMetaInfoWithBalanceCheck({
      balance: balance.value,
      currentMetaInfo: multiSinglesMetaInfo.value,
      multiSinglesAvailableForBet: multiSinglesAvailableForBet.value,
      lastModifiedId: lastModifiedMultiSingleId.value,
    });
    setMultiSinglesMetaInfo(checkedMetaInfo);
    setLastModifiedMultiSingleId(null);
  };

  const safeSetSystemValue = (value?: string | null): void => {
    if (value && /^\d/.test(value)) {
      setSystemValue(value);
    }
  };

  const claimToChangeSportEventsStatuses = (entries: SlipEntry[]): void => {
    const mappedEntries = entries.map((entry) => mapSlipEntryToEventWithChangedStatus(entry));
    bus.emit(BusEvent.SPORT_EVENTS_STATUS_CHANGED, { events: mappedEntries, source: 'slip' });
  };

  // need to be defined this way, used both on Ui and for outdated event removal
  let removeBetClick: (id: SlipEntryId) => Promise<void>;

  const onClosedMarketsChange = (payload: OnClosedMarketsChangePayload): void => {
    /** will add timers to delete closed/missed events or remove timers for events which is alive again */
    setClosedMarkets(handleClosedMarketsChange({
      ...payload,
      currentlyClosedMarkets: closedMarkets.value,
      timeout: slipDeleteClosedEventTimeout.value,
    }, removeBetClick));
  };

  const checkEventsWithChangedStatus = (): void => {
    const changedEvents = getSlipEntriesWithChangedStatus(slipEntries.value);
    if (isMatchedArrays(changedEvents, entriesWithChangedStatus.value, sortSlipEntries)) {
      return;
    }
    clearFocusOnUnavailableEvents(changedEvents);
    const { unlockedEventsData, unlockedEventsIds, entriesWithClosedMarket } = getClosedAndUnlockedEventsData({
      slipEntries: slipEntries.value,
      changedEvents,
      entriesWithChangedStatus: entriesWithChangedStatus.value,
    });
    claimToChangeSportEventsStatuses([...unlockedEventsData, ...changedEvents]);
    // save changed
    setEntriesWithChangedStatus(changedEvents);
    // schedule delete for closed markets
    if (entriesWithClosedMarket.length || unlockedEventsIds.size) {
      onClosedMarketsChange({ changedEntries: entriesWithClosedMarket, unlockedEventsIds });
    }
  };

  const checkEventsWithChangedMarkets = (): void => {
    if (eventsWithChangedMarkets.value.length) {
      bus.emit(BusEvent.SPORT_EVENTS_RUNNERS_CHANGED, { events: [...eventsWithChangedMarkets.value], source: 'slip' });
    }
  };

  const saveToLocalStorage = (): void => {
    const snapshot: SlipSnapshotForLocalStorage = {
      batchedSlipInfo: getSlipInfoWithoutTrace(batchedSlipInfo.value),
      betsInfo: betsInfo.value,
      betMode: betMode.value,
      stakeInputValue: stakeInputValue.value,
      selectedEntriesIds: selectedEntriesIds.value,
      lastBetTimeStamp: lastBetTimeStamp.value,
      multiSinglesMetaInfo: multiSinglesMetaInfo.value,
    };
    saveSnapshotToLocalStorage(snapshot);
  };

  const emitSlipSelectionChange = (): void => {
    const selected = selectedEntriesIds.value.map((id) => convertSlipEntryIdToObject(id));
    bus.emit(BusEvent.SLIP_SELECTION_CHANGE, { selected });
  };

  const applyBatchedSlipInfoUpdate = ({ updatedSlipInfo, isBackgroundUpdate }: ApplyBatchedSlipInfoUpdatePayload) => {
    if (!updatedSlipInfo?.slipEntries.length
      || isSamePlainObject(batchedSlipInfo.value, updatedSlipInfo)) {
      return;
    }
    const allSlipEntries = getAllBatchedEntries(updatedSlipInfo.slipEntries);
    const isValid = isSlipEntriesMatchSelected(allSlipEntries, selectedEntriesIds.value);
    if (!isValid) {
      const replacementResult = totalHandicapReplaceEnabled.value
        ? checkTotalHandicapReplacement({
            oldEntries: slipEntries.value,
            oldEntryCache: slipEntryCache.value,
            updatedEntries: allSlipEntries,
            selectedEntriesIds: selectedEntriesIds.value,
            betsInfo: betsInfo.value,
            autoAcceptChanges: autoAcceptTotalHandicapChanges.value,
          })
        : null;
      if (!replacementResult) {
        /** mask as sync error */
        setIncorrectSyncStatus(true);
        return;
      }
      setSelectedEntriesIds(replacementResult.selectedEntriesIds);
      setSlipEntryCache(replacementResult.slipEntryCache);
      setBetsInfo(replacementResult.betsInfo);
      emitSlipSelectionChange();
    } else {
      const updatedBetsInfo = updateBetsInfoByCurrentEntries(betsInfo.value, allSlipEntries);
      setBetsInfo(updatedBetsInfo);
    }
    /** reset sync error if it was */
    setIncorrectSyncStatus(false);
    /** is ok - update store */
    setBatchedSlipInfo(updatedSlipInfo);
    updateMultiSinglesMetaInfo();

    updateFreeBets(getFreeBetsFromBatchedSlipInfo(updatedSlipInfo));

    /** remove added events from slipEntryCache */
    if (slipEntryCache.value.length) {
      const missedItems = getNotSyncedEntries(slipEntryCache.value, allSlipEntries);
      setSlipEntryCache(missedItems);
    }

    /** autoset/autofix stake value */
    if (!isBackgroundUpdate) {
      const nextStakeValue = selectedStakeValue.value || defaultBetValue.value || 0;
      safeSetStakeValue({
        value: nextStakeValue,
        minStake: minStake.value,
        maxStake: maxStake.value,
      });
    }
    /** save selected system value */
    safeSetSystemValue(updatedSlipInfo.slipEntries[0].slipType);

    saveToLocalStorage();

    /** update sportline if necessary */

    if (entriesWithChangedStatus.value.length
      || updatedSlipInfo.slipEntries.some((entry) => (entry && entry?.status === SlipStatus.MARKETS_CHANGED))) {
      checkEventsWithChangedStatus();
    }
    checkEventsWithChangedMarkets();
  };

  const backgroundUpdateSlipInfo = async (): Promise<void> => {
    if (!slipEntries.value.length || isSlipUpdatesLocked.value) {
      return;
    }

    const savedLastChangeTime = lastChangeTime.value;

    const response = await getBatchedSlipData({
      slipEntries: getSlipInfoRequestEntries(slipEntries.value, slipType.value),
      slipType: slipType.value,
      oddsType: currentOddsType.value,
      silent: true,
    });
    if (response) {
      if (!betsInfoCount.value
        || isSlipUpdatesLocked.value
        || (savedLastChangeTime < lastChangeTime.value)) {
        /**
         * !betsInfoCount === list was cleared after request was sent
         * lastChangeTime < this.state.lastChangeTime - data is outdated and can break local state
         */
        return;
      }
      applyBatchedSlipInfoUpdate({ updatedSlipInfo: response, isBackgroundUpdate: true });
    }
  };

  const startSyncSlip = (): void => {
    if (process.env.VUE_APP_RENDERING_SSR || syncIntervalId) {
      return;
    }
    syncIntervalId = Timer.setInterval(() => {
      void backgroundUpdateSlipInfo();
    }, slipEventsUpdateInterval.value);
  };

  const clearSyncInterval = (): void => {
    if (process.env.VUE_APP_RENDERING_CSR && syncIntervalId) {
      Timer.clearInterval(syncIntervalId);
    }
    syncIntervalId = null;
  };

  const enableUpdates = (): void => {
    setUpdateLockStatus(false);
    startSyncSlip();
  };

  const disableUpdates = (): void => {
    setUpdateLockStatus(true);
    clearSyncInterval();
  };

  const clearAddedItemsQueue = (): void => {
    const cachedEventsIds = slipEntryCache.value.map((entry) => getSlipEntryId(entry));
    setSelectedEntriesIds(selectedEntriesIds.value.filter((item) => !cachedEventsIds.includes(item)));
    emitSlipSelectionChange();
    setSlipEntryCache([]);
  };

  const handleSlipClose = (): void => {
    bus.emit(BusEvent.CLAIM_TO_SLIP_CLOSE, {});
  };

  const cancelSlipAdding = (): void => {
    clearAddedItemsQueue();
    if (slipEntries.value.length) {
      enableUpdates();
    } else {
      handleSlipClose();
    }
  };

  const checkFreeBet = (): void => {
    checkIfNeedToCancelFreebet(betMode.value, {
      value: defaultBetValue.value || 0,
      minStake: minStake.value,
      maxStake: maxStake.value,
    });
  };

  const onAddingToSlipError = ({ message, entriesError }: OnAddingErrorPayload): void => {
    let busEventPayload: ShowSlipModalWarningPayload = {
      type: BetSlipWarningTypes.GENERIC_WARNING,
      message,
    };
    if (entriesError === AddSlipEntriesError.LIMIT) {
      busEventPayload = { type: BetSlipWarningTypes.MARKET_STATUS_LIMIT };
      logBetsLimitError(limitErrorInfoPayload.value);
    }
    if (entriesError === AddSlipEntriesError.DUPLICATE) {
      busEventPayload = { type: BetSlipWarningTypes.MARKET_STATUS_DUPLICATE, message };
    }
    cancelSlipAdding();
    bus.emit(BusEvent.SHOW_SLIP_MODAL_WARNING, { ...busEventPayload });
  };

  const catchSyncSlipError = (error: unknown): void => {
    if (error instanceof GqlApiServiceSuspendedError) {
      bus.emit(
        BusEvent.SHOW_SLIP_MODAL_WARNING,
        { type: BetSlipWarningTypes.GENERIC_WARNING, message: error.message },
      );
      cancelSlipAdding();
    } else {
      logger.error(convertToBaseError(error));
      bus.emit(BusEvent.SHOW_SLIP_MODAL_WARNING, { type: BetSlipWarningTypes.CONNECTION_ERROR });
    }
  };

  const syncAddedSlipItems = async ({ isAdding, newSlipType, refreshOriginalOdds }: SyncAddedSlipItemsPayload = {}): Promise<void> => {
    if (!allEntries.value.length) {
      return;
    }

    const computedSlipType = getSafeSlipType(newSlipType || slipType.value, allEntries.value.length);

    const slipEntriesForRequest = getSlipInfoRequestEntries(allEntries.value, computedSlipType);

    const savedLastChangeTime = lastChangeTime.value;

    try {
      const response = await getBatchedSlipData({
        slipEntries: slipEntriesForRequest,
        slipType: computedSlipType,
        oddsType: currentOddsType.value,
      });
      if (!response) {
        throw new Error('Unable to get slip data');
      }
      if (savedLastChangeTime < lastChangeTime.value) {
        /** do not apply update if its answer not for the latest query */
        return;
      }
      if (computedSlipType !== SlipTypeSingle && shouldForceSingleMode(response)) {
        /** recursive call here */
        void syncAddedSlipItems({ newSlipType: SlipTypeSingle });
        return;
      }
      /** check for errors */
      if (isAdding && response.slipEntries.length === 1) {
        // single or combi slip
        const addingErrors = checkSlipSyncErrors(response.slipEntries[0]);
        if (addingErrors) {
          onAddingToSlipError(addingErrors);
          return;
        }
      }

      if (isLeaveEnterAnimationPlaying.value) {
        /** to let slow devices complete slip animation smoothly */
        await sleep(100);
      }
      applyBatchedSlipInfoUpdate({ updatedSlipInfo: response });
      if (refreshOriginalOdds) {
        const updatedBetsInfo = replaceOriginalOddsValues(betsInfo.value, response);
        setBetsInfo(updatedBetsInfo);
      }

      matchSlipType();
      checkFreeBet();
      enableUpdates();
    } catch (rawError) {
      catchSyncSlipError(rawError);
    }
  };

  const selectBetMode = async (mode: SlipTypeId): Promise<void> => {
    setBetMode(mode);
    checkFreeBet();
    setCustomWarningMessage('');
    if (batchedSlipInfo.value && isBetModeMatchesSlipTypes.value && matchedBetMode.value !== mode) {
      disableUpdates();
      setLastChangeTime(Date.now());
      setBetModeWasManuallyChanged(true);
      const updatedSlipType = getMatchedSlipType({
        betMode: mode,
        slipTypes: slipTypes.value,
        slipSize: slipEntries.value.length,
        selectedSystemValue: selectedSystemValue.value,
      });
      setSlipType(updatedSlipType);
      setSystemValue(updatedSlipType);
      await syncAddedSlipItems({ newSlipType: updatedSlipType });
    }
  };

  const clearClosedMarketsData = (): void => {
    for (const timeoutId of Object.values(closedMarkets.value)) {
      Timer.clearTimeout(timeoutId);
    }
    setClosedMarkets({});
  };

  const onSlipClear = (): void => {
    setBetModeWasManuallyChanged(false);
    setBatchedSlipInfo(null);
    setMultiSinglesMetaInfo({});
    setBetsInfo({});
    setSelectedEntriesIds([]);
    clearClosedMarketsData();
    emitSlipSelectionChange();
    safeSetStakeValue({ maxStake: null, minStake: null });
    clearLocalStorageState();
    setEntriesWithChangedStatus([]);
    clearFreeBet();
    setCustomWarningMessage('');
  };

  const removeBetFromList = (id: SlipEntryId): void => {
    removeFromSelectedEntriesIds(id);
    removeFromSlipEntryCache(id);
    setLastBetTimeStamp(null);

    logRemoveFromSlip(id, allEntries.value);

    removeFromClosedMarkets(id);
    emitSlipSelectionChange();

    const slipEntriesCount = batchedSlipInfo.value?.slipEntries.length;

    if (!slipEntriesCount || !batchedSlipInfo.value) {
      return;
    }
    const clearedEntries: SlipInfoArray | null = slipEntriesCount > 1
      ? getSinglesClearedFromBetById(batchedSlipInfo.value, id)
      : getCombiClearedFromBetById(batchedSlipInfo.value, id);
    if (!clearedEntries.length && !slipEntryCache.value.length) {
      onSlipClear();
      handleSlipClose();
      return;
    }
    setSlipEntries(clearedEntries);
    updateMultiSinglesMetaInfo();
  };

  removeBetClick = async (id: SlipEntryId): Promise<void> => {
    disableUpdates();
    removeBetFromList(id);
    if (slipEventsCount.value === 1) {
      setBetMode(SlipTypeId.SINGLE);
    }
    setLastChangeTime(Date.now());
    await syncAddedSlipItems();
  };

  const removeBetEvents = ({ leaveBetResultChoice, slipUnsettledSingles }: RemoveBetEventsPayload = {
    leaveBetResultChoice: LeaveSlipResultChoice.CLEAR_ALL,
    slipUnsettledSingles: null,
  }): void => {
    disableUpdates();
    if (batchedSlipInfo.value && slipUnsettledSingles && leaveBetResultChoice === LeaveSlipResultChoice.SAVE_UNACCEPTED) {
      const dataForUnsettledBets = getSlipDataForUnsettledBets(batchedSlipInfo.value, slipUnsettledSingles);
      setSelectedEntriesIds(slipUnsettledSingles);
      applyBatchedSlipInfoUpdate({
        updatedSlipInfo: dataForUnsettledBets,
      });
    }
    if (leaveBetResultChoice !== LeaveSlipResultChoice.CLEAR_ALL) {
      logSaveSlipItemsAfterBet({
        selectedEntriesIds: selectedEntriesIds.value,
        leaveBetResultChoice,
      });
      enableUpdates();
    }
    if (leaveBetResultChoice === LeaveSlipResultChoice.CLEAR_ALL) {
      setSelectedEntriesIds([]);
      setSlipEntryCache([]);
      setBetMode(SlipTypeId.SINGLE);
      onSlipClear();
    }
    emitSlipSelectionChange();
  };

  const acceptEntriesReplace = (): void => {
    setBetsInfo(clearOldRunnerInfo(betsInfo.value));
  };

  const allowTotalHandicapChanges = (): void => {
    void savePriceChangePolicy({ totalHandicapPriceChangePolicy: priceChangePolicyAllowChanges });
    acceptEntriesReplace();
  };

  const acceptSlipChanges = async (): Promise<void> => {
    disableUpdates();
    setCustomWarningMessage('');
    setPendingChanges(true);
    const currentEntries = [...slipEntries.value];
    let updatedEvents = currentEntries;
    logAcceptChanges(currentEntries);
    if (marketsChanged.value) {
      updatedEvents = getOnlyAvailableSlipEntries(updatedEvents);
      if (!updatedEvents.length) {
        removeBetEvents();
        setPendingChanges(false);
        return;
      }
      const availableEventsIds = updatedEvents.map((item) => getSlipEntryId(item));
      setSelectedEntriesIds(availableEventsIds);
      setEntriesWithChangedStatus([]);
      clearClosedMarketsData();
      emitSlipSelectionChange();
    }
    if (pricesChanged.value) {
      const { updatedEntries, updatedBetsInfo } = getItemsForUpdatedPrice(updatedEvents, betsInfo.value);
      setBetsInfo(updatedBetsInfo);
      updatedEvents = updatedEntries;
    }
    if (entriesHasChanged.value && !autoAcceptTotalHandicapChanges.value) {
      acceptEntriesReplace();
    }
    updateLocalSlipInfoEntries({ entries: updatedEvents });
    await syncAddedSlipItems();
    setPendingChanges(false);
  };

  const selectSystemValue = async (value: string): Promise<void> => {
    disableUpdates();
    await syncAddedSlipItems({ newSlipType: value });
  };

  const toggleBanker = async (id: SlipEntryId): Promise<void> => {
    const updatedEntries = getSlipEntriesWithToggledBanker(id, slipEntries.value);
    if (updatedEntries) {
      disableUpdates();
      updateLocalSlipInfoEntries({ entries: updatedEntries });
      await syncAddedSlipItems();
    }
  };

  const cancelRestoreSlipFromLocalStorage = (): void => {
    clearLocalStorageState();
    setMultiSinglesMetaInfo({});
    setRestoreFromLS(false);
  };

  const restoreSlipInfoFromLocalStorage = async (): Promise<void> => {
    try {
      const snapshot = getSnapshotFromLocalStorage();
      if (!snapshot?.batchedSlipInfo) {
        cancelRestoreSlipFromLocalStorage();
        return;
      }
      setRestoreFromLS(true);
      setMultiSinglesMetaInfo(snapshot.multiSinglesMetaInfo);
      const restoredEntries = getAllBatchedEntries(snapshot.batchedSlipInfo.slipEntries);
      assert(isSlipEntryArray(restoredEntries), 'unexpected value for slipInfo.entries from localstorage');
      if (Object.values(snapshot.betsInfo).some((entry) => ('betChoice' in entry || 'oddsType' in entry))) {
        /*
        old property names in localstorage, cancel to not get entries without proper runner/market labels
        if values will come from api, have no sense
        */
        cancelRestoreSlipFromLocalStorage();
        return;
      }
      const slipTypeFromSnapshot = getCombiSlipInfo(snapshot.batchedSlipInfo)?.slipType || null;
      const actualData = await getBatchedSlipData({
        slipEntries: getSlipInfoRequestEntries(restoredEntries, slipTypeFromSnapshot),
        slipType: slipTypeFromSnapshot,
        oddsType: currentOddsType.value,
      });

      if (!actualData || !restoringSlipFromLS.value) {
        cancelRestoreSlipFromLocalStorage();
        return;
      }
      const actualEntries = getOnlyAvailableSlipEntries(getAllBatchedEntries(actualData.slipEntries));
      if (!actualEntries.length
        || (!isMultiSinglesEnabled.value && snapshot.betMode === SlipTypeId.SINGLE && actualEntries.length > 1)) {
        cancelRestoreSlipFromLocalStorage();
        return;
      }

      // ok, setting values
      setBetMode(snapshot.betMode);
      setSystemValue(slipTypeFromSnapshot);
      const actualSelectedIds = actualEntries.map((entry) => getSlipEntryId(entry));
      setSelectedEntriesIds(actualSelectedIds);
      setBetsInfo(syncBetsInfoAfterSlipRestore({
        oldBetsInfo: snapshot.betsInfo,
        actualSlipInfo: actualData,
        runnerReplaceEnabled: totalHandicapReplaceEnabled.value,
      }));
      setLastBetTimeStamp(snapshot.lastBetTimeStamp);
      if (process.env.VUE_APP_LAYOUT_DESKTOP) {
        /** otherwise causes bugs with desktop overlays */
        setSlipVisibility(true);
      }
      const actualSlipEntries = actualData.slipEntries.map((slipEntry) => ({
        ...slipEntry,
        status: SlipStatus.OK,
      }));
      setBatchedSlipInfo({
        slipEntries: actualSlipEntries,
        combiAvailable: actualData.combiAvailable,
      });
      updateLocalSlipInfoEntries({
        entries: actualEntries,
        overwriteMultisingles: snapshot.betMode === SlipTypeId.SINGLE,
      });
      updateMultiSinglesMetaInfo();
      const parsedInputValue = Number.parseFloat(stakeInputValue.value);
      safeSetStakeValue({
        value: !Number.isNaN(parsedInputValue) ? parsedInputValue : undefined,
        maxStake: maxStake.value,
        minStake: minStake.value,
      });
      emitSlipSelectionChange();
      setRestoreFromLS(false);
      void syncAddedSlipItems();
    } catch (error) {
      logger.error(`restoreSlipInfoFromLocalStorage error: ${error}`);
      cancelRestoreSlipFromLocalStorage();
    }
  };

  const addToSlip = (item: SlipListItem): void => {
    /** reset view if its new bet after just placed one */
    setDefaultSlipView();

    /** set next placeBet request as new */
    setLastBetTimeStamp(null);
    setCustomWarningMessage('');

    /** save last change timestamp */
    setLastChangeTime(Date.now());

    /** prevent replace from previous update */
    disableUpdates();

    const initialEntriesCount = slipEventsCount.value;
    const isMatchedBetMode = isBetModeMatchesSlipTypes.value;

    /** save items info for client UI */
    addToBetsInfo(item);

    if (restoringSlipFromLS.value) {
      setRestoreFromLS(false);
    }

    /** register event id */

    addToSelectedEntriesIds(getSlipEntryId(item));
    emitSlipSelectionChange();

    logAddingToSlip(item);

    /** get correct object shape for API request */
    const slipEntry = getSlipEntryFromSlipListItem(item);

    /** save cache */
    addToSlipEntryCache(slipEntry);

    /** open automatically if its first bet */
    if (initialEntriesCount === 0 && showSlipOnFirstAdded.value) {
      handleSlipVisibilityChange(true);
    }

    let newSlipType: string | undefined;
    /** adding second bet will switch to express */
    if (initialEntriesCount === 1 && (!isMultiSinglesEnabled.value || (!betModeWasManuallyChanged.value
      && canSwitchToCombi.value))) {
      newSlipType = 'e-2'; // e-2 - express, two entries
      setBetMode(SlipTypeId.EXPRESS);
    }
    if (!isMatchedBetMode && initialEntriesCount === 2 && betMode.value === SlipTypeId.SYSTEM) {
      /** adding 3rd item with active system tab, with message like "to enable system, add atleast 3 item in slip" */
      newSlipType = '0_2/3'; // system 2/3 no bankers
    }
    void Promise.resolve().then(() => {
      void syncAddedSlipItems({ isAdding: true, newSlipType });
    });
  };

  const acceptSinglePriceChanges = async (id: SlipEntryId): Promise<void> => {
    const matchedEntry = findSlipEntryById(id, slipEntries.value);
    logAcceptChanges(matchedEntry ? [matchedEntry] : []);
    acceptSingleEntryChanges(id);
    await backgroundUpdateSlipInfo();
  };

  const setupSlipFromSharedData = async (payload: SetupSlipFromSharedDataPayload): Promise<void> => {
    setLastChangeTime(Date.now());
    const actualData = await requestBatchedSlipInfo({
      slipEntries: getSlipInfoRequestEntries(payload.slipEntries, payload.slipType),
      slipType: payload.slipType,
      oddsType: currentOddsType.value,
      silent: false,
    }, apiClient);
    setSelectedEntriesIds(payload.selectedEntriesIds);
    setBetsInfo(payload.betsInfo);
    setBatchedSlipInfo(actualData);
    matchSlipType();
    safeSetStakeValue({
      value: defaultBetValue.value || 0,
      minStake: minStake.value,
      maxStake: maxStake.value,
    });
    enableUpdates();
  };

  // bus related
  const updateSlipEventsFromSportline = (payload: ClaimToUpdateSportEventRunnerOddChangedEventData | ClaimToUpdateSportEventStatusChangedEventData): void => {
    if (!batchedSlipInfo.value || payload.source === 'slip') {
      return;
    }
    setSlipEntries(updateSlipInfoFromSportlineData(batchedSlipInfo.value, payload));
  };

  const init = (): void => {
    void restoreSlipInfoFromLocalStorage();
    bus.on(BusEvent.SPORT_EVENTS_RUNNERS_CHANGED, (data: ClaimToUpdateSportEventRunnerOddChangedEventData) => {
      updateSlipEventsFromSportline(data);
    });
    bus.on(BusEvent.SPORT_EVENTS_STATUS_CHANGED, (data: ClaimToUpdateSportEventStatusChangedEventData) => {
      updateSlipEventsFromSportline(data);
    });
  };

  init();
  watch(multiSinglesTotalPrice, (value, oldValue) => {
    saveToLocalStorage();
    if (isLoggedIn.value && (value > balance.value || oldValue > balance.value)) {
      // run only if total price is more then balance, or
      // if oldValue > balance.value and value <= balance.value - we need run function to clean error
      checkMultiSinglesStakeOverBalance();
    }
  });
  watch(currentOddsType, () => {
    void syncAddedSlipItems({ refreshOriginalOdds: true });
  });

  const resetPriceChangesError = (): void => {
    setCustomWarningMessage('');
    if (batchedSlipInfo.value) {
      applyBatchedSlipInfoUpdate({
        updatedSlipInfo: clearStatusOnBatchedSlipInfo(batchedSlipInfo.value),
        isBackgroundUpdate: true,
      });
    }
  };

  return {
    addToSlip,
    syncAddedSlipItems,
    cancelSlipAdding,
    removeBetEvents,
    removeBetClick,
    selectBetMode,
    acceptSlipChanges,
    acceptSinglePriceChanges,
    checkMultiSinglesStakeOverBalance,
    toggleBanker,
    selectSystemValue,
    setClearBetListScheduled,
    removeBetFromList,
    enableUpdates,
    disableUpdates,
    matchSlipType,
    applyBatchedSlipInfoUpdate,
    saveToLocalStorage,
    setUpdateLockStatus,
    setBetMode,
    setLastBetTimeStamp,
    clearLocalStorageState,
    onAddingToSlipError,
    setCustomWarningMessage,
    resetPriceChangesError,
    setupSlipFromSharedData,
    acceptEntriesReplace,
    allowTotalHandicapChanges,
    slipEventsCount,
    bankersAvailable,
    systemOptions,
    formattedTotalOdds,
    hasLimitError,
    warningMessage,
    mustAcceptChanges,
    entriesHasChanged,
    isSyncInProgress,
    slipTraceLines,
    multiSinglesTotalPrice,
    singleModeAvailable,
    expressModeAvailable,
    systemModeAvailable,
    slipType,
    isMultiSinglesMode,
    betEvents,
    combinedEntriesInfo,
    combiOrFirstSlip,
    isBetModeMatchesSlipTypes,
    slipEntries,
    bankerCount,
    multiSinglesUiCount,
    displayedSlipEventsCount,
    maxSlipSize,
    maxSystemSize,
    totalOdds,
    maxStake,
    minStake,
    multiSinglesAvailableForBet,
    pricesChanged,
    marketsChanged,
    isPendingChanges,
    batchedSlipInfo,
    clearBetListScheduled,
    betMode,
    selectedSystemValue,
    lastBetTimeStamp,
    restoringSlipFromLS,
    maxAvailableFastBetValue,
    selectedStakeValue,
    isHandicapInSelection,
    allOdds,
    taxPercent,
    totalHandicapReplaceEnabled,
    autoAcceptTotalHandicapChanges,
    maxPrize,
    multiSinglesTotalWin,
    multiSinglesTotalTax,
  };
});

export default useSlipInfoStore;
