import { logger } from '@leon-hub/logging';

import type { LeaveSlipResultChoice } from '../../../enums';
import type { SlipEntryId } from '../types';
import convertSlipEntryIdToObject from '../../../utils/convertSlipEntryIdToObject';

interface Payload {
  selectedEntriesIds: SlipEntryId[];
  leaveBetResultChoice: LeaveSlipResultChoice;
}

export default function logSaveSlipItemsAfterBet({
  selectedEntriesIds,
  leaveBetResultChoice,
}: Payload): void {
  const events = selectedEntriesIds.map((id) => convertSlipEntryIdToObject(id));
  logger.info('Save bets to slip', {
    events,
    saveChoice: leaveBetResultChoice,
  });
}
