<script lang="ts" setup>
import { BadgeKind, VBadge } from '@components/badge';
import { ButtonKind, VButton } from '@components/buttons';
import { VCircularProgress } from '@components/circular-progress';
import { SwitchKind, VSwitch } from '@components/switch';
import { VIcon } from '@components/v-icon';

import type { VListItemInnerEmits, VListItemInnerExpose, VListItemInnerProps, VListItemInnerSlots } from '../../types';
import { ListItemSuffixAlign } from '../../enums';
import { useVListItemInner } from './composables/useVListItemInner';

import $style from '../../styles/common.module.scss';

const props = withDefaults(defineProps<VListItemInnerProps>(), {
  suffixAlign: ListItemSuffixAlign.CENTER,
  switchKind: SwitchKind.DEFAULT,
  progressValue: 0,
  badgeKind: BadgeKind.DEFAULT,
  buttonKind: ButtonKind.CANCEL,
});

const emit = defineEmits<VListItemInnerEmits>();

defineSlots<VListItemInnerSlots>();

function emitClick(event: MouseEvent): void {
  emit('click', event);
}
function emitChangeSwitcher(value: boolean): void {
  emit('change-switcher', value);
}
function emitClickSwitcher(event: Event): void {
  emit('click-switcher', event);
}
function emitClickPrefix(): void {
  emit('click-prefix');
}
function emitClickDefault(): void {
  emit('click-default');
}
function emitClickSuffix(): void {
  emit('click-suffix');
}
function emitClickButton(): void {
  emit('click-button');
}

const {
  tag,
  badgeProperties,
  switchProperties,
  computedIconProperties,
  progressProperties,
  haveSuffix,
  havePrefix,
  haveAdditionalContent,
  iconSuffixProperties,
  buttonProperties,
  listItemInnerRef,
  scrollIntoView,
} = useVListItemInner(props);

defineExpose<VListItemInnerExpose>({
  scrollIntoView,
});
</script>

<template>
  <component v-auto-id="'VListItemInner'"
    :is="tag"
    ref="listItemInnerRef"
    :class="{
      [$style['list-item__inner']]: true,
      [$style['list-item__inner--with-prefix']]: havePrefix,
      [$style['list-item__inner--with-suffix']]: haveSuffix,
      [$style['list-item__inner--colored']]: haveSuffix && iconColored,
      [$style['list-item__inner--text-centered']]: isTitleCentered && !havePrefix,
      [$style['list-item__inner--expand']]: haveExpand,
      [$style['list-item__inner--nowrap']]: noWrap,
      [$style['list-item__inner--disabled']]: haveSwitcher && isSwitchDisabled,
    }"
    :href="tag === 'a' ? href : null"
    @click="emitClick"
  >
    <div
      v-if="havePrefix"
      :class="{
        [$style['list-item__prefix']]: true,
        [$style['list-item__prefix--colored']]: iconColored,
      }"
      @click="emitClickPrefix"
    >
      <slot name="prefix">
        <div
          v-if="!!iconName"
          :class="{
            [$style['list-item__prefix-icon']]: true,
            [$style['list-item__prefix-icon--new']]: isNew,
            [$style['list-item__prefix-icon--colored']]: iconColored,
            [$style[`list-item__prefix-icon--${iconName}`]]: iconColored && iconName,
          }"
          :style="iconCustomStyles"
        >
          <VIcon v-bind="computedIconProperties" />
        </div>
      </slot>
    </div>
    <div
      :class="[$style['list-item__default']]"
      @click="emitClickDefault"
    >
      <span
        v-if="customIconSrc"
        :class="[$style['list-item__cdn-icon']]"
      >
        <img
          width="100%"
          :src="customIconSrc"
          :alt="customIconSrc"
        >
      </span>
      <span
        v-if="!!title && !$slots.inner"
        v-data-test="{ el: 'list-item-title' }"
      >
        {{ title }}
      </span>
      <slot
        v-else
        name="inner"
      />
    </div>
    <div
      v-if="haveSuffix"
      :class="[
        $style[`list-item__suffix`],
        $style[`list-item__suffix--${suffixAlign}`],
      ]"
      @click="emitClickSuffix"
    >
      <VSwitch
        v-if="haveSwitcher"
        v-bind="switchProperties"
        @click="emitClickSwitcher"
        @change="emitChangeSwitcher"
      />
      <template v-else>
        <div
          v-if="!!counter || !!suffixText"
          :class="$style['list-item__suffix-text']"
        >
          {{ counter || suffixText }}
        </div>
        <VBadge
          v-if="!!badgeLabel && !isRewardCenter"
          v-bind="badgeProperties"
          :class="$style['list-item__suffix-badge']"
        />
        <VBadge
          v-if="'1' && !!badgeLabel && isRewardCenter"
          v-bind="badgeProperties"
          :class="$style['list-item__suffix-badge--reward-center']"
        />
        <VCircularProgress
          v-else-if="haveProgress || progressValue > 0"
          v-bind="progressProperties"
          :class="$style['list-item__suffix-progress']"
        />
        <VIcon
          v-if="haveExpand || !!iconSuffixName || haveProgress || progressValue > 0"
          v-bind="iconSuffixProperties"
          :class="$style['list-item__suffix-icon']"
        />
      </template>
      <VButton
        v-if="!!buttonLabel"
        v-bind="buttonProperties"
        :class="$style['list-item__suffix-button']"
        @click="emitClickButton"
      />
    </div>
    <div
      v-if="haveAdditionalContent"
      :class="$style['list-item__additional-content']"
    >
      <slot name="additional" />
    </div>
  </component>
</template>
