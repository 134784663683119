import type {
  ClaimToUpdateSportEventRunnerOddChanged,
  ClaimToUpdateSportEventStatusChanged,
} from 'web/src/modules/slip/types/сlaimToUpdate';
import type {
  CoreMarketResponse,
  CoreMarketRunnerResponse,
  CoreSportEventResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';

import isMatchedChange from './isMatchedChange';

export function isSportEventResponseIncludesChanges(
  currentResponse?: GetSportEventsResponse | CoreSportEventResponse,
  changes?: (ClaimToUpdateSportEventStatusChanged | ClaimToUpdateSportEventRunnerOddChanged)[],
): boolean {
  if (!currentResponse || !changes) { return false; }
  const events = 'events' in currentResponse ? currentResponse.events : [currentResponse];
  return events.some((event: CoreSportEventResponse) => (event.markets ?? [])
    .some((market: CoreMarketResponse) => (market.runners || [])
      .some((runner: CoreMarketRunnerResponse) => (changes || [])
        .some((change) => isMatchedChange(change, {
          eventId: event.id,
          marketId: market.id,
          runnerId: runner.id,
        })))));
}
