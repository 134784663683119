<script lang="ts" setup>
import { VIcon } from '@components/v-icon';

import type { BonusLoyaltyProgramBalanceEmits, BonusLoyaltyProgramBalanceProps } from './types';
import { useBonusLoyaltyProgramBalance } from './composables';

const props = withDefaults(defineProps<BonusLoyaltyProgramBalanceProps>(), {
});
const emit = defineEmits<BonusLoyaltyProgramBalanceEmits>();

const {
  handleClick,
  iconProps,
} = useBonusLoyaltyProgramBalance(props, emit);
</script>

<template>
  <div v-auto-id="'BonusLoyaltyProgramBalance'"
    :class="{
      [$style['bonus-loyalty-program-balance']]: true,
      [$style['bonus-loyalty-program-balance--has-icon']]: !!iconName,
      [$style['bonus-loyalty-program-balance--inactive']]: isInactive,
      [$style['bonus-loyalty-program-balance--secondary']]: isSecondary,
      [$style['bonus-loyalty-program-balance--is-small']]: isSmall,
      [$style['bonus-loyalty-program-balance--no-hover']]: hasNoHover,
    }"
    @click.stop="handleClick"
  >
    <VIcon
      v-if="!!iconName"
      :class="[$style['bonus-loyalty-program-balance__icon']]"
      v-bind="iconProps"
    />
    <span :class="[$style['bonus-loyalty-program-balance__text']]">{{ text }}</span>
  </div>
</template>

<style lang="scss" module>
.bonus-loyalty-program-balance {
  $self: &;

  display: inline-flex;
  column-gap: 8px;
  align-items: center;
  padding: 6px 8px;
  background-color: var(--Layer0);
  border-radius: 20px;

  @include for-hover {
    &:not(&--no-hover) {
      &:hover {
        cursor: pointer;
        background-color: var(--OpacityLayer2);
      }
    }
  }

  &__icon {
    fill: var(--BrandDefault);
  }

  &__text {
    @include medium\13\16;

    color: var(--TextDefault);
  }

  &--inactive {
    #{$self}__icon {
      fill: var(--TextSecondary);
    }

    #{$self}__text {
      color: var(--TextSecondary);
    }
  }

  &--secondary {
    background-color: var(--OpacityLayer1);
  }

  &--has-icon {
    padding-right: 10px;
  }

  &--is-small {
    column-gap: 2px;
    padding: 2px 6px 2px 4px;

    #{$self}__text {
      @include medium\11\16\-02;
    }
  }
}
</style>
