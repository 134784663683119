import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import type { SlipEntryId, SlipEntrySelectedIdentifier } from '../../slip-info/types';
import { convertSlipEntryIdToObject } from '../../../utils';

const useSlipSelectedEntriesStore = defineStore('slip-selected-entries', () => {
  /** keep events ids as source of truth during the updates */
  const selectedEntriesIds = ref<SlipEntryId[]>([]);

  const selectedEventsInfo = computed<SlipEntrySelectedIdentifier[]>(
    () => selectedEntriesIds.value.map((id) => convertSlipEntryIdToObject(id)),
  );

  function addToSelectedEntriesIds(id: SlipEntryId): void {
    if (selectedEntriesIds.value.includes(id)) {
      return;
    }

    selectedEntriesIds.value = [...selectedEntriesIds.value, id];
  }

  function removeFromSelectedEntriesIds(id: SlipEntryId): void {
    selectedEntriesIds.value = selectedEntriesIds.value.filter((item) => item !== id);
  }

  function setSelectedEntriesIds(ids: SlipEntryId[]): void {
    selectedEntriesIds.value = ids;
  }

  return {
    selectedEntriesIds,
    selectedEventsInfo,
    addToSelectedEntriesIds,
    removeFromSelectedEntriesIds,
    setSelectedEntriesIds,
  };
});

export default useSlipSelectedEntriesStore;
