import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { IconName } from "@leon-hub/icons";
import { getUuid, Deferred } from "@leon-hub/utils";
import { Events } from "@leon-hub/yandex-metrika";
import { useAnalytics } from "@core/analytics";
import { useI18n } from "@core/i18n";
import { ButtonKind } from "@components/buttons";
import { ModalWidth } from "@components/dialogs";
import { JumbotronIconKind } from "@components/jumbotron";
var DialogPriority = /* @__PURE__ */ ((DialogPriority2) => {
  DialogPriority2[DialogPriority2["default"] = 1] = "default";
  DialogPriority2[DialogPriority2["notification"] = 2] = "notification";
  DialogPriority2[DialogPriority2["error"] = 3] = "error";
  return DialogPriority2;
})(DialogPriority || {});
var AnalyticsModalType = /* @__PURE__ */ ((AnalyticsModalType2) => {
  AnalyticsModalType2["WARNING"] = "warning";
  AnalyticsModalType2["WARNING_EGS"] = "warningEGS";
  return AnalyticsModalType2;
})(AnalyticsModalType || {});
var DialogComponent = /* @__PURE__ */ ((DialogComponent2) => {
  DialogComponent2["ConfirmModal"] = "ConfirmModal";
  DialogComponent2["CountDownModal"] = "CountDownModal";
  DialogComponent2["CmsContentModal"] = "CmsContentModal";
  DialogComponent2["CustomModal"] = "CustomModal";
  return DialogComponent2;
})(DialogComponent || {});
var PresetName = /* @__PURE__ */ ((PresetName2) => {
  PresetName2["ALERT_SUCCESS"] = "ALERT_SUCCESS";
  PresetName2["ALERT_SUCCESS_NO_BUTTONS"] = "ALERT_SUCCESS_NO_BUTTONS";
  PresetName2["ALERT_WARNING"] = "ALERT_WARNING";
  PresetName2["ALERT_ERROR"] = "ALERT_ERROR";
  PresetName2["CONFIRM"] = "CONFIRM";
  PresetName2["CMS_MODAL"] = "CMS_MODAL";
  PresetName2["CUSTOM"] = "CUSTOM";
  return PresetName2;
})(PresetName || {});
var DialogAction = /* @__PURE__ */ ((DialogAction2) => {
  DialogAction2["MODAL_CLOSE"] = "modalClose";
  DialogAction2["MODAL_DESTROY"] = "modalDestroy";
  DialogAction2["CONFIRM"] = "confirm";
  DialogAction2["SECONDARY_CLICK"] = "secondaryClick";
  DialogAction2["ADDITIONAL_BUTTON"] = "additionalButton";
  DialogAction2["MODAL_COUNTDOWN"] = "modalCountDown";
  return DialogAction2;
})(DialogAction || {});
function getUpdatedPresetButtons(preset, buttons) {
  var _a;
  if ((_a = preset.buttons) == null ? void 0 : _a.length) {
    const buttonCopy = [...preset.buttons];
    for (const [index, button] of buttonCopy.entries()) {
      const updatedFields = buttons[index];
      if (updatedFields) {
        buttonCopy[index] = {
          ...button,
          ...updatedFields
        };
      }
    }
    return buttonCopy;
  }
  return buttons || [];
}
const useDialogsStore = /* @__PURE__ */ defineStore("dialogs", () => {
  const { $translate } = useI18n();
  const analytics = useAnalytics();
  const dialogsList = ref([]);
  const currentDialog = computed(() => dialogsList.value[0]);
  const hasInnerDialog = ref(false);
  const dialogPresets = computed(() => {
    const baseCloseButton = {
      action: DialogAction.MODAL_CLOSE,
      label: $translate("JSP_PCL_FBOT_CLOSE").value
    };
    const baseAlertModal = {
      priority: DialogPriority.notification,
      modalComponent: DialogComponent.ConfirmModal,
      iconName: IconName.CHECK_OUTLINE,
      iconKind: JumbotronIconKind.SUCCESS,
      width: ModalWidth.SMALL,
      buttons: [baseCloseButton]
    };
    return {
      ALERT_SUCCESS: baseAlertModal,
      ALERT_SUCCESS_NO_BUTTONS: {
        ...baseAlertModal,
        buttons: []
      },
      ALERT_WARNING: {
        ...baseAlertModal,
        priority: DialogPriority.error,
        iconKind: JumbotronIconKind.DEFAULT,
        iconName: IconName.ATTENTION_OUTLINE,
        analyticsModalType: AnalyticsModalType.WARNING
      },
      ALERT_ERROR: {
        ...baseAlertModal,
        priority: DialogPriority.error,
        iconKind: JumbotronIconKind.DEFAULT,
        iconName: IconName.ATTENTION_OUTLINE,
        analyticsModalType: AnalyticsModalType.WARNING,
        title: $translate("JS_CAPTION_ATTENTION").value
      },
      CONFIRM: {
        priority: DialogPriority.default,
        modalComponent: DialogComponent.ConfirmModal,
        iconName: IconName.ATTENTION_OUTLINE,
        buttons: [
          {
            action: DialogAction.CONFIRM,
            label: $translate("LIVECHAT_MODAL_CLOSE_BTN").value
          },
          {
            action: DialogAction.MODAL_CLOSE,
            kind: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? "tertiary-primary" : ButtonKind.TRANSPARENT,
            label: $translate("JSPACC_SETT_CANCEL").value
          }
        ]
      },
      CUSTOM: {
        priority: DialogPriority.default,
        buttons: []
      },
      CMS_MODAL: {
        priority: DialogPriority.default,
        fullHeight: true,
        width: ModalWidth.SMALL,
        modalComponent: DialogComponent.CmsContentModal,
        iconName: IconName.ATTENTION_OUTLINE,
        noPadding: true,
        buttons: [
          {
            ...baseCloseButton,
            label: $translate("WEB2_CLOSE_CUSTOMER_NOTIFICATION").value
          }
        ]
      }
    };
  });
  function resolveDialogPromises(id) {
    for (const item of dialogsList.value) {
      if (!id || item.dialog.id === id) {
        item.promise.resolve();
      }
    }
  }
  function closeDialog(id) {
    resolveDialogPromises(id);
    dialogsList.value = dialogsList.value.filter((item) => item.dialog.id !== id);
  }
  function closeAllDialogs() {
    resolveDialogPromises();
    dialogsList.value = [];
  }
  function sortDialogs() {
    dialogsList.value.sort((a, b) => {
      if (a.dialog.priority > b.dialog.priority) {
        return -1;
      }
      if (a.dialog.priority < b.dialog.priority) {
        return 1;
      }
      return a.createdAt - b.createdAt;
    });
  }
  const showDialog = (options) => {
    const id = options.id || getUuid();
    const preset = dialogPresets.value[options.presetName];
    let newButtons = preset.buttons ? [...preset.buttons] : [];
    const { buttons, ...dialogOptions } = options.options;
    if (buttons && preset) {
      newButtons = getUpdatedPresetButtons(preset, buttons);
    }
    const item = {
      dialog: {
        ...preset,
        ...dialogOptions,
        buttons: newButtons,
        id,
        priority: options.options.priority ?? DialogPriority.default
      },
      resolvers: {
        [DialogAction.MODAL_DESTROY]: () => closeDialog(id)
      },
      promise: new Deferred(),
      createdAt: Date.now()
    };
    const subscribe = (resolvers) => {
      item.resolvers = {
        ...item.resolvers,
        ...resolvers
      };
    };
    dialogsList.value.push(item);
    sortDialogs();
    if (item.dialog.analyticsModalType) {
      analytics.push(Events.MODAL_ERROR_MESSAGES, {
        [String(item.dialog.analyticsModalType)]: item.dialog.confirmMessage
      });
    }
    return {
      id,
      subscribe,
      promise: item.promise.promise
    };
  };
  function callAction(modalId, action, value) {
    var _a, _b, _c, _d;
    for (const dialog of dialogsList.value) {
      if (dialog.dialog.id === modalId) {
        const doDestroyDialog = (_b = (_a = dialog.resolvers)[action]) == null ? void 0 : _b.call(_a, {
          modalId,
          value
        });
        if (doDestroyDialog !== false) {
          void ((_d = (_c = dialog.resolvers)[DialogAction.MODAL_DESTROY]) == null ? void 0 : _d.call(_c, {
            modalId
          }));
        }
      }
    }
  }
  function setFullHeight(modalId, fullHeight) {
    for (const dialog of dialogsList.value) {
      if (dialog.dialog.id === modalId) {
        dialog.dialog.fullHeight = fullHeight;
      }
    }
  }
  function updateDialogProps(modalId, newProps) {
    for (const dialog of dialogsList.value) {
      if (dialog.dialog.id === modalId) {
        dialog.dialog = {
          ...dialog.dialog,
          ...newProps
        };
      }
    }
  }
  return {
    currentDialog,
    dialogPresets,
    showDialog,
    closeAllDialogs,
    closeDialog,
    callAction,
    setFullHeight,
    updateDialogProps,
    hasInnerDialog
  };
});
function useDialogs() {
  const {
    showDialog,
    closeDialog,
    closeAllDialogs
  } = useDialogsStore();
  return {
    showDialog,
    closeDialog,
    closeAllDialogs
  };
}
export {
  AnalyticsModalType,
  DialogAction,
  DialogComponent,
  DialogPriority,
  PresetName,
  useDialogs,
  useDialogsStore
};
