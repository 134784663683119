import { MarketStatus } from '@leon-hub/api-sdk';

import type { EntryWithChangedStatus, SlipEntry } from '../types';

export default function mapSlipEntryToEventWithChangedStatus({
  event,
  market,
  runner,
  marketStatus,
}: SlipEntry): EntryWithChangedStatus {
  return {
    event,
    market,
    runner,
    marketStatus:
      marketStatus === MarketStatus.CLOSED
      || marketStatus === MarketStatus.SUSPENDED
      || marketStatus === MarketStatus.MISSING
        ? `${marketStatus}` : null,
  };
}
