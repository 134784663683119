<script lang="ts" setup>
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';

import { ButtonHeight, ButtonKind, VButton } from '@components/buttons';
import { VIcon } from '@components/v-icon';

import type {
  VHintBlockEmits,
  VHintBlockProps,
  VHintBlockSlots,
} from './types';
import { HintBlockKind } from '../../enums';

withDefaults(defineProps<VHintBlockProps>(), {
  hasIcon: true,
  icon: IconName.ATTENTION_OUTLINE,
  label: '',
  labelCancel: '',
  labelAccept: '',
  kind: HintBlockKind.DEFAULT,
  isWideFooter: false,
});
const emit = defineEmits<VHintBlockEmits>();
defineSlots<VHintBlockSlots>();

function emitClick(event: MouseEvent): void {
  emit('click', event);
}

function emitOnCancel(): void {
  emit('on-cancel');
}

function emitOnAccept(): void {
  emit('on-accept');
}
</script>

<template>
  <div v-auto-id="'VHintBlock'"
    :class="{
      [$style['hint-block']]: true,
      [$style['hint-block--centered']]: isCentered,
      [$style['hint-block--titled']]: !!$slots.title,
      [$style['hint-block--no-icon']]: !hasIcon,
      [$style[`hint-block--kind-${kind}`]]: kind !== HintBlockKind.DEFAULT,
    }"
    @click="emitClick"
  >
    <div :class="$style['hint-block__wrapper']">
      <div
        v-if="hasIcon"
        :class="$style['hint-block__icon']"
      >
        <VIcon
          v-if="!$slots.icon"
          v-data-test="iconDataTest"
          :name="icon ?? IconName.INFO_OUTLINE"
          :size="IconSize.SIZE_24"
        />
        <slot
          v-else
          name="icon"
        />
      </div>
      <div
        v-if="label || $slots.label"
        :class="$style['hint-block__label']"
      >
        <slot name="label">
          {{ label }}
        </slot>
      </div>
      <div
        v-else
        :class="{
          [$style['hint-block__content']]: true,
          [$style['hint-block__content--indent']]: labelCancel || labelAccept,
        }"
      >
        <div
          v-if="$slots.title"
          :class="$style['hint-block__title']"
        >
          <slot name="title" />
        </div>
        <slot />
      </div>
      <div
        v-if="labelCancel || labelAccept"
        :class="$style['hint-block__action']"
      >
        <VButton
          v-if="labelCancel"
          v-bind="{
            height: ButtonHeight.TINY,
            kind: ButtonKind.TRANSPARENT,
          }"
          :label="labelCancel"
          :class="$style['hint-block__action-button']"
          @click="emitOnCancel"
        />
        <VButton
          v-if="labelAccept"
          v-bind="{
            height: ButtonHeight.TINY,
            kind: ButtonKind.TRANSPARENT,
          }"
          :label="labelAccept"
          :class="[
            $style['hint-block__action-button'],
            $style['hint-block__action-button--green'],
          ]"
          @click="emitOnAccept"
        />
      </div>
    </div>
    <div
      v-if="$slots.footer"
      :class="{
        [$style['hint-block__footer']]: true,
        [$style['hint-block__footer--width-wide']]: $props.isWideFooter,
        [$style['hint-block__footer--width-normal']]: !$props.isWideFooter,
      }"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<style lang="scss" module>
$kinds: (
  default: var(--TextSecondary),
  warning: var(--AlertText),
  success: var(--BrandDefault),
  error: var(--ErrorText),
  attention: var(--ErrorText),
);

.hint-block {
  $self: &;

  position: relative;
  padding: 8px;
  color: var(--TextPrimary);
  text-align: left;
  background-color: var(--Layer1);
  border-radius: 5px;

  &__wrapper {
    display: flex;
  }

  &__icon {
    margin: 4px 8px 4px 0;
  }

  @each $name, $kind in $kinds {
    &--kind-#{$name} {
      color: $kind;
    }
  }

  &--kind-error {
    margin-top: 20px;
    background-color: var(--ErrorField);
  }

  &__label {
    @include regular\14\24\025;

    display: flex;
    flex: 1;
    margin: 4px 8px 4px 0;

    #{$self}--no-icon & {
      margin-left: 8px;
    }
  }

  &__action {
    display: flex;

    #{$self}--titled & {
      position: absolute;
      right: 8px;
      bottom: 8px;
    }

    &-button {
      &--green {
        color: var(--BrandDefault);
      }
    }
  }

  &__content {
    @include regular\14\24\025;

    flex: 1;
    margin: 0 8px 4px 0;
    color: var(--TextPrimary);

    #{$self}--no-icon & {
      margin: 0 8px;
    }

    #{$self}--titled & {
      &--indent {
        margin-bottom: 44px;
      }
    }
  }

  &__title {
    @include medium\18\24;

    margin: 4px 0;
    color: var(--TextDefault);
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: 16px 32px 8px;
  }

  &--centered {
    .hint-block {
      &__wrapper {
        justify-content: center;
      }

      &__default-label {
        max-width: 100%;
      }

      &__labels {
        flex: none;
      }
    }
  }

  &__footer--width-wide {
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
  }

  @include is-app-layout-desktop {
    &__footer--width-normal {
      justify-content: flex-start;
      width: 311px;
      padding-right: 0;
    }

    &__action-button--green {
      &:hover:not([disabled]) {
        color: var(--BrandHighlight);
      }
    }
  }
}
</style>
