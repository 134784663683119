import type { ComputedRef } from 'vue';
import { computed, toRef } from 'vue';

import { useI18n } from '@core/i18n';

import { useAccumulatorBoostStore } from 'web/src/modules/slip/submodules/accumulator-boost/store';
import { getWinBoostPercentageString } from 'web/src/modules/slip/submodules/accumulator-boost/utils';

interface UseWinInfoRoute {
  isWinInfoRouteEnabled: ComputedRef<boolean>;
  winInfoRouteCaption: ComputedRef<string>;
}

export function useWinInfoRoute(): UseWinInfoRoute {
  const { $translate } = useI18n();
  const accumulatorBoostStore = useAccumulatorBoostStore();

  const maxAccumulatorBoostMultiplier = toRef(accumulatorBoostStore, 'maxAccumulatorBoostMultiplier');
  const accumulatorBoostEnabled = toRef(accumulatorBoostStore, 'accumulatorBoostEnabled');
  const isWinBoost = toRef(accumulatorBoostStore, 'isWinBoost');

  const isWinInfoRouteEnabled = computed<boolean>(() => accumulatorBoostEnabled.value && isWinBoost.value);

  const headerTranslateParams = computed<Record<string, string>>(() => ({
    max: getWinBoostPercentageString(maxAccumulatorBoostMultiplier.value),
  }));

  const winInfoRouteCaption = computed<string>(() => $translate('WEB2_WIN_BOOST_INFO_MODAL_CAPTION', headerTranslateParams).value);

  return {
    isWinInfoRouteEnabled,
    winInfoRouteCaption,
  };
}
