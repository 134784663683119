import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { PromoJackpot } from '@leon-hub/api-sdk';
import { getPromoJackpots } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

const useJackpotBannerStore = defineStore('jackpot-banners', () => {
  const api = useGraphqlClient();

  const jackpots = ref<PromoJackpot[]>([]);

  async function getBannerJackpotsState(id: string, providerId: string) {
    const response = await getPromoJackpots(
      api,
      (node) => node.queries.externalGames.getPromoJackpots,
      {
        options: {
          ids: [
            {
              id,
              providerId,
            },
          ],
        },
      },
      { silent: true },
    );

    if (response) {
      jackpots.value.push(...response.jackpots);
    }
  }

  return {
    jackpots,
    getBannerJackpotsState,
  };
});

export default useJackpotBannerStore;
