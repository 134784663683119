import type { SportEventLiveStatusInfo } from 'web/src/modules/sportline/types';
import type { LiveStatusResponse } from 'web/src/modules/sportline/types/rest';
import { MatchProgressTimeDirection } from 'web/src/modules/sportline/enums';
import { LiveStatusTimeDirection } from 'web/src/modules/sportline/enums/rest';

import parseScore from './parseScore';

export default class LiveStatusParseStrategy {
  constructor(
    protected liveStatus: LiveStatusResponse,
  ) {}

  getMainTime(): SportEventLiveStatusInfo['matchProgress']['mainTime'] {
    const { progress } = this.liveStatus;

    if (!progress || !/^[^']+'$/.test(progress)) {
      return undefined;
    }

    const rawProgress = progress.replace('\'', '') || '';
    const [mainTime] = rawProgress.split('+');
    const result = mainTime ? parseInt(mainTime, 10) : 0;

    return {
      minutes: Number.isNaN(result) ? 0 : result,
      seconds: 0,
    };
  }

  getAdditionalTime(): SportEventLiveStatusInfo['matchProgress']['additionalTime'] {
    const { progress } = this.liveStatus;

    if (!progress || !/^[^']+'$/.test(progress)) {
      return undefined;
    }

    const rawProgress = progress.replace('\'', '') || '';
    const [, additionalTime] = rawProgress.split('+');

    if (!additionalTime) {
      return undefined;
    }

    const result = additionalTime ? parseInt(additionalTime, 10) : 0;

    return {
      minutes: Number.isNaN(result) ? 0 : result,
      seconds: 0,
    };
  }

  getTimeDirection(): MatchProgressTimeDirection {
    // Get direction info from fullProgress for LEONWEB-3935
    // This info may be empty for default strategy
    const { fullProgress } = this.liveStatus;

    if (!fullProgress) {
      return MatchProgressTimeDirection.None;
    }

    if (fullProgress.timeDirection === LiveStatusTimeDirection.Reverse) {
      return MatchProgressTimeDirection.ReversedNone;
    }

    return MatchProgressTimeDirection.None;
  }

  getCurrentSetScore(): SportEventLiveStatusInfo['matchProgress']['currentSetScore'] {
    const { progress } = this.liveStatus;
    const parsedScore = parseScore(progress);

    if (!parsedScore) {
      return undefined;
    }

    const [host, guest] = parsedScore.parts;

    return host && guest
      ? {
          host,
          guest,
          delimiter: parsedScore.delimiter,
        }
      : undefined;
  }

  getTotalScore(): SportEventLiveStatusInfo['matchProgress']['totalScore'] {
    const [host, guest] = parseScore(this.liveStatus.score)?.parts || [];

    return {
      host: host?.replace('*', '') || '',
      guest: guest?.replace('*', '') || '',
    };
  }

  getInnings(): SportEventLiveStatusInfo['matchProgress']['innings'] {
    const [host, guest] = parseScore(this.liveStatus.score)?.parts || [];

    return {
      host: !!host?.includes('*'),
      guest: !!guest?.includes('*'),
    };
  }

  getTotalScoreDetails(): SportEventLiveStatusInfo['matchProgress']['totalScoreDetails'] {
    const setScores = (this.liveStatus.setScores || '')
      .split(';')
      .map((part) => parseScore(part)?.parts || []);

    return {
      host: setScores.map((scores) => scores[0]).filter((value) => !!value),
      guest: setScores.map((scores) => scores[1]).filter((value) => !!value),
    };
  }

  getPenaltyScore(): SportEventLiveStatusInfo['matchProgress']['penaltyScore'] {
    const [host, guest] = parseScore(this.liveStatus.penaltyScore)?.parts || [];

    return host && guest
      ? {
          host: host.replace('*', ''),
          guest: guest.replace('*', ''),
        }
      : undefined;
  }

  getStage(): SportEventLiveStatusInfo['matchProgress']['stage'] {
    return this.liveStatus.stage || '';
  }
}
