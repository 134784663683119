import type { VCircularProgressProps } from '../../types';
import { CircleColor, CircleFilterShadows } from '../../enums';

export function getFilterShadowColorMatrix(
  props: Pick<VCircularProgressProps, 'circleColor'>,
): CircleFilterShadows {
  switch (props.circleColor) {
    case CircleColor.YELLOW:
      return CircleFilterShadows.YELLOW;
    case CircleColor.PURPLE:
      return CircleFilterShadows.PURPLE;
    case CircleColor.RED:
      return CircleFilterShadows.RED;
    default:
      return CircleFilterShadows.DEFAULT;
  }
}
