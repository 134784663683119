import type { SlipEntryId } from '../../slip-info/types';
import type { MakeBetResultItem } from '../types';
import { getSlipEntryId } from '../../../utils';

export function isTotalHandicapReplacedInBetResult(result: MakeBetResultItem, selectedEntriesIdsSet: Set<SlipEntryId>): boolean {
  /** Can be replaced via Set.difference in the future */
  const resultEntriesIds: SlipEntryId[] = result.slipInfo?.entries.map((entry) => getSlipEntryId(entry)) ?? [];
  const replacedItemsIds: SlipEntryId[] = resultEntriesIds.filter((id) => !selectedEntriesIdsSet.has(id));
  return !!replacedItemsIds.length;
}
