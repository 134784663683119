import type { VCircularProgressProps } from '../../types';

export function getRelativeSafePercentage(
  props: Pick<VCircularProgressProps, 'percentage'>,
): number {
  if (!props.percentage) {
    return 0;
  }

  if (props.percentage >= 100) {
    return 110;
  }
  if (props.percentage >= 98) {
    return 98;
  }
  if (props.percentage <= 0) {
    return 0;
  }
  return props.percentage;
}
