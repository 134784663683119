import type { BetInfoObject, SlipEntryId, SlipListItem } from '../types';
import getSlipEntryId from '../../../utils/getSlipEntryId';
import { getSafeCompetitorsValue } from './getSafeCompetitorsValue';

export default function convertSlipListItemToBetsInfoObject(slipListItem: SlipListItem): BetInfoObject {
  const id: SlipEntryId = getSlipEntryId(slipListItem);
  const {
    competitors,
    runnerName,
    marketName,
    eventName,
    odds,
    oddsStr,
    isPrimaryMarket,
  } = slipListItem;
  return {
    [id]: {
      competitors: getSafeCompetitorsValue(competitors),
      runnerName: runnerName ?? '',
      marketName: marketName ?? '',
      eventName: eventName ?? undefined,
      originalOdds: odds,
      originalOddsStr: oddsStr ?? undefined,
      isPrimaryMarket: isPrimaryMarket ?? false,
    },
  };
}
