<script setup lang="ts">
import type {
  CustomBannerButtonEmits,
  CustomBannerButtonProps,
} from './types';

defineProps<CustomBannerButtonProps>();
const emit = defineEmits<CustomBannerButtonEmits>();
</script>

<template>
  <a v-auto-id="'CustomBannerButton'"
    :class="{
      [$style['custom-banner-button']]: true,
      [$style['custom-banner-button--big']]: isBig,
      [$style['custom-banner-button--full-width']]: isFullWidth,
    }"
    :href="href"
    @click="emit('click', $event)"
  >
    {{ text }}
  </a>
</template>

<style module lang="scss">
@include for-layout using($isDesktop) {
  .custom-banner-button {
    @include medium\13\16;

    display: inline-block;
    height: 40px;
    padding: 12px;
    color: var(--DLayer1);
    text-transform: uppercase;
    text-decoration: none;
    background-color: var(--DTextDefault);
    border-radius: 5px;
    transition: box-shadow 0.2s ease;

    @if $isDesktop {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5), 0 1px 2px 0 rgba(0, 0, 0, 0.3);

      &:hover {
        box-shadow: 0 0 12px var(--White);
      }
    }

    &--big {
      @include medium\14\16\025;

      height: 44px;
      padding: 14px 16px;
    }

    &--full-width {
      width: 100%;
    }
  }
}
</style>
