import { assert } from '@leon-hub/guards';

import type { MultiSinglesMetaInfo } from '../../multi-singles/types';
import type { CombinedSlipEntryInfo, SlipEntryId } from '../types';
import isSlipEntryId from '../../../guards/isSlipEntryId';

interface Payload {
  currentMetaInfo: MultiSinglesMetaInfo;
  balance: number;
  multiSinglesAvailableForBet: CombinedSlipEntryInfo[];
  lastModifiedId: SlipEntryId | null;
}

export default function getMetaInfoWithBalanceCheck({
  multiSinglesAvailableForBet,
  currentMetaInfo,
  balance,
  lastModifiedId,
}: Payload): MultiSinglesMetaInfo {
  const metaInfoCopy: MultiSinglesMetaInfo = {};
  for (const id of Object.keys(currentMetaInfo)) {
    assert(isSlipEntryId(id));
    metaInfoCopy[id] = {
      ...currentMetaInfo[id],
      stakeOverBalance: false,
    };
  }
  let totalPrice = 0;
  let highestValue = 0;
  let entryWithHighestStake;
  for (const entry of multiSinglesAvailableForBet) {
    const stakeValue = Number(entry.metaInfo?.stakeValue || '0');
    totalPrice += stakeValue;
    if (stakeValue >= highestValue) {
      highestValue = stakeValue;
      entryWithHighestStake = entry.id;
    }
  }
  const stakeOverBalance = balance !== null && totalPrice > balance;
  if (stakeOverBalance) {
    const entryToShowError = lastModifiedId ?? entryWithHighestStake;
    assert(isSlipEntryId(entryToShowError));
    metaInfoCopy[entryToShowError] = { ...currentMetaInfo[entryToShowError], stakeOverBalance: true };
  }
  return metaInfoCopy;
}
