import { toRef, ref, computed, watch } from "vue";
import { defineStore } from "pinia";
import { useIsLoggedIn } from "@core/auth";
import { usePaymentsConfig, useWebSocketsConfig } from "@core/site-config";
import { useI18nStore } from "@core/i18n";
import { useRootStore } from "@core/root";
import { logger } from "@leon-hub/logging";
import { assert, isString, isNumber } from "@leon-hub/guards";
import { ZetaDspService } from "@leon-hub/metrics";
import { AccountType } from "@leon-hub/websocket";
import { Events } from "@leon-hub/yandex-metrika";
import { useAnalytics, useGoogleAnalytics } from "@core/analytics";
import { useAppsflyer } from "@core/appsflyer";
import { useCustomerDataStore } from "@core/customer";
import { useThemeStore } from "@core/theme";
import { useWebSockets } from "@core/websockets";
import { getBalance, doAppsFlyerQualifiedDepositsReported } from "@leon-hub/api-sdk";
import { useGraphqlClient } from "@core/app-rest-client";
import { IconName, isIconNameKey } from "@leon-hub/icons";
var Currency = /* @__PURE__ */ ((Currency2) => {
  Currency2["EUR"] = "EUR";
  Currency2["RUB"] = "RUB";
  Currency2["USD"] = "USD";
  Currency2["UAH"] = "UAH";
  Currency2["KZT"] = "KZT";
  Currency2["PLN"] = "PLN";
  Currency2["GBP"] = "GBP";
  Currency2["SEK"] = "SEK";
  Currency2["NOK"] = "NOK";
  Currency2["CHF"] = "CHF";
  Currency2["BYN"] = "BYN";
  Currency2["DKK"] = "DKK";
  Currency2["TRY"] = "TRY";
  Currency2["ARS"] = "ARS";
  Currency2["MXN"] = "MXN";
  Currency2["BRL"] = "BRL";
  Currency2["CLP"] = "CLP";
  Currency2["UYU"] = "UYU";
  Currency2["PEN"] = "PEN";
  Currency2["COP"] = "COP";
  Currency2["CZK"] = "CZK";
  Currency2["BSF"] = "BSF";
  Currency2["CNY"] = "RMB";
  Currency2["HKD"] = "HKD";
  Currency2["TWD"] = "TWD";
  Currency2["INR"] = "INR";
  Currency2["JPY"] = "JPY";
  Currency2["AZN"] = "AZN";
  Currency2["UZS"] = "UZS";
  Currency2["CAD"] = "CAD";
  Currency2["TJS"] = "TJS";
  Currency2["KGS"] = "KGS";
  Currency2["TMT"] = "TMT";
  Currency2["MDL"] = "MDL";
  Currency2["AUD"] = "AUD";
  Currency2["NZD"] = "NZD";
  Currency2["VND"] = "VND";
  Currency2["THB"] = "THB";
  Currency2["BTC"] = "BTC";
  Currency2["LTC"] = "LTC";
  Currency2["ETH"] = "ETH";
  Currency2["KHQ"] = "KHQ";
  Currency2["LCC"] = "LCC";
  Currency2["TZS"] = "TZS";
  Currency2["USDT"] = "USDT";
  Currency2["USDC"] = "USDC";
  return Currency2;
})(Currency || {});
const defaultCurrency = /* @__PURE__ */ (() => process.env.VUE_APP_PRODUCT_LEONRU)() ? "RUB" : "USD";
const defaultCurrencyLocale = /* @__PURE__ */ (() => process.env.VUE_APP_PRODUCT_LEONRU)() ? "ru" : "en";
const dollarSign = "$";
const kroneSign = "kr";
const bonusCurrencySign = /* @__PURE__ */ (() => process.env.VUE_APP_PRODUCT_SLOTT)() ? "XP" : "L";
const bonusCurrency = "L";
const bonusLocale = "en-US";
const currencySymbols = /* @__PURE__ */ Object.freeze({
  [/* @__PURE__ */ (() => Currency.EUR)()]: "€",
  [/* @__PURE__ */ (() => Currency.RUB)()]: "₽",
  [/* @__PURE__ */ (() => Currency.USD)()]: dollarSign,
  [/* @__PURE__ */ (() => Currency.KZT)()]: "₸",
  [/* @__PURE__ */ (() => Currency.UAH)()]: "₴",
  [/* @__PURE__ */ (() => Currency.PLN)()]: "zł",
  [/* @__PURE__ */ (() => Currency.GBP)()]: "£",
  [/* @__PURE__ */ (() => Currency.SEK)()]: kroneSign,
  [/* @__PURE__ */ (() => Currency.NOK)()]: kroneSign,
  [/* @__PURE__ */ (() => Currency.DKK)()]: kroneSign,
  [/* @__PURE__ */ (() => Currency.BYN)()]: "руб",
  [/* @__PURE__ */ (() => Currency.TRY)()]: "₺",
  [/* @__PURE__ */ (() => Currency.BRL)()]: "R$",
  [/* @__PURE__ */ (() => Currency.ARS)()]: dollarSign,
  [/* @__PURE__ */ (() => Currency.MXN)()]: dollarSign,
  [/* @__PURE__ */ (() => Currency.CLP)()]: dollarSign,
  [/* @__PURE__ */ (() => Currency.UYU)()]: dollarSign,
  [/* @__PURE__ */ (() => Currency.COP)()]: dollarSign,
  [/* @__PURE__ */ (() => Currency.HKD)()]: dollarSign,
  [/* @__PURE__ */ (() => Currency.PEN)()]: "S/",
  [/* @__PURE__ */ (() => Currency.CZK)()]: "Kč",
  [/* @__PURE__ */ (() => Currency.BSF)()]: "B$",
  [/* @__PURE__ */ (() => Currency.CNY)()]: "¥",
  [/* @__PURE__ */ (() => Currency.JPY)()]: "¥",
  [/* @__PURE__ */ (() => Currency.TWD)()]: "NT$",
  [/* @__PURE__ */ (() => Currency.INR)()]: "₹",
  [/* @__PURE__ */ (() => Currency.BTC)()]: "₿",
  [/* @__PURE__ */ (() => Currency.LTC)()]: "Ł",
  [/* @__PURE__ */ (() => Currency.ETH)()]: "Ξ",
  [/* @__PURE__ */ (() => Currency.AZN)()]: "₼",
  [/* @__PURE__ */ (() => Currency.UZS)()]: "UZS",
  [/* @__PURE__ */ (() => Currency.CAD)()]: dollarSign,
  [/* @__PURE__ */ (() => Currency.TJS)()]: "SM",
  [/* @__PURE__ */ (() => Currency.KGS)()]: "с",
  [/* @__PURE__ */ (() => Currency.TMT)()]: "T",
  [/* @__PURE__ */ (() => Currency.MDL)()]: "L",
  [/* @__PURE__ */ (() => Currency.AUD)()]: "A$",
  [/* @__PURE__ */ (() => Currency.NZD)()]: "NZ$",
  [/* @__PURE__ */ (() => Currency.VND)()]: "đ",
  [/* @__PURE__ */ (() => Currency.THB)()]: "฿",
  [/* @__PURE__ */ (() => Currency.TZS)()]: "TZS",
  // bonus currency
  [/* @__PURE__ */ (() => Currency.KHQ)()]: bonusCurrencySign,
  L: bonusCurrencySign,
  [/* @__PURE__ */ (() => Currency.LCC)()]: bonusCurrencySign,
  [/* @__PURE__ */ (() => Currency.USDT)()]: "USDT",
  [/* @__PURE__ */ (() => Currency.USDC)()]: "USDC"
});
function getCurrencySymbol(currency) {
  const matchedSymbol = currencySymbols[currency];
  if (!matchedSymbol) {
    logger.warn(`getCurrencySymbol: unexpected currency value: ${currency}`);
  }
  return matchedSymbol || currency;
}
function isSupportedLocale(locale) {
  if (!locale)
    return false;
  try {
    1 .toLocaleString(locale);
    return true;
  } catch (error) {
    logger.error("Not supported locale, check formatMoneyLocale settings in language configuration.", error);
    return false;
  }
}
const useCurrencyStore = /* @__PURE__ */ defineStore("currency", () => {
  const rootStore = useRootStore();
  const formatMoneyLocale = toRef(rootStore, "formatMoneyLocale");
  const defaultUICurrency = toRef(rootStore, "defaultUICurrency");
  const i18nStore = useI18nStore();
  const { isLoggedIn } = useIsLoggedIn();
  const lang = toRef(i18nStore, "lang");
  const stateCurrency = ref(defaultCurrency);
  const currency = computed(() => (isLoggedIn.value ? stateCurrency.value : defaultUICurrency.value) || defaultCurrency);
  const formattedCurrency = computed(() => getCurrencySymbol(currency.value));
  const currencyLocale = computed(() => {
    const currentLang = lang.value.toLowerCase();
    const fallbackLocale = currentLang && isSupportedLocale(currentLang) ? currentLang : defaultCurrencyLocale;
    return formatMoneyLocale.value || fallbackLocale;
  });
  function setCurrency(value) {
    stateCurrency.value = value;
  }
  return {
    currency,
    formattedCurrency,
    currencyLocale,
    setCurrency
  };
});
function useCurrency() {
  const currency = toRef(useCurrencyStore(), "currency");
  return {
    currency
  };
}
const useCustomerFinanceStore = /* @__PURE__ */ defineStore("customer-finance", () => {
  const { isLoggedIn } = useIsLoggedIn();
  const { currency } = useCurrency();
  const { balanceButtonCurrencies, lowBalanceAmounts } = usePaymentsConfig();
  let lastModifiedAt = 0;
  const balance = ref(0);
  const formattedBalance = computed(() => balance.value.toFixed(2));
  const isAddBalanceButtonAsCurrency = computed(() => balanceButtonCurrencies.value.includes(currency.value));
  const isLowBalance = computed(() => {
    const amount = lowBalanceAmounts.value.find((item) => currency.value === item.currency);
    return !!amount && amount.value > balance.value;
  });
  function setBalance(value, lastModifiedValue) {
    if (lastModifiedValue > lastModifiedAt) {
      balance.value = value;
      lastModifiedAt = lastModifiedValue;
    }
  }
  function init() {
    watch(isLoggedIn, (newValue) => {
      if (!newValue) {
        lastModifiedAt = 0;
      }
    });
  }
  init();
  return {
    balance,
    formattedBalance,
    isAddBalanceButtonAsCurrency,
    isLowBalance,
    setBalance
  };
});
function useBalance() {
  const balance = toRef(useCustomerFinanceStore(), "balance");
  return {
    balance
  };
}
function shouldReduceMoneyAmount(amount, currency, amountLimit = 1e6) {
  return !!currency && [Currency.VND, Currency.UZS].includes(currency) && amount >= amountLimit;
}
function useFormatMoney() {
  const currencyStore = useCurrencyStore();
  const currencyLocale = toRef(() => currencyStore.currencyLocale);
  const currency = toRef(() => currencyStore.currency);
  return (amount, inputOptions = {}) => {
    var _a;
    const options = { ...inputOptions };
    const defaultFractionDigits = Number.isInteger(amount) && amount !== 0 ? 0 : 2;
    const defaultCurrencyDisplayOption = "code";
    let formattedValue;
    const defaultOptions = {
      locale: currencyLocale.value,
      style: "currency",
      currency: currency.value,
      currencyDisplay: defaultCurrencyDisplayOption,
      minimumFractionDigits: defaultFractionDigits,
      maximumFractionDigits: defaultFractionDigits
    };
    const originalCurrency = (options == null ? void 0 : options.currency) ?? defaultOptions.currency;
    let workingOptions = { ...options };
    if ((options == null ? void 0 : options.currency) === bonusCurrency || ((_a = options.currency) == null ? void 0 : _a.length) !== 3) {
      const locale2 = (options == null ? void 0 : options.currency) === bonusCurrency ? bonusLocale : currencyLocale.value;
      workingOptions = {
        ...workingOptions,
        currency: "KHQ",
        locale: locale2
      };
    }
    const { locale, ...intlOptions } = workingOptions;
    const formatOptions = {
      ...defaultOptions,
      ...intlOptions
    };
    if (options.hideCurrency) {
      formatOptions.style = "decimal";
    }
    const { currency: customCurrency } = formatOptions;
    if (options.reduceAmount && shouldReduceMoneyAmount(amount, customCurrency, options.reduceAmount)) {
      const roundedCurrency = Math.round(amount / 1e3);
      formattedValue = roundedCurrency.toLocaleString(
        locale || currencyLocale.value,
        {
          style: "decimal"
        }
      );
      return `${formattedValue}K`;
    }
    formattedValue = amount.toLocaleString(
      locale || currencyLocale.value,
      formatOptions
    );
    if (originalCurrency === bonusCurrency) {
      formattedValue = formattedValue.replace(`${formatOptions.currency}`, "").trim();
      if (!options.hideCurrency) {
        formattedValue = `${formattedValue} ${formatOptions.currency}`;
      }
    }
    if (customCurrency && originalCurrency && formatOptions.currencyDisplay === defaultCurrencyDisplayOption && !options.hideCurrency) {
      formattedValue = formattedValue.replace(customCurrency, getCurrencySymbol(originalCurrency));
    }
    return formattedValue;
  };
}
function useCustomerFinanceApi() {
  const apiClient = useGraphqlClient();
  function getBalance$1(silent) {
    return getBalance(apiClient, (node) => node.queries.finance.getBalance, void 0, { silent });
  }
  return {
    getBalance: getBalance$1
  };
}
async function cordovaTrackDepositQualified({
  isAppsFlyerDepositQualified,
  login
}) {
  const api = useGraphqlClient();
  if (isAppsFlyerDepositQualified) {
    try {
      assert(isString(login), "login is not correct for cordovaTrackDepositQualified");
      void useAppsflyer().trackDepositQualified({ login });
      await doAppsFlyerQualifiedDepositsReported(
        api,
        (node) => node.mutations.finance.setDepositMetricsNotified,
        {
          options: {}
        }
      );
    } catch (error) {
      logger.info(`AppsFlyer trackDepositQualified: ${error}`);
    }
  }
}
const useCustomerBalanceUpdateStore = /* @__PURE__ */ defineStore("customer-balance-update", () => {
  const { setBalance } = useCustomerFinanceStore();
  const { isLoggedIn } = useIsLoggedIn();
  const { getBalance: getBalance2 } = useCustomerFinanceApi();
  const { subscribeAuthorized } = useWebSockets();
  const { isBalanceEnabled } = useWebSocketsConfig();
  const { push } = useAnalytics();
  const { pushGTM } = useGoogleAnalytics();
  const customerData = useCustomerDataStore();
  const currencyStore = useCurrencyStore();
  const currency = toRef(currencyStore, "currency");
  const socketPollingTimeout = ref(5e3);
  const themeStore = useThemeStore();
  const theme = toRef(themeStore, "theme");
  const firstTheme = toRef(themeStore, "firstTheme");
  const cbcBalanceFromPolling = ref({
    balance: 0,
    lastModifiedAt: 0
  });
  const cashbackFromPolling = ref(0);
  function sendMetrics(depositMetrics) {
    if (depositMetrics.length) {
      const {
        login,
        country,
        vipStatus,
        customerBetType,
        shortComments
      } = customerData;
      for (const metric of depositMetrics) {
        if (process.env.VUE_APP_PLATFORM_CORDOVA) {
          void useAppsflyer().trackDeposit({
            login,
            isFirstDeposit: metric.firstDeposit,
            method: metric.paymentSystemId,
            price: metric.systemAmount,
            country
          });
        }
        const zetaDepositMetrics = {
          systemAmount: metric.systemAmount,
          paymentId: metric.paymentId,
          paymentSystemId: metric.paymentSystemId.toLowerCase(),
          firstDeposit: metric.firstDeposit,
          userId: login,
          currency: currency.value,
          firstTheme: firstTheme.value,
          theme: theme.value,
          shortComments: shortComments.length ? shortComments[0] : void 0,
          country,
          vipStatus,
          customerBetType
        };
        makeDeposit(zetaDepositMetrics);
      }
    }
  }
  async function loadData(silent) {
    if (!isLoggedIn.value) {
      return;
    }
    const response = await getBalance2(silent);
    setBalance(response.balance, response.lastModifiedAt);
    currencyStore.setCurrency(response.currency);
    sendMetrics(response.depositMetrics);
    cbcBalanceFromPolling.value = {
      balance: response.cbcBalance,
      lastModifiedAt: response.lastModifiedAt
    };
    if (isNumber(response.cashbackBalance)) {
      cashbackFromPolling.value = response.cashbackBalance;
    }
  }
  function subscribeOnBalanceUpdate() {
    subscribeAuthorized({
      method: "onBalanceChange",
      onMessage: (data) => {
        const {
          walletTypeId,
          availableBalance,
          date
        } = data.onBalanceChange;
        switch (walletTypeId) {
          case AccountType.MAIN:
            setBalance(availableBalance, date);
            break;
          case AccountType.CBC:
            cbcBalanceFromPolling.value = {
              balance: data.onBalanceChange.availableBalance,
              lastModifiedAt: data.onBalanceChange.date
            };
            break;
          case AccountType.BETTING:
            break;
          default:
            logger.error(`Unsupported balance type: ${data.onBalanceChange.walletTypeId}`, data);
            break;
        }
      },
      isEnabled: isBalanceEnabled,
      polling: {
        timeout: socketPollingTimeout,
        callback: () => loadData()
      }
    });
  }
  function init() {
    subscribeOnBalanceUpdate();
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      const isAppsFlyerDepositQualified = toRef(customerData, "isAppsFlyerDepositQualified");
      watch(isAppsFlyerDepositQualified, (newValue) => {
        if (newValue) {
          void cordovaTrackDepositQualified({
            login: customerData.login,
            isAppsFlyerDepositQualified: newValue
          });
        }
      }, {
        immediate: true
      });
    }
  }
  function makeDeposit(depositMetrics) {
    push(Events.Z_MAKE_DEPOSITE, {
      deposits: {
        ok: {
          paymentSystemId: `dep${depositMetrics.paymentSystemId.toLowerCase()}`,
          firstDeposit: depositMetrics.firstDeposit
        }
      }
    });
    const {
      systemAmount,
      paymentId,
      userId,
      firstTheme: firstTheme2,
      theme: theme2
    } = depositMetrics;
    const googleMetricsData = {
      ...depositMetrics,
      amount: systemAmount,
      transactionId: paymentId
    };
    if (depositMetrics.firstDeposit) {
      pushGTM(Events.FIRST_DEPOSIT, googleMetricsData);
      push(Events.Z_AB_TEST_NEW_USERS, {
        ab_test_light_mode: {
          [firstTheme2.toLowerCase()]: {
            first_deposit: theme2.toLowerCase()
          }
        }
      });
      ZetaDspService.getInstance().firstDeposit({ systemAmount, paymentId, userId });
    } else {
      pushGTM(Events.MAKE_DEPOSIT, googleMetricsData);
      ZetaDspService.getInstance().makeDeposit({ systemAmount, paymentId, userId });
    }
  }
  void init();
  return {
    cbcBalanceFromPolling,
    cashbackFromPolling,
    loadData
  };
});
function getCurrencyAbbrForIcon(currency) {
  switch (currency) {
    case Currency.SEK:
    case Currency.NOK:
    case Currency.DKK:
      return Currency.DKK;
    case Currency.ARS:
    case Currency.MXN:
    case Currency.CLP:
    case Currency.UYU:
    case Currency.COP:
    case Currency.HKD:
    case Currency.CAD:
      return Currency.USD;
    default:
      return currency;
  }
}
function getCurrencyIcon(currency) {
  const fallbackIcon = IconName.COIN_USD;
  if (!currency) {
    return fallbackIcon;
  }
  const nameByCurrency = `COIN_${getCurrencyAbbrForIcon(currency)}`;
  if (nameByCurrency in IconName) {
    assert(isIconNameKey(nameByCurrency));
    return IconName[nameByCurrency];
  }
  return fallbackIcon;
}
export {
  Currency,
  bonusCurrency,
  defaultCurrency,
  getCurrencyIcon,
  getCurrencySymbol,
  shouldReduceMoneyAmount,
  useBalance,
  useCurrency,
  useCurrencyStore,
  useCustomerBalanceUpdateStore,
  useCustomerFinanceStore,
  useFormatMoney
};
