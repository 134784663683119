export enum CircleBackground {
  DEFAULT = 'default',
  COLORED = 'colored',
  WHITE = 'white',
}

export enum CircleColor {
  DEFAULT = 'default',
  PURPLE = 'purple',
  YELLOW = 'yellow',
  GREEN = 'green',
  WHITE = 'white',
  LIGHT_BLUE = 'light-blue',
  RED = 'red',
}

export enum CircleFilterShadows {
  DEFAULT = '0 0 0 0 0.121569 0 0 0 0 0.678431 0 0 0 0 0.4 0 0 0 0.5 0',
  YELLOW = '0 0 0 0 0.85098 0 0 0 0 0.619608 0 0 0 0 0.0313726 0 0 0 0.5 0',
  PURPLE = '0 0 0 0 0.392157 0 0 0 0 0.282353 0 0 0 0 1 0 0 0 0.5 0',
  RED = '0 0 0 0 0.839216 0 0 0 0 0.0862745 0 0 0 0 0.105882 0 0 0 0.5 0',
}

export enum CircleSize {
  MINI = 'mini',
  DEFAULT = 'default',
  MEDIUM = 'medium',
  BIG = 'big',
  SIZE_32 = 'size-32',
  SIZE_40 = 'size-40',
  SIZE_42 = 'size-42',
  SIZE_44 = 'size-44',
  SIZE_80 = 'size-80',
  SIZE_92 = 'size-92',
  SIZE_94 = 'size-94',
  SIZE_112 = 'size-112',
  SIZE_152 = 'size-152',
  SIZE_180 = 'size-180',
  SIZE_218 = 'size-218',
  SIZE_S = 'size-s',
  SIZE_M = 'size-m',
  SIZE_L = 'size-l',
}
