/**
 * @deprecated should use backend string related to user selected format PROJ-395
 * currently under isOddsFormatSwitcherEnabled flag
 * returns odds in "1.20" format
 * @param input [float] - raw odd value
 * */
function formatOdd (input: number): string {
  const [beforeDot, afterDot] = `${input}`.split('.');
  const getDecimals = () => {
    if (!afterDot) return '00';
    if (afterDot.length === 1) return `${afterDot}0`;
    return afterDot.slice(0, 2);
  };
  return `${beforeDot}.${getDecimals()}`;
}

export default formatOdd;
