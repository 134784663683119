import { normalizeError } from '@leon-hub/errors';
import { isNotNull } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';

import type { Sport } from 'web/src/modules/sportline/types';
import type {
  BuildSportOptions,
  CoreSportResponse,
  GetSportsLeagueResponse,
  GetSportsRegionResponse,
  GetSportsResponse,
  GetSportsSportResponse,
  SportsTreeLeagueElement,
  SportsTreeRegionElement,
  SportsTreeSportElement,
} from 'web/src/modules/sportline/types/rest';
import {
  createSportEventsCounters,
  reduceListWithSportEventsCounters,
} from 'web/src/modules/sportline/utils';
import {
  parseLeagueResponse,
  parseRegionResponse,
  parseSportResponse,
} from 'web/src/modules/sportline/utils/rest/build';
import {
  extractBuildSportOptions,
} from 'web/src/modules/sportline/utils/rest/pre-build';

interface SportListAdapterFilters {
  sport?: ((sport: GetSportsSportResponse) => boolean)[];
  region?: ((region: GetSportsRegionResponse) => boolean)[];
  league?: ((league: GetSportsLeagueResponse) => boolean)[];
}

type SportsListAdapterOptions = BuildSportOptions & { responseFilters?: SportListAdapterFilters };

/**
 * Used to prepare standard sport lists
 * like prematch page (sports) and sidebar, filters
 */
export class SportsListAdapter {
  // @TODO merge with parseLeagueResponse
  private static createLeagueElement(options: {
    rawSport: GetSportsSportResponse;
    rawRegion: GetSportsRegionResponse;
    rawLeague: GetSportsLeagueResponse;
  } & BuildSportOptions): Maybe<SportsTreeLeagueElement> {
    let leagueElement: Maybe<SportsTreeLeagueElement> = null;
    const {
      rawSport,
      rawRegion,
      rawLeague,
    } = options;

    try {
      const { region } = parseRegionResponse({
        ...options,
        sportResponse: rawSport,
        regionResponse: rawRegion,
      });
      const { league } = parseLeagueResponse({
        ...options,
        sportResponse: rawSport,
        regionResponse: rawRegion,
        leagueResponse: rawLeague,
      });

      // @TODO check counters generation
      leagueElement = {
        league,
        region,
        counters: createSportEventsCounters(rawLeague.inplay, rawLeague.prematch, 0, rawLeague.outright),
      };
    } catch (rawError) {
      const error = normalizeError(rawError);

      logger.error(error);
    }

    return leagueElement;
  }

  private static createSport(response: CoreSportResponse, buildSportOptions: BuildSportOptions): Maybe<Sport> {
    try {
      const rawSport = parseSportResponse(response, buildSportOptions);
      return rawSport?.sport ?? null;
    } catch (rawError) {
      const error = normalizeError(rawError);
      logger.error(error);
      return null;
    }
  }

  constructor(
    private readonly response: Readonly<GetSportsResponse>,
    private readonly options: SportsListAdapterOptions = extractBuildSportOptions(),
  ) {}

  // @TODO fix complexity
  getSports(): SportsTreeSportElement[] {
    const responseFilters = this.options?.responseFilters ?? {};

    return this.response.map<SportsTreeSportElement | null>((rawSport) => {
      const isCorrectSport = (responseFilters.sport ?? []).every((filter) => filter(rawSport));

      if (!isCorrectSport) {
        return null;
      }

      let leaguesCount = 0;

      const regions: SportsTreeRegionElement[] = rawSport.regions.map((rawRegion): Maybe<SportsTreeRegionElement> => {
        const isCorrectRegion = (responseFilters.region ?? []).every((filter) => filter(rawRegion));

        if (!isCorrectRegion) {
          return null;
        }

        const leagues: SportsTreeLeagueElement[] = rawRegion.leagues.map<SportsTreeLeagueElement | null>((rawLeague) => {
          const isCorrectLeague = (responseFilters.league ?? []).every((filter) => filter(rawLeague));

          if (!isCorrectLeague) {
            return null;
          }

          return SportsListAdapter.createLeagueElement({
            ...this.options,
            rawSport,
            rawRegion,
            rawLeague,
          });
        }).filter(isNotNull);

        leaguesCount += leagues.length;

        const { region } = parseRegionResponse({
          ...this.options,
          sportResponse: rawSport,
          regionResponse: rawRegion,
        });

        return region
          ? {
              region,
              leagues,
              counters: reduceListWithSportEventsCounters(leagues),
            }
          : null;
      }).filter(isNotNull);

      const buildSportOptions = extractBuildSportOptions(this.options);
      const sport = SportsListAdapter.createSport(rawSport, buildSportOptions);

      return sport
        ? {
            sport,
            leaguesCount,
            regions,
            counters: reduceListWithSportEventsCounters(regions),
          }
        : null;
    }).filter(isNotNull);
  }
}
