export enum ListType {
  GROUP = 'group',
  SEPARATE = 'separate',
}

export enum ListItemSuffixAlign {
  TOP = 'top',
  CENTER = 'center',
}

export enum ListItemAccordionKind {
  DEFAULT = 'default',
  PROMO = 'promo',
}

export enum ListItemExtendedIconBackground {
  ORANGE = 'orange',
  MINT = 'mint',
  PINK = 'pink',
  BLUE = 'blue',
  YELLOW = 'yellow',
  RED = 'red',
}

export enum ListItemExtendedKind {
  ICON = 'icon',
  ICON_WITH_BACKGROUND = 'icon-with-background',
  LOGO = 'logo',
  NOTIFICATION = 'notification',
  CHECKBOX = 'checkbox',
}

export enum ListItemExtendedSubtitleColor {
  DEFAULT = 'default',
  BRAND = 'brand',
  PRIMARY = 'primary',
}
