import { defineStore } from "pinia";
import { toRef, ref, computed, watch } from "vue";
import { verifyEmail, getUserProfileBonus, getUserData, doChangeCustomerNickname, setAdditionalPropsUser, doMarkDeviceUntrusted, resendVerificationEmail, getConsents, doUpdateNotificationConsents, doChangePassword, logout, doLoginPinCode, RequestOptionsPriority, doLoginFingerprint, doLoginG2sv, doLoginCas, doLogin, doModifyTimeZone, loginTypeEmail, loginTypePhone, loginTypeAccount } from "@leon-hub/api-sdk";
import { setAuthData } from "@leon-hub/cordova";
import { useEventsBus, BusEvent } from "@leon-hub/event-bus";
import { isObject, assert, isNumber, isString } from "@leon-hub/guards";
import { useLocalStorageManager } from "@leon-hub/local-storage";
import { logger } from "@leon-hub/logging";
import { roundToDivisible } from "@leon-hub/utils";
import { useAppEmitter, startLoginDeviceRoutineEventType } from "@core/app-emitter";
import { useGraphqlClient } from "@core/app-rest-client";
import { ServerDate } from "@core/app-settings";
import { useAuthStore, IS_CLEAR_PASSWORD_KEY } from "@core/auth";
import { useCustomerDataStore } from "@core/customer";
import { useErrorsConverter } from "@core/errors";
import { useI18nLanguage } from "@core/i18n";
import { useCustomerFinanceStore, useCurrencyStore } from "@core/money";
import { captchaChallengeWasShownKey, DeviceCustomerLoginStorage, getDeferredDeviceIDStorage, LOCAL_STORAGE_LOGIN_TAB_KEY } from "@core/storage";
import { useSyncState } from "@core/sync-state";
import { Events } from "@leon-hub/yandex-metrika";
import { useAnalytics, useGoogleAnalytics } from "@core/analytics";
import { useThemeStore } from "@core/theme";
var LoginMethod = /* @__PURE__ */ ((LoginMethod2) => {
  LoginMethod2["DEFAULT"] = "Default";
  LoginMethod2["CAS"] = "CAS";
  LoginMethod2["G2SV"] = "Two-factor authentication";
  LoginMethod2["FINGERPRINT"] = "Fingerprint";
  return LoginMethod2;
})(LoginMethod || {});
function analyticsLogLogin(options, customerData) {
  const { yandexZInit, push } = useAnalytics();
  const { pushGTM } = useGoogleAnalytics();
  const optionsData = {
    loginMethod: options.loginMethod,
    captchaType: options.payload.captchaType,
    loginTab: options.payload.loginType ?? "default",
    customerBetType: customerData == null ? void 0 : customerData.customerBetType,
    shortComments: (customerData == null ? void 0 : customerData.shortComments.length) ? customerData == null ? void 0 : customerData.shortComments[0] : void 0,
    event: "login"
  };
  yandexZInit({
    userId: (customerData == null ? void 0 : customerData.customerLogin) ?? "",
    customerType: customerData == null ? void 0 : customerData.vipStatus,
    country: customerData == null ? void 0 : customerData.countryName,
    currentTheme: useThemeStore().theme
  });
  pushGTM(Events.LOGIN_EVENT, { ...optionsData });
  push(Events.Z_LOGGED_IN, {
    login: {
      ok: {
        [optionsData.loginTab]: {
          captchaChallengeWasShown: window.sessionStorage.getItem(captchaChallengeWasShownKey),
          captcha: optionsData.captchaType,
          loginType: optionsData.loginMethod
        }
      }
    }
  });
}
const localStorageCustomerTimezoneOffsetKey = "customerTimezoneOffset";
const useUserStore = /* @__PURE__ */ defineStore("user", () => {
  const api = useGraphqlClient();
  const bus = useEventsBus();
  const localStorage = useLocalStorageManager();
  const authStore = useAuthStore();
  const { setLoggedIn } = authStore;
  const isLoggedIn = toRef(authStore, "isLoggedIn");
  const { convertToBaseError } = useErrorsConverter();
  const customerDataStore = useCustomerDataStore();
  const {
    setCustomerData,
    setupCustomerData,
    updateCustomerData,
    loadCustomerPhone
  } = useCustomerDataStore();
  const { setBalance } = useCustomerFinanceStore();
  const { setCurrency } = useCurrencyStore();
  const isEmailConfirmed = toRef(customerDataStore, "isEmailConfirmed");
  const userLanguageTag = toRef(customerDataStore, "userLanguageTag");
  const customerData = toRef(customerDataStore, "customerData");
  const profileData = ref(null);
  const pinCodeData = ref(null);
  const consents = ref({});
  const confirmationLinkWasSent = ref(false);
  const tooManyConfirmationLinkRequests = ref(false);
  const loginOptionsCAS = ref(null);
  const isBonusTermsAccepted = computed(() => {
    var _a;
    return ((_a = profileData.value) == null ? void 0 : _a.bonusTermsAccepted) ?? false;
  });
  const inGameValue = computed(() => {
    var _a;
    return ((_a = profileData.value) == null ? void 0 : _a.waitingBetsAmount) || 0;
  });
  const safeUserConsents = computed(() => {
    if (isEmailConfirmed.value) {
      return consents.value;
    }
    return {
      ...consents.value,
      email: false
    };
  });
  const loadUserBonusData = async (silent) => {
    const response = await getUserProfileBonus(
      api,
      (node) => node.queries.customer.getProfileData,
      { options: {} },
      { ...isObject(silent) ? silent : { silent } }
    );
    if (response == null ? void 0 : response.profileData) {
      profileData.value = response.profileData;
    }
  };
  const getUserDataQuery = (silent) => Promise.all([
    loadCustomerData(silent),
    loadUserBonusData(silent)
  ]);
  const loadUserData = async (silent) => {
    await getUserDataQuery(silent);
  };
  const doLogin$1 = async (options) => {
    const { loginMethod } = options;
    let loginMutation;
    api.clearQueue();
    const requestOptions = {
      priority: RequestOptionsPriority.HIGH,
      timeout: 6e4
    };
    switch (options.loginMethod) {
      case LoginMethod.DEFAULT:
        loginMutation = doLogin(api, (node) => node.mutations.auth.login, {
          options: options.payload
        }, requestOptions);
        break;
      case LoginMethod.CAS:
        loginOptionsCAS.value = options;
        loginMutation = doLoginCas(
          api,
          (node) => node.mutations.auth.loginSso,
          {
            options: options.payload
          },
          requestOptions
        );
        break;
      case LoginMethod.G2SV:
        loginMutation = doLoginG2sv(api, (node) => node.mutations.auth.loginG2sv, {
          options: options.payload
        }, requestOptions);
        break;
      case LoginMethod.FINGERPRINT:
        loginMutation = doLoginFingerprint(api, (node) => node.mutations.auth.loginFingerprint, {
          options: options.payload
        }, requestOptions);
        break;
      default:
        throw new Error(`Unknown loginMethod=${loginMethod}`);
    }
    await Promise.all([
      loginMutation,
      getUserDataQuery(requestOptions)
    ]);
    if (options.payload.loginType) {
      localStorage.setItem(LOCAL_STORAGE_LOGIN_TAB_KEY, options.payload.loginType);
    }
    if (process.env.VUE_APP_PLATFORM_CORDOVA && options.loginMethod === LoginMethod.DEFAULT) {
      await setAuthData({
        login: options.payload.username,
        password: options.payload.password
      });
      localStorage.removeItem(IS_CLEAR_PASSWORD_KEY);
    }
    if (userLanguageTag.value) {
      await useI18nLanguage().setLanguageTag({
        languageTag: userLanguageTag.value,
        reload: true
      });
    }
    setLoggedIn(true);
    analyticsLogLogin(options, customerData.value);
  };
  const auth = async (payload) => {
    await doLogin$1({ loginMethod: LoginMethod.DEFAULT, payload });
    window.sessionStorage.removeItem(captchaChallengeWasShownKey);
    DeviceCustomerLoginStorage.setLogin(payload.username);
  };
  const authCas = async (payload) => {
    await doLogin$1({ loginMethod: LoginMethod.CAS, payload });
    window.sessionStorage.removeItem(captchaChallengeWasShownKey);
    DeviceCustomerLoginStorage.setLogin(payload.username);
  };
  const authG2sv = async (payload) => {
    await doLogin$1({ loginMethod: LoginMethod.G2SV, payload });
  };
  const authFingerprint = async (payload) => {
    await doLogin$1({ loginMethod: LoginMethod.FINGERPRINT, payload });
  };
  const setPinCodeData = (data) => {
    pinCodeData.value = data;
  };
  const doPinCodeLogin = async (pinCode) => {
    await doLoginPinCode(api, (node) => node.mutations.auth.loginPinCode, {
      options: {
        ...pinCodeData.value,
        pinCode
      }
    });
    setLoggedIn(true);
    pinCodeData.value = null;
    await loadUserData(true);
    if (loginOptionsCAS.value) {
      analyticsLogLogin(loginOptionsCAS.value, customerData.value);
      loginOptionsCAS.value = null;
    }
  };
  const logout$1 = async () => {
    api.clearQueue();
    setLoggedIn(false);
    await logout(api, (node) => node.mutations.auth.logout);
  };
  const resetUserState = () => {
    setLoggedIn(false);
    setCustomerData(null);
    profileData.value = null;
  };
  const signOut = async () => {
    await logout$1();
    resetUserState();
  };
  const changePassword = async ({ oldPassword, newPassword }) => {
    await doChangePassword(api, (node) => node.mutations.customer.changePassword, {
      options: {
        oldPassword,
        newPassword
      }
    });
  };
  const getNotificationConsents = async (silent) => {
    const response = await getConsents(api, (node) => node.queries.customer.notifications.getConsents, {
      options: {}
    }, {
      silent
    });
    if (response == null ? void 0 : response.consents) {
      consents.value = response.consents;
    }
  };
  const updateNotificationConsents = async (options) => {
    const { ts, ...consentsSettings } = options;
    consents.value = consentsSettings;
    await doUpdateNotificationConsents(api, (node) => node.mutations.customer.updateNotificationConsents, {
      options
    });
  };
  const enablePushConsent = async () => {
    const response = await getConsents(api, (node) => node.queries.customer.notifications.getConsents, {
      options: {}
    });
    await updateNotificationConsents({
      ...response.consents,
      pushNotification: true
    });
  };
  const confirmEmailByToken = async (token) => verifyEmail(api, (node) => node.mutations.customer.verifyEmail, {
    options: {
      token
    }
  });
  const setTooManyConfirmationLinkRequests = (tooMany) => {
    tooManyConfirmationLinkRequests.value = tooMany;
  };
  const resendVerificationEmail$1 = async () => {
    try {
      await resendVerificationEmail(api, (node) => node.mutations.customer.resendVerificationEmail, {
        options: {}
      });
    } catch (error) {
      const formattedError = convertToBaseError(error);
      if (formattedError.code.equals("EMAIL_VERIFICATION_SENT_TOO_OFTEN")) {
        setTooManyConfirmationLinkRequests(true);
        return;
      }
      throw error;
    }
  };
  const checkCustomerTimezoneOffset = async () => {
    if (!isLoggedIn.value) {
      localStorage.removeItem(localStorageCustomerTimezoneOffsetKey);
      return;
    }
    const savedOffset = localStorage.getItem(localStorageCustomerTimezoneOffsetKey);
    const currentOffset = ServerDate.getCustomerTimezoneOffset();
    if (savedOffset !== String(currentOffset)) {
      let timeZone = currentOffset * 60;
      if (timeZone % 30 !== 0) {
        logger.error("Customer timezone is not divisible by 30", {
          timeZone,
          timezoneOffset: currentOffset,
          oldTimezoneOffset: savedOffset,
          serverTimeShift: ServerDate.getTimeShift(),
          getTimezoneOffset: (/* @__PURE__ */ new Date()).getTimezoneOffset()
        });
        timeZone = roundToDivisible(timeZone, 30);
      }
      await doModifyTimeZone(api, (node) => node.mutations.customer.modifyTimeZone, {
        options: { timeZone }
      }, { silent: true });
      localStorage.setItem(localStorageCustomerTimezoneOffsetKey, String(currentOffset));
    }
  };
  const markDeviceUntrusted = async (options) => {
    await doMarkDeviceUntrusted(api, (node) => node.mutations.customer.markDeviceUntrusted, {
      options
    });
    if (isLoggedIn.value)
      await logout$1();
  };
  const setAdditionalPropsUser$1 = async (options) => {
    try {
      await setAdditionalPropsUser(api, (node) => node.mutations.customer.config.setConfig, {
        options
      });
    } catch (error) {
      logger.error("UserActions setAdditionalPropsUser Error:", error);
    }
    customerDataStore.setCustomerConfigCache(options.config, options.value);
  };
  const updateNickname = async (options) => {
    await doChangeCustomerNickname(api, (node) => node.mutations.customer.changeNickname, {
      options
    });
  };
  const setConfirmationLinkWasSent = (wasSent) => {
    confirmationLinkWasSent.value = wasSent;
  };
  useSyncState(async (silent) => {
    if (isLoggedIn.value) {
      await loadUserData(silent);
    }
  }, "user", {
    disableLoginLogoutHandlers: true
  });
  const initBusEvents = () => {
    bus.on(BusEvent.NEW_BET_HAS_BEEN_PLACED, () => {
      void loadUserBonusData(true);
    });
  };
  async function loadCustomerData(silent) {
    const options = { ...isObject(silent) ? silent : { silent } };
    const response = await getUserData(api, (node) => node.queries.customer.getUserData, {
      options: {}
    }, options);
    assert(response.customerData);
    setupCustomerData(response.customerData);
    const { balance, currency } = response.customerData;
    if (isNumber(balance) && isString(currency)) {
      setBalance(balance, 1);
      setCurrency(currency);
    }
  }
  async function updateUserData(payload) {
    await updateCustomerData(payload);
    await loadCustomerData();
  }
  async function updateCustomerPhone({ silent }) {
    if (!customerData.value) {
      await loadCustomerData(silent);
      return;
    }
    await loadCustomerPhone({ silent });
  }
  function loginWatchCallback(newIsLoggedIn) {
    if (newIsLoggedIn) {
      void checkCustomerTimezoneOffset();
      if (!process.env.VUE_APP_PRODUCT_LEONRU) {
        const isRefreshRequired = useI18nLanguage().isLanguageChangeable(userLanguageTag.value);
        if (isRefreshRequired) {
          const { login } = customerDataStore;
          void getDeferredDeviceIDStorage(login).set(true);
        } else {
          useAppEmitter().emit(startLoginDeviceRoutineEventType);
        }
      }
    } else {
      setCustomerData(null);
      profileData.value = null;
      void checkCustomerTimezoneOffset();
    }
  }
  function init() {
    initBusEvents();
    watch(isLoggedIn, loginWatchCallback);
  }
  init();
  return {
    // state/getters
    isBonusTermsAccepted,
    inGameValue,
    consents: safeUserConsents,
    confirmationLinkWasSent,
    tooManyConfirmationLinkRequests,
    profileData,
    // actions
    auth,
    authCas,
    authG2sv,
    authFingerprint,
    doPinCodeLogin,
    setPinCodeData,
    logout: logout$1,
    signOut,
    changePassword,
    getNotificationConsents,
    updateNotificationConsents,
    enablePushConsent,
    confirmEmailByToken,
    resendVerificationEmail: resendVerificationEmail$1,
    markDeviceUntrusted,
    setAdditionalPropsUser: setAdditionalPropsUser$1,
    updateNickname,
    loadUserData,
    setConfirmationLinkWasSent,
    setTooManyConfirmationLinkRequests,
    loadCustomerData,
    updateUserData,
    updateCustomerPhone,
    loadUserBonusData
  };
});
function getLoginType(value) {
  if (value.includes("@")) {
    return loginTypeEmail;
  }
  if (value.length >= 10) {
    return loginTypePhone;
  }
  if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
    return loginTypeEmail;
  }
  return loginTypeAccount;
}
export {
  LoginMethod,
  getLoginType,
  useUserStore
};
