import type { Ref } from 'vue';
import { computed } from 'vue';

import { isString } from '@leon-hub/guards';

import type {
  Market,
  MarketType,
  Runner,
  RunnerLike,
  Sport,
} from 'web/src/modules/sportline/types';
import { defaultMarketColumnsOptions } from 'web/src/modules/sportline/constants';
import { placeRunnersToColumns } from 'web/src/modules/sportline/utils/markets';

interface MarketRunnersProps {
  market: Ref<Maybe<Market>>;
  marketType: Ref<Maybe<MarketType>>;
  sport: Ref<Maybe<Sport>>;
}

interface MarketRunnersComposable {
  runnersPlaces: Ref<Maybe<Runner>[]>;
}

export function useMarketRunners(
  props: MarketRunnersProps,
): MarketRunnersComposable {
  const {
    market,
    marketType,
    sport,
  } = props;
  const runnersPlaces = computed(() => placeRunnersToColumns({
    market: market.value,
    marketType: marketType.value,
    sportFamily: sport.value?.representation.family,
  }, defaultMarketColumnsOptions)
    .map((runner: RunnerLike): Maybe<Runner> => (runner && !isString(runner) ? runner : null)));

  return {
    runnersPlaces,
  };
}
