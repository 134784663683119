import { defineStore } from 'pinia';
import { ref, toRef } from 'vue';

import type { BatchMakeBetRequest } from '@leon-hub/api-sdk';
import { ApiConnectionError, ApiTechnicalError, GqlApiAccessDeniedError } from '@leon-hub/api';
import { SlipStatus } from '@leon-hub/api-sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { assert, isString } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { Timer } from '@leon-hub/utils';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from '@core/analytics';
import { useGraphqlClient } from '@core/app-rest-client';
import { ServerDate } from '@core/app-settings';
import { useCustomerDataStore } from '@core/customer';
import { useErrorsConverter } from '@core/errors';
import { useCurrency } from '@core/money';
import { useTheme } from '@core/theme';

import { useEventsSubscriptionsRequestStore } from 'web/src/modules/sportline/submodules/events-subscriptions/store';
import {
  SPORTLINE_EVENT_SUBSCRIPTION_REQUEST_TYPE,
} from 'web/src/modules/sportline/submodules/events-subscriptions/store/enums';

import type { SlipEntry, SlipEntryId } from '../../slip-info/types';
import type {
  HandleBetPlaceErrorPayload,
  HandlePlaceBetResultsPayload,
  LastResultPayload,
  LogBetResultPayload,
  MakeBetResultItem,
  TrackBetErrorPayload,
} from '../types';
import { BetSlipResultState, BetSlipWarningTypes, LeaveSlipResultChoice } from '../../../enums';
import { isLiveEventSlipEntry, isUnavailableEntryStatus } from '../../../utils';
import { useDefaultBetValue } from '../../default-bet-value/composables';
import { useFreebetStore } from '../../freebet/store';
import { useMultiSinglesStore } from '../../multi-singles/store';
import { usePendingBetsStore } from '../../pending-bets/store';
import { useSlipInfoStore } from '../../slip-info/store';
import { useSlipSelectedEntriesStore } from '../../slipSelectedEntries/store';
import { useStakeInputStore } from '../../stake-input/store';
import {
  betPlaceErrorYMTrigger,
  betResultHasLimitExceedError,
  getBatchMakeBetEntries,
  isAnotherBetResultError,
  isAtLeastOneBetWasAccepted,
  isTotalHandicapReplacedInBetResult,
  logPlaceBetResultError,
  makeBetResultLog,
  parseBetResults,
  requestLastBetResult,
  requestPlaceBet,
} from '../utils';

const lastResultTimeout = 3000;

const usePlaceBetStore = defineStore('place-bet-store', () => {
  const apiClient = useGraphqlClient();

  const bus = useEventsBus();

  const slipInfoStore = useSlipInfoStore();

  const { convertToBaseError } = useErrorsConverter();

  const {
    removeBetEvents,
    setLastBetTimeStamp,
    clearLocalStorageState,
    applyBatchedSlipInfoUpdate,
    enableUpdates,
    disableUpdates,
    saveToLocalStorage,
    setCustomWarningMessage,
  } = slipInfoStore;

  const minStake = toRef(slipInfoStore, 'minStake');
  const maxStake = toRef(slipInfoStore, 'maxStake');
  const slipType = toRef(slipInfoStore, 'slipType');
  const isMultiSinglesMode = toRef(slipInfoStore, 'isMultiSinglesMode');
  const slipEntries = toRef(slipInfoStore, 'slipEntries');
  const maxAvailableFastBetValue = toRef(slipInfoStore, 'maxAvailableFastBetValue');
  const multiSinglesUiCount = toRef(slipInfoStore, 'multiSinglesUiCount');
  const combiOrFirstSlip = toRef(slipInfoStore, 'combiOrFirstSlip');
  const slipEventsCount = toRef(slipInfoStore, 'slipEventsCount');
  const batchedSlipInfo = toRef(slipInfoStore, 'batchedSlipInfo');
  const lastBetTimeStamp = toRef(slipInfoStore, 'lastBetTimeStamp');
  const combinedEntriesInfo = toRef(slipInfoStore, 'combinedEntriesInfo');
  const selectedStakeValue = toRef(slipInfoStore, 'selectedStakeValue');
  const totalHandicapReplaceEnabled = toRef(slipInfoStore, 'totalHandicapReplaceEnabled');
  const autoAcceptTotalHandicapChanges = toRef(slipInfoStore, 'autoAcceptTotalHandicapChanges');

  const selectedEntriesIds = toRef(useSlipSelectedEntriesStore(), 'selectedEntriesIds');

  const selectedFreeBetId = toRef(useFreebetStore(), 'selectedFreeBetId');

  const { loadPendingBets } = usePendingBetsStore();

  const { currency } = useCurrency();

  const { theme } = useTheme();

  const analyticsService = useAnalytics();

  const customerDataStore = useCustomerDataStore();

  const useStandardBet = toRef(customerDataStore, 'useStandardBet');
  const standardBetAmount = toRef(customerDataStore, 'standardBetAmount');
  const priceChangePolicy = toRef(customerDataStore, 'priceChangePolicy');
  const totalHandicapPriceChangePolicy = toRef(customerDataStore, 'totalHandicapPriceChangePolicy');
  const login = toRef(customerDataStore, 'login');

  const { defaultBetValue } = useDefaultBetValue();

  const stakeInputStore = useStakeInputStore();

  const {
    setStakeInputIsFocused,
    safeSetStakeValue,
  } = stakeInputStore;

  const selectedFastMoneyValue = toRef(stakeInputStore, 'selectedFastMoneyValue');

  const multiSinglesStore = useMultiSinglesStore();

  const sameStakeForSingles = toRef(multiSinglesStore, 'sameStakeForSingles');
  const multiSinglesMetaInfo = toRef(multiSinglesStore, 'multiSinglesMetaInfo');

  // state

  const resultState = ref<BetSlipResultState>(BetSlipResultState.INITIAL);

  const setResultState = (value: BetSlipResultState): void => {
    resultState.value = value;
  };

  const placeBetErrorText = ref<string>('');

  const setPlaceBetErrorText = (errorText: string): void => {
    placeBetErrorText.value = errorText;
  };

  /** responsible gambling limitations */
  const limitsExceeded = ref<boolean>(false);

  const setLimitsExceeded = (isExceeded: boolean): void => {
    limitsExceeded.value = isExceeded;
  };

  const totalEntriesInSlip = ref<number>(0);

  const setTotalEntriesInSlip = (count: number): void => {
    totalEntriesInSlip.value = count;
  };

  const totalBetsAccepted = ref<number>(0);

  const setTotalBetsAccepted = (count: number): void => {
    totalBetsAccepted.value = count;
  };

  const slipUnsettledSingles = ref<SlipEntryId[] | null>(null);

  const setSlipUnsettledSingles = (list: SlipEntryId[] | null): void => {
    slipUnsettledSingles.value = list;
  };

  /** what to clear on slip close after bet was placed */
  const leaveBetResultChoice = ref<LeaveSlipResultChoice>(LeaveSlipResultChoice.CLEAR_ALL);

  const setLeaveBetResultChoice = (choice: LeaveSlipResultChoice): void => {
    leaveBetResultChoice.value = choice;
  };

  const lastBetId = ref<number | null>(null);

  const setLastBetId = (id: number | null): void => {
    lastBetId.value = id;
  };

  const lastPlacedSingleEntries = ref<SlipEntry[]>([]);

  const setLastPlacedSingles = (value: SlipEntry[]): void => {
    lastPlacedSingleEntries.value = value;
  };

  const isTotalHandicapReplacedInResult = ref<boolean>(false);

  const setIsTotalHandicapReplacedInResult = (value: boolean): void => {
    isTotalHandicapReplacedInResult.value = value;
  };

  // former actions

  const logBetResult = ({
    acceptedBets,
    entriesCount,
    liveEventsIds,
  }: LogBetResultPayload): void => {
    makeBetResultLog({
      acceptedBets,
      multiSinglesMetaInfo: multiSinglesMetaInfo.value,
      sameStakeForSingles: sameStakeForSingles.value,
      isMultiSinglesMode: isMultiSinglesMode.value,
      stakeValue: selectedStakeValue.value,
      entriesCount,
      selectedFastMoneyValue: selectedFastMoneyValue.value,
      maxAvailableFastBetValue: maxAvailableFastBetValue.value,
    }, {
      liveEventsIds,
      slipType: slipType.value,
      priceChangePolicy: priceChangePolicy.value,
      currency: currency.value,
      login: login.value,
    }, {
      analyticsService,
      theme: theme.value,
      useDefaultBet: useStandardBet.value,
      amountBet: standardBetAmount.value || false,
      currentValue: selectedStakeValue.value,
    });
  };

  const resetSlipResultState = (doClearSlip = true): void => {
    if (doClearSlip) {
      removeBetEvents({
        leaveBetResultChoice: leaveBetResultChoice.value,
        slipUnsettledSingles: slipUnsettledSingles.value,
      });
    }
    setResultState(BetSlipResultState.INITIAL);
    setLeaveBetResultChoice(LeaveSlipResultChoice.CLEAR_ALL);
    setLastBetId(null);
    setLastPlacedSingles([]);
    setLimitsExceeded(false);
    setSlipUnsettledSingles(null);
    setStakeInputIsFocused(false);
    setIsTotalHandicapReplacedInResult(false);
    const nextInputValue = defaultBetValue.value || 0;
    safeSetStakeValue({
      value: nextInputValue,
      minStake: minStake.value,
      maxStake: maxStake.value,
    });
  };

  const handleBetPlaceSuccess = (): void => {
    void loadPendingBets();
    bus.emit(BusEvent.NEW_BET_HAS_BEEN_PLACED, {});
    setResultState(BetSlipResultState.SUCCESS);
    setLastBetTimeStamp(null);
    clearLocalStorageState();
  };

  const onMarkResultStateAsError = (saveTimestamp = false): void => {
    if (slipEventsCount.value) {
      enableUpdates();
    }
    if (!saveTimestamp) {
      setLastBetTimeStamp(null);
    }
    setResultState(BetSlipResultState.ERROR);
  };

  const handlePlaceBetResults = ({ results, entriesCount }: HandlePlaceBetResultsPayload): void => {
    const {
      acceptedBets,
      unsettledBetsIds,
      updatedSlipEntries,
      haveLimitsError,
      acceptedSingleEntries,
    } = parseBetResults([...results], selectedEntriesIds.value);
    const acceptedCount = acceptedBets.length;
    setTotalEntriesInSlip(multiSinglesUiCount.value);
    setTotalBetsAccepted(acceptedCount);
    if (acceptedCount === 1) {
      setLastBetId(acceptedBets[0].betId ?? null);
    } else {
      setLastPlacedSingles(acceptedSingleEntries);
    }
    if (haveLimitsError) {
      setLimitsExceeded(true);
    }
    if (updatedSlipEntries) {
      applyBatchedSlipInfoUpdate({
        updatedSlipInfo: {
          slipEntries: updatedSlipEntries,
          combiAvailable: batchedSlipInfo.value?.combiAvailable ?? true,
        },
        isBackgroundUpdate: true,
      });
    }
    setSlipUnsettledSingles(unsettledBetsIds.length ? unsettledBetsIds : null);
    /** logging and analytics */
    const liveEventsIds = slipEntries.value.filter((entry) => isLiveEventSlipEntry(entry))
      .map(({ event }) => String(event));
    logBetResult({ acceptedBets, liveEventsIds, entriesCount });
    handleBetPlaceSuccess();
  };
  const getLastBetResult = async ({ fallbackMessage, remainingAttempts }: LastResultPayload): Promise<void> => {
    const response = await requestLastBetResult(apiClient);
    if (response && isAtLeastOneBetWasAccepted(response.results)) {
      handlePlaceBetResults({
        results: response.results,
        entriesCount: response.results.length,
      });
    } else if (remainingAttempts > 1) {
      // call itself again
      Timer.setTimeout(() => {
        void getLastBetResult({
          remainingAttempts: remainingAttempts - 1,
          fallbackMessage,
        });
      }, lastResultTimeout);
    } else {
      if (fallbackMessage) {
        setPlaceBetErrorText(fallbackMessage);
      }
      onMarkResultStateAsError();
      removeBetEvents();
    }
  };

  const handleBetErrorCode = (errorCode: string): { shouldStop: boolean } => {
    let shouldStop = false;
    if (errorCode.includes('freebet')) {
      bus.emit(BusEvent.SHOW_SLIP_MODAL_WARNING, {
        type: BetSlipWarningTypes.FREEBET_VALIDATION_ERROR,
      });
      setResultState(BetSlipResultState.INITIAL);
      shouldStop = true;
    }
    if (errorCode.includes('bets-limit-exceeded')) {
      setLimitsExceeded(true);
    }
    return { shouldStop };
  };

  const claimToSlipClose = (): void => {
    bus.emit(BusEvent.CLAIM_TO_SLIP_CLOSE, {});
  };

  const resetResultErrorState = (): void => {
    setPlaceBetErrorText('');
    setResultState(BetSlipResultState.INITIAL);
    setLimitsExceeded(false);
    if (slipEventsCount.value) {
      enableUpdates();
    } else {
      claimToSlipClose();
    }
  };

  const setPriceChangesError = (message: string): void => {
    setCustomWarningMessage(message);
    setPlaceBetErrorText('');
    setResultState(BetSlipResultState.INITIAL);
    enableUpdates();
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleSingleBetError = (result: MakeBetResultItem): void => {
    const { errorMessage, errorCode, slipInfo } = result;
    const errorText = errorMessage || slipInfo?.message;
    const trackBetErrorPayload: TrackBetErrorPayload = {
      errorMessage: errorText || null,
      errorCode: errorCode || null,
      slipStatus: slipInfo?.status ?? null,
      slipMessage: slipInfo?.message ?? null,
    };
    logPlaceBetResultError(trackBetErrorPayload);
    const { shouldStop } = handleBetErrorCode(errorCode ?? '');
    if (shouldStop) {
      onMarkResultStateAsError();
      return;
    }
    if (errorText) {
      setPlaceBetErrorText(errorText);
    }
    let isPriceChangesError = false;
    if (slipInfo && batchedSlipInfo.value) {
      const { status } = slipInfo;
      const trace = combiOrFirstSlip.value?.trace ?? null;
      if (status === SlipStatus.MARKETS_CHANGED) {
        if (totalHandicapReplaceEnabled.value) {
          setIsTotalHandicapReplacedInResult(isTotalHandicapReplacedInBetResult(result, new Set(selectedEntriesIds.value)));
        }
        if (!isTotalHandicapReplacedInResult.value) {
          const hasUnavailableEntries = slipInfo
            .entries
            ?.some(({ marketStatus }) => isUnavailableEntryStatus(marketStatus));
          if (!hasUnavailableEntries) {
            betPlaceErrorYMTrigger(trackBetErrorPayload, analyticsService);
            isPriceChangesError = true;
          }
        }
      }
      applyBatchedSlipInfoUpdate({
        updatedSlipInfo: {
          slipEntries: [{ ...slipInfo, trace }],
          combiAvailable: batchedSlipInfo.value.combiAvailable,
        },
      });
    }
    if (isPriceChangesError) {
      // LEONWEB-13537
      setPriceChangesError(errorText ?? '');
      setLastBetTimeStamp(null);
      return;
    }
    onMarkResultStateAsError();
  };

  const handleMultipleBetError = (results: MakeBetResultItem[]): void => {
    const resultWithLimitError = results.find((result) => betResultHasLimitExceedError(result));
    if (resultWithLimitError) {
      setLimitsExceeded(true);
      setPlaceBetErrorText(resultWithLimitError.errorMessage ?? '');
    }
  };

  const handleBetPlaceError = (payload?: HandleBetPlaceErrorPayload): void => {
    const { results, error } = payload ?? {};
    if (results) {
      if (results.length === 1) {
        // one single or combi slip
        handleSingleBetError(results[0]);
        return;
      }
      // multisingles
      handleMultipleBetError(results);
    }
    let shouldSaveTimestamp = false;
    if (error) {
      const baseError = convertToBaseError(error);
      shouldSaveTimestamp = baseError instanceof ApiConnectionError || baseError instanceof ApiTechnicalError;
      if (isAnotherBetResultError(baseError)) {
        /**
         * With these type of errors we have to call another endpoint and probably got result.
         * Works better with timeout.
         * Why not just don't send 'duration'/accept-in-progress again and now throw error from the backend? No idea
         */
        Timer.setTimeout(() => {
          void getLastBetResult({
            remainingAttempts: 2,
            fallbackMessage: baseError.message,
          });
        }, lastResultTimeout);
        return;
      }
      setPlaceBetErrorText(baseError.message);
    }
    onMarkResultStateAsError(shouldSaveTimestamp);
  };

  const placeBet = async (payload?: BatchMakeBetRequest): Promise<void> => {
    if (!batchedSlipInfo.value) {
      throw new Error('placeBetError: empty batchedSlipInfo');
    }
    if (!lastBetTimeStamp.value) {
      setLastBetTimeStamp(ServerDate.now());
    }
    const freeBetId = selectedFreeBetId.value ? Number(selectedFreeBetId.value) : undefined;
    const batchedEntries = getBatchMakeBetEntries({
      stakeValue: selectedStakeValue.value,
      batchedSlipInfo: batchedSlipInfo.value,
      priceChangePolicy: priceChangePolicy.value,
      isMultiSinglesMode: isMultiSinglesMode.value,
      sameStakeForSingles: sameStakeForSingles.value,
      combinedSlipEntriesForSingles: combinedEntriesInfo.value,
      autoAcceptTotalHandicapChanges: autoAcceptTotalHandicapChanges.value,
      freeBetId,
    });

    /**
     * if retrying, we have payload with same BatchMakeBetRequest options
     * passed as argument
     */
    const requestPayload: BatchMakeBetRequest = payload ?? {
      slipType: slipType.value,
      entries: batchedEntries,
      timestamp: Number(lastBetTimeStamp.value),
      pcp: priceChangePolicy.value,
      // LEONWEB-15081
      ...(totalHandicapReplaceEnabled.value ? { totalHandicapPcp: totalHandicapPriceChangePolicy.value } : {}),
    };
    disableUpdates();
    if (!payload) {
      setResultState(BetSlipResultState.PENDING);
    }
    try {
      saveToLocalStorage();
      const response = await requestPlaceBet(apiClient, requestPayload);
      if (!response) {
        setResultState(BetSlipResultState.INITIAL);
        return;
      }
      const { duration, results } = response;
      if (duration && duration > 0) {
        setResultState(BetSlipResultState.WAIT_FOR_RETRY);
        logger.info('Place bet accept-in-progress', {
          duration,
          errorCode: 'betting.accept-in-progress',
        });
        // recursive call
        if (process.env.VUE_APP_RENDERING_CSR) {
          Timer.setTimeout(() => {
            void placeBet(requestPayload);
          }, duration);
        }
        return;
      }
      if (isAtLeastOneBetWasAccepted(results)) {
        handlePlaceBetResults({
          results,
          entriesCount: batchedEntries.length,
        });
      } else {
        /** all bets have been declined */
        handleBetPlaceError({ results });
      }
    } catch (error) {
      if (error instanceof GqlApiAccessDeniedError) {
        throw error;
      }

      logger.error('Place bet error', error);
      handleBetPlaceError({ error });
    }
  };

  const handleSlipResultLeave = (closeChoice: LeaveSlipResultChoice): void => {
    if (closeChoice === LeaveSlipResultChoice.SAVE_ALL) {
      analyticsService.push(AnalyticsEvent.CLICK_MAP, { clickCounter: { bet_slip: 'keep_in_bet_slip' } });
    }
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      useEventsSubscriptionsRequestStore().setShowSubscriptionRequest(SPORTLINE_EVENT_SUBSCRIPTION_REQUEST_TYPE.SLIP);
    }
    assert(closeChoice && isString(closeChoice), 'handleSlipResultLeave: closeChoice is not passed');
    if (closeChoice !== LeaveSlipResultChoice.CLEAR_ALL) {
      setLeaveBetResultChoice(closeChoice);
      resetSlipResultState();
      return;
    }
    claimToSlipClose();
  };

  const onLimitsButtonClick = (): void => {
    resetSlipResultState(false);
    claimToSlipClose();
  };

  return {
    resetSlipResultState,
    placeBet,
    resetResultErrorState,
    handleSlipResultLeave,
    setResultState,
    setLeaveBetResultChoice,
    onLimitsButtonClick,
    resultState,
    placeBetErrorText,
    limitsExceeded,
    totalEntriesInSlip,
    totalBetsAccepted,
    leaveBetResultChoice,
    slipUnsettledSingles,
    lastBetId,
    lastPlacedSingleEntries,
    isTotalHandicapReplacedInResult,
  };
});

export default usePlaceBetStore;
