import { logger } from '@leon-hub/logging';

import type { SlipListItem } from '../types';

export default function logAddingToSlip(item: SlipListItem): void {
  const {
    betline,
    event,
    market,
    runner,
    odds,
  } = item;
  logger.info('Adding to betslip', {
    betline,
    event,
    market,
    runner,
    odds,
  });
}
