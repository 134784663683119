<script lang="ts" setup>
import {
  computed,
  toRef,
} from 'vue';

import type { Maybe } from '@leon-hub/types';

import type { CountryCode } from './enums';
import type { VCountryFlagProps } from './types';
import { useVCountryFlag } from './composables/useVCountryFlag';
import { VCountryFlagSize } from './enums';

const props = defineProps<VCountryFlagProps>();

const name = toRef(props, 'name');
const isSprite = toRef(props, 'sprite', false);
const countryCode = toRef(props, 'code', 'RU' as CountryCode || null);
const size = toRef(props, 'size', VCountryFlagSize.SIZE_12);

const countryCodeFallback = computed(() => (
  ((name.value ?? countryCode.value)?.toUpperCase() || null) as Maybe<CountryCode>
));

const {
  position,
  imageUrl,
} = useVCountryFlag({ countryCode: countryCodeFallback });

const spriteSrc = require('../assets/flags/flags.png');

const style = computed<string>(() => {
  if (isSprite.value) {
    return `background: url(${spriteSrc}) ${position.value} / 255px no-repeat`;
  }

  return `background-image: url(${imageUrl.value})`;
});
</script>

<template>
  <span v-auto-id="'VCountryFlag'"
    :class="{
      [$style['country-flag']]: true,
      [$style[`country-flag-${size}`]]: !rounded,
      [$style['country-flag__rounded']]: rounded,
    }"
  >
    <span
      :class="$style['country-flag__img']"
      :style="!process.env.VUE_APP_PRERENDER ? style : undefined"
      :data-prerender-style="process.env.VUE_APP_PRERENDER ? style : undefined"
    />
  </span>
</template>

<style module lang="scss">
.country-flag {
  $self: &;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &__img {
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    border-radius: 2px;
  }

  &__rounded {
    width: 20px;
    height: 20px;
    padding: 1.6px;

    #{$self}__img {
      width: 100%;
      height: 100%;
      background-size: cover;
      border-radius: 100%;
    }
  }

  &-size {
    $sizes: (
      0: ('height': 0),
      10: ('height': 10px),
      12: ('height': 12px),
      16: ('height': 16px),
      18: ('height': 18px),
      20: ('height': 20px),
      22: ('height': 22px),
      24: ('height': 24px),
      26: ('height': 26px),
      28: ('height': 28px),
      40: ('height': 40px),
      54: ('height': 54px),
    );

    @each $name, $size in $sizes {
      &-#{$name} {
        width: calc(get($size, 'height') * 4 / 3);
        height: get($size, 'height');
      }
    }

    &-full-width {
      width: 100%;
      height: 75%;
    }
  }
}
</style>
