import { isString } from '@leon-hub/guards';

import type {
  MarketGroupSpecifier,
  MarketGroupStyle,
  SportMarketGroup,
} from 'web/src/modules/sportline/types';
import type { CoreSportMarketGroup, MarketGroupStyleResponse } from 'web/src/modules/sportline/types/rest';
import { MarketGroupStyleType } from 'web/src/modules/sportline/enums';

function isMarketGroupStyleType(value?: unknown): value is MarketGroupStyleType {
  return isString(value) && Object.values(MarketGroupStyleType).includes(value as MarketGroupStyleType);
}

function getMarketGroupStyle(style?: MarketGroupStyleResponse): Optional<MarketGroupStyle> {
  return style?.type
    ? { type: isMarketGroupStyleType(style.type) ? style.type : MarketGroupStyleType.DEFAULT }
    : undefined;
}

export function createMarketGroupSpecifiers(group: Pick<CoreSportMarketGroup, 'specifier'>): Optional<MarketGroupSpecifier> {
  const id = group.specifier?.specifier;
  return id ? { id } : undefined;
}

export function createMarketGroup(group: CoreSportMarketGroup): SportMarketGroup {
  return {
    id: String(group.id),
    name: group.name,
    marketTypeIds: group.marketTypeIds.map(String),
    style: getMarketGroupStyle(group?.style),
    specifier: createMarketGroupSpecifiers(group),
  };
}

export function createMarketGroups(marketGroups: CoreSportMarketGroup[]): SportMarketGroup[] {
  return marketGroups.map(createMarketGroup);
}
