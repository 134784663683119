import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';
import { isObject } from '@leon-hub/guards';

import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { DateTimeRange, SportEventsSportsDateTimeFilter } from 'web/src/modules/sportline/types';
import { isSportFilterKickoffPeriod, isSportlineType } from 'web/src/modules/sportline/guards';

function isSportDateTimeFilter(value?: Maybe<SportEventsSportsDateTimeFilter>): value is SportDateTimeFilter {
  return isObject(value) && ('sportlineType' in value || 'activeKickoffPeriod' in value);
}

function isDateTimeRange(value?: Maybe<SportEventsSportsDateTimeFilter>): value is DateTimeRange {
  return isObject(value) && 'to' in value && 'from' in value;
}

export function getSportDateTimeFilterByValue(
  value?: Maybe<SportEventsSportsDateTimeFilter>,
): Maybe<SportDateTimeFilter> {
  if (isSportlineType(value)) {
    return { activeKickoffPeriod: SportFilterKickoffPeriod.ALL, sportlineType: value };
  }

  if (isSportFilterKickoffPeriod(value)) {
    return { activeKickoffPeriod: value };
  }

  if (isDateTimeRange(value)) {
    return {
      activeKickoffPeriod: SportFilterKickoffPeriod.CUSTOM,
      customDateFrom: value.from,
      customDateTo: value.to,
    };
  }

  if (isSportDateTimeFilter(value)) {
    return value.sportlineType
      ? { activeKickoffPeriod: SportFilterKickoffPeriod.ALL, sportlineType: value.sportlineType }
      : { ...value };
  }

  return null;
}
