<script lang="ts" setup>
import type { FocusableInputRef } from '@leon-hub/focus';
import { dataTest as vDataTest } from '@leon-hub/directives';
import { useAutofocus } from '@leon-hub/focus';

import type {
  VCheckboxEmits,
  VCheckboxProps,
  VCheckboxSlots,
} from '../types';
import { VCheckboxIcon } from '../../VCheckboxIcon';
import { useVCheckbox } from '../composables';

const props = withDefaults(defineProps<VCheckboxProps>(), {
  name: '',
  tabIndex: 0,
  label: '',
  error: '',
});

const emit = defineEmits<VCheckboxEmits>();

defineSlots<VCheckboxSlots>();

const {
  isHover,
  isFocus,
  isChecked,
  onMouseEnter,
  onMouseLeave,
  onInput,
  onChange,
  onFocus,
  onBlur,
} = useVCheckbox(props, emit);

const { focus, focusable } = useAutofocus(props);

defineExpose<FocusableInputRef>({
  focus,
});
</script>

<template>
  <div v-auto-id="'VCheckbox'"
    :class="{
      [$style['checkbox-container']]: true,
      [$style['checkbox-container--no-padding']]: noPadding,
    }"
  >
    <label
      :class="{
        [$style.checkbox]: true,
        [$style['checkbox--disabled']]: disabled,
        [$style['checkbox--error']]: !!error,
      }"
      :for="id || undefined"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <VCheckboxIcon
        :checked="isChecked"
        :is-hover="!disabled && (isHover || isFocus)"
        :disabled="disabled"
        :is-error="!!error"
      >
        <input
          :id="id || undefined"
          ref="focusable"
          v-data-test="inputDataTest"
          :checked="checked"
          :disabled="disabled"
          :name="name"
          :tabindex="tabIndex"
          :autofocus="process.env.VUE_APP_OS_IOS ? undefined : autofocus"
          :class="$style['checkbox__input']"
          type="checkbox"
          @change.stop="onChange"
          @focus.stop="onFocus"
          @blur.stop="onBlur"
          @input.stop="onInput"
          @click.stop
        >
      </VCheckboxIcon>
      <span
        v-if="!!label || $slots.label"
        :class="$style['checkbox__text-container']"
      >
        <span
          :class="$style['checkbox__text']"
          data-test-id="text"
        >
          <slot
            name="label"
          >
            {{ label }}
          </slot>
        </span>
      </span>
    </label>
    <div
      v-if="error && !disabled && !checked"
      data-test-id="hint-error"
      :class="$style['checkbox__hint-error']"
    >
      {{ error }}
    </div>
  </div>
</template>

<style lang="scss" module>
.checkbox-container {
  padding: $checkboxContainerPadding;

  &--no-padding {
    padding: 0;
  }
}

.checkbox {
  $self: &;

  display: flex;
  cursor: pointer;

  &--disabled {
    cursor: default;
    & #{$self}__text, & #{$self}__hint-error {
      opacity: 0.3;
    }
  }

  &__input {
    @include z-index(base);

    position: absolute;
    top: -10px;
    left: -10px;
    z-index: 1;
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__text {
    @include checkboxLabel;

    display: block;
    flex: 1;
    padding-top: 2px;
    margin-left: 12px;
    color: $checkboxLabelColor;
    user-select: none;
    transition: color 0.4s $animationCubicBezier;
  }

  &__hint-error {
    @include checkboxLabelError;

    display: block;
    flex-shrink: 0;
    flex-basis: 100%;
    padding: $checkboxErrorHintPaddingTop 0 0;
    color: $checkboxErrorHintColor;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__hint-link {
    margin-left: 12px;
  }

  &--error#{$self}__text {
    display: flex;
    align-items: center;
  }

  &__text-container {
    display: block;
    min-height: 24px;
  }
}
</style>
