import type { Ref } from 'vue';
import { computed } from 'vue';

import type { Optional } from '@leon-hub/types';
import { IconSize } from '@leon-hub/icons';

import { VCountryFlagSize } from '../VCountryFlag/enums';

interface UseVFlagIconProps {
  size: Ref<IconSize>;
  flagSize: Ref<Optional<VCountryFlagSize>>;
}

interface UseVFlagIconComposable {
  finalFlagSize: Ref<VCountryFlagSize>;
}

export function useVFlagIcon(
  props: UseVFlagIconProps,
): UseVFlagIconComposable {
  const { size, flagSize } = props;

  const finalFlagSize = computed<VCountryFlagSize>(() => {
    if (flagSize.value) {
      return flagSize.value;
    }

    switch (size.value) {
      case IconSize.SIZE_20: return VCountryFlagSize.SIZE_10;
      case IconSize.SIZE_24: return VCountryFlagSize.SIZE_12;
      case IconSize.SIZE_28: return VCountryFlagSize.SIZE_16;
      // fallback to see unsupported IconSize property
      default: return VCountryFlagSize.SIZE_0;
    }
  });

  return {
    finalFlagSize,
  };
}
