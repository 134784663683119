import type { FieldGuards } from '@leon-hub/types';
import {
  isBoolean,
  isString,
  isUndefined,
  isValidInterface,
} from '@leon-hub/guards';

import type {
  BetlineWidgetType,
  CoreSportlineEventWidget,
  CoreSportlineEventWidgetData,
} from 'web/src/modules/sportline/types/rest';

export function isBetlineWidgetType(value: unknown): value is BetlineWidgetType {
  // don't check values to avoid problems with new widget types
  return isString(value);
}

export function isCoreSportlineEventWidgetData(value: unknown, silent = false): value is CoreSportlineEventWidgetData {
  return isValidInterface<CoreSportlineEventWidgetData>(value, {
    liveIframe: [isUndefined, isString],
    prematchIframe: [isUndefined, isString],
  }, { silent });
}

export const coreSportlineEventWidgetResponseFieldGuards: FieldGuards<CoreSportlineEventWidget> = {
  widgetId: [isUndefined, isString],
  widgetType: [isUndefined, isBetlineWidgetType],
  widgetVirtual: [isUndefined, isBoolean],
  widgetData: [isUndefined, isCoreSportlineEventWidgetData],
};
