import { defineStore } from 'pinia';

import { useDebugVirtualListRendererStoreComposable, useIsDebugEnabled } from './composables';

export const useSportlineDebugStore = defineStore('sportline-debug', () => {
  const { isDebugEnabled } = useIsDebugEnabled();
  const virtualListRenderer = useDebugVirtualListRendererStoreComposable({ isDebugEnabled });

  virtualListRenderer.loadVirtualListRendererEnabled();

  return {
    isDebugEnabled,
    ...virtualListRenderer,
  };
});
