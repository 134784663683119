import type { MaybeRef, Ref } from 'vue';
import { provide } from 'vue';

import type {
  ExpandedSportlineBlockMap,
  SetExpandedStateMethod,
  SetExpandedStatePayload,
} from '../types';
import { ProvideExpandedBlockMapKey, ProvideSetExpandedBlockStateKey } from '../constants';
import { applyExpandedStatePayload } from '../utils';
import { useExpandedSportsLocalStorageRef } from './useExpandSportBlockLocalStorage';

interface UseProvideIsExpandedSportlineBlockProps {
  defaultValueRef?: MaybeRef<boolean>;
}

interface UseProvideIsExpandedSportlineBlockComposable {
  expandedStateMap: Ref<ExpandedSportlineBlockMap>;
  setExpandedState: SetExpandedStateMethod;
}

export function useProvideIsExpandedSportlineBlock(props: UseProvideIsExpandedSportlineBlockProps): UseProvideIsExpandedSportlineBlockComposable {
  const { defaultValueRef } = props;

  const expandedStateMap = useExpandedSportsLocalStorageRef();

  function setExpandedState(payload: SetExpandedStatePayload): void {
    expandedStateMap.value = applyExpandedStatePayload(payload, expandedStateMap.value, defaultValueRef);
  }

  provide(ProvideExpandedBlockMapKey, expandedStateMap);
  provide(ProvideSetExpandedBlockStateKey, setExpandedState);

  return {
    expandedStateMap,
    setExpandedState,
  };
}
