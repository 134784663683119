import type { Ref } from 'vue';
import { computed } from 'vue';

import { useI18n } from '@core/i18n';

interface UseMoreCaptionLabelProps {
  totalCount: Ref<number>;
  primaryCount?: Ref<number>;
}

interface UseMoreCaptionLabelComposable {
  moreCaption: Ref<string>;
}

export function useMoreCaptionLabel(
  props: UseMoreCaptionLabelProps,
): UseMoreCaptionLabelComposable {
  const {
    totalCount,
    primaryCount,
  } = props;

  const { $translate } = useI18n();

  const baseCaptionLabel = process.env.VUE_APP_LAYOUT_DESKTOP
    ? $translate('WEB2_SPORT_EXT_BETS_COUNT')
    : $translate('JS_SPORTS_QUICKFILTERS_ALL');

  const secondaryCount = computed<number>(() => (totalCount.value - (primaryCount?.value ?? 0)));

  const moreCaption = computed<string>(() => {
    if (secondaryCount.value <= 0) {
      return '';
    }
    if (primaryCount?.value) {
      return `+${secondaryCount.value}`;
    }
    return `${baseCaptionLabel.value} +${totalCount.value}`;
  });

  return {
    moreCaption,
  };
}
