import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';

import type { SliceEventsResponseErrorOptions } from 'web/src/modules/sportline/errors/rest';
import type { GetSportEventsResponse } from 'web/src/modules/sportline/types/rest';
import { SliceEventsResponseError } from 'web/src/modules/sportline/errors/rest';
import { ResponseMappings } from 'web/src/modules/sportline/utils/rest/pre-build';

export class SportEventsResponseChangeUtils {
  /**
   * Used for restore duplicates in response
   * @param {GetSportEventsResponse} response
   * @return {GetSportEventsResponse}
   */
  static normalizeDuplicatesResponse(response?: GetSportEventsResponse | null): GetSportEventsResponse | null {
    if (!response) {
      return null;
    }

    const responseMappings = new ResponseMappings();
    const events = response.events.reduce<GetSportEventsResponse['events']>((result, event) => {
      try {
        const leagueResponse = responseMappings.getLeagueResponseOrSetNew(event.league);
        const regionResponse = responseMappings.getRegionResponseOrSetNew(leagueResponse.region);
        const sportResponse = responseMappings.getSportResponseOrSetNew(leagueResponse.sport);
        const normalizedEvent = <GetSportEventsResponse['events'][0]>{
          ...event,
          league: {
            ...leagueResponse,
            region: regionResponse,
            sport: sportResponse,
          },
        };

        return [...result, normalizedEvent];
      } catch (rawError) {
        const error = normalizeError(rawError);

        logger.error(error);
        return result;
      }
    }, []);

    return {
      ...response,
      totalCount: events.length,
      events,
    };
  }

  static sliceEvents(response: GetSportEventsResponse, options: {
    sports?: Maybe<number>;
    eventsInSport?: Maybe<number>;
    regions?: Maybe<number>;
    eventsInRegion?: Maybe<number>;
  }): GetSportEventsResponse {
    const countsIn = {
      sport: {} as Record<number, number>,
      region: {} as Record<number, number>,
    };
    const limits: Required<typeof options> = {
      sports: options.sports || null,
      eventsInSport: options.eventsInSport || null,
      regions: options.regions || null,
      eventsInRegion: options.eventsInRegion || null,
    };
    const responseMappings = new ResponseMappings();
    const eventsErrors: SliceEventsResponseErrorOptions['payload']['eventsErrors'] = [];
    const events = response.events.filter((event) => {
      try {
        const leagueResponse = responseMappings.getLeagueResponseOrSetNew(event.league);
        const regionResponse = responseMappings.getRegionResponseOrSetNew(leagueResponse.region);
        const sportResponse = responseMappings.getSportResponseOrSetNew(leagueResponse.sport);

        const currentSportsCount = Object.keys(countsIn.sport).length;
        const isNewSport = !(sportResponse.id in countsIn.sport);
        if (isNewSport && limits.sports != null && currentSportsCount >= limits.sports) {
          return false;
        }

        const currentEventsInSportCount = countsIn.sport[sportResponse.id] || 0;
        if (limits.eventsInSport !== null && currentEventsInSportCount >= limits.eventsInSport) {
          return false;
        }

        const currentRegionsCount = Object.keys(countsIn.region).length;
        const isNewRegion = !(regionResponse.id in countsIn.region);
        if (isNewRegion && limits.regions !== null && currentRegionsCount >= limits.regions) {
          return false;
        }

        const currentEventsInRegionCount = countsIn.region[regionResponse.id] || 0;
        if (limits.eventsInRegion !== null && currentEventsInRegionCount >= limits.eventsInRegion) {
          return false;
        }

        countsIn.sport[sportResponse.id] = currentEventsInSportCount + 1;
        countsIn.region[regionResponse.id] = currentEventsInRegionCount + 1;

        return true;
      } catch (rawError) {
        const error = normalizeError(rawError);

        eventsErrors.push({ event, originalError: error });
        return false;
      }
    });

    if (eventsErrors.length > 0) {
      // throw error cause to empty events block
      logger.error(new SliceEventsResponseError({
        payload: { eventsErrors, limits },
        silent: true,
      }));
    }

    return {
      ...response,
      totalCount: events.length,
      events,
    };
  }

  static excludeResponses(
    response: GetSportEventsResponse | null,
    exclude: GetSportEventsResponse | null,
  ): GetSportEventsResponse | null {
    if (!response) {
      return null;
    }

    if (!exclude) {
      return response;
    }

    const excludedEventsIds = exclude.events.map((event) => event.id);
    const events = response.events.filter((event) => !excludedEventsIds.includes(event.id));

    return {
      ...response,
      totalCount: events.length,
      events,
    };
  }

  static concatResponses(responses: ReadonlyArray<Readonly<GetSportEventsResponse> | null>): GetSportEventsResponse {
    return responses.reduce<GetSportEventsResponse>((result, response) => (
      response
        ? {
            enabled: result.enabled && response.enabled,
            vtag: response.vtag || result.vtag,
            events: [...result.events, ...response.events],
            totalCount: result.totalCount + response.totalCount,
          }
        : result
    ), <GetSportEventsResponse>{
      enabled: true,
      vtag: '',
      events: [],
      totalCount: 0,
    });
  }

  static isSomeIdsInList(response: Maybe<Readonly<GetSportEventsResponse>>, { eventsIds, leaguesIds }: {
    eventsIds?: ReadonlyArray<string>;
    leaguesIds?: ReadonlyArray<string>;
  }): boolean {
    return !!response?.events
      .some((eventResponse) => (eventsIds || []).includes(String(eventResponse.id))
        || (leaguesIds || []).includes(String(eventResponse.league.id)));
  }
}
