import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  MarketsColumn,
  MarketType,
} from 'web/src/modules/sportline/types';

import type { MarketColumnsInfo } from './types';
import { getMarketColumnsFromMarketColumnsInfo, getMarketTypesFromMarketColumnsInfo } from './utils';

interface UseSportlineElementMarketsProps {
  sportlineElement: Ref<Maybe<MarketColumnsInfo>>;
}

interface UseSportlineElementMarketColumnsComposable {
  marketsColumns: Ref<MarketsColumn[]>;
}

interface UseSportlineElementMarketTypesComposable {
  marketsTypes: Ref<MarketType[]>;
}

export function useSportlineElementMarketColumns(
  props: UseSportlineElementMarketsProps,
): UseSportlineElementMarketColumnsComposable {
  const { sportlineElement } = props;

  const marketsColumns = computed<MarketsColumn[]>(() => getMarketColumnsFromMarketColumnsInfo(sportlineElement.value));

  return { marketsColumns };
}

export function useSportlineElementMarketTypes(
  props: UseSportlineElementMarketsProps,
): UseSportlineElementMarketTypesComposable {
  const { sportlineElement } = props;

  const marketsTypes = computed<MarketType[]>(() => getMarketTypesFromMarketColumnsInfo(sportlineElement.value));

  return { marketsTypes };
}
