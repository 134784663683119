/* eslint-disable ts/no-redeclare */

export * from './CyberSportDiscipline';

export * from './SportFamily';

export { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';

export enum CustomFilter {
  MyFavoriteLeagues = 'MyFavoriteLeagues',
  AllEvents = 'AllEvents',
  None = 'None',
  Stream = 'Stream',
  Favorites = 'Favorite', // all favorites page (events and leagues) value same to favorite events
  /** @deprecated use ZeroMargin "best-odds" instead */
  ZeroMarginDeprecated = 'Margin-0',
  ZeroMargin = 'best-odds',
  Cybersport = 'Cybersport', // is used for navigation
}

export enum FilterIdentificator {
  SPORT = 'sport-filter',
  CUSTOM = 'custom-filter',
}

export enum LogoSource {
  Scores = 'scores',
  Manual = 'manual',
}

export enum MarketTypeSign {
  Default = 'Default',
  HomeDrawAway = 'HomeDrawAway',
  HomeAway = 'HomeAway',
  YesNo = 'YesNo',
  UnderOver = 'UnderOver',
  OddEven = 'OddEven',
}

export enum MatchProgressTimeDirection {
  None = 'None',
  ReversedNone = 'ReversedNone', // Static, but we need show info about reversed direction
  Direct = 'Direct',
  Reversed = 'Reversed',
}

export enum SportEventMatchPhase {
  PreGame = 'PRE_GAME',
  InPlay = 'IN_PLAY',
  GameAbandoned = 'GAME_ABANDONED',
  BreakInPlay = 'BREAK_IN_PLAY',
  Overtime = 'OVERTIME',
  PostGame = 'POST_GAME',
}

export const SelectionTag = {
  YES: 'YES',
  NO: 'NO',
  UNDER: 'UNDER',
  OVER: 'OVER',
  ODD: 'ODD',
  EVEN: 'EVEN',
  HOME: 'HOME',
  DRAW: 'DRAW',
  AWAY: 'AWAY',
  NEITHER: 'NEITHER',
  BOTH: 'BOTH',
  COMPETITOR: 'COMPETITOR',
  RANGE: 'RANGE',
  SCORE: 'SCORE',
  OTHER: 'OTHER',
  HOMEDRAW: 'HOMEDRAW',
  HOMEAWAY: 'HOMEAWAY',
  DRAWAWAY: 'DRAWAWAY',
} as const;
export type SelectionTag = typeof SelectionTag[keyof typeof SelectionTag];

export const SportEventMarketTypeTag = {
  TOTAL: 'TOTAL',
  HANDICAP: 'HANDICAP',
  REGULAR: 'REGULAR',
} as const;
export type SportEventMarketTypeTag = typeof SportEventMarketTypeTag[keyof typeof SportEventMarketTypeTag];

export enum SportEventStatus {
  Open = 1,
  Suspended = 2,
  Closed = 3,
}

export enum SportlineType {
  Live = 1,
  Prematch = 2,
}

export enum SportSegmentId {
  Default = 'default',
  CyberSport = 'cyberSport',
}

export const SportProgressBarType = {
  None: 'none',
  Linear: 'linear',
} as const;
export type SportProgressBarType = typeof SportProgressBarType[keyof typeof SportProgressBarType];

export const SportlineEventPeriodType = {
  Main: 1,
  Additional: 2,
} as const;
export type SportlineEventPeriodType = typeof SportlineEventPeriodType[keyof typeof SportlineEventPeriodType];

export const MarketGroupStyleType = {
  DEFAULT: 'DEFAULT',
  PROMO: 'PROMO',
} as const;
export type MarketGroupStyleType = typeof MarketGroupStyleType[keyof typeof MarketGroupStyleType];
