import type { Reactive } from 'vue';
import { toRef } from 'vue';

import type { UseVBrandProps, VBrandProps } from 'web/src/components/Brand/VBrand/types';

export function prepareUseLogoProps(props: Reactive<VBrandProps>): UseVBrandProps {
  return {
    isHeader: toRef(() => props.isHeader),
    isSimplifiedLogo: toRef(() => props.isSimplifiedLogo),
    isDark: toRef(() => props.isDark),
    isDarkIcon: toRef(() => props.isDarkIcon),
  };
}
