import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { SportlineType } from 'web/src/modules/sportline/enums';
import type {
  Market,
  Runner,
} from 'web/src/modules/sportline/types';
import type {
  RunnerProps as RunnerComponentProps,
  SlipInfoCaption,
} from 'web/src/modules/sportline/views/runner/types';
import { useSlipRunnerClick } from 'web/src/modules/slip/composable';
import { useSlipSelectedEntriesStore } from 'web/src/modules/slip/submodules/slipSelectedEntries/store';
import isMatchedSlipInfo from 'web/src/modules/slip/utils/isMatchedSlipInfo';
import { SportEventStatus } from 'web/src/modules/sportline/enums';
import { useOpenInCatalog } from 'web/src/modules/sportline/submodules/catalog';
import { createSlipInfoRef } from 'web/src/modules/sportline/views/runner/utils';

interface RunnerProps {
  runner: Ref<Maybe<Runner>>;
  market: Ref<Maybe<Market>>;
  sportEventId: Ref<string>;
  isOutrightEvent: Ref<undefined | boolean>;
  sportlineType: Ref<Maybe<SportlineType>>;
  slipCaption: Ref<SlipInfoCaption>;
  doDisplayName: Ref<boolean>;
}

export interface RunnerComposable {
  id: Ref<Optional<string>>;
  value: Ref<string>;
  price: Ref<number>;
  name: Ref<string>;
  isEmpty: Ref<boolean>;
  isLocked: Ref<boolean>;
  handicap: Ref<Optional<string>>;
  label: Ref<Maybe<string>>;
  isSelected: Ref<boolean>;
  addToSlip(payload: { event: MouseEvent }): void;
}

const DASHES = '--';

export function runnerComponentPropsToRefs(props: RunnerComponentProps): RunnerProps {
  return {
    runner: toRef(props, 'runner'),
    market: toRef(props, 'market'),
    sportEventId: toRef(props, 'sportEventId'),
    isOutrightEvent: toRef(props, 'isOutrightEvent'),
    sportlineType: toRef(props, 'sportlineType', null),
    slipCaption: toRef(props, 'slipCaption'),
    doDisplayName: toRef(props, 'doDisplayName', false),
  };
}

export function useRunner(props: RunnerProps): RunnerComposable {
  const { doDisplayName, runner } = props;

  const { onSlipRunnerClick } = useSlipRunnerClick();
  const { openInCatalogIfEnabled } = useOpenInCatalog();

  const slipSelectedEntriesStore = useSlipSelectedEntriesStore();

  const selectedEventsInfo = toRef(() => slipSelectedEntriesStore.selectedEventsInfo);

  const id = computed(() => runner.value?.id);
  const value = computed(() => runner.value?.value || DASHES);
  const price = computed(() => runner.value?.price ?? 0);
  const name = computed(() => runner.value?.name || DASHES);
  const isEmpty = computed(() => !runner.value);
  const isLocked = computed(() => runner.value?.status !== SportEventStatus.Open);
  const handicap = computed(() => runner.value?.handicap);

  const slipInfo = createSlipInfoRef(props);

  const isSelected = computed(() => selectedEventsInfo.value
    .some((item) => (slipInfo.value ? isMatchedSlipInfo(item, slipInfo.value) : false)));

  const label = computed<Maybe<string>>(() => {
    if (isEmpty.value) { return null; }
    if (doDisplayName.value) { return name.value ?? null; }
    return handicap.value ?? null;
  });

  function addToSlip({ event }: { event: MouseEvent }): void {
    // handle click if runner is not locked or if it is selected to remove it from the slip
    const canAddToSlip = !isLocked.value || isSelected.value;
    if (!canAddToSlip) { return; }

    const item = slipInfo.value;
    if (!item) { return; }

    if (openInCatalogIfEnabled(event, { eventId: item.event, marketId: item.market })) { return; }
    onSlipRunnerClick(item);
  }

  return {
    id,
    value,
    price,
    name,
    isEmpty,
    isLocked,
    handicap,
    isSelected,
    label,
    addToSlip,
  };
}
