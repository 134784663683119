import { AppError } from "@leon-hub/app-errors";
import { AbstractError, normalizeError } from "@leon-hub/errors";
import { useI18n } from "@core/i18n";
function useErrorsConverter() {
  const { $translate } = useI18n();
  const defaultMessage = $translate("WEB2_TECHNICAL_ERROR");
  function convertToBaseError(error, message) {
    if (error instanceof AbstractError) {
      return error;
    }
    return new AppError({
      message: message || defaultMessage.value,
      originalError: normalizeError(error, message)
    });
  }
  return {
    convertToBaseError
  };
}
export {
  useErrorsConverter
};
