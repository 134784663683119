import type { Ref } from 'vue';
import { toRef } from 'vue';

import type { BroadcastEventInfoSubscription } from 'web/src/modules/sportline/types';
import { useSportlineGlobalEventsStore } from 'web/src/modules/sportline/store/useSportlineGlobalEventsStore';

export interface GlobalSportlineEventsComposable {
  broadcastEventInfoSubscriptions: Ref<BroadcastEventInfoSubscription[]>;
  onInit(): void;
}

export function useGlobalSportlineEvents(): GlobalSportlineEventsComposable {
  const sportlineGlobalEventsStore = useSportlineGlobalEventsStore();
  const broadcastEventInfoSubscriptions = toRef(sportlineGlobalEventsStore, 'broadcastEventInfoSubscriptions');

  function onInit(): void {
    sportlineGlobalEventsStore.initSportlineEvents();
  }

  return {
    broadcastEventInfoSubscriptions,
    onInit,
  };
}
