import type { MaybeRef, Ref } from 'vue';
import { computed, toRef } from 'vue';

import { BetLine } from '@leon-hub/api-sdk';

import type { SlipListItem } from 'web/src/modules/slip/types';
import type {
  Market,
  Runner,
} from 'web/src/modules/sportline/types';
import type { SlipInfoCaption } from 'web/src/modules/sportline/views/runner/types';
import { SelectionTag, SportlineType } from 'web/src/modules/sportline/enums';

import { selectionTagsToRunnerTags } from './selectionTagsToRunnerTags';
import { sportEventMarketTypeToApi } from './sportEventMarketTypeToApi';

function sportlineTypeToBetLine({ type, isOutrightEvent }: {
  type: SportlineType;
  isOutrightEvent?: boolean;
}): BetLine {
  switch (true) {
    case (isOutrightEvent): return BetLine.OUTRIGHT;
    case (type === SportlineType.Live): return BetLine.LIVE;
    case (type === SportlineType.Prematch): return BetLine.PREMATCH;
    default: return BetLine.VSPORTS;
  }
}

export function getBetChoice(runner: Runner, competitors: string[]): string {
  const tags = new Set(runner.tags);

  if (tags.has(SelectionTag.HOME) && competitors[0]) { return competitors[0]; }
  if (tags.has(SelectionTag.AWAY) && competitors[1]) { return competitors[1]; }

  return runner.name;
}

export function createSlipInfoRef(props: {
  runner?: MaybeRef<Maybe<Runner>>;
  market?: MaybeRef<Maybe<Market>>;
  slipCaption: MaybeRef<SlipInfoCaption>;
  sportEventId?: MaybeRef<Maybe<string>>;
  isOutrightEvent?: MaybeRef<Optional<boolean>>;
  sportlineType?: MaybeRef<Maybe<SportlineType>>;
}): Ref<Maybe<SlipListItem>> {
  const runner = toRef(props.runner ?? null);
  const market = toRef(props.market ?? null);
  const slipCaption = toRef(props.slipCaption);
  const sportEventId = toRef(props.sportEventId ?? null);
  const isOutrightEvent = toRef(props.isOutrightEvent ?? false);
  const sportlineType = toRef(props.sportlineType ?? null);

  return computed<Maybe<SlipListItem>>(() => {
    if (
      !runner.value
      || !market.value
      || !sportlineType.value
      || !sportEventId.value
    ) { return null; }

    const slipItem: SlipListItem = {
      competitors: slipCaption.value.competitors,
      eventName: slipCaption.value.eventName || '',
      marketName: market.value.name || '',
      // @TODO fix after requirements will be updated for LEONWEB-14160
      // runnerName: getBetChoice(runner.value, slipCaption.value.competitors ?? []),
      runnerName: runner.value.name,
      event: Number(sportEventId.value),
      market: Number(market.value.id || 0),
      runner: Number(runner.value.id),
      odds: runner.value.price,
      oddsStr: runner.value.value,
      betline: sportlineTypeToBetLine({
        type: sportlineType.value,
        isOutrightEvent: isOutrightEvent.value,
      }),
      zeroMargin: !!market.value.hasZeroMargin,
      isPrimaryMarket: !!market.value.isPrimary,
      ts: Date.now(),
      marketTypeIdentifier: market.value?.marketTypeId,
      runnerTags: selectionTagsToRunnerTags(runner.value?.tags),
      marketTypeTag: sportEventMarketTypeToApi(market.value?.typeTag),
    };

    return slipItem;
  });
}
