import type { Ref } from 'vue';
import { computed } from 'vue';

import { Theme } from '@leon-hub/api-sdk';

import { useTheme } from '@core/theme';

import type { SportlineBackground } from 'web/src/modules/sportline/types';

interface UseSportlineBackgroundColorProps {
  background: Ref<Maybe<SportlineBackground>>;
}

interface UseSportlineBackgroundColorComposable {
  backgroundColor: Ref<Maybe<string>>;
}

export default function useSportlineBackgroundColor(
  props: UseSportlineBackgroundColorProps,
): UseSportlineBackgroundColorComposable {
  const { theme } = useTheme();

  const { background } = props;

  const backgroundColor = computed<Maybe<string>>(() => {
    if (theme.value === Theme.LIGHT && background.value?.light) {
      return background.value.light || null;
    }

    return background.value?.dark || null;
  });

  return {
    backgroundColor,
  };
}
