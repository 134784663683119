import type { Reactive, Ref } from 'vue';
import { computed } from 'vue';

import type {
  MarketColumnId,
  MarketsColumn,
  MarketType,
} from 'web/src/modules/sportline/types';

import type {
  ProvidedSelectedColumnMap,
  SelectedColumnInfoMap,
} from '../types';

/**
 * Creates selected columns for sport basis based on selected market type
 */
export function createSelectedColumnMapRef(
  selectedMarketTypes: Ref<SelectedColumnInfoMap<Maybe<MarketType>>>,
  columnsMap: Ref<SelectedColumnInfoMap<MarketsColumn[]>>,
  selectedColumnIdMap: Reactive<SelectedColumnInfoMap<Maybe<MarketColumnId>>>,
): Ref<ProvidedSelectedColumnMap> {
  return computed<ProvidedSelectedColumnMap>(() => {
    const result: ProvidedSelectedColumnMap = {};
    const selectedTypesValue = selectedMarketTypes.value;

    for (const id of Object.keys(selectedTypesValue)) {
      const selectedColumnId = selectedColumnIdMap[id] ?? null;
      const columns = columnsMap.value[id] ?? [];
      const selected = selectedTypesValue[id] ?? null;

      result[id] = {
        selectedColumnId,
        selectedColumnSize: selected?.columns ?? null,
        selectedColumnSign: selected?.sign ?? null,
        columns,
      };
    }

    return result;
  });
}
