import { toRef, watch } from 'vue';

import type {
  SyncStateCallback,
  SyncStateComposable,
  SyncStateOptions,
  SyncStateTimerName,
} from '@core/sync-state';
import { useRootStore } from '@core/root';
import { useSyncState } from '@core/sync-state';

type SyncSportlineOptions = Omit<SyncStateOptions, 'disableLoginLogoutHandlers'>;

/**
 * Sync state based on timer and ctag instead of is login state
 * @see LEONWEB-8926, LEONWEB-14562
 */
export function useSyncSportline(
  callback: SyncStateCallback,
  timerName: SyncStateTimerName,
  options: SyncSportlineOptions = {},
): SyncStateComposable {
  const rootStore = useRootStore();

  const cTag = toRef(() => rootStore.cTag);

  const syncState = useSyncState(callback, timerName, {
    ...options,
    disableLoginLogoutHandlers: true,
  });
  const { isSyncAvailable } = syncState;

  watch(cTag, () => {
    if (!isSyncAvailable.value) {
      return;
    }
    void callback();
  });

  return syncState;
}

/**
 * Should be used for graphql requests (in this case we can just sync state as usual)
 */
export function useSyncSportlineWithoutCTag(
  callback: SyncStateCallback,
  timerName: SyncStateTimerName,
  options: SyncStateOptions = {},
): SyncStateComposable {
  return useSyncState(callback, timerName, options);
}
