import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import type { AccumulatorCoefficientBoostSettings } from '@leon-hub/api-sdk';
import { SlipTypeId } from '@leon-hub/api-sdk';

import { useSiteConfigStore } from '@core/site-config';

import { useOddsSettings } from 'web/src/modules/sportline/composables/settings';
import formatOdd from 'web/src/utils/formatOdd';

import type { WinBoostOptionsRow } from '../types';
import { getFreebetMaxPrize } from '../../../utils';
import { useFreebetStore } from '../../freebet/store';
import { useSlipInfoStore } from '../../slip-info/store';
import { useStakeInputStore } from '../../stake-input/store';
import {
  getAccumulatorBoostPercentage,
  getAccumulatorBoostValues,
  getCurrentAccumulatorBoostSetting,
  getNextAccumulatorBoostSetting,
  getSatisfyingBoostSettingsEventsCount,
  getSortedBoostSettings,
  getWinBoostPercentageString,
  getWinBoostValue,
} from '../utils';

const useAccumulatorBoostStore = defineStore('accumulator-boost', () => {
  const { isOddsFormatSwitcherEnabled } = useOddsSettings();

  const siteConfigStore = useSiteConfigStore();

  const slipBlock = toRef(siteConfigStore, 'slipBlock');

  const accumulatorBoostEnabled = computed<boolean>(() => slipBlock.value?.accumulatorBoostEnabled ?? false);
  const accumulatorBoostMinOdds = computed<number>(() => slipBlock.value?.accumulatorBoostMinOdds ?? 1);
  const accumulatorBoostOnlyPrimaryMarkets = computed<boolean>(() => slipBlock.value?.accumulatorBoostOnlyPrimaryMarkets ?? false);
  const isWinBoost = computed<boolean>(() => slipBlock.value?.accumulatorBoostIsWinBoost ?? false);

  const accumulatorBoostPerOutcomeCount = computed<AccumulatorCoefficientBoostSettings[]>(() => slipBlock.value?.accumulatorBoostPerOutcomeCount ?? []);

  const freebetStore = useFreebetStore();

  const currentFreeBet = toRef(freebetStore, 'currentFreeBet');

  const slipInfoStore = useSlipInfoStore();

  const batchedSlipInfo = toRef(slipInfoStore, 'batchedSlipInfo');
  const combiOrFirstSlip = toRef(slipInfoStore, 'combiOrFirstSlip');
  const totalOdds = toRef(slipInfoStore, 'totalOdds');
  const betMode = toRef(slipInfoStore, 'betMode');
  const combinedEntriesInfo = toRef(slipInfoStore, 'combinedEntriesInfo');
  const selectedStakeValue = toRef(slipInfoStore, 'selectedStakeValue');

  const stakeInputStore = useStakeInputStore();

  const safeStakeValue = toRef(stakeInputStore, 'safeStakeValue');

  /** '--' is a placeholder what probably will not even visible with normal connection speed */
  const savedAccumulatorBoostMinOddsStr = ref<string>('--');

  watch(() => batchedSlipInfo.value?.accumulatorBoostMinOddsStr, (value?: string) => {
    if (value && isOddsFormatSwitcherEnabled.value) {
      savedAccumulatorBoostMinOddsStr.value = value;
    }
  });

  const formattedAccumulatorBoostMinOdds = computed<string>(() => {
    if (!isOddsFormatSwitcherEnabled.value) {
      return formatOdd(accumulatorBoostMinOdds.value);
    }
    return savedAccumulatorBoostMinOddsStr.value;
  });

  const totalOddsWithoutAccumulatorBoost = computed<number | null>(() => {
    const unBoostedOdds = combiOrFirstSlip.value?.totalOddsWithoutAccumulatorBoost;
    if (isWinBoost.value || !unBoostedOdds || unBoostedOdds === totalOdds.value) {
      return null;
    }
    return unBoostedOdds;
  });

  const formattedOddsWithoutBoost = computed<string>(() => {
    if (betMode.value !== SlipTypeId.EXPRESS
      || !totalOddsWithoutAccumulatorBoost.value
      || totalOdds.value === totalOddsWithoutAccumulatorBoost.value
    ) {
      return '';
    }
    if (isOddsFormatSwitcherEnabled.value) {
      return combiOrFirstSlip.value?.totalOddsWithoutAccumulatorBoostStr ?? '';
    }
    return formatOdd(totalOddsWithoutAccumulatorBoost.value);
  });

  const isAccumulatorBoostApplicable = computed<boolean>(() => {
    if (!accumulatorBoostEnabled.value) {
      return false;
    }
    return betMode.value === SlipTypeId.EXPRESS;
  });

  const satisfyingBoostSettingsEventsCount = computed<number>(() => getSatisfyingBoostSettingsEventsCount({
    combinerSlipEntries: combinedEntriesInfo.value,
    minOdds: accumulatorBoostMinOdds.value,
    onlyPrimaryMarkets: accumulatorBoostOnlyPrimaryMarkets.value,
  }));

  const sortedSettingsTable = ref<AccumulatorCoefficientBoostSettings[]>([]);
  const maxAccumulatorBoostMultiplier = ref<number>(0);
  const minAccumulatorBoostMultiplier = ref<number>(0);
  const minCount = ref<number>(0);

  const prepareSettings = (): void => {
    sortedSettingsTable.value = getSortedBoostSettings(accumulatorBoostPerOutcomeCount.value);
    const boostValues = getAccumulatorBoostValues({
      sortedSettings: sortedSettingsTable.value,
      isWinBoost: isWinBoost.value,
    });
    maxAccumulatorBoostMultiplier.value = boostValues.maxBoost;
    minAccumulatorBoostMultiplier.value = boostValues.minBoost;
    minCount.value = boostValues.minCount;
  };

  watch(accumulatorBoostPerOutcomeCount, () => {
    prepareSettings();
  }, { immediate: true });
  watch(isWinBoost, () => {
    prepareSettings();
  });

  const currentAccumulatorBoostMultiplierSetting = computed<AccumulatorCoefficientBoostSettings | null>(() => getCurrentAccumulatorBoostSetting(
    satisfyingBoostSettingsEventsCount.value,
    sortedSettingsTable.value,
  ));

  const currentAccumulatorBoostMultiplier = computed<number>(() => {
    if (!currentAccumulatorBoostMultiplierSetting.value) {
      return 0;
    }
    const { boost } = currentAccumulatorBoostMultiplierSetting.value;
    return isWinBoost.value ? boost : getAccumulatorBoostPercentage(boost);
  });

  const nextAccumulatorBoostMultiplierSetting = computed<AccumulatorCoefficientBoostSettings | null>(() => getNextAccumulatorBoostSetting(
    satisfyingBoostSettingsEventsCount.value,
    sortedSettingsTable.value,
  ));

  const nextAccumulatorBoostMultiplier = computed<number>(() => {
    if (!nextAccumulatorBoostMultiplierSetting.value) {
      return maxAccumulatorBoostMultiplier.value;
    }
    const { boost } = nextAccumulatorBoostMultiplierSetting.value;
    return isWinBoost.value ? boost : getAccumulatorBoostPercentage(boost);
  });

  const itemsToReachNextBoost = computed<number>(() => {
    if (!nextAccumulatorBoostMultiplierSetting.value) {
      return 0;
    }
    return nextAccumulatorBoostMultiplierSetting.value.count - satisfyingBoostSettingsEventsCount.value;
  });

  const maxPrizeWithoutBoost = computed<number | null>(() => {
    if (betMode.value !== SlipTypeId.EXPRESS || !totalOddsWithoutAccumulatorBoost.value || !selectedStakeValue.value) {
      return null;
    }
    if (currentFreeBet.value) {
      return getFreebetMaxPrize(currentFreeBet.value, totalOddsWithoutAccumulatorBoost.value) || null;
    }
    return totalOddsWithoutAccumulatorBoost.value * selectedStakeValue.value;
  });

  const potentialWin = computed<number>(() => (totalOdds.value ?? 0) * safeStakeValue.value);

  const winBoostValue = computed<number | null>(() => {
    if (!isAccumulatorBoostApplicable.value
      || !isWinBoost.value
      || !selectedStakeValue.value
      || currentFreeBet.value
      || !currentAccumulatorBoostMultiplier.value) {
      return null;
    }
    return getWinBoostValue({
      winValue: potentialWin.value,
      stake: safeStakeValue.value,
      boostPercent: currentAccumulatorBoostMultiplier.value,
    });
  });

  const boostedWinValue = computed<number | null>(() => {
    if (!winBoostValue.value) {
      return null;
    }
    return potentialWin.value + winBoostValue.value;
  });

  const winBoostInfoRows = computed<WinBoostOptionsRow[]>(() => {
    if (!accumulatorBoostEnabled.value || !isWinBoost.value) {
      return [];
    }
    return accumulatorBoostPerOutcomeCount.value.map((item) => ({
      count: `${item.count}`,
      boost: getWinBoostPercentageString(item.boost),
    }));
  });

  return {
    accumulatorBoostEnabled,
    formattedOddsWithoutBoost,
    isAccumulatorBoostApplicable,
    maxAccumulatorBoostMultiplier,
    minAccumulatorBoostMultiplier,
    minCount,
    currentAccumulatorBoostMultiplier,
    nextAccumulatorBoostMultiplier,
    itemsToReachNextBoost,
    maxPrizeWithoutBoost,
    formattedAccumulatorBoostMinOdds,
    isWinBoost,
    boostedWinValue,
    winBoostValue,
    winBoostInfoRows,
  };
});

export default useAccumulatorBoostStore;
