import type { Ref } from 'vue';
import {
  inject,
  ref,
  toRef,
} from 'vue';

import type { IconNameType, OptionalAnyIconName } from '@leon-hub/icons';
import { assert } from '@leon-hub/guards';
import { IconName } from '@leon-hub/icons';

import type { VListAccordionSharedStateProps, VListItemAccordionEmits, VListItemAccordionProps, VListItemInnerExpose } from '../types';
import { accordionSharedState, isListAccordionSharedStateProps } from '../types';
import { getAccordionIconName } from '../utils/getAccordionIconName';

export interface VListItemAccordionComposable {
  isOpen: Ref<boolean>;
  iconSuffixProperties: Ref<IconNameType>;
  iconNameAccordion: Ref<OptionalAnyIconName>;
  $accordionSharedState: VListAccordionSharedStateProps;
  isIndependentlyOpen: Ref<boolean>;
  toggle(force?: boolean): void;
  listItemRef: Ref<VListItemInnerExpose | undefined>;
  scrollIntoView(): void;
}

export function useVListItemAccordion(
  props: VListItemAccordionProps,
  emit: VListItemAccordionEmits,
): VListItemAccordionComposable {
  const listItemRef = ref<VListItemInnerExpose>();
  const $accordionSharedState = inject<VListAccordionSharedStateProps>(accordionSharedState);

  assert(isListAccordionSharedStateProps($accordionSharedState), 'List Accordion shared state incorrect');

  const isIndependentlyOpen = ref(false);

  const isOpen = toRef(() => {
    if (props.isDynamicOpen) {
      return !!props.open;
    }

    if ($accordionSharedState.independentItems) {
      return isIndependentlyOpen.value;
    }

    if ($accordionSharedState.openItem.value) {
      return $accordionSharedState.openItem.value === props.id;
    }

    return !!props.id && !!props.open;
  });

  function toggle(force = false): void {
    if (props.haveSwitcher && props.isSwitchDisabled && !force) {
      emit('click-disabled', true);
    }

    if (force || !props.haveSwitcher || (props.haveSwitcher && !props.isSwitchDisabled)) {
      if (!force) {
        emit('click', true);
      }

      isIndependentlyOpen.value = !isIndependentlyOpen.value;
      if (!$accordionSharedState?.independentItems) {
        $accordionSharedState?.updateOpenItem(isOpen.value ? null : props.id);
      }
    }
  }

  function scrollIntoView(): void {
    listItemRef.value?.scrollIntoView();
  }

  const iconOpen = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_MINUS_SMALL : IconName.EXPAND_UP;
  const iconClose = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_PLUS_SMALL : IconName.EXPAND_DOWN;

  return {
    isOpen,
    iconSuffixProperties: toRef(() => (isOpen.value ? iconOpen : iconClose)),
    iconNameAccordion: toRef(() => getAccordionIconName(props)),
    $accordionSharedState,
    isIndependentlyOpen,
    toggle,
    listItemRef,
    scrollIntoView,
  };
}
