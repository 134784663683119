import type { Ref } from 'vue';
import { toRef } from 'vue';

import type { FastMoneyChoiceOption } from 'web/src/components/FastMoneyChoice';
import { useFastBetsStore } from 'web/src/modules/fast-bets/store';

interface UseFastBetsValues {
  isFastBetsEnabled: Ref<boolean>;
  fastBetsShowMax: Ref<boolean>;
  fastBetsOptions: Ref<FastMoneyChoiceOption[]>;
}

export default function useFastBetsValues(): UseFastBetsValues {
  const fastBetsStore = useFastBetsStore();

  const isFastBetsEnabled = toRef(fastBetsStore, 'isFastBetsEnabled');

  const fastBetsShowMax = toRef(fastBetsStore, 'fastBetsShowMax');

  const fastBetsOptions = toRef(fastBetsStore, 'fastBetsOptions');

  return {
    isFastBetsEnabled,
    fastBetsShowMax,
    fastBetsOptions,
  };
}
