import type { SlipEntry } from '../../slip-info/types';
import type { MultiSinglesMetaInfo } from '../types';
import getSlipEntryId from '../../../utils/getSlipEntryId';

interface Payload {
  slipEntryCache: SlipEntry[];
  currentMetaInfo: MultiSinglesMetaInfo;
  defaultBetAmount: number | null;
}

export default function getMultiSinglesMetaInfoForCache({
  slipEntryCache,
  currentMetaInfo,
  defaultBetAmount,
}: Payload): MultiSinglesMetaInfo {
  const cacheMetaInfo: MultiSinglesMetaInfo = {};
  for (const item of slipEntryCache) {
    cacheMetaInfo[getSlipEntryId(item)] = {
      stakeValue: `${defaultBetAmount || 0}`,
    };
  }
  return {
    ...currentMetaInfo,
    ...cacheMetaInfo,
  };
}
