import {
  doFavoriteSportEventRemove,
  doFavoriteSportEventsAdd,
  doToggleCustomerLeague,
  getCustomerLeagues,
  getCustomerMatches,
  RequestOptionsPriority,
} from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type {
  DoFavoriteSportEventRemoveResponse,
  DoFavoriteSportEventsAddResponse,
  DoToggleCustomerLeagueResponse,
  GetCustomerLeaguesResponse,
  GetCustomerMatchesResponse,
} from 'web/src/modules/sportline/types/rest';

export function toggleFavoriteLeagueRequest(
  { sportId, leagueId }: { leagueId: string; sportId: string },
  { silent }: { silent?: boolean },
): Promise<DoToggleCustomerLeagueResponse> {
  const apiClient = useGraphqlClient();
  return doToggleCustomerLeague(apiClient, (node) => node.mutations.customer.leagues.toggleLeague, { options: { leagueKey: `${sportId}_${leagueId}` } }, { silent });
}

export function getFavoritesLeaguesIdsRequest(
  options: { dateFrom?: Maybe<number>; dateTo?: Maybe<number> },
  { silent }: { silent?: boolean },
): Promise<GetCustomerLeaguesResponse> {
  const apiClient = useGraphqlClient();
  return getCustomerLeagues(apiClient, (node) => node.queries.customer.leagues.getLeagues, { options }, {
    silent,
    priority: silent ? RequestOptionsPriority.LOW : undefined,
  });
}

export function getFavoritesEventsIdsRequest(
  { silent }: { silent?: boolean },
): Promise<GetCustomerMatchesResponse> {
  const apiClient = useGraphqlClient();
  return getCustomerMatches(apiClient, (node) => node.queries.customer.matches.listMatchesIds, { options: {} }, {
    silent,
    priority: silent ? RequestOptionsPriority.LOW : undefined,
  });
}

export function addFavoriteEventsRequest(
  options: { eventIds: number[] },
  { silent }: { silent?: boolean },
): Promise<DoFavoriteSportEventsAddResponse> {
  const apiClient = useGraphqlClient();
  return doFavoriteSportEventsAdd(apiClient, (node) => node.mutations.customer.matches.addMatches, { options }, { silent });
}

export function removeFavoriteEventRequest(
  options: { eventId: number },
  { silent }: { silent?: boolean },
): Promise<DoFavoriteSportEventRemoveResponse> {
  const apiClient = useGraphqlClient();
  return doFavoriteSportEventRemove(apiClient, (node) => node.mutations.customer.matches.removeMatch, { options }, { silent });
}
