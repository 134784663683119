import type { KeyBoardInputPayload } from '../types';
import { KeyboardInputType } from '../../../enums';

function handleBackspace (currentValue: string): string {
  if (!currentValue.length || currentValue.length === 1) {
    return '';
  }
  return currentValue.slice(0, -1);
}

function handleNumber (currentValue: string, numberInput?: string): string {
  const numbersAfterDot = currentValue.split('.')[1];
  const reachedDecimalLimit = numbersAfterDot && numbersAfterDot.length >= 2;
  if (!numberInput || reachedDecimalLimit) return currentValue;
  if (currentValue === '0') {
    if (numberInput === '0') {
      return currentValue;
    }
    return numberInput;
  }
  return `${currentValue}${numberInput}`;
}

function handleDecimalSeparatorInput (currentValue: string): string {
  if (currentValue.includes('.')) {
    return currentValue;
  }
  return `${currentValue === '' ? '0' : currentValue}.`;
}

export default function getModifiedStakeInputValue(
  source: string,
  { action, value, removeCurrentValue }: KeyBoardInputPayload,
): string {
  const currentValue = removeCurrentValue ? '' : source;

  switch (action) {
    case KeyboardInputType.BACKSPACE:
      return handleBackspace(currentValue);
    case KeyboardInputType.NUMBER:
      return handleNumber(currentValue, value);
    case KeyboardInputType.DECIMAL:
      return handleDecimalSeparatorInput(currentValue);
    default:
      return currentValue;
  }
}
