import type { PriceChangePolicy } from '@leon-hub/api-sdk';
import { MarketStatus } from '@leon-hub/api-sdk';

import type { MultiSinglesMetaInfo } from '../../multi-singles/types';
import type { BetInfoObject, CombinedSlipEntryInfo, SlipEntry } from '../types';
import { OddChangeVariants } from '../../../components/SlipListItem/enums';
import { getSlipEntryId } from '../../../utils';
import { getSafeCompetitorsValue } from './getSafeCompetitorsValue';
import isPriceChangedMatters from './isPriceChangedMatters';

function getChangeVariant (marketStatus?: MarketStatus | null): OddChangeVariants {
  switch (marketStatus) {
    case MarketStatus.PRICE_DOWN:
      return OddChangeVariants.DOWN;
    case MarketStatus.PRICE_UP:
      return OddChangeVariants.UP;
    default:
      return OddChangeVariants.NONE;
  }
}

export default function getCombinedSlipEntryInfo(payload: {
  input: SlipEntry[];
  betsInfo: BetInfoObject;
  multiSinglesMetaInfo: MultiSinglesMetaInfo;
  priceChangePolicy?: PriceChangePolicy | null;
}): CombinedSlipEntryInfo[] {
  const {
    input,
    betsInfo,
    multiSinglesMetaInfo,
    priceChangePolicy,
  } = payload;
  return input.map((entry) => {
    const id = getSlipEntryId(entry);
    const matchedBetsInfo = betsInfo[id];
    if (!matchedBetsInfo) {
      throw new Error('getCombinedSlipEntryInfo: there is no matched betsInfo');
    }
    const metaInfo = multiSinglesMetaInfo[id];
    const matchedPriceChangePolicy = !isPriceChangedMatters({
      priceChangePolicy,
      originalOdds: matchedBetsInfo?.originalOdds,
      currentOdds: entry.odds,
    });
    let oddChangeVariant = getChangeVariant(entry.marketStatus);
    if (!matchedPriceChangePolicy && entry.odds && matchedBetsInfo.originalOdds) {
      if (entry.odds > matchedBetsInfo.originalOdds) {
        oddChangeVariant = OddChangeVariants.UP;
      } else {
        oddChangeVariant = OddChangeVariants.DOWN;
      }
    }
    return {
      id,
      ...entry,
      competitors: getSafeCompetitorsValue(matchedBetsInfo?.competitors),
      eventName: entry.eventName ?? matchedBetsInfo.eventName,
      marketName: entry.marketName ?? matchedBetsInfo.marketName,
      runnerName: entry.runnerName ?? matchedBetsInfo.runnerName,
      originalOdds: matchedBetsInfo.originalOdds,
      originalOddsStr: matchedBetsInfo.originalOddsStr,
      isPrimaryMarket: entry.isPrimaryMarket ?? matchedBetsInfo.isPrimaryMarket,
      metaInfo,
      matchedPriceChangePolicy,
      oddChangeVariant,
      oldRunnerName: matchedBetsInfo.oldRunnerName,
      replacedAt: matchedBetsInfo.replacedAt,
    };
  });
}
