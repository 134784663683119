import { BetSlipResultState, BetSlipSummaryStatus } from '../../../enums';

interface Payload {
  resultState: BetSlipResultState;
  stakeAboveMaximum: boolean;
  notEnoughBalance: boolean;
  stakeBelowMinimum: boolean;
  marketsChanged: boolean;
  entriesChanged: boolean;
  pricesChanged: boolean;
  hasLimitError: boolean;
  isFreeBet: boolean;
}

export default function getSummaryStatus({
  resultState,
  stakeAboveMaximum,
  notEnoughBalance,
  stakeBelowMinimum,
  marketsChanged,
  pricesChanged,
  entriesChanged,
  hasLimitError,
  isFreeBet,
}: Payload): BetSlipSummaryStatus {
  if (resultState === BetSlipResultState.ERROR
    || resultState === BetSlipResultState.SUCCESS) {
    return BetSlipSummaryStatus.DONE;
  }
  if (resultState === BetSlipResultState.PENDING
    || resultState === BetSlipResultState.WAIT_FOR_RETRY) {
    return BetSlipSummaryStatus.WAITING;
  }
  if (hasLimitError) {
    return BetSlipSummaryStatus.LIMIT;
  }
  if (marketsChanged && pricesChanged) {
    return BetSlipSummaryStatus.CHANGED_PRICES_AND_MARKETS;
  }
  if (marketsChanged) {
    return BetSlipSummaryStatus.MARKETS_CHANGED;
  }
  if (pricesChanged && entriesChanged) {
    return BetSlipSummaryStatus.CHANGED_PRICES_AND_ENTRIES;
  }
  if (pricesChanged) {
    return BetSlipSummaryStatus.PRICES_CHANGED;
  }
  if (entriesChanged) {
    return BetSlipSummaryStatus.ENTRIES_CHANGED;
  }
  if (notEnoughBalance) {
    return BetSlipSummaryStatus.NOT_ENOUGH_BALANCE;
  }
  if (isFreeBet && (stakeAboveMaximum || stakeBelowMinimum)) {
    return BetSlipSummaryStatus.UNABLE_TO_APPLY_FREEBET;
  }
  if (stakeBelowMinimum) {
    return BetSlipSummaryStatus.BELOW_MINIMUM;
  }
  if (stakeAboveMaximum) {
    return BetSlipSummaryStatus.ABOVE_MAXIMUM;
  }
  return BetSlipSummaryStatus.DEFAULT;
}
