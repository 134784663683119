import type {
  BetInfoItem,
  BetInfoObject,
  SlipEntry,
  SlipEntryId,
} from '../types';
import findSlipEntryById from '../../../utils/findSlipEntryById';

interface Payload {
  id: SlipEntryId;
  allEntries: SlipEntry[];
  currentBetsInfo: BetInfoObject;
  clearReplacementData?: boolean;
}

const emptyReplacementDataFields: Pick<BetInfoItem, 'replacedAt' | 'oldRunnerName'> = {
  oldRunnerName: undefined,
  replacedAt: undefined,
};

export default function updateBetsInfoOddById({
  id,
  allEntries,
  currentBetsInfo,
  clearReplacementData,
}: Payload): BetInfoObject | null {
  const currentItem = findSlipEntryById(id, allEntries);
  if (currentItem && currentBetsInfo[id]) {
    return {
      ...currentBetsInfo,
      [id]: {
        ...currentBetsInfo[id],
        ...(clearReplacementData ? emptyReplacementDataFields : {}),
        originalOdds: currentItem.odds,
      },
    };
  }
  return null;
}
