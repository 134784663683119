import type { Theme } from '@leon-hub/api-sdk';

import type { AnalyticsComposable } from '@core/analytics';

import type { GetTrackingSlipEntriesPayload, TrackingPayload } from '../types';
import betPlaceSuccessYMTrigger from './betPlaceSuccessYMTrigger';
import getTrackingSlipEntries from './getTrackingSlipEntries';
import logPlaceBetResultSuccess from './logPlaceBetResultSuccess';
import trackSportBet from './trackSportBet';

export default function makeBetResultLog(
  getTrackingSlipEntriesPayload: GetTrackingSlipEntriesPayload,
  additionalTrackData: Pick<TrackingPayload, 'liveEventsIds'
  | 'slipType'
  | 'priceChangePolicy'
  | 'currency'
  | 'login'>,
  {
    theme,
    analyticsService,
    useDefaultBet,
    amountBet,
    currentValue,
  }: {
    theme: Theme;
    analyticsService: AnalyticsComposable;
    useDefaultBet: boolean;
    amountBet: number | false;
    currentValue: number;
  },
): void {
  const trackingPayload: TrackingPayload = {
    slipEntries: getTrackingSlipEntries(getTrackingSlipEntriesPayload),
    ...additionalTrackData,
  };
  logPlaceBetResultSuccess(trackingPayload);
  betPlaceSuccessYMTrigger(trackingPayload, {
    theme,
    analyticsService,
    useDefaultBet,
    amountBet,
    currentValue,
  });

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    trackSportBet(trackingPayload);
  }
}
