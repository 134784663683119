import type { RegionElement, SportElement, SportlineEvent } from 'web/src/modules/sportline/types';

import { reduceListWithSportEventsCounters } from '../getCounters';

export default function filterSportListRegions<SE extends SportlineEvent = SportlineEvent>(
  sportElements: SportElement<SE>[],
  filter: (regionElement: RegionElement<SE>) => boolean,
): SportElement<SE>[] {
  return sportElements
    .map((sportElement) => {
      const regions = sportElement.regions.filter((regionElement) => filter(regionElement));

      return {
        ...sportElement,
        regions,
        counters: reduceListWithSportEventsCounters(regions),
      };
    })
    .filter((sportElement) => sportElement.regions.length > 0);
}
