import { CountryCode } from '@components/country-flag';

import { CyberSportDiscipline } from 'web/src/modules/sportline/enums';

type RegionFamily = CyberSportDiscipline | CountryCode;

export function isCyberSportDiscipline(value: unknown): value is CyberSportDiscipline {
  return Object.values(CyberSportDiscipline).includes(value as CyberSportDiscipline);
}

export function isRegionFamily(value: unknown): value is RegionFamily {
  return isCyberSportDiscipline(value) || Object.values(CountryCode).includes(value as CountryCode);
}
