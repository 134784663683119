import { RequestGroupScore24 } from '@leon-hub/api';
import {
  getBetlineLeagueStandings as getBetlineLeagueStandingsByEventId,
  getLeagueStandingsByLeagueId as getBetlineLeagueStandingsByLeagueId,
} from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type { BetlineLeagueStandingsResponse } from 'web/src/modules/sportline/types/rest';

export default function getBetlineLeagueStandings(
  options: { eventId: number } | { leagueId: number },
  { silent }: { silent?: boolean },
): Promise<BetlineLeagueStandingsResponse> {
  const apiClient = useGraphqlClient();

  if ('leagueId' in options) {
    return getBetlineLeagueStandingsByLeagueId(apiClient, (node) => node.queries.betLine.getLeagueStandingsByLeagueId, { options }, {
      silent,
      group: RequestGroupScore24,
    });
  }

  return getBetlineLeagueStandingsByEventId(apiClient, (node) => node.queries.betLine.getLeagueStandings, { options }, {
    silent,
    group: RequestGroupScore24,
  });
}
