<script lang="ts" setup>
import type { VDynamicContentLinkEmits, VDynamicContentLinkProps } from './types';
import { useVDynamicContentLink } from './composables';

const props = withDefaults(defineProps<VDynamicContentLinkProps>(), {
  href: '',
});
const emit = defineEmits<VDynamicContentLinkEmits>();
const {
  handleClick,
  computedRel,
} = useVDynamicContentLink(props, emit);
</script>

<template>
  <a v-auto-id="'VDynamicContentLink'"
    :href="href"
    :rel="computedRel"
    :target="target"
    @click="handleClick($event)"
  >
    <slot />
  </a>
</template>
