<script setup lang="ts">
import type { SBadgeProps } from '../../sbadge-types';
import type { VBadgeEmits, VBadgeProps } from '../../types';
import LBadge from '../LBadge/LBadge.vue';
import SBadge from '../SBadge/SBadge.vue';

const props = defineProps<VBadgeProps>();
const emit = defineEmits<VBadgeEmits>();
function remappedProps(p: VBadgeProps): SBadgeProps {
  return {
    size: 'small',
    type: 'primary',
    iconName: p.iconName,
    label: p.label,
    isCounter: p.isCounter,
  };
}
</script>

<template>
  <SBadge
    v-if="null"
    v-bind="remappedProps(props)"
    @click="emit('click', $event)"
  />
  <LBadge
    v-else
    v-bind="props"
    @click="emit('click', $event)"
  />
</template>
