import type { ComputedRef, Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { IPriceChangePolicy } from '@leon-hub/api-sdk';

import { usePriceChangePolicy } from '@core/customer';

import type { BetInputErrorKind } from 'web/src/modules/fast-bets/enums';
import type { BetSlipEditFastBetItem } from 'web/src/modules/fast-bets/types';
import { useFastBetsStore } from 'web/src/modules/fast-bets/store';

import { SettingsTabs } from '../enums';
import { useSlipInfoStore } from '../submodules/slip-info/store';
import useSlipUserSettings from '../submodules/slip-user-settings/store/useSlipUserSettings';
import { useSlipViewSettingsStore } from '../submodules/slip-view-settings/store';

interface UseBetSlipSettings {
  // settings block
  activeSettingsTabId: Ref<SettingsTabs>;
  selectSettingsTab(id: SettingsTabs): void;
  onSettingsBackClick(): void;
  // SlipCommonSettings
  priceChangePolicy: Ref<IPriceChangePolicy>;
  totalHandicapPriceChangePolicy: Ref<IPriceChangePolicy>;
  showSlipOnFirstAdded: Ref<boolean>;
  totalHandicapReplaceEnabled: Ref<boolean>;
  changePriceChangePolicy(value: IPriceChangePolicy): void;
  changeTotalHandicapPolicy(value: IPriceChangePolicy): void;
  toggleShowSlipOnFirstAdded(): void;
  // editFastBetProps
  minFastBet: Ref<number>;
  maxFastBet: Ref<number | null>;
  maxEnabled: Ref<boolean>;
  changesIsSaved: Ref<boolean>;
  fastBets: Ref<BetSlipEditFastBetItem[]>;
  useStandardBet: Ref<boolean>;
  minStandardBet: ComputedRef<number>;
  maxStandardBet: ComputedRef<number | undefined>;
  standardBetAmount: Ref<number | null>;
  standardBetAmountIsSaved: Ref<boolean>;
  standardBetInputErrorKind: Ref<BetInputErrorKind>;
  initFastBetInputValues(): void;
  onBastBetSettingsLeave(): void;
  handleFastBetValuesBlur(): void;
  handleFastBetValuesChange(values: string[]): void;
  onStandardBetFocus(): void;
  handleFastBetMaxSwitch(): Promise<void>;
  toggleUseStandardBet(): void;
  onStandardBetInput(value: string): void;
  onStandardBetBlur(): void;
}

export function useBetSlipSettings(): UseBetSlipSettings {
  const { priceChangePolicy, totalHandicapPriceChangePolicy, savePriceChangePolicy } = usePriceChangePolicy();

  const slipInfoStore = useSlipInfoStore();

  const slipSettingsStore = useSlipUserSettings();

  const fastBetsStore = useFastBetsStore();

  const slipViewSettingsStore = useSlipViewSettingsStore();

  const { resetPriceChangesError, acceptEntriesReplace } = slipInfoStore;

  const totalHandicapReplaceEnabled = toRef(slipInfoStore, 'totalHandicapReplaceEnabled');

  const {
    initFastBetInputValues,
    handleFastBetValuesBlur,
    handleFastBetValuesChange,
    clearFastBetInputValues,
    handleFastBetMaxSwitch,
  } = fastBetsStore;

  const {
    selectSettingsTab,
    saveBetSlipConfiguration,
    clearEditStandardBet,
    onStandardBetFocus,
    onStandardBetInput,
    onStandardBetBlur,
  } = slipSettingsStore;

  const activeSettingsTabId = toRef(slipSettingsStore, 'activeSettingsTabId');
  const showSlipOnFirstAdded = toRef(slipSettingsStore, 'showSlipOnFirstAdded');

  // SlipEditFastBet
  const minFastBet = toRef(fastBetsStore, 'minAvailableFastBet');
  const maxFastBet = toRef(fastBetsStore, 'maxAvailableFastBet');
  const fastBets = toRef(fastBetsStore, 'fastBetInputItems');
  const changesIsSaved = toRef(fastBetsStore, 'fastBetsSaved');
  const maxEnabled = toRef(fastBetsStore, 'fastBetsShowMax');

  const standardBetLimitations = toRef(slipSettingsStore, 'standardBetLimitations');
  const standardBetInputErrorKind = toRef(slipSettingsStore, 'standardBetInputErrorKind');
  const standardBetAmountIsSaved = toRef(slipSettingsStore, 'standardBetAmountIsSaved');
  const standardBetAmount = toRef(slipSettingsStore, 'standardBetAmount');
  const useStandardBet = toRef(slipSettingsStore, 'useStandardBet');

  const minStandardBet = computed<number>(() => standardBetLimitations.value?.min || 0);
  const maxStandardBet = computed<number | undefined>(() => standardBetLimitations.value?.max);

  const toggleShowSlipOnFirstAdded = (): void => {
    void saveBetSlipConfiguration({
      showSlipOnFirstAdded: !showSlipOnFirstAdded.value,
    });
  };

  const toggleUseStandardBet = (): void => {
    void saveBetSlipConfiguration({
      useStandardBet: !useStandardBet.value,
    });
  };

  const onBastBetSettingsLeave = (): void => {
    clearFastBetInputValues();
    clearEditStandardBet();
    onStandardBetFocus();
  };

  const {
    hideSlipSettings,
  } = slipViewSettingsStore;

  const onSettingsBackClick = (): void => {
    hideSlipSettings();
    selectSettingsTab(SettingsTabs.COMMON_SETTINGS);
  };

  const changePriceChangePolicy = (value: IPriceChangePolicy): void => {
    resetPriceChangesError();
    void savePriceChangePolicy({
      priceChangePolicy: value,
    });
  };

  const changeTotalHandicapPolicy = (value: IPriceChangePolicy): void => {
    void savePriceChangePolicy({
      totalHandicapPriceChangePolicy: value,
    });
    acceptEntriesReplace();
  };

  return {
    activeSettingsTabId,
    selectSettingsTab,
    onSettingsBackClick,
    priceChangePolicy,
    totalHandicapPriceChangePolicy,
    showSlipOnFirstAdded,
    totalHandicapReplaceEnabled,
    changePriceChangePolicy,
    changeTotalHandicapPolicy,
    toggleShowSlipOnFirstAdded,
    minFastBet,
    maxFastBet,
    maxEnabled,
    changesIsSaved,
    fastBets,
    useStandardBet,
    minStandardBet,
    maxStandardBet,
    standardBetAmount,
    standardBetAmountIsSaved,
    standardBetInputErrorKind,
    initFastBetInputValues,
    onBastBetSettingsLeave,
    handleFastBetValuesBlur,
    handleFastBetValuesChange,
    onStandardBetFocus,
    handleFastBetMaxSwitch,
    toggleUseStandardBet,
    onStandardBetInput,
    onStandardBetBlur,
  };
}
