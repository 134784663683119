import type {
  Maybe,
  Optional,
} from '@leon-hub/types';
import { IconSize } from '@leon-hub/icons';

import { VCountryFlagSize } from '../enums';

export function mapIconToFlagSize(size?: Maybe<IconSize>): Optional<VCountryFlagSize> {
  switch (size) {
    case IconSize.SIZE_16: return VCountryFlagSize.SIZE_16;
    case IconSize.SIZE_20: return VCountryFlagSize.SIZE_20;
    case IconSize.SIZE_24: return VCountryFlagSize.SIZE_24;
    case IconSize.SIZE_28: return VCountryFlagSize.SIZE_28;
    default: return undefined;
  }
}
