import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import type {
  ClaimToUpdateSportEventRunnerOddChangedEventData,
  ClaimToUpdateSportEventStatusChangedEventData,
} from 'web/src/modules/slip/types/сlaimToUpdate';
import type {
  CoreSportEventResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import createApplyEventsStatusChanges from 'web/src/modules/sportline/utils/apply-changes/applyEventsStatusChanges';
import createApplyRunnersOddsChanges from 'web/src/modules/sportline/utils/apply-changes/createApplyRunnersOddsChanges';

interface SportlineEventsChangesListenersProps<T extends GetSportEventsResponse | CoreSportEventResponse> {
  getResponse(): Maybe<T>;
  setResponse(response: T): void;
}

interface SportlineEventsChangesListenersComposable {
  onInit(): void;
}

export function useSportlineEventsChangesListeners<T extends GetSportEventsResponse | CoreSportEventResponse>(
  props: SportlineEventsChangesListenersProps<T>,
): SportlineEventsChangesListenersComposable {
  const bus = useEventsBus();

  const applyStatusChanges = createApplyEventsStatusChanges(props.getResponse, props.setResponse);
  const applyOddsChanges = createApplyRunnersOddsChanges(props.getResponse, props.setResponse);

  function onInit(): void {
    bus.on(BusEvent.SPORT_EVENTS_STATUS_CHANGED, (data: ClaimToUpdateSportEventStatusChangedEventData) => {
      if (data.source === 'line') { return; }
      applyStatusChanges(data.events);
    });
    bus.on(BusEvent.SPORT_EVENTS_RUNNERS_CHANGED, (data: ClaimToUpdateSportEventRunnerOddChangedEventData) => {
      if (data.source === 'line') { return; }
      applyOddsChanges(data.events);
    });
  }

  return {
    onInit,
  };
}
