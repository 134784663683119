export enum BadgeKind {
  ACCENT = 'accent',
  DEFAULT = 'default',
  SQUARE = 'square',
  SQUARE_WHITE = 'square-white',
  SQUARE_HIGHLIGHT = 'square-highlight',
  SQUARE_LIGHT = 'square-light',
  SQUARE_ERROR = 'square-error',
  SQUARE_INVERT = 'square-invert',
  CHAT = 'chat',
  WHITE = 'white',
  GREEN = 'green',
  DISABLED = 'disabled',
}

export enum BadgePosition {
  DEFAULT = 'default',
  TOP = 'top',
  RIGHT = 'right',
  LEFT = 'left',
}

export enum BadgeSize {
  DEFAULT = 'default',
  LARGE = 'large',
}
