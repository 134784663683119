import type { Slots } from 'vue';

import {
  isObject,
  isString,
} from '@leon-hub/guards';

import type {
  VBaseButtonProps,
  VButtonProps,
} from '../types';

export const buttonKinds = [
  'primary',
  'secondary',
  'secondary-outline',
  'tertiary-primary',
  'tertiary-secondary',
  'quaternary-primary',
  'quaternary-secondary',
  'quinary-primary',
  'quinary-secondary',
] as const;

export type SButtonKind = typeof buttonKinds[number];

export const buttonSizes = [
  'xsmall', // 32px
  'small', // 36px
  'medium', // 44px
  'large', // 52px
  'xlarge', // 56px
] as const;

export type SButtonSize = typeof buttonSizes[number];

export interface SButtonProps extends VBaseButtonProps {
  kind?: SButtonKind;
  size?: SButtonSize;
}

export type SButtonSlots = Slots & {
  default(props: Record<string, never>): void; // Label
};

export function isSButtonProps(argument: unknown): argument is SButtonProps {
  return !!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
    && isObject(argument) && isString(argument.kind) && (buttonKinds as readonly string[]).includes(argument.kind);
}

export function isVButtonProps(argument: unknown): argument is VButtonProps {
  return !isSButtonProps(argument);
}
