import type { MarketType } from 'web/src/modules/sportline/types';
import type { CoreMarketResponse } from 'web/src/modules/sportline/types/rest';
import { extractSignBySelectionTags } from 'web/src/modules/sportline/utils/rest/pre-build';

export default function createMarketType(marketResponse: CoreMarketResponse): MarketType {
  return {
    id: String(marketResponse.marketTypeId),
    name: marketResponse.name,
    columns: marketResponse.cols,
    sign: extractSignBySelectionTags(marketResponse.selectionTypes ?? []),
  };
}
