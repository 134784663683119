import type { Reactive, Ref } from 'vue';
import { computed } from 'vue';

import type {
  MarketColumnId,
  MarketsColumn,
  MarketType,
} from 'web/src/modules/sportline/types';
import { selectMarketTypeByColumn } from 'web/src/modules/sportline/utils/markets';

import type {
  SelectedColumnInfoElement,
  SelectedColumnInfoMap,
} from '../types';

/**
 * Creates selected market type map for sport basis
 * (selected type for sport, one from available for all columns)
 */
export function createSelectedMarketTypesMapRef(
  sportlineElements: Ref<SelectedColumnInfoElement[]>,
  columnsMap: Ref<SelectedColumnInfoMap<MarketsColumn[]>>,
  marketTypesMap: Ref<SelectedColumnInfoMap<MarketType[]>>,
  selectedColumnIdMap: Reactive<SelectedColumnInfoMap<Maybe<MarketColumnId>>>,
): Ref<SelectedColumnInfoMap<Maybe<MarketType>>> {
  return computed<SelectedColumnInfoMap<Maybe<MarketType>>>(() => {
    const elements = sportlineElements.value;
    const result: SelectedColumnInfoMap<Maybe<MarketType>> = {};

    for (const element of elements) {
      const { key } = element;

      if (!key) {
        continue;
      }

      const columns = columnsMap.value[key] ?? [];
      const allMarketsTypes = marketTypesMap.value[key] ?? [];
      const selectedColumnId = selectedColumnIdMap[key] ?? null;

      result[key] = selectMarketTypeByColumn({ columns, allMarketsTypes, selectedColumnId });
    }

    return result;
  });
}
