import { defineStore } from 'pinia';
import { ref } from 'vue';

import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import type {
  BroadcastEventInfoSubscription,
  CatalogClickPayload,
} from 'web/src/modules/sportline/types';
import { useOpenInCatalog } from 'web/src/modules/sportline/submodules/catalog';

/** @deprecated @TODO split and use composable instead of EventsBus */
export const useSportlineGlobalEventsStore = defineStore('sportline-global-events', () => {
  const eventsBus = useEventsBus();

  const { openInCatalogIfEnabled } = useOpenInCatalog();

  let isSportlineEventsInited = false;

  const broadcastEventInfoSubscriptions = ref<BroadcastEventInfoSubscription[]>([]);

  function internalInitOpenCatalogClick(): void {
    eventsBus.on(BusEvent.CATALOG_CLICK, (
      { event, payload }: { type: string; event: MouseEvent; payload: CatalogClickPayload },
    ) => {
      openInCatalogIfEnabled(event, payload);
    });
  }

  function internalInitBroadcastSlipChanges(): void {
    eventsBus.on(
      BusEvent.SLIP_SELECTION_CHANGE,
      ({ selected }: (typeof BusEvent.SLIP_SELECTION_CHANGE)['Payload']) => {
        broadcastEventInfoSubscriptions.value = selected.map((key) => ({ key }));
      },
    );
  }

  function initSportlineEvents(): void {
    if (isSportlineEventsInited) { return; }

    internalInitOpenCatalogClick();
    internalInitBroadcastSlipChanges();

    isSportlineEventsInited = true;
  }

  return {
    initSportlineEvents,
    broadcastEventInfoSubscriptions,
  };
});
