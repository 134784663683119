import { Tag } from '@leon-hub/tags';

import type { VBaseButtonProps } from '../../types';

export function getBaseAttributesForButton(
  props: Pick<VBaseButtonProps, 'tag' | 'type' | 'href' | 'target' | 'for'>,
): Record<string, string | undefined> {
  switch (props.tag) {
    case Tag.BUTTON:
      return {
        type: props.type,
      };
    case Tag.A:
      return {
        href: props.href,
        target: props.target,
      };
    case Tag.ROUTER_LINK:
      return {
        to: props.href,
      };
    case Tag.LABEL:
      return props.for ? { for: props.for } : {};
    default:
      return {};
  }
}
