import type { FieldGuards } from '@leon-hub/types';
import {
  bindIsArrayOf,
  isNumber,
  isUndefined,
  isValidInterface,
} from '@leon-hub/guards';

import type { CoreSportResponse } from 'web/src/modules/sportline/types/rest';

import { isCoreSportMarketColumnResponse } from './isCoreSportMarketColumnResponse';
import { isCoreSportMarketGroup } from './isCoreSportMarketGroup';
import { isMinimalWorkingSportResponseFieldGuards } from './isMinimalWorkingSportResponse';

export const isCoreSportResponseFieldGuards: FieldGuards<CoreSportResponse> = {
  id: isMinimalWorkingSportResponseFieldGuards.id,
  name: isMinimalWorkingSportResponseFieldGuards.name,
  weight: isNumber,
  family: isMinimalWorkingSportResponseFieldGuards.family,
  mainMarkets: [isUndefined, bindIsArrayOf(isCoreSportMarketColumnResponse)],
  marketGroups: [isUndefined, bindIsArrayOf(isCoreSportMarketGroup)],
  virtual: isMinimalWorkingSportResponseFieldGuards.virtual,
};

export function isCoreSportResponse(value: unknown): value is CoreSportResponse {
  return isValidInterface<CoreSportResponse>(value, isCoreSportResponseFieldGuards);
}
