import type {
  CoreLeagueResponse,
  CoreRegionResponse,
  CoreSportResponse,
  GetLeagueResponse,
} from 'web/src/modules/sportline/types/rest';

export interface SplitEntityResponse {
  leagueResponse: Maybe<CoreLeagueResponse>;
  regionResponse: Maybe<CoreRegionResponse>;
  sportResponse: Maybe<CoreSportResponse>;
}

export function splitGetLeagueResponse(response?: Maybe<GetLeagueResponse> | false): Maybe<SplitEntityResponse> {
  if (!response) { return null; }

  const { region, ...leagueFields } = response;
  const { sport, ...regionFields } = region;

  const sportResponse: CoreSportResponse = sport;
  const regionResponse: CoreRegionResponse = regionFields;
  const leagueResponse: CoreLeagueResponse = {
    ...leagueFields,
    region: regionResponse,
    sport: sportResponse,
  };

  return {
    leagueResponse,
    regionResponse,
    sportResponse,
  };
}
