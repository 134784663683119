import { SportFamily as SdkSportFamily } from '@leon-hub/api-sdk';

type SportFamily = SdkSportFamily;
// eslint-disable-next-line ts/no-redeclare
const SportFamily = Object.keys(SdkSportFamily)
  .reduce((result, key) => ({
    ...result,
    [key]: SdkSportFamily[key as keyof typeof SportFamily].toLowerCase(),
  }), {}) as typeof SdkSportFamily;

export const sportFamilyValues = Object.values(SportFamily);

export { SportFamily };
