import type { PendingBet } from '@leon-hub/api-sdk';
import { BetType } from '@leon-hub/api-sdk';
import { DateTime } from '@leon-hub/utils';

import type { FormatMoneyFunc } from '@core/money';

import formatOdd from 'web/src/utils/formatOdd';

import type { SlipListHistoryItemData } from '../../../components/SlipListItem/types';
import isCompetitorsTuple from '../../../guards/isCompetitorsTuple';

export interface MapPendingBetToSlipListHistoryItemProps {
  pendingBet: PendingBet;
  expressTitle: string;
  systemTitle: string;
  formatMoney: FormatMoneyFunc;
}

function getPendingBetMatchedType(oddsType: string): BetType {
  const status = oddsType.toLowerCase();
  if (status === 'system') {
    return BetType.SYSTEM;
  }
  if (status === 'combi') {
    return BetType.EXPRESS;
  }
  return BetType.SINGLE;
}

export default function mapPendingBetToSlipListHistoryItemData({
  pendingBet,
  expressTitle,
  systemTitle,
  formatMoney,
}: MapPendingBetToSlipListHistoryItemProps): SlipListHistoryItemData {
  const {
    betId,
    itemsQuantity,
    outcomeSr,
    eventNameParts,
    combiStatus,
    isEnabledForCashout,
    odds,
    oddsStr,
    oddsType,
    stake,
    acceptDate,
    isLive,
    cashoutAmount,
  } = pendingBet;
  const betType = getPendingBetMatchedType(oddsType || '');
  const competitors = isCompetitorsTuple(eventNameParts) ? eventNameParts : undefined;
  const namesMap = {
    [BetType.SINGLE]: `${eventNameParts.join(' ').trim()}`,
    [BetType.EXPRESS]: expressTitle,
    [BetType.SYSTEM]: systemTitle,
  };
  return {
    id: betId || 0,
    eventName: competitors ? undefined : namesMap[betType],
    competitors,
    odd: oddsStr ?? formatOdd(odds || 0),
    marketName: betType === BetType.SINGLE ? `${oddsType}` : '',
    runnerName: betType === BetType.SYSTEM ? `${combiStatus}` : `${outcomeSr}`,
    itemsQuantity: itemsQuantity ?? undefined,
    stake: formatMoney(stake || 0),
    cashOutAvailable: !!isEnabledForCashout,
    cashoutAmount,
    stakeAmount: stake,
    isLive,
    betType,
    eventDate: acceptDate
      ? `${DateTime.withTimeStamp(acceptDate).toDateTime()}`
      : undefined,
  };
}
