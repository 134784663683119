<script setup lang="ts">
import type { VListProps } from '../../types';
import { useVList } from '../../composables/useVList';
import { ListType } from '../../enums';

import $style from '../../styles/common.module.scss';

const props = withDefaults(defineProps<VListProps>(), {
  listType: ListType.SEPARATE,
});

useVList(props);
</script>

<template>
  <ul v-auto-id="'VList'"
    :class="{
      [$style['list']]: true,
      [$style[`list--${listType}`]]: listType !== ListType.SEPARATE,
    }"
  >
    <slot />
  </ul>
</template>
