import type { Runner } from 'web/src/modules/sportline/types';
import type { CoreMarketRunnerResponse } from 'web/src/modules/sportline/types/rest';
import { SelectionTag } from 'web/src/modules/sportline/enums';
import { BetlineSelectionTag } from 'web/src/modules/sportline/enums/rest';
import { extractRunnerStatus } from 'web/src/modules/sportline/utils/rest/pre-build';

type ExtractRunnerStatusProperties = Parameters<typeof extractRunnerStatus>[1];

export interface CreateRunnerProperties extends ExtractRunnerStatusProperties {
  eventId: string;
  marketId: string;
  handicap?: string;
}

const selectionTagMap = {
  [BetlineSelectionTag.YES]: SelectionTag.YES,
  [BetlineSelectionTag.NO]: SelectionTag.NO,
  [BetlineSelectionTag.UNDER]: SelectionTag.UNDER,
  [BetlineSelectionTag.OVER]: SelectionTag.OVER,
  [BetlineSelectionTag.ODD]: SelectionTag.ODD,
  [BetlineSelectionTag.EVEN]: SelectionTag.EVEN,
  [BetlineSelectionTag.HOME]: SelectionTag.HOME,
  [BetlineSelectionTag.DRAW]: SelectionTag.DRAW,
  [BetlineSelectionTag.AWAY]: SelectionTag.AWAY,
  [BetlineSelectionTag.NEITHER]: SelectionTag.NEITHER,
  [BetlineSelectionTag.BOTH]: SelectionTag.BOTH,
  [BetlineSelectionTag.COMPETITOR]: SelectionTag.COMPETITOR,
  [BetlineSelectionTag.RANGE]: SelectionTag.RANGE,
  [BetlineSelectionTag.SCORE]: SelectionTag.SCORE,
  [BetlineSelectionTag.OTHER]: SelectionTag.OTHER,
  [BetlineSelectionTag.HOMEDRAW]: SelectionTag.HOMEDRAW,
  [BetlineSelectionTag.HOMEAWAY]: SelectionTag.HOMEAWAY,
  [BetlineSelectionTag.DRAWAWAY]: SelectionTag.DRAWAWAY,
};

export function createRunner(
  runnerResponse: CoreMarketRunnerResponse,
  properties: CreateRunnerProperties,
): Readonly<Runner> {
  const { eventId, marketId } = properties;

  return {
    eventId,
    marketId,
    id: String(runnerResponse.id),
    name: runnerResponse.name,
    value: runnerResponse.priceStr || runnerResponse.price.toFixed(2),
    price: runnerResponse.price,
    status: extractRunnerStatus(runnerResponse, properties),
    column: runnerResponse.c,
    row: runnerResponse.r,
    handicap: runnerResponse.handicap,
    tags: (runnerResponse.tags ?? [])
      .map((tag) => selectionTagMap[tag])
      .filter((tag?: Optional<SelectionTag>): tag is SelectionTag => !!tag),
  };
}
