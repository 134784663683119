import type { Theme } from '@leon-hub/api-sdk';
import { SlipTypeId } from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import type { AnalyticsComposable } from '@core/analytics';

import type {
  BetsYMFormatUpdatedType,
} from 'web/src/modules/slip/submodules/place-bet/types/BetsYMFormatType';

import type {
  BetsYMFormatType,
  BetYMFormatDataEventIdType,
  TrackingPayload,
} from '../types';
import checkBetPlaceType from './checkBetPlaceType';

export interface AdditionalTrackingPayload {
  theme: Theme;
  analyticsService: AnalyticsComposable;
  useDefaultBet: boolean;
  amountBet: number | false;
  currentValue: number;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function betPlaceSuccessYMTrigger({
  slipEntries,
  liveEventsIds,
  slipType,
  priceChangePolicy,
}: TrackingPayload, {
  theme,
  analyticsService,
  useDefaultBet,
  amountBet,
  currentValue,
}: AdditionalTrackingPayload): void {
  const mode = theme.toLowerCase();

  for (const entry of slipEntries) {
    const { isMaxStake, fastBets, result } = entry;
    if (result.betId && slipType) {
      const betPlaceEvents = result.slipInfo?.entries ?? [];
      const betsYMFormat: BetsYMFormatType = [];
      const betsYMFormatFullIdent: BetsYMFormatType = [];
      const betsYMFormatFullIdentUpdated: BetsYMFormatUpdatedType = [];
      const betTransferItems = result.betTransfer?.items ?? [];

      for (const item of betTransferItems) {
        const {
          sportName,
          regionName,
          leagueName,
          eventName,
          marketName,
          runnerName,
          eventId,
        } = item;

        if (sportName && regionName && leagueName && eventName && marketName && runnerName && eventId) {
          betsYMFormat.push({
            [sportName]: {
              [regionName]: {
                [leagueName]: {
                  [eventName]: {
                    [marketName]: runnerName,
                  },
                },
              },
            },
          });

          betsYMFormatFullIdent.push({
            [sportName]: {
              [leagueName]: {
                [eventName]: {
                  [eventId]: {
                    [marketName]: runnerName,
                  },
                },
              },
            },
          });
        }

        if (sportName && leagueName && eventName && eventId && marketName && runnerName) {
          const matchingEvent = betPlaceEvents.find((e) => e.event === eventId);

          if (matchingEvent) {
            betsYMFormatFullIdentUpdated.push({
              [sportName]: {
                [leagueName]: {
                  [eventName]: {
                    [eventId]: {
                      [marketName]: {
                        [matchingEvent.market]: {
                          [runnerName]: matchingEvent.runner,
                        },
                      },
                    },
                  },
                },
              },
            });
          }
        }
      }

      const betYMFormatDataIDs: BetYMFormatDataEventIdType = [];

      const entries = result.slipInfo?.entries ?? [];

      for (const item of entries) {
        const event = item.event.toString();
        const market = item.market.toString();
        const runner = item.runner.toString();

        if (event && market && runner) {
          betYMFormatDataIDs.push({
            [event]: {
              [market]: runner,
            },
          });
        }
      }

      const amount = !useDefaultBet || currentValue !== amountBet ? 'false' : amountBet.toString();
      const listSlipTypesId = slipEntries.flatMap((item) => item.result.slipInfo?.slipTypes.map((el) => el.typeId));
      const isEachSingle = listSlipTypesId.every((el) => el === SlipTypeId.SINGLE);

      analyticsService.push(AnalyticsEvent.Z_SUBMIT_SLIP_OK, {
        submitSlipData: {
          multiodinar: isEachSingle ? slipEntries.length > 1 : false,
          mode,
          ok: {
            [result.betId]: {
              slipType: {
                [slipType]: checkBetPlaceType(betPlaceEvents, liveEventsIds),
              },
              bets: betsYMFormat,
            },
          },
          maxStake: isMaxStake || false,
          fastBets: fastBets || false,
          autoUpdateCoeff: priceChangePolicy,
          defaultStake: {
            feature: useDefaultBet ? 'on' : 'off',
            amountOfStake: amount,
          },
        },
        submitSlipDataFullIdent: {
          [slipType]: betsYMFormatFullIdent,
        },
        submitSlipDataFull: {
          [checkBetPlaceType(betPlaceEvents, liveEventsIds)]: betsYMFormat,
        },
        submitSlipDataID: betYMFormatDataIDs,
        submitSlipDataFullIdentUpdated: {
          [slipType]: betsYMFormatFullIdentUpdated,
        },
      });
    }
  }
}
