import type { ComputedRef, MaybeRef, Ref } from 'vue';
import {
  computed,
  inject,
  toRef,
} from 'vue';

import type { MarketTypeSign } from 'web/src/modules/sportline/enums';
import type { MarketsColumn } from 'web/src/modules/sportline/types';
import type { SelectMarketTypeSettings, SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';

import type { ProvidedSelectedColumnMap } from '../types';
import { ProvideSelectedColumnKey } from '../constants';

interface UseInjectSelectedColumnProps {
  basisKey: MaybeRef<SportlineFragmentBasisKey>;
}

interface UseInjectSelectedColumnComposable {
  selectedColumnId: ComputedRef<Maybe<string>>;
  selectedColumnSize: ComputedRef<Maybe<number>>;
  selectedColumnSign: ComputedRef<Maybe<MarketTypeSign>>;
  columns: ComputedRef<MarketsColumn[]>;
}

export function useInjectSelectedColumn(
  props: UseInjectSelectedColumnProps,
): UseInjectSelectedColumnComposable {
  // do not inject settings for blocks without sport info
  const basisKey: Ref<SportlineFragmentBasisKey> = toRef(props.basisKey);
  const columnMap = toRef(inject<ProvidedSelectedColumnMap>(ProvideSelectedColumnKey, {}));

  const providedSportColumnSettings = computed<Maybe<SelectMarketTypeSettings>>(() => {
    if (!basisKey.value) {
      return null;
    }

    return columnMap.value[basisKey.value] ?? null;
  });

  const selectedColumnId = computed(() => (providedSportColumnSettings.value?.selectedColumnId ?? null));
  const selectedColumnSize = computed(() => (providedSportColumnSettings.value?.selectedColumnSize ?? null));
  const selectedColumnSign = computed(() => (providedSportColumnSettings.value?.selectedColumnSign ?? null));
  const columns = computed(() => (providedSportColumnSettings.value?.columns ?? []));

  return {
    selectedColumnId,
    selectedColumnSize,
    selectedColumnSign,
    columns,
  };
}
