import type {
  Ref,
  Slots,
} from 'vue';
import {
  computed,
  onMounted,
  ref,
} from 'vue';

import { hasSlot } from '@leon-hub/utils';

import type { VBaseButtonProps } from '../types';
import { getBaseAttributesForButton } from './utils/getBaseAttributesForButton';
import { getIconComponentForButton } from './utils/getIconComponentForButton';

export interface BaseButtonComposable {
  isAnimated: Ref<boolean>;
  mainTag: Ref<HTMLElement | undefined>;
  iconComponent: Ref<ReturnType<typeof getIconComponentForButton>>;
  isIconOnly: Ref<boolean>;
  isIconRight: Ref<boolean>;
  computedAttributes: Ref<Record<string, string | undefined>>;
  onTouchStart(): void;
  onTouchEnd(): void;
  focusOnElement(): void;
}

export function useBaseButton(props: VBaseButtonProps, slots: Slots): BaseButtonComposable {
  const isAnimated = ref(false);

  const mainTag = ref<HTMLElement>();
  const iconComponent = computed(() => getIconComponentForButton(props));
  const hasDefaultSlot = computed(() => hasSlot(slots, 'default'));
  const hasIconSlot = computed(() => hasSlot(slots, 'icon'));
  const isIconOnly = computed(() => !props.label && !hasDefaultSlot.value && (!!props.iconName || hasIconSlot.value));
  const isIconRight = computed(() => !!props.iconName && !!props.iconRight);
  const computedAttributes = computed(() => getBaseAttributesForButton(props));

  const focusOnElement = (): void => {
    if (mainTag.value && ('focus' in mainTag.value)) {
      mainTag.value.focus();
    }
  };

  onMounted(() => {
    if (props.isAutofocus) {
      focusOnElement();
    }
  });

  function onTouchStart(): void {
    isAnimated.value = true;
  }

  function onTouchEnd(): void {
    isAnimated.value = false;
  }

  return {
    mainTag,
    isAnimated,
    iconComponent,
    isIconOnly,
    isIconRight,
    computedAttributes,
    onTouchStart,
    onTouchEnd,
    focusOnElement,
  };
}
