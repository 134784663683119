import type { MaybeRef, Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { SportlineEvent, SportlineEventId } from 'web/src/modules/sportline/types';
import type { CheckMaybe } from 'web/src/modules/sportline/types/utils';

export function useSportlineEventId<E extends Maybe<SportlineEvent>>(
  sportlineEvent: MaybeRef<E>,
): Ref<CheckMaybe<E, SportlineEventId>> {
  const sportlineEventRef = toRef(sportlineEvent);
  return computed(() => (sportlineEventRef.value?.id ?? null) as CheckMaybe<E, SportlineEventId>);
}
