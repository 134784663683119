import { isUndefined } from '@leon-hub/guards';

import type {
  SportEventLiveStatusInfo,
  SportEventMatchProgress,
  SportlineEventIncidentStatistics,
  SportlineEventIncidentStatisticsInfo,
  SportlineEventPeriod,
  SportlineEventPeriodsInfo,
} from 'web/src/modules/sportline/types';
import type {
  LiveStatusIncidentStatistics,
  LiveStatusResponse,
  ParseLiveStatusOptions,
} from 'web/src/modules/sportline/types/rest';
import { SportlineEventPeriodType } from 'web/src/modules/sportline/enums';

import FullProgressLiveStatusParseStrategy from './FullProgressLiveStatusParseStrategy';
import LiveStatusParseStrategy from './LiveStatusParseStrategy';

export function createSportEventLiveStatusInfo(
  liveStatus: LiveStatusResponse,
  options?: ParseLiveStatusOptions,
): SportEventLiveStatusInfo {
  const strategy = options?.useFullProgress
    ? new FullProgressLiveStatusParseStrategy(liveStatus)
    : new LiveStatusParseStrategy(liveStatus);

  const matchProgress: SportEventMatchProgress = {
    stage: strategy.getStage(),
    totalScore: strategy.getTotalScore(),
    totalScoreDetails: strategy.getTotalScoreDetails(),
    innings: strategy.getInnings(),
    penaltyScore: strategy.getPenaltyScore(),
    currentSetScore: strategy.getCurrentSetScore(),
    mainTime: strategy.getMainTime(),
    additionalTime: strategy.getAdditionalTime(),
    timeDirection: strategy.getTimeDirection(),
  };

  return { matchProgress };
}

export function createSportlineEventPeriodsInfo(liveStatus: LiveStatusResponse): SportlineEventPeriodsInfo {
  const {
    periodDurationMin,
    numberOfPeriods,
    additionalPeriodDurationMin,
    numberOfAdditionalPeriods,
  } = liveStatus;

  const mainPeriods = periodDurationMin
    ? Array.from({ length: numberOfPeriods ?? 0 }, (_, index): SportlineEventPeriod => ({
        type: SportlineEventPeriodType.Main,
        number: index + 1,
        duration: periodDurationMin,
      }))
    : [];

  const additionalPeriods = additionalPeriodDurationMin
    ? Array.from({ length: numberOfAdditionalPeriods ?? 0 }, (_, index): SportlineEventPeriod => ({
        type: SportlineEventPeriodType.Additional,
        number: index + 1,
        duration: additionalPeriodDurationMin,
      }))
    : [];

  if (!mainPeriods.length && !additionalPeriods.length) { return {}; }

  return {
    periods: [...mainPeriods, ...additionalPeriods],
  };
}

function createSportlineEventIncidentStatistics(statistics?: Maybe<LiveStatusIncidentStatistics>): SportlineEventIncidentStatistics {
  if (!statistics) { return {}; }
  return {
    corners: statistics.corners,
    redCards: statistics.redCards,
    yellowCards: statistics.yellowCards,
    yellowRedCards: statistics.yellowRedCards,
    currentSuspensionCount: statistics.currentSuspensionCount,
  };
}

export function createSportlineEventIncidentStatisticsInfo(liveStatus: LiveStatusResponse): SportlineEventIncidentStatisticsInfo {
  const { homeStatistics, awayStatistics } = liveStatus;

  const host = createSportlineEventIncidentStatistics(homeStatistics);
  const guest = createSportlineEventIncidentStatistics(awayStatistics);

  if (!isUndefined(host.currentSuspensionCount) || !isUndefined(guest.currentSuspensionCount)) {
    const hostSuspensions = host.currentSuspensionCount ?? 0;
    const guestSuspensions = guest.currentSuspensionCount ?? 0;

    if (guestSuspensions > hostSuspensions) {
      host.powerPlay = guestSuspensions - hostSuspensions;
    } else if (hostSuspensions > guestSuspensions) {
      guest.powerPlay = hostSuspensions - guestSuspensions;
    }
  }

  return {
    incidentStatistics: { host, guest },
  };
}
