<script lang="ts" setup>
import { computed } from 'vue';

import type { IconNameType } from '@leon-hub/icons';
import {
  IconSize,
} from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VIcon } from '@components/v-icon';

import type { VBadgeEmits, VBadgeProps } from '../../types';
import { BadgeKind, BadgePosition, BadgeSize } from '../../enums';

const props = withDefaults(defineProps<VBadgeProps>(), {
  label: '',
  kind: BadgeKind.DEFAULT,
  position: BadgePosition.DEFAULT,
  iconName: '',
});
const emit = defineEmits<VBadgeEmits>();

const iconProperties = computed<VIconProps | undefined>(() => (
  props.iconName
    ? {
        name: props.iconName as IconNameType,
        size: IconSize.SIZE_12,
      }
    : undefined
));

function onClick(event: Event): void {
  emit('click', event);
}
</script>

<template>
  <div v-auto-id="'Badge'"
    :class="{
      [$style.badge]: true,
      [$style[`badge--kind-${kind}`]]: kind !== BadgeKind.DEFAULT,
      [$style[`badge--position-${position}`]]: position !== BadgePosition.DEFAULT,
      [$style[`badge--size-${size}`]]: size !== BadgeSize.DEFAULT,
      [$style['badge--active']]: isActive,
    }"
    @click="onClick"
  >
    <VIcon
      v-if="props.iconName"
      v-bind="iconProperties"
      :class="$style.badge__icon"
    />
    <span
      v-if="label"
      :class="$style.badge__label"
      :title="label"
    >
      {{ label }}
    </span>
  </div>
</template>

<style lang="scss" module>
$positions: (
  left: (
    margin-right: 4px,
  ),
  right: (
    margin-left: 8px,
  ),
  top: (
    transform: translateY(-50%),
  ),
);

.badge {
  $self: &;

  @include medium\12\16;

  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  height: 16px;
  padding: 0 4px;
  vertical-align: middle;
  color: var(--Layer1);
  cursor: default;
  background-color: var(--TextPrimary);
  border-radius: 8px;

  &--active {
    color: var(--Highlight);
    background-color: var(--TextDefault);
  }

  &--kind {
    &-chat {
      color: var(--BrandText);
      background-color: var(--ErrorDefault);

      &#{$self}--active {
        color: var(--BrandText);
        background-color: var(--ErrorHighlight);
      }
    }

    &-square {
      @include bold\9\12\02\caps;

      height: 12px;
      font-family: 'Roboto Condensed', sans-serif;
      color: var(--Layer2);
      background-color: var(--TextDefault);
      border-radius: 2px;

      &-highlight {
        @include bold\9\12\02\caps;

        height: 12px;
        font-family: 'Roboto Condensed', sans-serif;
        color: var(--TextDefault);
        background-color: var(--Highlight);
        border-radius: 2px;
      }

      &-light {
        @include bold\9\12\02\caps;

        height: 12px;
        font-family: 'Roboto Condensed', sans-serif;
        color: var(--DLayer2);
        background-color: var(--DTextDefault);
        border-radius: 2px;
      }

      &-error {
        @include bold\9\12\02\caps;

        height: 12px;
        font-family: 'Roboto Condensed', sans-serif;
        color: var(--White);
        background-color: var(--ErrorDefault);
        border-radius: 2px;
      }

      &-white {
        @include bold\9\12\02\caps;

        height: 12px;
        font-family: 'Roboto Condensed', sans-serif;
        color: var(--Black);
        background-color: var(--White);
        border-radius: 2px;
      }

      &-invert {
        @include bold\9\12\02\caps;

        height: 12px;
        font-family: 'Roboto Condensed', sans-serif;
        color: var(--BadgeColorInvert);
        background-color: var(--BadgeBgInvert);
        border-radius: 2px;
      }
    }

    &-accent {
      color: var(--DBrandText);
      background: linear-gradient(150deg, #892eff 12.02%, #5a3eff 88.34%);
    }

    &-white {
      @include bold\9\12\02\caps;

      height: 12px;
      font-family: 'Roboto Condensed', sans-serif;
      color: var(--DLayer2);
      background-color: var(--DTextDefault);
      border-radius: 2px;
    }

    &-green {
      color: var(--BrandDefault);
      background-color: var(--BrandDefaultOpacity02);
    }

    &-disabled {
      color: var(--TextSecondary);
      background-color: var(--Highlight);
    }
  }

  &--position {
    @each $name, $config in $positions {
      &-#{$name} {
        @each $key, $value in $config {
          #{$key}: $value;
        }
      }
    }
  }

  &--size-large {
    height: 20px;

    &#{$self}--kind-green,
    &#{$self}--kind-disabled, {
      @include medium\11\16\-02\caps;

      border-radius: 5px;
    }
  }

  & + & {
    margin-left: 4px;
  }

  &__inner {
    position: relative;
    top: calc(50%);
    display: flex;
    align-items: center;
    height: 100%;
    line-height: normal;
    transform: translateY(-50%);
  }

  &__icon {
    margin-right: 4px;
  }

  &__label {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }
}
</style>
