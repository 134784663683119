import type { InjectionKey, Ref } from 'vue';

import type {
  IconNameType,
  OptionalAnyIconName,
} from '@leon-hub/icons';
import type { Maybe } from '@leon-hub/types';
import {
  isBoolean,
  isFunction,
  isNull,
  isObject,
  isString,
} from '@leon-hub/guards';

import type { BadgeKind } from '@components/badge';
import type { ButtonKind } from '@components/buttons';
import type { SwitchKind } from '@components/switch';

import type { ListItemAccordionKind, ListItemExtendedIconBackground, ListItemExtendedKind, ListItemExtendedSubtitleColor, ListItemSuffixAlign, ListType } from '../enums';

type Refonly<T> = {
  [P in keyof T]: Ref<T[P]>;
};

export interface VListAccordionSharedState {
  openItem: string | null;
  independentItems: boolean;
}

export interface VListItemAccordionEmits {
  (e: 'click', value: boolean): void;
  (e: 'click-disabled', value: boolean): void;
}

export interface VListProps {
  listType?: ListType;
  independentItems?: boolean;
  isRewardCenter?: boolean;
}

export interface VListItemInnerExpose {
  scrollIntoView(): void;
}

export interface VListItemAccordionProps {
  id: string;
  title: string;
  badgeLabel?: string;
  counter?: string;
  countryCode?: string;
  haveExpand?: boolean;
  haveProgress?: boolean;
  haveSwitcher?: boolean;
  href?: string;
  iconCustomStyles?: string;
  iconName?: IconNameType;
  iconSuffixName?: IconNameType;
  isActive?: boolean;
  isBigMargin?: boolean;
  isDone?: boolean;
  isNew?: boolean;
  isSwitchChecked?: boolean;
  isSwitchDisabled?: boolean;
  isTitleCentered?: boolean;
  noBackground?: boolean;
  noHover?: boolean;
  noWrap?: boolean;
  open?: boolean;
  progressValue?: number;
  suffixAlign?: ListItemSuffixAlign;
  suffixText?: string;
  switchKind?: SwitchKind;
  type?: ListItemAccordionKind;
  isDynamicOpen?: boolean;
  isInsideList?: boolean;
}

export interface VListItemEmits {
  (e: 'click', event: MouseEvent): void;
  (e: 'change-switcher', value: boolean): void;
  (e: 'click-switcher', event: KeyboardEvent): void;
  (e: 'click-button', value: boolean): void;
}

export interface VListItemProps {
  iconName?: OptionalAnyIconName;
  countryCode?: string;
  isNew?: boolean;
  title?: string;
  isTitleCentered?: boolean;
  badgeLabel?: string;
  badgeKind?: BadgeKind;
  badgeIcon?: OptionalAnyIconName;
  counter?: string;
  haveExpand?: boolean;
  haveSwitcher?: boolean;
  switchKind?: SwitchKind;
  isSwitchChecked?: boolean;
  isSwitchDisabled?: boolean;
  suffixAlign?: ListItemSuffixAlign;
  noHover?: boolean;
  iconSuffixName?: IconNameType;
  isActive?: boolean;
  href?: string;
  progressValue?: number;
  noWrap?: boolean;
  haveProgress?: boolean;
  iconCustomStyles?: string;
  suffixText?: string;
  isBigMargin?: boolean;
  iconColored?: boolean;
  buttonLabel?: string;
  buttonKind?: ButtonKind;
  isRewardCenter?: boolean;
  customIconSrc?: string;
}

export interface VListItemInnerEmits {
  (e: 'click', event: MouseEvent): void;
  (e: 'change-switcher', value: boolean): void;
  (e: 'click-switcher', event: Event): void;
  (e: 'click-prefix'): void;
  (e: 'click-default'): void;
  (e: 'click-suffix'): void;
  (e: 'click-button'): void;
}

export interface VListItemInnerProps {
  badgeLabel?: string;
  badgeKind?: BadgeKind;
  badgeIcon?: OptionalAnyIconName;
  buttonLabel?: string;
  buttonKind?: ButtonKind;
  counter?: string;
  haveExpand?: boolean;
  haveProgress?: boolean;
  haveSwitcher?: boolean;
  isRewardCenter?: boolean;
  href?: string;
  iconColored?: boolean;
  iconCustomStyles?: string;
  iconName?: OptionalAnyIconName;
  iconSuffixName?: IconNameType;
  isNew?: boolean;
  isSwitchChecked?: boolean;
  isSwitchDisabled?: boolean;
  isTitleCentered?: boolean;
  noWrap?: boolean;
  progressValue?: number;
  suffixAlign?: ListItemSuffixAlign;
  suffixText?: string;
  switchKind?: SwitchKind;
  title?: string;
  customIconSrc?: string;
}

export interface VListItemInnerSlots {
  prefix(props: Record<string, never>): void;
  inner(props: Record<string, never>): void;
  additional(props: Record<string, never>): void;
}

export interface VListItemAccordionExpose {
  scrollIntoView(): void;
}

export interface VListItemAccordionSlots {
  inner?(props: Record<string, never>): void;
  content?(props: { isOpen?: Maybe<boolean>; toggle(force?: boolean): void }): void;
}

export interface VListItemExtendedProps {
  haveExpand?: boolean;
  iconName?: IconNameType;
  iconBackground?: ListItemExtendedIconBackground;
  isChecked?: boolean;
  isDisabled?: boolean;
  kind?: ListItemExtendedKind;
  subTitleFirst?: string;
  subTitleFirstColor?: ListItemExtendedSubtitleColor;
  subTitleSecond?: string;
  title?: Maybe<string>;
  hideUnavailableMessage?: boolean;
}

export interface VListItemExtendedEmits {
  (e: 'click'): void;
  (e: 'click-checkbox'): void;
}

export interface VListItemExtendedSlots {
  'logo-image'?(props: Record<string, never>): void;
}

export interface VListAccordionSharedStateProps
  extends Readonly<Refonly<Omit<VListAccordionSharedState, 'independentItems'>>> {
  independentItems: boolean;
  updateOpenItem(value: VListAccordionSharedState['openItem']): void;
}

export function isListAccordionSharedStateProps(value: unknown): value is VListAccordionSharedStateProps {
  return isObject(value) && isObject(value.openItem)
    && (isString(value.openItem.value) || isNull(value.openItem.value))
    && isBoolean(value.independentItems)
    && isFunction(value.updateOpenItem);
}

export const accordionSharedState = Symbol('accordion state') as InjectionKey<VListAccordionSharedStateProps>;
