import { ServerDate } from '@core/app-settings';

import type { LiveStatusTime } from 'web/src/modules/sportline/types/rest';
import { MatchProgressTimeDirection } from 'web/src/modules/sportline/enums';
import { isStaticMatchProgressTime } from 'web/src/modules/sportline/utils';

export default function getTimeCorrection(
  createAt: number | undefined,
  direction: MatchProgressTimeDirection,
): Required<LiveStatusTime> {
  if (!createAt || isStaticMatchProgressTime(direction)) {
    return {
      minutes: 0,
      seconds: 0,
    };
  }

  const correctionMS = ServerDate.now() - createAt;
  const correctionTotalSeconds = Math.floor(correctionMS / 1000);
  const correctionMinutes = Math.floor(correctionTotalSeconds / 60);
  const correctionSeconds = correctionTotalSeconds - correctionMinutes * 60;
  const correctionDirection = direction === MatchProgressTimeDirection.Direct ? 1 : -1;

  return {
    minutes: correctionDirection * correctionMinutes,
    seconds: correctionDirection * correctionSeconds,
  };
}
