import { logger } from '@leon-hub/logging';

import type { GetChangesInput } from 'web/src/modules/sportline/services/api/types';
import type { GetSportEventsChangesResponse } from 'web/src/modules/sportline/types/rest';
import { assertUnknownGetEventsChangesResponse } from 'web/src/modules/sportline/asserts/rest';
import useSportlineApi from 'web/src/modules/sportline/services/api/useSportlineApi';
import {
  filterCorruptedEventsInChangesResponse,
  logCorruptedEventsIds,
} from 'web/src/modules/sportline/utils/response';

export default async function getEventsChanges(input: GetChangesInput): Promise<GetSportEventsChangesResponse> {
  const apiClient = useSportlineApi();
  const response = await apiClient.getChanges(input);
  try {
    assertUnknownGetEventsChangesResponse(response);
    return filterCorruptedEventsInChangesResponse(response, logCorruptedEventsIds);
  } catch (error) {
    logger.error('apiClient getEventsChanges response corrupted', error);
    // always return response for user
    return { totalCount: 0, data: [] };
  }
}
