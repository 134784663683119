import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type { SiteConfigDocument } from '@core/site-config';

interface UseBetlineSettingsProps {
  sportlineBlock: Ref<Optional<SiteConfigDocument['sportLine']>>;
}

interface UseBetlineSettingsComposable {
  isLiveOutrightsEnabled: Ref<boolean>;
  isExtraLiveEnabled: Ref<boolean>;
  isUpcomingEventsEnabled: Ref<boolean>;
}

export function useBetlineSettings(
  props: UseBetlineSettingsProps,
): UseBetlineSettingsComposable {
  const { sportlineBlock } = props;

  const isLiveOutrightsEnabled = computed(() => !!sportlineBlock.value?.liveOutrightsEnabled);
  const isExtraLiveEnabled = process.env.VUE_APP_FEATURE_VIRTUAL_SPORT_ENABLED
    ? computed<boolean>(() => !!sportlineBlock.value?.virtualSportLiveEventsEnabled)
    : ref(false);
  const isUpcomingEventsEnabled = computed(() => !!sportlineBlock.value?.displayPrematchEventsInLiveEnabled);

  return {
    isLiveOutrightsEnabled,
    isExtraLiveEnabled,
    isUpcomingEventsEnabled,
  };
}
