import type {
  GetHeadlineMatchesChangesResponse,
  GetHeadlineMatchesResponse,
  GetMergedHeadlineEventsResponse,
  GetMergedHeadlineMatchesChangesResponse,
} from 'web/src/modules/sportline/types/rest';

export function isGetHeadlineMatchesChangesResponseCheck(
  value: GetHeadlineMatchesResponse | GetHeadlineMatchesChangesResponse,
): value is GetHeadlineMatchesChangesResponse {
  if ('events' in value && !!value.events) {
    return 'data' in value.events;
  }

  if ('live' in value && !!value.live && 'prematch' in value && !!value.prematch) {
    return 'data' in value.live && 'data' in value.prematch;
  }

  throw new Error('Unexpected headline response');
}

export function isGetMergedHeadlineMatchesResponseCheck(
  value: GetHeadlineMatchesResponse,
): value is GetMergedHeadlineEventsResponse {
  const eventsKey: keyof GetMergedHeadlineEventsResponse = 'events';
  return eventsKey in value && !!value[eventsKey];
}

export function isGetMergedHeadlineMatchesChangesResponseCheck(
  value: GetHeadlineMatchesChangesResponse,
): value is GetMergedHeadlineMatchesChangesResponse {
  const eventsKey: keyof GetMergedHeadlineMatchesChangesResponse = 'events';
  return eventsKey in value && !!value[eventsKey];
}
