import {
  isNumber,
  isString,
  isUndefined,
  isValidInterface,
} from '@leon-hub/guards';

import type {
  LiveStatusIncidentStatistics,
  LiveStatusProgress,
  LiveStatusResponse,
  LiveStatusTime,
} from 'web/src/modules/sportline/types/rest';
import { LiveStatusTimeDirection } from 'web/src/modules/sportline/enums/rest';

export function isLiveStatusTimeDirection(value: unknown): value is LiveStatusTimeDirection {
  return isString(value)
    && Object.values(LiveStatusTimeDirection).includes(value as LiveStatusTimeDirection);
}

export function isLiveStatusTime(value: unknown): value is LiveStatusTime {
  return isValidInterface<LiveStatusTime>(value, {
    minutes: [isUndefined, isNumber],
    seconds: [isUndefined, isNumber],
  });
}

export function isLiveStatusProgress(value: unknown): value is LiveStatusProgress {
  return isValidInterface<LiveStatusProgress>(value, {
    score: [isUndefined, isString],
    time: [isUndefined, isLiveStatusTime],
    additionalTime: [isUndefined, isLiveStatusTime],
    timeDirection: [isUndefined, isLiveStatusTimeDirection],
  });
}

export function isLiveStatusIncidentStatistics(value: unknown): value is LiveStatusIncidentStatistics {
  return isValidInterface<LiveStatusIncidentStatistics>(value, {
    corners: [isUndefined, isNumber],
    redCards: [isUndefined, isNumber],
    yellowCards: [isUndefined, isNumber],
    yellowRedCards: [isUndefined, isNumber],
    currentSuspensionCount: [isUndefined, isNumber],
  });
}

export function isLiveStatusResponse(value: unknown): value is LiveStatusResponse {
  return isValidInterface<LiveStatusResponse>(value, {
    stage: isString,
    progress: [isUndefined, isString],
    score: isString,
    setScores: [isUndefined, isString],
    penaltyScore: [isUndefined, isString],
    fullProgress: [isUndefined, isLiveStatusProgress],
    createAt: [isUndefined, isNumber],
    homeStatistics: [isUndefined, isLiveStatusIncidentStatistics],
    awayStatistics: [isUndefined, isLiveStatusIncidentStatistics],
  });
}
