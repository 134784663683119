import { onComponentActivated, onComponentDeactivated } from "@leon-hub/vue-utils";
import { GraphQLWebSocketService, WebSocketAccessRole, WebSocketSubscription, isSubscriptionAllowed } from "@leon-hub/websocket";
import { watch } from "vue";
import { useIsLoggedIn } from "@core/auth";
import { defineStore } from "pinia";
import { useWebSocketsConfig } from "@core/site-config";
function useMockedWebSockets() {
  const result = {
    unsubscribe: () => {
    }
  };
  return {
    subscribe: () => result,
    subscribeAuthorized: () => result,
    subscribeAnonymous: () => result
  };
}
const useWebsocketSubscriptionsStore = /* @__PURE__ */ defineStore("websocket-subscriptions", () => {
  const webSocketService = new GraphQLWebSocketService();
  const { isLoggedIn } = useIsLoggedIn();
  const { isEnabled } = useWebSocketsConfig();
  let subscriptions = [];
  function isSubscriptionAllowed2(subscription) {
    return subscription.isAllowed({
      isLoggedIn: isLoggedIn.value
    });
  }
  function subscribeSubscription(subscription) {
    if (!webSocketService.isSubscribed(subscription)) {
      webSocketService.subscribe(subscription);
    }
  }
  function unsubscribeSubscription(subscription) {
    if (webSocketService.isSubscribed(subscription)) {
      webSocketService.unsubscribe(subscription);
    }
  }
  function subscribeWs(subscription) {
    if (isSubscriptionAllowed2(subscription)) {
      subscribeSubscription(subscription);
    } else {
      unsubscribeSubscription(subscription);
    }
  }
  function subscribe(input) {
    subscriptions.push(input);
    subscribeWs(input);
  }
  function unsubscribe(input) {
    unsubscribeSubscription(input);
    subscriptions = subscriptions.filter((subscription) => subscription.method !== input.method);
  }
  function init() {
    watch(isLoggedIn, (newValue) => {
      for (const subscription of subscriptions) {
        subscribeWs(subscription);
        if (subscription.isPollingMustBeCalledOnLogin() && newValue) {
          subscription.callPollingRequest();
        }
      }
    }, {
      immediate: true
    });
    watch(isEnabled, (newValue) => {
      webSocketService.setEnabled(newValue);
    }, {
      immediate: true
    });
  }
  init();
  return {
    subscribe,
    unsubscribe
  };
});
function useWebSockets() {
  if (process.env.VUE_APP_RENDERING_SSR) {
    return useMockedWebSockets();
  }
  const socketService = useWebsocketSubscriptionsStore();
  function subscribe({
    method,
    onMessage,
    isEnabled,
    variables,
    polling,
    access
  }) {
    const { isLoggedIn } = useIsLoggedIn();
    const subscription = new WebSocketSubscription({
      method,
      onMessage,
      variables,
      polling: polling ? {
        ...polling,
        timeout: polling.timeout.value
      } : void 0,
      access,
      isWsEnabled: isEnabled.value
    });
    function handlePolling() {
      if (isSubscriptionAllowed(isLoggedIn.value, access)) {
        subscription.startPollingRequest();
      } else {
        subscription.stopPollingRequest();
      }
    }
    let unwatchPollingTimeout;
    if (polling == null ? void 0 : polling.timeout) {
      unwatchPollingTimeout = watch(polling.timeout, (newValue) => {
        subscription.setPollingTimeout(newValue);
      }, {
        immediate: true
      });
    }
    let unwatchLoggedIn = watch(isLoggedIn, handlePolling, { immediate: true });
    let unwatchEnabled = watch(isEnabled, (newValue) => {
      subscription.setIsWsEnabled(newValue);
      socketService.unsubscribe(subscription);
      socketService.subscribe(subscription);
      if (!newValue) {
        handlePolling();
      }
    }, {
      immediate: true
    });
    function unsubscribe() {
      subscription.stopPollingRequest();
      socketService.unsubscribe(subscription);
      unwatchPollingTimeout == null ? void 0 : unwatchPollingTimeout();
      unwatchLoggedIn == null ? void 0 : unwatchLoggedIn();
      unwatchEnabled == null ? void 0 : unwatchEnabled();
      unwatchPollingTimeout = void 0;
      unwatchLoggedIn = void 0;
      unwatchEnabled = void 0;
    }
    return {
      unsubscribe
    };
  }
  function subscribeAuthorized(options) {
    return subscribe({
      ...options,
      access: WebSocketAccessRole.AUTHORIZED
    });
  }
  function subscribeAnonymous(options) {
    return subscribe({
      ...options,
      access: WebSocketAccessRole.ANONYMOUS
    });
  }
  return {
    subscribe,
    subscribeAuthorized,
    subscribeAnonymous
  };
}
function useLifecycleWebSockets(options) {
  const websockets = useWebSockets();
  let socketSubscribeResult;
  onComponentActivated(() => {
    socketSubscribeResult = websockets.subscribe(options);
  });
  onComponentDeactivated(() => {
    socketSubscribeResult == null ? void 0 : socketSubscribeResult.unsubscribe();
    socketSubscribeResult = void 0;
  });
}
function useAuthorizedLifecycleWebSockets(options) {
  useLifecycleWebSockets({
    ...options,
    access: WebSocketAccessRole.AUTHORIZED
  });
}
export {
  useAuthorizedLifecycleWebSockets,
  useLifecycleWebSockets,
  useWebSockets
};
