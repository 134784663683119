import type { MaybeRef, Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { SportSegmentSettings } from 'web/src/modules/sportline/types/settings';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';

export function useSportSegmentsSettings(sportSegmentsSettingsId: MaybeRef<string>): Ref<SportSegmentSettings> {
  const id = toRef(sportSegmentsSettingsId);
  const settings = toRef(useSportlineSettingsStore(), 'sportSegmentsSettings');
  return computed<SportSegmentSettings>(() => settings.value[id.value] || settings.value.default);
}
