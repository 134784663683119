function isDataUrl(value: string) {
  return value.startsWith('data:');
}

function isAbsoluteUrl(value: string) {
  return /^(https?:)?\/\/\w/.test(value);
}

export function normalizeImagePath(appUrl: string, imageRawSource: string): string {
  if ((process.env.VUE_APP_RENDERING !== 'csr' && !process.env.VUE_APP_STORYBOOK)
    || process.env.VUE_APP_PLATFORM === 'cordova') {
    return imageRawSource;
  }
  return isAbsoluteUrl(imageRawSource) || isDataUrl(imageRawSource)
    ? imageRawSource
    : `${appUrl === '' ? '' : new URL(appUrl).origin}/${imageRawSource.replace(/^\/+/, '')}`;
}
