import type { SlipEntryId, SlipEntrySelectedIdentifier } from '../submodules/slip-info/types';
import { slipEntryIdentifierSeparator } from '../constants';

export default function convertSlipEntryIdToObject(id: SlipEntryId): SlipEntrySelectedIdentifier {
  const [event, market, runner] = id.split(slipEntryIdentifierSeparator);
  return {
    event: Number(event),
    market: Number(market),
    runner: Number(runner),
  };
}
