import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
} from 'vue';

import type { FastBetsSettings } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';
import { useCustomerDataStore } from '@core/customer';
import { useCurrency, useFormatMoney } from '@core/money';
import { useSiteConfigStore } from '@core/site-config';

import type { FastMoneyChoiceOption } from 'web/src/components/FastMoneyChoice';

import type { BetSlipEditFastBetItem } from '../types';
import { BetInputErrorKind } from '../enums';
import {
  getFastBetInputError,
  requestFastBetValuesSave,
  requestSaveShowMaxFastBet,
} from '../utils';

const emptyFastBetsSettings: FastBetsSettings = { enabled: false, showMax: false, values: [] };

const useFastBetsStore = defineStore('fastBets', () => {
  const siteConfigStore = useSiteConfigStore();

  const customerDataStore = useCustomerDataStore();

  const api = useGraphqlClient();

  const { updateLocalCustomerData } = customerDataStore;

  const { currency } = useCurrency();

  const formatMoney = useFormatMoney();

  const slipSiteConfig = toRef(siteConfigStore, 'slipBlock');

  const customerFastBetsSettings = toRef(customerDataStore, 'fastBets');

  const fastBetInputItems = ref<BetSlipEditFastBetItem[]>([]);

  const fastBetsSaved = ref<boolean>(false);

  const defaultFastBetsSettings = computed<FastBetsSettings>(() => {
    const defaultSettings = slipSiteConfig.value?.fastBets;
    if (!defaultSettings) {
      return emptyFastBetsSettings;
    }

    const defaultCurrencySettings = defaultSettings.values.find((item) => item.currency === currency.value);
    const defaultFastBets = defaultCurrencySettings?.values ?? [];
    return {
      enabled: defaultFastBets.length > 0,
      values: defaultFastBets,
    };
  });

  const fastBetsSettings = computed<FastBetsSettings>(
    () => customerFastBetsSettings.value ?? defaultFastBetsSettings.value,
  );

  const fastBetsValues = computed<number[]>(() => fastBetsSettings.value.values);

  const fastBetsOptions = computed<FastMoneyChoiceOption[]>(() => fastBetsValues.value.map((value) => ({
    label: formatMoney(value, {
      useGrouping: false,
      currency: currency.value,
    }),
    value,
  })));

  const isFastBetsEnabled = computed<boolean>(() => fastBetsSettings.value.enabled ?? false);

  const fastBetsShowMax = computed<boolean>(() => fastBetsSettings.value.showMax ?? false);

  const maxAvailableFastBet = computed<number | null>(() => fastBetsSettings.value.maxValue || null);

  const minAvailableFastBet = computed<number>(() => fastBetsSettings.value.minValue || 1);

  const setFastBetInputItems = (items: BetSlipEditFastBetItem[]): void => {
    fastBetInputItems.value = items;
  };

  const setFastBetsSaved = (isSaved: boolean): void => {
    fastBetsSaved.value = isSaved;
  };

  const initFastBetInputValues = (): void => {
    const items = fastBetsValues.value.map((value) => ({
      value: `${value}`,
    }));
    setFastBetInputItems(items);
  };

  const checkFastBetInputValues = (): void => {
    const result = fastBetInputItems.value.map((item, index) => {
      const error = getFastBetInputError({
        input: item.value,
        max: maxAvailableFastBet.value,
        min: minAvailableFastBet.value,
        isRequired: true,
      });
      const changed = (error === BetInputErrorKind.NONE) && item.value !== `${fastBetsValues.value[index]}`;
      return {
        error,
        value: item.value,
        changed,
      };
    });
    setFastBetInputItems(result);
  };

  const handleFastBetValuesChange = (values: string[]): void => {
    setFastBetsSaved(false);
    const result = fastBetInputItems.value.map((item, index) => ({
      ...item,
      value: values[index],
    }));
    setFastBetInputItems(result);
    checkFastBetInputValues();
  };

  const saveFastBetValues = async (settings: FastBetsSettings): Promise<void> => {
    updateLocalCustomerData({ fastBets: settings });
    const response = await requestFastBetValuesSave(api, fastBetsSettings.value);
    if (response?.result === 'OK') {
      setFastBetsSaved(true);
    }
  };

  const handleFastBetValuesBlur = (): void => {
    checkFastBetInputValues();
    const validValues = fastBetInputItems.value.map((item, index) => {
      if (item.error === BetInputErrorKind.NONE) {
        return Number(item.value);
      }
      return fastBetsValues.value[index];
    });

    if (JSON.stringify(validValues) === JSON.stringify(fastBetsValues.value)) {
      return;
    }

    const settingsToSave: FastBetsSettings = { ...fastBetsSettings.value, values: validValues };
    void saveFastBetValues(settingsToSave);
  };

  const handleFastBetMaxSwitch = async (): Promise<void> => {
    const currentSettings = fastBetsSettings.value;
    const nextSettings = {
      ...currentSettings,
      showMax: !currentSettings.showMax,
    };
    updateLocalCustomerData({ fastBets: nextSettings });
    try {
      await requestSaveShowMaxFastBet(api, nextSettings.showMax);
    } catch (error) {
      updateLocalCustomerData({ fastBets: currentSettings });
      throw error;
    }
  };

  const clearFastBetInputValues = (): void => {
    setFastBetInputItems([]);
  };

  return {
    fastBetsOptions,
    isFastBetsEnabled,
    fastBetsShowMax,
    maxAvailableFastBet,
    minAvailableFastBet,
    fastBetInputItems,
    fastBetsSaved,
    handleFastBetValuesChange,
    handleFastBetValuesBlur,
    handleFastBetMaxSwitch,
    initFastBetInputValues,
    clearFastBetInputValues,
  };
});

export default useFastBetsStore;
