import { CountryCode, CountryFlag } from '../enums';

export const countryToFlagMapping: Record<CountryCode, CountryFlag> = {
  [CountryCode.AD]: CountryFlag.AD,
  [CountryCode.AE]: CountryFlag.AE,
  [CountryCode.AF]: CountryFlag.AF,
  [CountryCode.AG]: CountryFlag.AG,
  [CountryCode.AI]: CountryFlag.AI,
  [CountryCode.AL]: CountryFlag.AL,
  [CountryCode.AM]: CountryFlag.AM,
  [CountryCode.AO]: CountryFlag.AO,
  [CountryCode.AQ]: CountryFlag.AQ,
  [CountryCode.AR]: CountryFlag.AR,
  [CountryCode.AS]: CountryFlag.AS,
  [CountryCode.AT]: CountryFlag.AT,
  [CountryCode.AU]: CountryFlag.AU,
  [CountryCode.AW]: CountryFlag.AW,
  [CountryCode.AX]: CountryFlag.AX,
  [CountryCode.AZ]: CountryFlag.AZ,
  [CountryCode.BA]: CountryFlag.BA,
  [CountryCode.BB]: CountryFlag.BB,
  [CountryCode.BD]: CountryFlag.BD,
  [CountryCode.BE]: CountryFlag.BE,
  [CountryCode.BF]: CountryFlag.BF,
  [CountryCode.BG]: CountryFlag.BG,
  [CountryCode.BH]: CountryFlag.BH,
  [CountryCode.BI]: CountryFlag.BI,
  [CountryCode.BJ]: CountryFlag.BJ,
  [CountryCode.BL]: CountryFlag.BL,
  [CountryCode.BM]: CountryFlag.BM,
  [CountryCode.BN]: CountryFlag.BN,
  [CountryCode.BO]: CountryFlag.BO,
  [CountryCode.BQ]: CountryFlag.BQ,
  [CountryCode.BR]: CountryFlag.BR,
  [CountryCode.BS]: CountryFlag.BS,
  [CountryCode.BT]: CountryFlag.BT,
  [CountryCode.BV]: CountryFlag.BV,
  [CountryCode.BW]: CountryFlag.BW,
  [CountryCode.BY]: CountryFlag.BY,
  [CountryCode.BZ]: CountryFlag.BZ,
  [CountryCode.CA]: CountryFlag.CA,
  [CountryCode.CC]: CountryFlag.CC,
  [CountryCode.CD]: CountryFlag.CD,
  [CountryCode.CF]: CountryFlag.CF,
  [CountryCode.CG]: CountryFlag.CG,
  [CountryCode.CH]: CountryFlag.CH,
  [CountryCode.CI]: CountryFlag.CI,
  [CountryCode.CK]: CountryFlag.CK,
  [CountryCode.CL]: CountryFlag.CL,
  [CountryCode.CM]: CountryFlag.CM,
  [CountryCode.CN]: CountryFlag.CN,
  [CountryCode.CO]: CountryFlag.CO,
  [CountryCode.CR]: CountryFlag.CR,
  [CountryCode.CU]: CountryFlag.CU,
  [CountryCode.CV]: CountryFlag.CV,
  [CountryCode.CW]: CountryFlag.CW,
  [CountryCode.CX]: CountryFlag.CX,
  [CountryCode.CY]: CountryFlag.CY,
  [CountryCode.CZ]: CountryFlag.CZ,
  [CountryCode.DE]: CountryFlag.DE,
  [CountryCode.DJ]: CountryFlag.DJ,
  [CountryCode.DK]: CountryFlag.DK,
  [CountryCode.DM]: CountryFlag.DM,
  [CountryCode.DO]: CountryFlag.DO,
  [CountryCode.DZ]: CountryFlag.DZ,
  [CountryCode.EC]: CountryFlag.EC,
  [CountryCode.EE]: CountryFlag.EE,
  [CountryCode.EG]: CountryFlag.EG,
  [CountryCode.EH]: CountryFlag.EH,
  [CountryCode.ER]: CountryFlag.ER,
  [CountryCode.ES]: CountryFlag.ES,
  [CountryCode.ES_CT]: CountryFlag.ES_CT,
  [CountryCode.EN]: CountryFlag.GB,
  [CountryCode.ET]: CountryFlag.ET,
  [CountryCode.EU]: CountryFlag.EU,
  [CountryCode.FI]: CountryFlag.FI,
  [CountryCode.FJ]: CountryFlag.FJ,
  [CountryCode.FK]: CountryFlag.FK,
  [CountryCode.FM]: CountryFlag.FM,
  [CountryCode.FO]: CountryFlag.FO,
  [CountryCode.FR]: CountryFlag.FR,
  [CountryCode.GA]: CountryFlag.GA,
  [CountryCode.GB]: CountryFlag.GB,
  [CountryCode.GB_ENG]: CountryFlag.GB_ENG,
  [CountryCode.GB_NIR]: CountryFlag.GB_NIR,
  [CountryCode.GB_SCT]: CountryFlag.GB_SCT,
  [CountryCode.GB_WLS]: CountryFlag.GB_WLS,
  [CountryCode.GD]: CountryFlag.GD,
  [CountryCode.GE]: CountryFlag.GE,
  [CountryCode.GF]: CountryFlag.GF,
  [CountryCode.GG]: CountryFlag.GG,
  [CountryCode.GH]: CountryFlag.GH,
  [CountryCode.GI]: CountryFlag.GI,
  [CountryCode.GL]: CountryFlag.GL,
  [CountryCode.GM]: CountryFlag.GM,
  [CountryCode.GN]: CountryFlag.GN,
  [CountryCode.GP]: CountryFlag.GP,
  [CountryCode.GQ]: CountryFlag.GQ,
  [CountryCode.GR]: CountryFlag.GR,
  [CountryCode.GS]: CountryFlag.GS,
  [CountryCode.GT]: CountryFlag.GT,
  [CountryCode.GU]: CountryFlag.GU,
  [CountryCode.GW]: CountryFlag.GW,
  [CountryCode.GY]: CountryFlag.GY,
  [CountryCode.HK]: CountryFlag.HK,
  [CountryCode.HM]: CountryFlag.HM,
  [CountryCode.HN]: CountryFlag.HN,
  [CountryCode.HR]: CountryFlag.HR,
  [CountryCode.HT]: CountryFlag.HT,
  [CountryCode.HU]: CountryFlag.HU,
  [CountryCode.ID]: CountryFlag.ID,
  [CountryCode.IE]: CountryFlag.IE,
  [CountryCode.IL]: CountryFlag.IL,
  [CountryCode.IM]: CountryFlag.IM,
  [CountryCode.IN]: CountryFlag.IN,
  [CountryCode.IO]: CountryFlag.IO,
  [CountryCode.IQ]: CountryFlag.IQ,
  [CountryCode.IR]: CountryFlag.IR,
  [CountryCode.IS]: CountryFlag.IS,
  [CountryCode.IT]: CountryFlag.IT,
  [CountryCode.JE]: CountryFlag.JE,
  [CountryCode.JM]: CountryFlag.JM,
  [CountryCode.JO]: CountryFlag.JO,
  [CountryCode.JP]: CountryFlag.JP,
  [CountryCode.KE]: CountryFlag.KE,
  [CountryCode.KG]: CountryFlag.KG,
  [CountryCode.KH]: CountryFlag.KH,
  [CountryCode.KI]: CountryFlag.KI,
  [CountryCode.KM]: CountryFlag.KM,
  [CountryCode.KN]: CountryFlag.KN,
  [CountryCode.KP]: CountryFlag.KP,
  [CountryCode.KR]: CountryFlag.KR,
  [CountryCode.KW]: CountryFlag.KW,
  [CountryCode.KY]: CountryFlag.KY,
  [CountryCode.KZ]: CountryFlag.KZ,
  [CountryCode.LA]: CountryFlag.LA,
  [CountryCode.LB]: CountryFlag.LB,
  [CountryCode.LC]: CountryFlag.LC,
  [CountryCode.LI]: CountryFlag.LI,
  [CountryCode.LK]: CountryFlag.LK,
  [CountryCode.LR]: CountryFlag.LR,
  [CountryCode.LS]: CountryFlag.LS,
  [CountryCode.LT]: CountryFlag.LT,
  [CountryCode.LU]: CountryFlag.LU,
  [CountryCode.LV]: CountryFlag.LV,
  [CountryCode.LY]: CountryFlag.LY,
  [CountryCode.MA]: CountryFlag.MA,
  [CountryCode.MC]: CountryFlag.MC,
  [CountryCode.MD]: CountryFlag.MD,
  [CountryCode.ME]: CountryFlag.ME,
  [CountryCode.MF]: CountryFlag.MF,
  [CountryCode.MG]: CountryFlag.MG,
  [CountryCode.MH]: CountryFlag.MH,
  [CountryCode.MK]: CountryFlag.MK,
  [CountryCode.ML]: CountryFlag.ML,
  [CountryCode.MM]: CountryFlag.MM,
  [CountryCode.MN]: CountryFlag.MN,
  [CountryCode.MO]: CountryFlag.MO,
  [CountryCode.MP]: CountryFlag.MP,
  [CountryCode.MQ]: CountryFlag.MQ,
  [CountryCode.MR]: CountryFlag.MR,
  [CountryCode.MS]: CountryFlag.MS,
  [CountryCode.MT]: CountryFlag.MT,
  [CountryCode.MU]: CountryFlag.MU,
  [CountryCode.MV]: CountryFlag.MV,
  [CountryCode.MW]: CountryFlag.MW,
  [CountryCode.MX]: CountryFlag.MX,
  [CountryCode.MY]: CountryFlag.MY,
  [CountryCode.MZ]: CountryFlag.MZ,
  [CountryCode.NA]: CountryFlag.NA,
  [CountryCode.NC]: CountryFlag.NC,
  [CountryCode.NE]: CountryFlag.NE,
  [CountryCode.NF]: CountryFlag.NF,
  [CountryCode.NG]: CountryFlag.NG,
  [CountryCode.NI]: CountryFlag.NI,
  [CountryCode.NL]: CountryFlag.NL,
  [CountryCode.NO]: CountryFlag.NO,
  [CountryCode.NP]: CountryFlag.NP,
  [CountryCode.NR]: CountryFlag.NR,
  [CountryCode.NU]: CountryFlag.NU,
  [CountryCode.NZ]: CountryFlag.NZ,
  [CountryCode.OM]: CountryFlag.OM,
  [CountryCode.PA]: CountryFlag.PA,
  [CountryCode.PE]: CountryFlag.PE,
  [CountryCode.PF]: CountryFlag.PF,
  [CountryCode.PG]: CountryFlag.PG,
  [CountryCode.PH]: CountryFlag.PH,
  [CountryCode.PK]: CountryFlag.PK,
  [CountryCode.PL]: CountryFlag.PL,
  [CountryCode.PM]: CountryFlag.PM,
  [CountryCode.PN]: CountryFlag.PN,
  [CountryCode.PR]: CountryFlag.PR,
  [CountryCode.PS]: CountryFlag.PS,
  [CountryCode.PT]: CountryFlag.PT,
  [CountryCode.PW]: CountryFlag.PW,
  [CountryCode.PY]: CountryFlag.PY,
  [CountryCode.QA]: CountryFlag.QA,
  [CountryCode.RE]: CountryFlag.RE,
  [CountryCode.RO]: CountryFlag.RO,
  [CountryCode.RS]: CountryFlag.RS,
  [CountryCode.RU]: CountryFlag.RU,
  [CountryCode.RW]: CountryFlag.RW,
  [CountryCode.SA]: CountryFlag.SA,
  [CountryCode.SB]: CountryFlag.SB,
  [CountryCode.SC]: CountryFlag.SC,
  [CountryCode.SYC]: CountryFlag.SC,
  [CountryCode.SD]: CountryFlag.SD,
  [CountryCode.SE]: CountryFlag.SE,
  [CountryCode.SG]: CountryFlag.SG,
  [CountryCode.SH]: CountryFlag.SH,
  [CountryCode.SI]: CountryFlag.SI,
  [CountryCode.SJ]: CountryFlag.SJ,
  [CountryCode.SK]: CountryFlag.SK,
  [CountryCode.SL]: CountryFlag.SL,
  [CountryCode.SM]: CountryFlag.SM,
  [CountryCode.SN]: CountryFlag.SN,
  [CountryCode.SO]: CountryFlag.SO,
  [CountryCode.SR]: CountryFlag.SR,
  [CountryCode.SS]: CountryFlag.SS,
  [CountryCode.ST]: CountryFlag.ST,
  [CountryCode.SV]: CountryFlag.SV,
  [CountryCode.SX]: CountryFlag.SX,
  [CountryCode.SY]: CountryFlag.SY,
  [CountryCode.SZ]: CountryFlag.SZ,
  [CountryCode.TC]: CountryFlag.TC,
  [CountryCode.TD]: CountryFlag.TD,
  [CountryCode.TF]: CountryFlag.TF,
  [CountryCode.TG]: CountryFlag.TG,
  [CountryCode.TH]: CountryFlag.TH,
  [CountryCode.TJ]: CountryFlag.TJ,
  [CountryCode.TK]: CountryFlag.TK,
  [CountryCode.TL]: CountryFlag.TL,
  [CountryCode.TM]: CountryFlag.TM,
  [CountryCode.TN]: CountryFlag.TN,
  [CountryCode.TO]: CountryFlag.TO,
  [CountryCode.TR]: CountryFlag.TR,
  [CountryCode.TT]: CountryFlag.TT,
  [CountryCode.TV]: CountryFlag.TV,
  [CountryCode.TW]: CountryFlag.TW,
  [CountryCode.TZ]: CountryFlag.TZ,
  [CountryCode.SW]: CountryFlag.TZ,
  [CountryCode.UA]: CountryFlag.UA,
  [CountryCode.UG]: CountryFlag.UG,
  [CountryCode.UM]: CountryFlag.UM,
  [CountryCode.UN]: CountryFlag.UN,
  [CountryCode.US]: CountryFlag.US,
  [CountryCode.UY]: CountryFlag.UY,
  [CountryCode.UZ]: CountryFlag.UZ,
  [CountryCode.VA]: CountryFlag.VA,
  [CountryCode.VC]: CountryFlag.VC,
  [CountryCode.VE]: CountryFlag.VE,
  [CountryCode.VG]: CountryFlag.VG,
  [CountryCode.VI]: CountryFlag.VI,
  [CountryCode.VN]: CountryFlag.VN,
  [CountryCode.VU]: CountryFlag.VU,
  [CountryCode.WF]: CountryFlag.WF,
  [CountryCode.WS]: CountryFlag.WS,
  [CountryCode.XK]: CountryFlag.XK,
  [CountryCode.YE]: CountryFlag.YE,
  [CountryCode.YT]: CountryFlag.YT,
  [CountryCode.ZA]: CountryFlag.ZA,
  [CountryCode.ZM]: CountryFlag.ZM,
  [CountryCode.ZW]: CountryFlag.ZW,
  [CountryCode.WORLD]: CountryFlag.WORLD,
  [CountryCode.OLYMPIAD]: CountryFlag.OLYMPIAD,
};
