import type {
  FieldGuards,
} from '@leon-hub/types';
import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';
import {
  bindIsEnumValueType,
  isNull,
  isNumber,
  isString,
  isUndefined,
  isValidInterface,
} from '@leon-hub/guards';

import type { SportDateTimeFilter } from '../types';

export const isSportDateTimeFilterFieldGuards: FieldGuards<SportDateTimeFilter> = {
  activeKickoffPeriod: bindIsEnumValueType(SportFilterKickoffPeriod),
  customDateFrom: [isUndefined, isNull, isNumber],
  customDateTo: [isUndefined, isNull, isNumber],
  sportlineType: [isUndefined, isString, isNumber],
};

export default function isSportDateTimeFilterGuard(value: unknown): value is SportDateTimeFilter {
  return isValidInterface<SportDateTimeFilter>(value, isSportDateTimeFilterFieldGuards);
}
