import type { RouteLocationRaw } from 'vue-router';

import type { AppVueRouter } from '@core/router';

export default async function navigateToLocationsWithCallbacks(
  $router: AppVueRouter,
  location: RouteLocationRaw,
  cbs?: {
    beforeNavigation?(): Promise<void>;
    onAbort?(): void;
  },
): Promise<void> {
  if ($router.canNavigateTo(location)) {
    if (cbs?.beforeNavigation) {
      await cbs?.beforeNavigation();
    }

    await $router.push(location);
  } else if (cbs?.onAbort) {
    cbs?.onAbort();
  }
}
