import type { ComputedRef } from 'vue';
import { computed, toRef } from 'vue';

import { useCustomerDataStore } from '@core/customer';
import { useRootStore } from '@core/root';

interface UseDefaultBetValue {
  defaultBetValue: ComputedRef<number | null>;
}
export default function useDefaultBetValue(): UseDefaultBetValue {
  const customerDataStore = useCustomerDataStore();

  const useStandardBet = toRef(customerDataStore, 'useStandardBet');

  const standardBetAmount = toRef(customerDataStore, 'standardBetAmount');

  const rootStore = useRootStore();

  const slipDefaultAmount = toRef(rootStore, 'slipDefaultAmount');

  const defaultBetValue = computed<number | null>(() => {
    if (useStandardBet.value && standardBetAmount.value) {
      return standardBetAmount.value;
    }
    return slipDefaultAmount.value ?? null;
  });

  return {
    defaultBetValue,
  };
}
