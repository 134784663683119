export function routerLinkClickGuard(mouseEvent: MouseEvent): boolean | undefined {
  // don't redirect with control keys
  if (mouseEvent.metaKey || mouseEvent.altKey || mouseEvent.ctrlKey || mouseEvent.shiftKey) {
    return;
  }
  // don't redirect when preventDefault called
  if (mouseEvent.defaultPrevented) {
    return;
  }
  // don't redirect on right click
  if (mouseEvent.button !== undefined && mouseEvent.button !== 0) {
    return;
  }
  // don't redirect if `target="_blank"`
  const currentTarget = mouseEvent.currentTarget as HTMLLinkElement;
  if (currentTarget && currentTarget.getAttribute) {
    const target = currentTarget.getAttribute('target') || '';
    if (/\b_blank\b/i.test(target)) {
      return;
    }
  }
  // this may be a Weex event which doesn't have this method
  if (mouseEvent.preventDefault) {
    mouseEvent.preventDefault();
  }

  return true;
}
