import type { OkResponse } from '@leon-hub/api-sdk';
import { doSaveShowMaxFastBet } from '@leon-hub/api-sdk';

import type { ApplicationGqlClient } from '@core/app-rest-client';

export default function requestSaveShowMaxFastBet(
  api: ApplicationGqlClient,
  showMax: boolean,
): Promise<Pick<OkResponse, 'result'>> {
  return doSaveShowMaxFastBet(
    api,
    (node) => node.mutations.customer.saveShowMaxFastBet,
    { options: { show: showMax } },
  );
}
